import { useState } from "react";

type UseContractResultType = {
  isContractModal: boolean;
  base64Contract: string;
  isChecked: boolean;
  setIsContractModal(isContractModal: boolean): void;
  setBase64FileContract(base64Contract: string): void;
  setIsChecked(isChecked: boolean): void;
  onCheck(): void;
  onCloseContractModal(): void;
};
type UseContractType = () => UseContractResultType;

const useContractDialogState: UseContractType = () => {
  const [isContractModal, setIsContractModal] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [base64Contract, setBase64FileContract] = useState<string>("");
  const onCheck = () => setIsChecked(!isChecked);
  const onCloseContractModal = () => {
    setIsChecked(false);
    setIsContractModal(false);
  };

  return {
    isContractModal,
    setIsContractModal,
    isChecked,
    setIsChecked,
    onCheck,
    onCloseContractModal,
    base64Contract,
    setBase64FileContract,
  };
};

export default useContractDialogState;
