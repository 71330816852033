import { CookieNamesEnum, ErrorsEnum } from "@front-packages/dfa-constants";
import { AuthAPI } from "@front-packages/dfa-gql-api";
import { getCookie, setCookie } from "@front-packages/dfa-helpers";
import { RoutesNames } from "routes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { setIsAuth } from "../../store/account";
import { useErrors } from "../errors";
import useGetInfo from "./useGetInfo";

type AuthCheckType = () => Promise<boolean>;
interface IAuthCheck {
  authCheck: AuthCheckType;
}

const useAuthCheck = (): IAuthCheck => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getInfo = useGetInfo();
  const { setError } = useErrors();

  const authCheck: AuthCheckType = async () => {
    const refreshToken = getCookie(CookieNamesEnum.refreshToken);
    const token = getCookie(CookieNamesEnum.token);
    setCookie(CookieNamesEnum.token, "null");
    const { response, error } = await AuthAPI.RefreshAccessToken({ refreshToken });
    if (error) {
      if (error === ErrorsEnum.AccessTokenIsNotExpired) {
        setCookie(CookieNamesEnum.token, token);
        dispatch(setIsAuth(true));
        await getInfo();
        return true;
      }
      setError({ error, key: Math.random() });
      dispatch(setIsAuth(false));
      navigate(RoutesNames.SignIn);
      return false;
    }
    const { accessToken, refreshToken: newRefreshToken } = response;
    setCookie(CookieNamesEnum.refreshToken, newRefreshToken);
    setCookie(CookieNamesEnum.token, accessToken);
    dispatch(setIsAuth(true));
    await getInfo();
    return true;
  };

  return { authCheck };
};

export default useAuthCheck;
