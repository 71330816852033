import { AccountOperationAPI } from "@front-packages/dfa-gql-api";
import { ColumnsType, RowsType } from "Theme";
import { formattingDate, getStatusBadgeProps } from "helpers";
import { useErrors } from "hooks";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setPageLoading } from "store/global";

type Columns = "date" | "sum" | "status";

const useOperationsTable = () => {
  const dispatch = useAppDispatch();
  const { representative, company } = useAppSelector((state) => state.customer);
  const { setError } = useErrors();

  const [count, setCount] = useState<string>("10");
  const [page, setPage] = useState<number>(0);
  const [depositRows, setDepositRows] = useState<RowsType<Columns>>(null);
  const [withdrawRows, setWithdrawRows] = useState<RowsType<Columns>>(null);
  const [depositTotalCount, setDepositTotalCount] = useState<number>(0);
  const [withdrawTotalCount, setWithdrawTotalCount] = useState<number>(0);
  const columns = useRef<ColumnsType<Columns>>([
    {
      label: "Дата",
      field: "date",
      proportion: 4,
      // color: "success",
      // indicatorPosition: "start",
    },
    { label: "сумма", field: "sum", proportion: 2 },
    { label: "статус", field: "status", proportion: 2 },
  ]);

  const getDeposiOrdersList = async () => {
    dispatch(setPageLoading(true));
    const { response, error } = await AccountOperationAPI.GetDepositOrdersList({
      opts: {
        page,
        count: +count,
        representativeID: representative.id,
        clientID: company.id,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setDepositTotalCount(response.totalCount);
      const newRows: RowsType<Columns> = [];
      response.orders.forEach((order) => {
        newRows.push({
          date: formattingDate(order.createdDate, true),
          sum: `+${order.fiatAmount} RUB`,
          status: getStatusBadgeProps(order.depositOrderStatus, "Deposit").label,
        });
      });
      setDepositRows(newRows);
    }
  };
  const getWithdrawOrders = async (): Promise<void> => {
    dispatch(setPageLoading(true));
    const { response, error } = await AccountOperationAPI.GetWithdrawOrdersList({
      opts: { page, count: +count, companyName: company.shortName },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setWithdrawTotalCount(response.count);
      const newRows: RowsType<Columns> = [];
      response.withdrawOrders?.forEach((order) => {
        newRows.push({
          date: formattingDate(order.dateOfFiling, true),
          sum: `-${order.sum} RUB`,
          status: getStatusBadgeProps(order.status, "Withdraw").label,
        });
      });
      setWithdrawRows(newRows);
    }
  };
  console.log(withdrawRows);
  return {
    count,
    setCount,
    page,
    setPage,
    depositRows,
    columns,
    getDeposiOrdersList,
    withdrawRows,
    getWithdrawOrders,
    depositTotalCount,
    withdrawTotalCount,
  };
};

export default useOperationsTable;
// <Table
//               color="success"
//               rowHeight={40}
//               rowSx={{ rowStyle: "white" }}
//               titleRowSx={{ rowHeight: 40 }}
//               onRowClick={goToToken}
//               columns={columns.current}
//               rows={rows}
//             />
