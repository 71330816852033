import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthAPI } from "@front-packages/dfa-gql-api";
import { regCleanPhone } from "@front-packages/dfa-constants";
import { Button, Checkbox, Flex, Input, MFADialog, Text } from "../../../Theme";
import { useAuth, useErrors, useValidate } from "../../../hooks";
import { useAppDispatch } from "../../../store";
import { addNotification } from "../../../store/notifications";
import { RoutesNames } from "../../../routes";
import OfferModal from "./OfferModal";

const Invite: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { state, setState, ...auth } = useAuth();
  const [code, setCode] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");
  const [openOfferModal, setOpenOfferModal] = useState<boolean>(false);
  const [isOfferAgreeChecked, setIsOfferAgreeChecked] = useState<boolean>(false);
  const [isPersDataChecked, setIsPersDataChecked] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [openMFADialog, setOpenMFADialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { passValidInfo, invalidPhone } = useValidate({
    phoneNumber: state.phoneNumber,
    passwords: { mainPass: state.password, verifyPass: state.verifyPass },
  });
  const { setError } = useErrors();

  const closeMFADialog = () => setOpenMFADialog(false);

  const getCodeOrReg = async () => {
    setLoading(true);
    if (isChecked) {
      const isReg = await auth.registration();
      if (isReg) navigate(RoutesNames.SignIn);
    } else {
      const { response, error } = await AuthAPI.Send2FACodeRepresentative({
        phoneNumber: state.phoneNumber.replace(regCleanPhone, ""),
      });
      if (error) setError({ error, key: Math.random() });
      if (response) {
        setOpenMFADialog(true);
        setCode(response?.code);
        setSessionID(response?.sessionID);
      }
    }
    setLoading(false);
  };
  const checkRepresentative = async () => {
    setLoading(true);
    const isCheck = await auth.checkRepresentative(code, sessionID);
    if (typeof isCheck === "boolean" && !isCheck) {
      setSearchParams({ "set-pass": state.phoneNumber });
      setIsChecked(true);
    }
    if (typeof isCheck === "boolean" && isCheck) {
      dispatch(
        addNotification({
          type: "success",
          message:
            "Пользователь уже зарегистрирован, как представитель компании. Войдите в свою учетную запись представителя.",
        })
      );
      navigate(RoutesNames.SignIn);
    }
    if (openMFADialog) closeMFADialog();
    setCode("");
    setLoading(false);
  };
  // const handleEnter = async (e: any, form: "phoneNumber" | "code" | "pass") => {
  //   if (e.keyCode === 13) {
  //     e.preventDefault();
  //     if (form === "phoneNumber") await handlerGetCode();
  //     if (form === "code") await handlerCheckRepresentative();
  //   }
  // };

  useEffect(() => {
    const tel = searchParams.get("set-pass");
    if (tel && tel.length === 18) {
      setState.phoneNumber(tel);
      setIsChecked(true);
    } else setSearchParams("");
  }, []);

  return (
    <>
      <Flex
        sx={{ direction: "column", width: "50%", minWidth: "500px" }}
        adaptiveSX={{
          laptop: { minWidth: "400px" },
          tablet: { minWidth: "300px" },
          mobile: { minWidth: "90%" },
        }}
      >
        <Text color="primary" variant="h1" sx={{ mb: 0.6 }}>
          {isChecked ? "Установка пароля" : "Проверка представителя"}
        </Text>
        <Text sx={{ mb: 3, size: "MD" }}>
          {isChecked ? "Задайте пароль от учетной записи" : "Введите номер телефона"}
        </Text>
        {!isChecked && (
          <Input
            sx={{ mb: 2.2 }}
            type="tel"
            value={state.phoneNumber}
            onChange={setState.phoneNumber}
            error="none"
          />
        )}
        {isChecked && (
          <>
            <Input
              sx={{ mb: 1 }}
              type="password"
              value={state.password}
              placeholder="Введите пароль"
              onChange={setState.password}
              error={passValidInfo.mainPass.message}
            />
            <Input
              sx={{ mb: 1.5 }}
              type="password"
              value={state.verifyPass}
              placeholder="Подтвердите пароль"
              onChange={setState.verifyPass}
              error={passValidInfo.verifyPass.message}
            />
            <Checkbox
              sx={{ mb: 0.5 }}
              name="OfferAgree"
              label="Согласие с офертой"
              checked={isOfferAgreeChecked}
              onChange={
                isOfferAgreeChecked
                  ? () => setIsOfferAgreeChecked(false)
                  : () => setOpenOfferModal(true)
              }
            />
            <Checkbox
              name="PersDataAgree"
              label="Согласие на обработку персональных данных"
              checked={isPersDataChecked}
              onChange={setIsPersDataChecked}
              sx={{ mb: 1 }}
            />
          </>
        )}
        <Button
          onClick={getCodeOrReg}
          disabled={
            !isChecked
              ? !state.phoneNumber || invalidPhone
              : !state.password ||
                !state.verifyPass ||
                passValidInfo.mainPass.invalid ||
                passValidInfo.verifyPass.invalid ||
                !isOfferAgreeChecked ||
                !isPersDataChecked
          }
          loading={loading}
          id="confirmPhoneNumber"
        >
          {isChecked ? "Установить пароль" : "Подтвердить номер телефона"}
        </Button>
      </Flex>
      <MFADialog
        loading={loading}
        open={openMFADialog}
        onClose={closeMFADialog}
        onConfirm={checkRepresentative}
        setCode={setCode}
        code={code}
        descOperation="Выполняется подтверждение номера телефона"
      />
      {openOfferModal && (
        <OfferModal
          open={openOfferModal}
          onAgree={() => setIsOfferAgreeChecked(true)}
          onClose={() => setOpenOfferModal(false)}
        />
      )}
    </>
  );
};

export default Invite;
