const base64ToPdf = (base64: string) => {
  const base64ToArrayBuffer = () => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };
  const arrBuffer = base64ToArrayBuffer();
  return new Blob([arrBuffer], { type: "application/pdf" });
};

export default base64ToPdf;
