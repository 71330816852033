import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const HashtagIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 30)}
        height={getSize(size, 30)}
        fill="none"
      >
        <path
          fill="url(#a)"
          fillOpacity={0.25}
          d="M30 11.25V7.5h-7.5V0h-3.75v7.5h-7.5V0H7.5v7.5H0v3.75h7.5v7.5H0v3.75h7.5V30h3.75v-7.5h7.5V30h3.75v-7.5H30v-3.75h-7.5v-7.5H30Zm-11.25 7.5h-7.5v-7.5h7.5v7.5Z"
        />
        <defs>
          <linearGradient id="a" x1={0} x2={30} y1={30} y2={0} gradientUnits="userSpaceOnUse">
            <stop stopColor="#398151" />
            <stop offset={1} stopColor="#35C691" />
          </linearGradient>
        </defs>
      </svg>
    </Text>
  );
};

export default HashtagIcon;
