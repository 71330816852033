import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const IconChevron: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  bold,
  rotate = "down",
  contrast,
  sx,
}) => {
  let deg = 0;
  if (rotate === "up") deg = 180;
  if (rotate === "left") deg = 90;
  if (rotate === "right") deg = 270;
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        style={{ transform: `rotate(${deg}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={bold ? 4 : 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="6 9 12 15 18 9" />
      </svg>
    </Text>
  );
};

export default IconChevron;
