import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Token, TokenStatusGql } from "@front-packages/dfa-gql-api";
import { Badge, Button, Card, EntityProps, Grid, GridItem } from "Theme";
import { daysRest, formattingDate, getStatusBadgeProps, getAmountWithWordDay } from "helpers";
import { useAppSelector } from "store";
import { RoutesNames } from "routes";
import { InfoIcon } from "Theme/icons";
import { TokenShortType } from "constants/in_lib";
import CreateOrder from "../../components/CreateOrder";
import styles from "./styles";

interface IInvestorTokenCard {
  token: Token;
}

const InvestorTokenCard: React.FC<IInvestorTokenCard> = ({ token }) => {
  const navigate = useNavigate();
  const { pageLoading } = useAppSelector((state) => state.global);
  const {
    id,
    issuer,
    investStartDate,
    investFinishDate,
    ticker,
    status,
    rightsType,
    maxIssueLimit,
  } = token;
  const daysToCompletion = daysRest(investFinishDate);
  const [isCreateOrder, setIsCreateOrder] = useState<boolean>(false);

  const closeCreateOrder = () => setIsCreateOrder(false);
  const openCreateOrder = () => setIsCreateOrder(true);

  return (
    <>
      <Card
        bgWhite
        onClick={(status === TokenStatusGql.Started && openCreateOrder) || undefined}
        sx={styles.cardSx}
        adaptiveSX={styles.cardAdaptiveSx}
      >
        <Grid sx={styles.gridSx} adaptiveSX={styles.gridAdaptivSx}>
          <GridItem gridArea="a">
            <EntityProps isLoading={pageLoading} title="Эмитент" value={issuer} />
          </GridItem>
          <GridItem
            gridArea="b"
            placeSelf="stretch end"
            flex
            flexColumnGap={0.5}
            adaptiveSX={{ laptopL: { placeSelf: "stretch end" } }}
          >
            <EntityProps
              isLoading={pageLoading}
              value={
                <Badge
                  sx={{ minHeight: "30px" }}
                  isLoading={pageLoading}
                  color="info"
                  label={TokenShortType[rightsType] || "не указан"}
                />
              }
            />
            <EntityProps
              isLoading={pageLoading}
              value={
                <Badge
                  isLoading={pageLoading}
                  {...getStatusBadgeProps(status, "Token")}
                  sx={{ minHeight: "30px" }}
                />
              }
            />
          </GridItem>
          <GridItem flex gridArea="c">
            <EntityProps
              isLoading={pageLoading}
              value={
                <Button
                  title="Перейти к информации о выпуске"
                  onClick={() => navigate(`${RoutesNames.ViewIssues}?id=${id}`)}
                  iconEnd={<InfoIcon color="info" />}
                  onlyIcon
                  sx={{ justify: "start" }}
                />
              }
            />
          </GridItem>
          <GridItem gridArea="d">
            <EntityProps isLoading={pageLoading} title="Тикер" value={ticker} />
          </GridItem>
          <GridItem gridArea="e" adaptiveSX={{ tabletL: { placeSelf: "stretch end" } }}>
            <EntityProps
              isLoading={pageLoading}
              title="Дата старта"
              value={investStartDate ? formattingDate(investStartDate) : "Не назначена"}
            />
          </GridItem>
          <GridItem gridArea="f">
            <EntityProps
              isLoading={pageLoading}
              title={daysToCompletion >= 0 ? "До завершения" : "Прошло"}
              value={getAmountWithWordDay(Math.abs(daysToCompletion))}
            />
          </GridItem>
          <GridItem gridArea="g">
            <EntityProps
              isLoading={pageLoading}
              title="Сумма выпуска"
              value={`${maxIssueLimit || 0} RUB`}
            />
          </GridItem>
        </Grid>
        {/* {!isAccountIssuer && ( */}
        {/* <Flex sx={{ columnGap: 1, width: "100%", mt: 1 }}> */}
        {/* TODO: невозможно высчитать, оставил вдруг вернут количество купленых токенов */}
        {/* <LinerProgress */}
        {/*  value={Math.ceil((repaymentAmount / maxIssueLimit) * 100)} */}
        {/*  column */}
        {/*  label="Процент выкупа" */}
        {/*  color={status === "started" ? "info" : "secondary"} */}
        {/* /> */}
        {/* </Flex> */}
        {/* )} */}
      </Card>
      <CreateOrder open={isCreateOrder} onClose={closeCreateOrder} token={token} />
    </>
  );
};

export default InvestorTokenCard;
