/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// import { OrdersAPI, GetRedeemOrderListResponse, RedeemOrder } from "@front-packages/dfa-gql-api";
import { FilterBar, Flex, Paginator } from "../../../Theme";
import useChangeHeader from "../useChangeHeader";
import AssetsCard from "./components/AssetsCard";
import { useErrors } from "../../../hooks";
import { setPageLoading } from "../../../store/global";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Breakpoints } from "../../../constants/in_lib";
import { ISortOption } from "../../../Theme/components/Filterbar";

const Assets: React.FC = () => {
  useChangeHeader("Управление активами", "Назад");
  const { windowWidth } = useAppSelector((state) => state.global);
  const [redeemOrderList, setRedeemOrderList] = useState(null);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<string>("10");
  const { setError } = useErrors();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // TODO: Genapi GetRedeemOrderList delete!
  const getRedeemOrderList = async () => {
    setIsLoading(true);
    // dispatch(setPageLoading(true));
    // const { response, error } = await OrdersAPI.GetRedeemOrderList({
    //   opts: { page, count: +count },
    // });
    // if (response) {
    //   setRedeemOrderList(response);
    // }
    // if (error) setError({ error, key: Math.random() });
    setIsLoading(false);
  };

  useEffect(() => {
    getRedeemOrderList().then(() => dispatch(setPageLoading(false)));
  }, [page, count]);

  const sortOptions: Array<ISortOption> = [
    {
      type: "alpha",
      label: "Название",
      value: {
        normal: "name",
        revert: "nameRevert",
      },
      onClick() {
        console.log("click");
      },
    },
    {
      type: "number",
      label: "Сумма",
      value: {
        normal: "sum",
        revert: "sumRevert",
      },
      onClick() {
        console.log("click");
      },
    },
    {
      type: "any",
      label: "Исполнена",
      value: {
        normal: "date",
        revert: "dateRevert",
      },
      onClick() {
        console.log("click");
      },
    },
    {
      type: "number",
      label: "Количество",
      value: {
        normal: "amount",
        revert: "amountRevert",
      },
      onClick() {
        console.log("click");
      },
    },
  ];

  return (
    <Flex
      sx={{ width: "100%", mt: 3, direction: "column", rowGap: 1, flex: 1 }}
      adaptiveSX={{ laptop: { width: "80%" }, tablet: { width: "100%" } }}
    >
      <FilterBar
        options={sortOptions}
        current="name"
        count={count}
        setCount={setCount}
        isTablet={windowWidth <= Breakpoints.tablet}
      />
      {/* TODO: Genapi order: RedeemOrder */}
      {redeemOrderList?.redeemOrders?.map((order: any) => (
        <AssetsCard
          isLoading={isLoading}
          key={order?.id}
          redeemData={order}
          getRedeemData={getRedeemOrderList}
        />
      ))}
      {!!redeemOrderList?.count && (
        <Paginator
          page={page}
          setPage={setPage}
          amount={Math.ceil(redeemOrderList.count / +count)}
          withInput
        />
      )}
    </Flex>
  );
};

export default Assets;
