import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const CircleArrowIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  rotate,
  contrast,
  sx,
}) => {
  let deg = 0;
  if (rotate === "up") deg = 270;
  if (rotate === "left") deg = 180;
  if (rotate === "down") deg = 90;
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        style={{ transform: `rotate(${deg}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g>
          <rect fill="none" height={getSize(size, 24)} width={getSize(size, 24)} />
        </g>
        <g>
          <path d="M22,12c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12s4.48,10,10,10C17.52,22,22,17.52,22,12z M4,12c0-4.42,3.58-8,8-8 c4.42,0,8,3.58,8,8s-3.58,8-8,8C7.58,20,4,16.42,4,12z M16,12l-4,4l-1.41-1.41L12.17,13H8v-2h4.17l-1.59-1.59L12,8L16,12z" />
        </g>
      </svg>
    </Text>
  );
};

export default CircleArrowIcon;
