import React from "react";
import { MarketOrder, Token } from "@front-packages/dfa-gql-api";
import { v4 as uuidv4 } from "uuid";
import { Badge, Dialog, Flex, InfoCard, RowEntityProps, Text } from "Theme";
import { formattingDate } from "helpers";
import { EncodeObject } from "@cosmjs/proto-signing";
import { useGetChannel } from "hooks";
import SigningDialog from "components/Dialogs/Signing";
import { OrderModalType } from "../../../types";

interface IOrderInfoModalProps {
  token: Token;
  open: boolean;
  onClose(): void;
  marketOrderId: string;
  type: OrderModalType;
  marketOrderData: MarketOrder & { companyName?: string };
  isMyOrder?: boolean;
  isLoading?: boolean;
  queryAfterTx?(): Promise<void>;
}

const OrderInfoModal: React.FC<IOrderInfoModalProps> = ({
  open,
  onClose,
  type,
  token,
  isMyOrder,
  marketOrderId,
  marketOrderData,
  isLoading,
  queryAfterTx,
}) => {
  const isBuy = type === "buy";
  const color = isBuy ? "success" : "warning";
  const { channelId, setChannelId, getChannelId, isChannelIdLoading } = useGetChannel();

  const getMessage = (creator: string): EncodeObject => {
    return {
      typeUrl: "/rr.bcpcore.market.MsgSendSignAndProcess",
      value: {
        creator,
        port: "market",
        channelId,
        timeoutTimestamp: (Date.now() + 60000) * 1000000,
        orderId: marketOrderId,
        ibcId: uuidv4(),
      },
    };
  };

  const onCloseSigningDialog = () => {
    onClose();
    setChannelId("");
  };

  if (channelId && marketOrderId && token?.id)
    return (
      <SigningDialog
        // TODO: точно ли правильно и то и то добавлять?! Если мидл поменяет - убрать tokenID
        ids={{ orderID: marketOrderId, tokenID: token?.id }}
        getMessage={getMessage}
        open={!!channelId}
        close={onCloseSigningDialog}
        queryAfterTx={queryAfterTx}
      />
    );

  return (
    <Dialog
      title={`Заявка на ${isBuy ? "покупку" : "продажу"}`}
      type={color}
      open={open}
      onClose={onClose}
      confirmText="Принять заявку"
      onConfirm={getChannelId}
      loading={isChannelIdLoading}
    >
      <Flex sx={{ direction: "column", rowGap: 2 }}>
        {isMyOrder && <Badge label="Моя заявка" sx={{ maxWidth: "max-content" }} color={color} />}
        <Flex sx={{ direction: "column", rowGap: 0.5 }}>
          <Text variant="h5" color="secondary" sx={{ mb: 1 }}>
            ТОКЕН
          </Text>
          <Flex sx={{ columnGap: 0.5, justify: "space-between" }}>
            <InfoCard
              title="Тикер"
              value={token?.ticker}
              type="diamond"
              color={color}
              sx={{ minWidth: "50%" }}
              textMaxWidth="80%"
            />
            <InfoCard
              title="Номинал"
              value={token?.nominal}
              type="toll"
              color={color}
              sx={{ minWidth: "50%" }}
            />
          </Flex>
          <Flex sx={{ columnGap: 0.5, justify: "space-between" }}>
            <InfoCard
              title="Ставка дохода"
              value={token?.interest}
              type="percent"
              color={color}
              sx={{ minWidth: "50%" }}
            />

            <InfoCard
              title="дата погашения"
              value={formattingDate(token?.redemptionDate)}
              type="event"
              color={color}
              sx={{ minWidth: "50%" }}
            />
          </Flex>
        </Flex>
        <Flex sx={{ direction: "column", rowGap: 1, mb: 2 }}>
          <Text variant="h5" color="secondary">
            ЗАЯВКА
          </Text>
          <RowEntityProps
            title="Количество токенов"
            value={marketOrderData.tokenAmount}
            isLoading={isLoading}
          />
          <RowEntityProps
            title="Цена за единицу"
            value={marketOrderData.fiatPrice}
            isLoading={isLoading}
          />
          <RowEntityProps
            title="Сумма заявки"
            value={marketOrderData.fiatPrice * marketOrderData.tokenAmount}
            isLoading={isLoading}
          />
          <RowEntityProps
            title="Действие заявки до"
            value={formattingDate(marketOrderData.expireDate)}
            isLoading={isLoading}
          />
          {!isMyOrder && (
            <RowEntityProps
              title="Заявка создана"
              value={marketOrderData.companyName || ""}
              isLoading={isLoading}
            />
          )}
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default OrderInfoModal;
