import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const IconArrow: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  contrast,
  rotate = "down",
  sx,
}) => {
  let deg = 0;
  if (rotate === "up") deg = 270;
  if (rotate === "down") deg = 90;
  if (rotate === "left") deg = 180;
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        style={{ transform: `rotate(${deg}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="currentColor"
        enableBackground="new 0 0 24 24"
      >
        <rect fill="none" height="24" width="24" />
        <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" />
      </svg>
    </Text>
  );
};

export default IconArrow;
