import React, { useState } from "react";
import { Bip39 } from "@cosmjs/crypto";
import secp256k1 from "secp256k1";
import { Dialog, Input, MFADialog } from "Theme";
import { downloadTextHelper } from "helpers";
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { delay } from "@front-packages/dfa-helpers";
import { useAppDispatch, useAppSelector } from "store";
import useDialogState from "components/Dialogs/useDialogState";
import { addNotification } from "store/notifications";
import checkWordlist from "./checkWordlist";

interface IRestoreKeyModalProps {
  isOpen: boolean;
  handleCloseRestoreModal(): void;
}

const RestoreKeyModal: React.FC<IRestoreKeyModalProps> = ({ isOpen, handleCloseRestoreModal }) => {
  const dispatch = useAppDispatch();
  const { company, representative } = useAppSelector((state) => state.customer);
  const { state, getMfa, setState, cleanMfa } = useDialogState();
  const { mfa, loading } = state;
  const [errorText, setErrorText] = useState("");
  const [mnemo, setMnemo] = useState<any>("");
  const onChangeMnemo = (value: string) => {
    if (errorText) setErrorText("");
    setMnemo(value);
  };
  const onClose = () => {
    setMnemo("");
    setErrorText("");
    handleCloseRestoreModal();
  };

  const getWallet = async () => {
    const wallet = DirectSecp256k1HdWallet.fromMnemonic(mnemo);
    const checkWallet = async () => {
      await delay(100);
      if (!wallet) await checkWallet();
    };
    await checkWallet();
    return wallet;
  };

  const onGetMfa = async () => {
    const arrMnemoStrings = mnemo.split(" ");
    if (arrMnemoStrings?.length >= 24 && checkWordlist(arrMnemoStrings)) {
      await getMfa("representative", representative.id, "RestoreKeysFromMnemo(FRONT)");
    } else setErrorText("Неверная мнемо фраза. Попробуйте ввести другую");
  };

  const onConfirm = async () => {
    const privKey: any = Bip39.decode(mnemo);
    const wallet = await getWallet();
    const [acc] = await wallet.getAccounts();
    if (acc?.address === company?.wallet?.address) {
      const pubKey = secp256k1.publicKeyCreate(privKey);
      downloadTextHelper("dfa_private_key", `${privKey}:false`);
      downloadTextHelper("dfa_public_key", `${pubKey}:false`);
      cleanMfa();
      onClose();
      dispatch(
        addNotification({
          message: `Ключ восстановлен`,
          type: "success",
          position: "right",
        })
      );
    } else {
      cleanMfa();
      // setMnemo("");
      setErrorText("Неверная мнемо фраза. Попробуйте ввести другую");
    }
  };
  if (mfa) {
    return (
      <MFADialog
        open
        onClose={cleanMfa}
        descOperation="Выполняется операция по созданию заявки на деактивацию учетной записи"
        code={mfa.code}
        setCode={setState.code}
        onConfirm={onConfirm}
      />
    );
  }
  return (
    <Dialog
      open={isOpen}
      title="Восстановление приватного ключа"
      subTitle="Введите вашу мнемо-фразу, которую Вы скопировали при прохождении онбординга"
      onConfirm={onGetMfa}
      onClose={onClose}
      disabled={!mnemo || loading || mnemo?.split(" ")?.length < 24}
      loading={loading}
      confirmText="Восстановить"
    >
      <Input value={mnemo} onChange={onChangeMnemo} error={errorText} />
    </Dialog>
  );
};

export default RestoreKeyModal;
