import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const DownloadIcon: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 32)}
        height={getSize(size, 32)}
        fill="none"
      >
        <path
          fill="currentColor"
          d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2Zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5Z"
        />
      </svg>
    </Text>
  );
};

export default DownloadIcon;
