const getCitizenship = (cz: string) => {
  switch (cz) {
    case "643":
      return "Российская Федерация";
    default:
      return "Российская Федерация";
  }
};

export default getCitizenship;
