import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FilesAPI,
  File as IFile,
  TokenOrdersResponse,
  OrdersAPI,
  UserClientType,
  TokenStatusGql,
} from "@front-packages/dfa-gql-api";
import { EncodeObject } from "@cosmjs/proto-signing";
import { v4 as uuidv4 } from "uuid";
import { resetHeader, setHeader, setPageLoading } from "store/global";
import { useAppDispatch, useAppSelector } from "store";
import {
  Alert,
  Badge,
  Button,
  Card,
  CircleLoader,
  EntityProps,
  Flex,
  Grid,
  GridItem,
  NoData,
  Text,
  File,
  InfoCard,
  Accordion,
} from "Theme";
import { formattingDate, getEpochSeconds } from "helpers";
import { TokenShortType } from "constants/in_lib";
import { useErrors } from "hooks";
import { RoutesNames } from "routes";
import SigningDialog from "components/Dialogs/Signing";
import { WarningIcon } from "Theme/icons";
import JournalRedeem from "./modals/JournalRedeem";
import { useStateEditIssue } from "../hooks";
import RedeemInfoModal from "./modals/RedeemInfoModal";
import { OrdersList, RedeemJournals } from "./components";
import useRedeemJournalsTable from "./components/RedeemJournals/useRedeemJournalsTable";
import RedeemJournalItemsModal from "./modals/RedeemJournalItemsModal";

const ViewIssue: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setError } = useErrors();
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const { pageLoading } = useAppSelector((state) => state.global);
  const {
    getToken,
    state: { tokenDefault: token },
  } = useStateEditIssue(true);
  const tokenID = search.split("=")[1];

  // TODO: почему count = 4???
  const count = 4;
  const [page, setPage] = useState<number>(0);
  const [openSign, setOpenSign] = useState<boolean>(false);
  const [openJournal, setOpenJournal] = useState<boolean>(false);
  const [orderLoading, setOrdersLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<TokenOrdersResponse>(null);
  const [tokenFiles, setTokenFiles] = useState<IFile[]>(null);

  const redeemJournalsTableData = useRedeemJournalsTable(tokenID);

  const [openRedeemInfoModal, setOpenRedeemInfoModal] = useState(false);
  const onOpenRedeemInfoModal = () => setOpenRedeemInfoModal(true);

  const getTokenFiles = async () => {
    const { response, error } = await FilesAPI.GetTokenFiles({ tokenID });
    if (error) setError({ error, key: Math.random() });
    if (response) setTokenFiles(response);
  };

  const getMessage = (sender: string): EncodeObject => {
    return {
      typeUrl: "/rr.bcpcore.wasm.MsgInstantiateContract",
      value: {
        sender,
        name: "token-dvp",
        msg: btoa(
          JSON.stringify({
            emitent_addr: sender,
            min_order_limit: token.minOrderLimit,
            max_order_limit: token.maxOrderLimit,
            min_issue_limit: token.minIssueLimit,
            max_issue_limit: token.maxIssueLimit,
            invest_start_date: getEpochSeconds(token.investStartDate),
            invest_finish_date: getEpochSeconds(token.investFinishDate),
            ticker: token.ticker,
            nominal: token.nominal,
            interest: token.interest,
            redemption_date: getEpochSeconds(token.redemptionDate),
            transaction_guid: uuidv4(),
          })
        ),
        ibcId: "",
      },
    };
  };
  const goToIssues = () => navigate(isAccountIssuer ? RoutesNames.Issues : RoutesNames.Marketplace);
  const toggleSign = () => setOpenSign(!openSign);
  const toggleJournal = () => setOpenJournal(!openJournal);

  const getOrders = async () => {
    setOrdersLoading(true);
    const { response, error } = await OrdersAPI.GetOrdersList({
      opts: {
        page,
        count,
        clientType: isAccountIssuer ? UserClientType.Emitent : UserClientType.Investor,
        tokenID,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) setOrders(response);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: "Информация о выпуске",
        pageBackBtn: { label: "Назад" },
      })
    );
    getToken().then(() => dispatch(setPageLoading(false)));
    return () => {
      dispatch(resetHeader());
    };
  }, [search]);

  useEffect(() => {
    getOrders().then(() => setOrdersLoading(false));
  }, [page, search]);

  useEffect(() => {
    token && tokenID && getTokenFiles();
  }, [token, search, tokenID]);

  return (
    <>
      <Alert
        sx={{ mt: 1 }}
        type="info"
        title="Токен готов к погашению"
        text={
          isAccountIssuer
            ? `Наступил срок погашения Токена ${token?.ticker}. Пожалуйста, произведите погашение токена.`
            : "Токен готов к погашению и ожидает действие со стороны эмитента"
        }
        show={!pageLoading && token && token.status === TokenStatusGql.InRedeem}
        action={isAccountIssuer && onOpenRedeemInfoModal}
      />
      <Alert
        sx={{ mt: 1 }}
        type="success"
        title="Токен погашен"
        text={
          isAccountIssuer
            ? `Журнал погашения доступен для просмотра в нижней части страницы`
            : "Денежные средства зачислены на ваш расчетный счет"
        }
        show={!pageLoading && token && token.status === TokenStatusGql.Redeemed}
      />
      <Alert
        sx={{ mt: 1 }}
        type="warning"
        title="Идет прием заявок"
        // text={
        //   isAccountIssuer
        //     ? `В данный момент идет прием заявок на покупку от инвесторов. При наступлении даты окончания приема заявок этот процесс будет завершен`
        //     : "В данный момент выпуск доступен для подачи заявок"
        // }
        show={!pageLoading && token && token.status === TokenStatusGql.Started}
      />
      {!pageLoading && token && (
        <Flex sx={{ direction: "column", width: "100%", mt: 2, columnGap: 1, rowGap: 1 }}>
          <Flex sx={{ columnGap: 1 }}>
            <InfoCard type="diamond" title="Тип токена" value="Денежные требования" fullWidth />
            <InfoCard
              type="event"
              title="Начало сбора заявок"
              value={formattingDate(token?.investStartDate)}
              fullWidth
            />
            <InfoCard
              type="event"
              title="Окончание сбора заявок"
              value={formattingDate(token?.investFinishDate)}
              fullWidth
            />
            <InfoCard
              type="difference"
              title="Дата публикации"
              value={formattingDate(token?.plannedPublicDate)}
              fullWidth
            />
          </Flex>
          <Card
            title="Токен"
            bgWhite
            sx={{ wrap: "wrap", gap: 1 }}
            titleSx={{ color: "primary", bgColor: "primary" }}
          >
            <Grid
              sx={{
                gap: 1,
                templateColumns: "1.5fr 1fr 1fr 150px",
                areas: `"a a b b"
                        "c d d d"
                        "e f g g"`,
              }}
              adaptiveSX={{ mobile: { areas: `"g b b b" "a a a a" "c c c d" "e e e f"` } }}
            >
              <GridItem gridArea="a">
                <EntityProps color="primary" value={token?.ticker} title="тикер" />
              </GridItem>
              <GridItem flex flexColumnGap={0.5} gridArea="b" placeSelf="stretch end">
                <EntityProps
                  value={
                    <Badge
                      label={token?.interest ? `${token.interest || 0}%` : "нет ставки дохода"}
                      color="warning"
                    />
                  }
                />
                <EntityProps
                  value={
                    <Badge
                      label={TokenShortType[token?.rightsType] || "тип не указан"}
                      color="primary"
                    />
                  }
                />
              </GridItem>

              <GridItem gridArea="c">
                <EntityProps
                  color="primary"
                  value={`${token?.maxIssueLimit ? token.maxIssueLimit : 0} RUB`}
                  title="объем выпуска"
                />
              </GridItem>
              <GridItem gridArea="d">
                <EntityProps
                  color="primary"
                  value={`${token?.minIssueLimit ? token.minIssueLimit : 0} RUB`}
                  title="мин. объем выкупа"
                />
              </GridItem>

              <GridItem gridArea="e">
                <EntityProps color="primary" value={`${token?.nominal || 0} RUB`} title="номинал" />
              </GridItem>
              <GridItem gridArea="f">
                <EntityProps
                  color="primary"
                  value={`${
                    token?.maxIssueLimit && token?.nominal ? token.maxIssueLimit / token.nominal : 0
                  } шт`}
                  title="количество"
                />
              </GridItem>
              <GridItem
                gridArea="g"
                placeSelf="stretch end"
                adaptiveSX={{ mobile: { placeSelf: "stretch start" } }}
              >
                <EntityProps
                  color="primary"
                  value={formattingDate(token?.redemptionDate) || "Не указана"}
                  title="дата погашения"
                />
              </GridItem>
            </Grid>
          </Card>
          <Flex sx={{ direction: "column", rowGap: 1 }}>
            <Flex sx={{ columnGap: 1 }}>
              <Accordion title="ОБЪЁМ ПРАВ" bgWhite isInteractive>
                <Text sx={{ lineHeight: 1.5 }}>{token?.scopeRights || "Не заполнено"}</Text>
              </Accordion>
              <Accordion title="ОГРАНИЧЕНИЯ" bgWhite isInteractive>
                <Text sx={{ lineHeight: 1.5 }}>{token?.limits || "Не заполнено"}</Text>
              </Accordion>
            </Flex>
            <Flex sx={{ columnGap: 1 }}>
              <Accordion title="ОБЕСПЕЧЕНИЕ ПРАВ" bgWhite isInteractive>
                <Text sx={{ lineHeight: 1.5 }}>{token?.scopeRights || "Не заполнено"}</Text>
              </Accordion>
              <Accordion title="УСЛОВИЯ ВЫПУСКА" bgWhite isInteractive>
                <Text sx={{ lineHeight: 1.5 }}>{token?.provision || "Не заполнено"}</Text>
              </Accordion>
            </Flex>
          </Flex>
          {orderLoading && (
            <Flex sx={{ justify: "center", align: "center", width: "100%", mt: 5 }}>
              <CircleLoader />
            </Flex>
          )}
          {!orderLoading &&
            orders &&
            !!orders?.count &&
            token.status !== TokenStatusGql.Redeemed && (
              <Flex sx={{ width: "100%" }}>
                <OrdersList
                  count={orders.count}
                  orders={orders.orders}
                  page={page}
                  setPage={setPage}
                />
              </Flex>
            )}
          {tokenID &&
          token?.authorID &&
          (token.status === TokenStatusGql.InRedeem || token.status === TokenStatusGql.Redeemed) &&
          redeemJournalsTableData.rows.length ? (
            <Flex sx={{ width: "100%" }}>
              <RedeemJournals
                loading={redeemJournalsTableData.loading}
                countOfJournals={redeemJournalsTableData.countOfJournals}
                rows={redeemJournalsTableData.rows}
              />
            </Flex>
          ) : null}
          <Flex sx={{ width: "100%" }}>
            {tokenFiles?.length ? (
              <Flex sx={{ direction: "column", rowGap: 1 }}>
                <Text variant="h4" sx={{ mt: 1, width: "100%", weight: "regular" }}>
                  Загруженные файлы
                </Text>
                <Flex sx={{ columnGap: 1 }}>
                  {tokenFiles.map((file) => (
                    <File name={file.name} key={file.id} url={file.url} />
                  ))}
                </Flex>
              </Flex>
            ) : null}
          </Flex>
          <Flex sx={{ mt: 3, justify: "flex-start", width: "100%" }}>
            {isAccountIssuer && token?.status === TokenStatusGql.Signing && (
              <Button sx={{ mr: 1 }} onClick={toggleSign}>
                Подписать и опубликовать выпуск
              </Button>
            )}
            {isAccountIssuer && token?.status === TokenStatusGql.InRedeem && (
              <Button sx={{ mr: 1 }} onClick={onOpenRedeemInfoModal}>
                Погасить токен
              </Button>
            )}
            <Button variant="outline" onClick={goToIssues} color="secondary">
              К выпускам
            </Button>
          </Flex>
        </Flex>
      )}
      {!pageLoading && !token && (
        <NoData icon={WarningIcon} type="warning" text="Не удалось получить данные токена" />
      )}
      <SigningDialog
        close={toggleSign}
        open={openSign}
        getMessage={getMessage}
        ids={{ tokenID }}
        queryAfterTx={getToken}
      />
      {token && <JournalRedeem open={openJournal} onClose={toggleJournal} token={token} />}
      <RedeemInfoModal
        open={openRedeemInfoModal}
        onClose={() => setOpenRedeemInfoModal(false)}
        tokenID={tokenID}
      />
      <RedeemJournalItemsModal
        open={redeemJournalsTableData.openJournalItemsModal}
        loading={redeemJournalsTableData.journalItemsLoading}
        rows={redeemJournalsTableData.journalItemsRows}
        onClose={redeemJournalsTableData.onCloseJournalItemsModal}
        journalNumber={redeemJournalsTableData.journalNumber}
      />
    </>
  );
};

export default ViewIssue;
