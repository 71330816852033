import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Select, Text } from "Theme/components";
import { useAppDispatch, useAppSelector } from "store";
import { CheckIcon, IconArrow } from "Theme/icons";
import { useSubscription } from "@apollo/client";
import { AuthAPI, NotificationsBuilder } from "@front-packages/dfa-gql-api";
import { setOpenNotificationsCenter, setHasNewNotification } from "store/notificationsCenter";
import { useNotifications } from "hooks";
import { getCookie, setCookie } from "@front-packages/dfa-helpers";
import { CookieNamesEnum, ErrorsEnum } from "@front-packages/dfa-constants";
import { addNotification } from "store/notifications";
import { setLiveAction } from "store/global";
import NotificationItem from "./NotificationItem";
import { StyledNotificationsCenter } from "./NotificationsCenter";

const NotificationsCenter: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    handleSetNewNotifications,
    totalCount,
    pageIndex,
    setPageIndex,
    handleHasNewNotifications,
    notifOptions,
    selectedNotification,
    setSelectedNotification,
    markAllAsReaded,
  } = useNotifications();

  const ref = useRef(null);

  const { openNotificationsCenter, notifications, hasNew } = useAppSelector(
    (state) => state.notificationsCenter
  );
  const [accessToken, setAccessToken] = useState(getCookie("token"));
  useSubscription(NotificationsBuilder.subscribeNotifications, {
    variables: { accessToken },
    onError: async (onerrorerror) => {
      if (onerrorerror.message === "unauthorized") {
        const refreshToken = getCookie(CookieNamesEnum.refreshToken);
        const token = getCookie(CookieNamesEnum.token);
        setCookie(CookieNamesEnum.token, "null");
        const { response, error: e } = await AuthAPI.RefreshAccessToken({ refreshToken });
        if (e) {
          if (e === ErrorsEnum.AccessTokenIsNotExpired) {
            setCookie(CookieNamesEnum.token, token);
          }
        }
        setCookie(CookieNamesEnum.refreshToken, response?.refreshToken);
        setCookie(CookieNamesEnum.token, response?.accessToken);
        setAccessToken(response?.accessToken);
      }
    },
    shouldResubscribe: true,
    onSubscriptionData: async ({ subscriptionData: { data } }) => {
      if (openNotificationsCenter) dispatch(setHasNewNotification(true));
      else handleSetNewNotifications();

      dispatch(setLiveAction(data?.NotificationLive?.notificationType));
      dispatch(
        addNotification({
          type: "success",
          message: data?.NotificationLive?.data,
          position: "right",
        })
      );
    },
  });

  const handleScroll = async (e) => {
    e.persist();
    if (
      ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop < 1 &&
      totalCount > (pageIndex + 1) * 10
    ) {
      ref.current.scrollTop -=
        (totalCount - (pageIndex + 1) * 10 > 10 ? 0 : totalCount - (pageIndex + 1) * 10) * 10;
      setPageIndex((prev) => prev + 1);
    }
  };
  const onCloseNotificationsCenter = () => {
    dispatch(setOpenNotificationsCenter(false));
    ref.current.scrollTop = 0;
  };

  useEffect(() => {
    function openListener(e) {
      if (ref.current && !ref.current.contains(e.target)) onCloseNotificationsCenter();
    }
    document.addEventListener("click", openListener);
    return () => document.removeEventListener("click", openListener);
  }, []);

  useEffect(() => {
    handleSetNewNotifications();
    ref.current.scrollTop = 0;
  }, [selectedNotification]);
  return (
    <StyledNotificationsCenter ref={ref} open={openNotificationsCenter} onScroll={handleScroll}>
      <Flex
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          maxWidth: "555px",
          // justify: "space-between",
          // columnGap: 1,
          p: "10px 20px",
          align: "center",
          borderRadius: "15px",
          zIndex: 1,
          direction: "column",
        }}
        bgColor="white"
      >
        <Flex sx={{ width: "100%", maxWidth: "555px", justify: "space-between", columnGap: 1 }}>
          <Flex sx={{ columnGap: 0.5, align: "center" }}>
            {/* <Text color="primary">Уведомления</Text> */}
            <Select
              colored
              hideButton
              sx={{ p: "4.5px 20px", minWidth: "163px", height: "30px" }}
              value={selectedNotification}
              onChange={setSelectedNotification as any}
              options={
                notifOptions
                  ? notifOptions.map((el) => ({
                      value: el.value,
                      description: el.title,
                    }))
                  : [{ description: "Все", value: "all" }]
              }
              color="primary"
            />
          </Flex>
          <Button
            variant="link"
            color="primary"
            onClick={markAllAsReaded}
            iconStart={<CheckIcon color="primary" bold />}
          >
            Пометить все прочитанным
          </Button>
        </Flex>
        {hasNew && (
          <Button
            variant="link"
            iconStart={<IconArrow rotate="up" />}
            onClick={(e) => {
              handleHasNewNotifications(e);
              ref.current.scrollTop = 0;
            }}
          >
            У вас есть новые уведомления
          </Button>
        )}
      </Flex>
      <Flex sx={{ direction: "column", width: "100%" }}>
        {notifications?.length ? (
          notifications.map((notif, i) => (
            <NotificationItem
              id={notif.id}
              message={notif.message}
              createdDate={notif.createdDate}
              type={notif.type}
              isReaded={notif.isReaded}
              isLast={i === notifications.length - 1}
              key={notif.id}
              objectID={notif.objectID}
            />
          ))
        ) : (
          <Text sx={{ align: "center", justify: "center" }} color="primary">
            Нет уведомлений
          </Text>
        )}
      </Flex>
    </StyledNotificationsCenter>
  );
};

export default NotificationsCenter;
