import styled from "styled-components";

export const StyledButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${({ theme }) => theme.palette.somePrimary.light};
`;

export const StyledImg = styled.img`
  margin: auto 0;
  width: 50%;
`;
