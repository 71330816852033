import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const WarningIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  opacity,
  contrast,
  sx,
}) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        opacity={opacity ? opacity / 100 : undefined}
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 21)}
        width={getSize(size, 21)}
        fill="currentColor"
        viewBox="0 96 960 960"
      >
        <path d="m18 949 462-799 462 799H18Zm145-84h634L480 317 163 865Zm321.175-48q13.4 0 23.613-10.388Q518 796.224 518 782.825q0-13.4-10.388-23.112Q497.224 750 483.825 750q-13.4 0-23.613 9.888Q450 769.776 450 783.175q0 13.4 10.388 23.612Q470.776 817 484.175 817ZM454 708h60V492h-60v216Zm26-117Z" />
      </svg>
    </Text>
  );
};

export default WarningIcon;
