import React from "react";
import { RoutesTypes, RouteType, routes } from "routes";
import { useAppSelector } from "store";
import SidebarItem from "./SidebarItem";
import { StyledSidebarList } from "./SidebarList";

const SidebarListItems: React.FC = () => {
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const getLinks = (item: RouteType) => {
    if (
      item.type === RoutesTypes.Auth ||
      item.type === RoutesTypes.NotMenu ||
      (item.onlyInvestor && isAccountIssuer) ||
      (item.onlyIssuer && !isAccountIssuer)
    )
      return null;
    return <SidebarItem key={`${item.key}-li`} item={item} getLinks={getLinks} />;
  };

  return (
    <StyledSidebarList open type={RoutesTypes.First}>
      {routes.map(getLinks)}
    </StyledSidebarList>
  );
};

export default SidebarListItems;
