import styled, { css } from "styled-components";
import { ColorType, FlexType, IAdaptive, MarginType, PaddingType, SizeType } from "../../types";
import { adaptiveStyles, flexStyles, marginStyles, paddingStyles, sizeStyles } from "../../styles";

export interface ICard extends IAdaptive {
  type?: ColorType;
  bgWhite?: boolean;
  sx?: MarginType & SizeType & PaddingType & FlexType;
  filled?: boolean;
  withBorder?: boolean;
  watermark?: string;
}
interface IStyledCard extends ICard {
  isInteractive: boolean;
}
export const StyledCard = styled.div<IStyledCard>`
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 100px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
  ${({ theme, filled, type, withBorder, isInteractive, bgWhite }) => css`
    background-image: ${filled && !bgWhite && theme.palette[type].gradient[100]};
    background-color: ${bgWhite && "white"};
    border: ${withBorder && !bgWhite && `1px solid ${theme.palette[type].light}50`};
    color: ${filled && !bgWhite ? theme.palette.white.main : theme.palette.text.main};
    &:hover {
      transform: ${isInteractive && " translateY(-0.2rem)"};
      cursor: ${isInteractive && "pointer"};
      box-shadow: ${isInteractive && "0 10px 19px #00000015"};
    }
  `}
  flex: ${({ sx }) => sx?.flex};
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => paddingStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

StyledCard.defaultProps = {
  type: "text",
};

interface IContentWrap extends IAdaptive {
  sx?: FlexType;
  withWaterMark: boolean;
}

export const ContentWrap = styled.div<IContentWrap>`
  ${({ withWaterMark }) =>
    withWaterMark &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    `}
  height: 100%;
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  padding: 20px;
  word-break: break-word;
  flex-direction: column;
  ${({ sx }) => flexStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

interface ITitleContentWrap extends IAdaptive {
  bgColor?: ColorType;
  titleLight?: boolean;
  sx?: FlexType;
}

export const TitleContentWrap = styled.div<ITitleContentWrap>`
  height: 100%;
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  padding: 20px;
  word-break: break-word;
  // flex-direction: column;
  ${({ theme, bgColor }) => css`
    background-color: ${bgColor && bgColor !== "white" && theme.palette[bgColor].main}${theme.palette.opacity[10]};
    background-color: ${bgColor === "white" && "white"};
    padding-bottom: ${!bgColor && 0};
  `}
  ${({ sx }) => flexStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

interface IWatermark {
  contrast: boolean;
  color: ColorType;
  size?: number;
}
export const Watermark = styled.span<IWatermark>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1, 1);
  font-size: ${({ size }) => `${size}px`};
  font-weight: ${({ theme }) => theme.typography.weight.extraBold};
  text-transform: uppercase;
  -moz-user-select: none;
  user-select: none;
  color: ${({ contrast, theme, color }) =>
    contrast
      ? `${theme.palette.white.main}${theme.palette.opacity[5]}`
      : `${theme.palette[color].main}${theme.palette.opacity[5]}`};
`;
