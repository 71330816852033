import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const EventIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M591.212-226.666q-41.212 0-69.545-28.456-28.333-28.455-28.333-69.666 0-41.212 28.455-69.545 28.456-28.333 69.667-28.333T661-394.211q28.334 28.456 28.334 69.667T660.878-255q-28.455 28.334-69.666 28.334ZM186.666-80q-27 0-46.833-19.833T120-146.666v-600.001q0-27 19.833-46.833 19.833-19.834 46.833-19.834h56.667v-31.999q0-14.734 9.967-24.7Q263.267-880 278-880q15.017 0 25.175 9.967 10.158 9.966 10.158 24.7v31.999h333.334v-31.999q0-14.734 9.966-24.7Q666.6-880 681.333-880q15.017 0 25.175 9.967 10.159 9.966 10.159 24.7v31.999h56.667q27 0 46.833 19.834Q840-773.667 840-746.667v600.001q0 27-19.833 46.833T773.334-80H186.666Zm0-66.666h586.668v-420.001H186.666v420.001Z" />
      </svg>
    </Text>
  );
};

export default EventIcon;
