import React, { useRef } from "react";
import { Alert, Dialog, Flex } from "Theme";
import { PDFDoc } from "components";
import { base64ToPdf } from "helpers";

interface IInvoiceModalProps {
  open: boolean;
  onClose(): void;
  base64File: string;
}
const InvoiceModal: React.FC<IInvoiceModalProps> = ({ onClose, open, base64File }) => {
  const pdfDoc = useRef<Blob>(base64ToPdf(base64File));
  const pdfHref = URL.createObjectURL(pdfDoc.current);
  const onDownload = () => {
    const a = document.createElement("a");
    a.href = pdfHref;
    a.download = "Счет.pdf";
    a.click();
  };

  const onConfirm = () => {
    onDownload();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Счет на оплату"
      maxWidth="xxl"
      onConfirm={onConfirm}
      confirmText="Скачать"
      cancelText="Закрыть"
    >
      <Flex
        sx={{ overflowY: "auto", height: "420px", width: "100%", m: "0 auto", direction: "column" }}
      >
        <Alert
          sx={{ mt: 1 }}
          type="warning"
          title="Требуется оплата"
          text="По факту оплаты счета произойдет погашение Токена"
          show
        />
        {/* <Text>После оплаты счета Токен будет погашен.</Text> */}
        {pdfDoc.current && <PDFDoc pdfDoc={pdfDoc.current} />}
      </Flex>
    </Dialog>
  );
};

export default InvoiceModal;
