import styled, { css } from "styled-components";
import { paddingStyles, marginStyles, sizeStyles } from "../../styles";
import { ColorType, FlexType, MarginType, PaddingType, SizeType } from "../../types";

export interface IStyledTable {
  sx?: MarginType & PaddingType & SizeType;
}
export interface IStyledCell {
  height?: number;
  color?: ColorType;
  firstCell?: boolean;
  endCell?: boolean;
  endRow?: boolean;
  lastCellInCol?: boolean;
  bgWhite?: boolean;
  justify?: Pick<FlexType, "justify">;
}
export interface IStyledColumn {
  proportion?: 1 | 2 | 3 | 4 | 5;
  color?: ColorType;
  firstCol?: boolean;
  lastCol?: boolean;
}
export interface IIndicator {
  color: ColorType;
  position: "start" | "end";
}

export const Container = styled.div<IStyledTable>`
  display: flex;
  width: 100%;
  height: max-content;
  ${({ sx }) => css`
    ${marginStyles(sx)};
    ${paddingStyles(sx)};
    ${sizeStyles(sx)};
  `};
`;

export const Column = styled.div<IStyledColumn>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ proportion, theme, color, firstCol, lastCol }) => css`
    flex: ${proportion || 1};
    border-top: 1px solid ${theme.palette[color || "primary"].main}${theme.palette.opacity[15]};
    border-bottom: 1px solid ${theme.palette[color || "primary"].main}${theme.palette.opacity[15]};
    border-top-left-radius: ${firstCol && "15px"};
    border-bottom-left-radius: ${firstCol && "15px"};
    border-top-right-radius: ${lastCol && "15px"};
    border-bottom-right-radius: ${lastCol && "15px"};
    border-left: ${firstCol &&
    `1px solid ${theme.palette[color || "primary"].main}${theme.palette.opacity[15]}`};
    border-right: ${lastCol &&
    `1px solid ${theme.palette[color || "primary"].main}${theme.palette.opacity[15]}`};
  `}
`;

export const TitleCell = styled.div<IStyledCell>`
  display: flex;
  align-items: center;
  padding: 5px 22px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  column-gap: 10px;
  text-transform: capitalize;
  ${({ theme, bgWhite, onClick, color, height, firstCell, endCell, justify }) => css`
    justify-content: ${justify || "left"};
    height: ${height ? `${height}px` : "50px"};
    cursor: ${onClick && "pointer"};
    border-top-left-radius: ${firstCell && "15px"};
    border-top-right-radius: ${endCell && "15px"};
    border-bottom: ${`1px solid ${theme.palette[color || "primary"].main}${
      theme.palette.opacity[10]
    }`};
    background: ${bgWhite
      ? theme.palette.white
      : `${theme.palette[color].main}${theme.palette.opacity[5]}`};
  `}
`;

export const Cell = styled.div<IStyledCell>`
  display: flex;
  align-items: center;
  padding: 5px 22px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme, endRow, bgWhite, onClick, color, height, justify, lastCellInCol }) => css`
    border-bottom-left-radius: ${lastCellInCol && "15px"};
    border-bottom-right-radius: ${endRow && "15px"};
    justify-content: ${justify || "left"};
    height: ${height ? `${height}px` : "50px"};
    cursor: ${onClick && "pointer"};
    border-bottom: ${!endRow &&
    `1px solid ${theme.palette[color || "primary"].main}${theme.palette.opacity[15]}`};
    background: ${bgWhite
      ? theme.palette.white.light
      : `${theme.palette[color].main}${theme.palette.opacity[25]}`};
  `}
`;

export const Title = styled.span`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`;

export const Indicator = styled.span<IIndicator>`
  display: block;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ position }) => position === "start" && "8px"};
  right: ${({ position }) => position === "end" && "8px"};
  background: ${({ theme, color }) => theme.palette[color || "success"].light};
`;
