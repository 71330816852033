import React from "react";
import { RoutesNames } from "routes";
import {
  blockchain,
  filesReady,
  keys,
  kycDone,
  kycInProgress,
  mnenonic,
  startKyc,
  chat,
} from "./img";

export const getImage = (pathname: string, step: number): React.ReactNode => {
  if (pathname === RoutesNames.OnBoarding) {
    if (step === 1) return <img width={150} src={startKyc} alt="StartKYC!" />;
    if (step === 2) return <img width={150} src={kycInProgress} alt="KYCInProgress!" />;
    if (step === 3) return <img width={150} src={kycDone} alt="KYCDone!" />;
    if (step === 4) return <img width={150} src={keys} alt="Keys!" />;
    if (step === 5) return <img width={150} src={filesReady} alt="FilesReady!" />;
    if (step === 6) return <img width={150} src={mnenonic} alt="Mnenonic!" />;
    if (step === 7) return <img width={150} src={blockchain} alt="Blockchain!" />;
    return <img width={150} src={kycDone} alt="Blockchain!" />;
  }
  return <img src={chat} alt="Welcome!" />;
};

export const getOnBoardingText = (onBoardingStep: number): string => {
  switch (onBoardingStep) {
    case 1:
      return "Для прохождения процесса регистрации пройдите проверку KYC";
    case 2:
      return "Для прохождения процесса регистрации пройдите проверку KYC";
    case 3:
      return "Для прохождения процесса регистрации сгенерируйте ключевую пару";
    case 4:
      return "Парольная фраза является опциональным параметром. Установив парольную фразу, при потере Вами ключа, злоумышленники не смогут его использовать. Пропустите этот шаг, если не хотите устанавливать парольную фразу для использования ключа";
    case 5:
      return "Ключи сгенерированы успешно! Сохраните файлы с ключами в надежном месте. Не предоставляйте доступ к файлам третьим лицам!";
    case 6:
      return "Мнемофраза используется для восстановления доступа к кошельку в случае компроментации или утраты приватного ключа";
    case 7:
      return "Пожалуйста, не закрывайте и не обновляйте страницу, Ваши данные должны быть зарегистрированы в блокчейн";
    case 8:
      return "";
    default:
      return "Процесс регистрации данных в блокчейн";
  }
};

export const getOnBoardingTitle = (onBoardingStep: number): string => {
  switch (onBoardingStep) {
    case 1:
      return "Требуется проверка личности представителя";
    case 2:
      return "Процесс проверки KYC ещё не завершён";
    case 3:
      return "Проверка KYC пройдена";
    case 4:
      return "Генерация ключевой пары";
    case 5:
      return "Генерация ключевой пары";
    case 6:
      return "Мнемофраза";
    case 7:
      return "Идёт процесс регистрации данных в блокчейн";
    case 8:
      return "Вы успешно зарегистрированы в системе «SMART»";
    default:
      return "Процесс регистрации данных в блокчейн";
  }
};
