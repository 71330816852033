import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const IconContract: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        viewBox="0 0 48 48"
        fill="currentColor"
      >
        <path d="M17 34.75h14v-1.5H17Zm0-8.2h14V25H17ZM12.75 42q-1.15 0-1.95-.8t-.8-1.95V8.75q0-1.15.8-1.95t1.95-.8h16.7L38 14.55v24.7q0 1.15-.8 1.95t-1.95.8ZM28.7 15.2V7.55H12.75q-.45 0-.825.375-.375.375-.375.825v30.5q0 .45.375.825.375.375.825.375h22.5q.45 0 .825-.375.375-.375.375-.825V15.2ZM11.55 7.55v7.65-7.65V40.45 7.55Z" />
      </svg>
    </Text>
  );
};

export default IconContract;
