import React from "react";
import { ColorType, MarginType, SizeType, TypographyType } from "../../types";
import Flex from "../Flex";
import Text from "../Text";

interface IRowEntityProps {
  title: string;
  value: string | number;
  color?: ColorType;
  sx?: SizeType & MarginType;
  isLoading?: boolean;
  sxValue?: TypographyType;
  rows?: number;
}

const RowEntityProps: React.FC<IRowEntityProps> = ({
  title,
  value,
  color,
  sx,
  isLoading,
  sxValue,
  rows,
}) => {
  return (
    <Flex rows={rows} sx={{ align: "center", ...sx }}>
      <Text variant="h5">{title}:</Text>
      <Text
        rows={rows}
        isLoading={isLoading}
        color={color}
        sx={{ ml: 0.3, size: "MD", ...sxValue }}
        title={value?.toString()}
      >
        {value}
      </Text>
    </Flex>
  );
};

export default RowEntityProps;
