import styled, { css } from "styled-components";
import { RoutesTypes } from "routes";

export interface ISidebarLink {
  active: boolean;
  type: RoutesTypes;
}
export const StyledSidebarItem = styled.li`
  display: flex;
  flex-direction: column;
`;
export const StyledSidebarLink = styled.a`
  text-decoration: none;
  color: inherit;
  flex: 1;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.MD};
  font-weight: 500;
  ${({ theme }) => css`
    ${theme.device.tablet} {
      font-size: ${theme.typography.size.SM};
    }
  `}
`;

export const LinkWrap = styled.div<ISidebarLink>`
  display: flex;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 5px;
  ${({ theme }) =>
    css`
      color: ${theme.palette.primary.main};
    `}
  ${({ theme, active }) =>
    active &&
    css`
      color: white;
      background: ${theme.palette.primary.gradient[100]};
    `}
`;
