import { FilesAPI } from "@front-packages/dfa-gql-api";
import { getCookie } from "@front-packages/dfa-helpers";
import { downloadBlobHelper } from "helpers";
import { useErrors } from "hooks";
import React from "react";
import { Button } from "Theme";

interface IDownloadButtonProps {
  depositOrderID: string;
}

const DownloadButton: React.FC<IDownloadButtonProps> = ({ depositOrderID }) => {
  const { setError } = useErrors();

  const getDepositOrderFile = async () => {
    const { response, error } = await FilesAPI.GetDepositOrderInvoice({ depositOrderID });
    if (error) setError({ error, key: Math.random() });
    if (response?.url) {
      fetch(`${process.env.REACT_APP_MIDDLE_API}${response.url}`, {
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      })
        .then((resp) => resp.arrayBuffer())
        .then((arrayBuffer) => arrayBuffer);
    }
    return undefined;
  };

  const onDownload = async () => {
    const arrayBuffer = await getDepositOrderFile();
    const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
    if (arrayBuffer && pdfBlob) {
      downloadBlobHelper(pdfBlob, "sas");
    }
  };
  return (
    <Button variant="link" onClick={onDownload}>
      Скачать
    </Button>
  );
};

export default DownloadButton;
