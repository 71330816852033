import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { resetLiveAction } from "store/global";
import { CompaniesAPI } from "@front-packages/dfa-gql-api";
import { setCompanyInfo } from "store/customer";
import { useErrors } from "hooks/errors";
import useGetBalance from "./useGetBalance";

const useLiveActions = () => {
  const dispatch = useAppDispatch();
  const getBalance = useGetBalance();
  const { setError } = useErrors();

  const { liveAction } = useAppSelector((state) => state.global);
  console.log(liveAction);
  // TODO: вынести в хук

  const updateCompanyData = async () => {
    const { response, error } = await CompaniesAPI.GetCompanyInfo();
    if (error) setError({ error, key: Math.random() });
    if (response) dispatch(setCompanyInfo(response));
  };

  const queryAfterGetLiveAction = () => {
    switch (liveAction) {
      case "INVESTOR.FIAT_ADDED":
        getBalance();
        break;
      case "ACCOUNT.CREATE_DEACTIVATION_ORDER":
      case "ACCOUNT.REJECT_DEACTIVATION_ORDER_BY_ADMIN":
        updateCompanyData();
        break;
      default:
        break;
    }
    dispatch(resetLiveAction());
  };

  useEffect(() => {
    if (liveAction) queryAfterGetLiveAction();
  }, [liveAction]);
};

export default useLiveActions;
