import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColorType } from "../Theme";

export interface IHeaderState {
  title: string;
  subTitle: string;
  colorShift: number;
  badgePosition: "title" | "subtitle";
  badge: {
    label: string;
    color: ColorType;
  };
  pageBackBtn: {
    label: string;
    // color: ColorType;
    // url: string;
  };
}

// TODO: удалить когда на мидле сделают енамку для notificationType в Notification.
export type LiveActionType =
  | "EMITENT.READY_TO_REDEEM"
  | "EMITENT.INVOICE_FOR_REDEEM_IS_READY"
  | "INVESTOR.TOKEN_IS_REDEEMED"
  | "EMITENT.TOKEN_IS_REDEEMED"
  | "INVESTOR.FIAT_ADDED"
  | "INVESTOR.ACCEPTORDER_REJECTED"
  | "INVESTOR.ORDER_COMPLETED"
  | "ACCOUNT.CREATE_DEACTIVATION_ORDER"
  | "ACCOUNT.REJECT_DEACTIVATION_ORDER_BY_ADMIN";

export interface IGlobalState {
  globalLoading: boolean;
  pageLoading: boolean;
  header: IHeaderState;
  windowWidth: number;
  liveAction: LiveActionType;
}

const initialHeaderState: IHeaderState = {
  title: null,
  subTitle: null,
  badgePosition: "subtitle",
  badge: null,
  colorShift: 1,
  pageBackBtn: null,
};

const initialState: IGlobalState = {
  windowWidth: 1920,
  globalLoading: true,
  pageLoading: false,
  header: initialHeaderState,
  liveAction: null,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setWindowWidth(state, action: PayloadAction<number>) {
      state.windowWidth = action.payload;
    },
    setGlobalLoading(state, action: PayloadAction<boolean>) {
      state.globalLoading = action.payload;
    },
    setPageLoading(state, action: PayloadAction<boolean>) {
      state.pageLoading = action.payload;
    },
    resetHeader(state) {
      state.header = initialHeaderState;
    },
    setHeader(state, { payload }: PayloadAction<Partial<IHeaderState>>) {
      state.header = {
        badgePosition: payload.badgePosition || "subtitle",
        title: payload.title || state.header.title,
        subTitle: payload.subTitle || state.header.subTitle,
        colorShift: payload.colorShift || state.header.colorShift,
        badge: payload.badge || state.header.badge,
        pageBackBtn: payload.pageBackBtn || state.header.pageBackBtn,
      };
    },
    setLiveAction(state, action: PayloadAction<LiveActionType>) {
      state.liveAction = action.payload;
    },
    resetLiveAction(state) {
      state.liveAction = null;
    },
  },
});

export const {
  setGlobalLoading,
  resetHeader,
  setHeader,
  setPageLoading,
  setWindowWidth,
  setLiveAction,
  resetLiveAction,
} = globalSlice.actions;
export const globalReducer = globalSlice.reducer;
