import React, { useState } from "react";
import { TabType } from "Theme/components/TabsBar";
import { Account, Client, Documents, Representative } from "./components";

const useTabs = () => {
  const [currentTab, setCurrenTab] = useState<number>(0);

  const tabs: Array<TabType> = [
    {
      id: 0,
      label: "Клиент",
      node: <Client />,
    },
    {
      id: 1,
      label: "Представитель",
      node: <Representative />,
    },
    {
      id: 2,
      label: "Банковские реквизиты",
      node: <Account />,
    },
    {
      id: 3,
      label: "Документы",
      node: <Documents />,
    },
  ];

  return { currentTab, setCurrenTab, tabs };
};

export default useTabs;
