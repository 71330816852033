import {
  NotificationStatusGql,
  NotificationsAPI,
  OrderByCreatedDateNotificationGql,
} from "@front-packages/dfa-gql-api";
import { useAppDispatch, useAppSelector } from "store";
import {
  INotification,
  setAllIsReaded,
  setHasNewNotification,
  setNotifications,
  updateNotifications,
} from "store/notificationsCenter";
import { addNotification } from "store/notifications";
import { useEffect, useState } from "react";
import { useErrors } from "hooks/errors";

interface INotifOptions {
  value: "all" | "unread";
  title: "Все" | "Непрочитанные";
}

const useNotifications = () => {
  const notifOptions: INotifOptions[] = [
    { value: "all", title: "Все" },
    { value: "unread", title: "Непрочитанные" },
  ];
  const dispatch = useAppDispatch();
  const { setError } = useErrors();

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(null);
  const [selectedNotification, setSelectedNotification] = useState<"all" | "unread">(
    notifOptions[1]?.value || null
  );

  const { openNotificationsCenter, notifications } = useAppSelector(
    (state) => state.notificationsCenter
  );

  const getNotifications = async (status?: "all" | "unread", page?: number) => {
    const { response, error } = await NotificationsAPI.GetNotifications({
      opts: {
        pageIndex: page || page === 0 ? page : pageIndex,
        pageSize: 10,
        status: status === "unread" ? NotificationStatusGql.UnReadNotificationStatus : null,
        orderByCreatedDateDirection:
          OrderByCreatedDateNotificationGql.DescendingOrderByCreatedDateNotification,
      },
    });
    if (response) {
      setTotalCount(response?.count);
      const notifs: INotification[] = response?.notifications.map((notif) => {
        console.log(notif);
        return {
          createdDate: notif?.createdDate,
          id: notif?.id,
          message: notif?.data,
          // TODO: когда мидл исправит типы - будем брать без lowercase и без any
          type: notif.objectType.toLocaleLowerCase() as any,
          isReaded: notif?.status === NotificationStatusGql.ReadNotificationStatus,
          objectID: notif?.objectID,
        };
      });
      return notifs;
    }
    if (error) {
      dispatch(
        addNotification({
          type: "error",
          message:
            "При попытке получить данные об уведомлениях произошла непредвиденная ошибка на стороне сервера. Попробуйте позднее",
        })
      );
    }
    return undefined;
  };
  const handleSetNewNotifications = async () => {
    setPageIndex(0);
    const newNotifs = await getNotifications(selectedNotification, 0);
    dispatch(setNotifications(newNotifs));
    dispatch(setHasNewNotification(false));
  };

  const handleHasNewNotifications = (e) => {
    e.stopPropagation();
    handleSetNewNotifications();
    dispatch(setHasNewNotification(false));
  };

  const updateNotificationsAfterScroll = async (page: number) => {
    const newNotifs = await getNotifications(selectedNotification, page);
    dispatch(updateNotifications(newNotifs));
  };

  const changeNotifsStatuses = async () => {
    const { error } = await NotificationsAPI.UpdateNotificationsStatus({
      notificationIDs: notifications.map((n) => n.id),
      status: NotificationStatusGql.ReadNotificationStatus,
    });
    if (error) setError({ error, key: Math.random() });
  };
  const markAllAsReaded = async () => {
    dispatch(setAllIsReaded());
    await changeNotifsStatuses();
    const newNotifs = await getNotifications(selectedNotification);
    dispatch(setNotifications(newNotifs));
  };

  useEffect(() => {
    !openNotificationsCenter && handleSetNewNotifications();
  }, [openNotificationsCenter]);
  useEffect(() => {
    if (pageIndex) updateNotificationsAfterScroll(pageIndex);
  }, [pageIndex]);

  return {
    handleSetNewNotifications,
    pageIndex,
    setPageIndex,
    totalCount,
    handleHasNewNotifications,
    notifOptions,
    selectedNotification,
    setSelectedNotification,
    markAllAsReaded,
  };
};

export default useNotifications;
