import styled, { css } from "styled-components";
import { RoutesTypes } from "routes";

export interface ISidebarList {
  type: RoutesTypes;
  open: boolean;
  factor?: number;
}

export const StyledSidebarList = styled.ul<ISidebarList>`
  transition: height 200ms;
  ${({ type, open, factor }) =>
    type === RoutesTypes.First
      ? css`
          margin-bottom: 20px;
          overflow: auto;
          height: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
        `
      : css`
          overflow: hidden;
          margin-left: 5%;
          height: ${open && `${factor * 75}px`};
          height: ${!open && 0};
        `}
`;
