import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetCompanyInfo,
  GetRepresentativeInfo,
  GetBalance,
  PaymentDetails,
} from "@front-packages/dfa-gql-api";

export interface ICustomerState {
  balance: Partial<GetBalance>;
  company: Partial<GetCompanyInfo>;
  representative: Partial<GetRepresentativeInfo>;
  paymentDetails: Array<Partial<PaymentDetails>>;
}

const initialState: ICustomerState = {
  balance: null,
  company: null,
  representative: null,
  paymentDetails: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCompanyInfo(state, action: PayloadAction<Partial<GetCompanyInfo>>) {
      state.company = action.payload;
    },
    setRepresentativeInfo(state, action: PayloadAction<Partial<GetRepresentativeInfo>>) {
      state.representative = action.payload;
    },
    setBalance(state, action: PayloadAction<Partial<GetBalance>>) {
      state.balance = action.payload;
    },
    setPaymentDetails(state, action: PayloadAction<Array<Partial<PaymentDetails>>>) {
      state.paymentDetails = action.payload;
    },
  },
});

export const { setCompanyInfo, setRepresentativeInfo, setBalance, setPaymentDetails } =
  customerSlice.actions;
export const customerReducer = customerSlice.reducer;
