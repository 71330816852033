import React, { SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesTypes, RouteType } from "routes";
import { Button, Flex } from "Theme";
import { ChevronIcon } from "Theme/icons";
import { useAppDispatch, useAppSelector } from "store";
import { switchOpenSideBar } from "store/account";
import { Breakpoints } from "constants/in_lib";
import { LinkWrap, StyledSidebarItem, StyledSidebarLink } from "./StyledSidebarItem";
import { StyledSidebarList } from "../SidebarList";

interface ISidebarItemProps {
  getLinks(item: RouteType): React.ReactNode;
  item: RouteType;
}
const SidebarItem: React.FC<ISidebarItemProps> = ({ getLinks, item }) => {
  const { path, name, Icon, children, type } = item;

  const dispatch = useAppDispatch();
  const { openSideBar } = useAppSelector((state) => state.account);
  const { windowWidth } = useAppSelector((state) => state.global);
  const closeSideBar = () => dispatch(switchOpenSideBar());

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>();

  const click = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpen(true);
    navigate(path);
    if (windowWidth <= Breakpoints.laptopM && openSideBar) closeSideBar();
  };
  useEffect(() => {
    if (!pathname.includes(path)) setOpen(false);
  }, [pathname]);
  return (
    <StyledSidebarItem>
      <LinkWrap type={type} active={pathname === path}>
        <StyledSidebarLink href={path} onClick={click}>
          {Icon && (
            <Flex
              sx={{
                p: "5px 8px",
                justify: "center",
                align: "center",
                borderRadius: "10px",
                mr: 0.6,
                width: "40px",
                height: "40px",
              }}
              bgColor={pathname !== path ? "primary" : "white"}
              bgSaturation={100}
              bgType="gradient"
            >
              <Icon contrast={pathname !== path} size="medium" color="primary" />
            </Flex>
          )}
          {name}
        </StyledSidebarLink>
        {type === RoutesTypes.First && children && (
          <Flex sx={{ p: "15px 20px 15px 0" }}>
            <Button
              sx={{ ml: "auto" }}
              iconStart={
                <ChevronIcon
                  contrast={pathname === path}
                  size="medium"
                  rotate={open ? "up" : "down"}
                />
              }
              onlyIcon
              onClick={() => setOpen(!open)}
            />
          </Flex>
        )}
      </LinkWrap>
      {children && (
        <StyledSidebarList factor={children.length} type={children[0].type} open={open}>
          {children.map(getLinks)}
        </StyledSidebarList>
      )}
    </StyledSidebarItem>
  );
};

export default SidebarItem;
