const getDate = (date: Date | string) => {
  const hoursAndMinutes = `${new Date(date).toLocaleTimeString("ru", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
  const msToday = new Date().getHours() * 60 * 60 * 1000;
  if (Date.now() - new Date(date).getTime() < msToday) return `Сегодня в ${hoursAndMinutes}`;
  if (
    Date.now() - new Date(date).getTime() > msToday &&
    Date.now() - new Date(date).getTime() < msToday + 86400000
  )
    return `Вчера в ${hoursAndMinutes}`;
  return `${new Date(date).toLocaleDateString("ru", { dateStyle: "long" })} в ${hoursAndMinutes}`;
};

export default getDate;
