import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const BuildingIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 35)}
        height={getSize(size, 34)}
        fill="none"
      >
        <path
          fill="url(#a)"
          fillOpacity={0.25}
          d="M26.875 15.125v-15H8.125v7.5h-7.5v26.25h15v-7.5h3.75v7.5h15v-18.75h-7.5Zm-18.75 15h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Zm7.5 7.5h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Zm7.5 15h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Zm7.5 22.5h-3.75v-3.75h3.75v3.75Zm0-7.5h-3.75v-3.75h3.75v3.75Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0.625}
            x2={34.375}
            y1={33.875}
            y2={0.125}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#398151" />
            <stop offset={1} stopColor="#35C691" />
          </linearGradient>
        </defs>
      </svg>
    </Text>
  );
};

export default BuildingIcon;
