import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "routes";
import { useAppDispatch, useAppSelector } from "store";
import { resetHeader, setHeader } from "store/global";
import { Flex, NoData, Paginator, Table } from "Theme";
import useSecondaryMarketData from "./useSecondaryMarketData";
import { NewOrderModal } from "./components/Modals";
import { SearchForm } from "./components";

const SecondaryMarket: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pageLoading } = useAppSelector((state) => state.global);

  const {
    columns,
    marketRows,
    currentSortOption,
    newOrderModalType,
    onCloseNewOrderModal,
    tokenTicker,
    contract,
    tokenID,
    filterProps,
    setFilterProps,
    isLoading,
    page,
    setPage,
    totalCount,
    count,
  } = useSecondaryMarketData();

  useEffect(() => {
    dispatch(
      setHeader({
        title: "Вторичный рынок",
      })
    );
    return () => {
      dispatch(resetHeader());
    };
  }, []);

  return (
    <>
      <Flex sx={{ direction: "column", width: "100%", mt: "auto", flex: "1 1 0%", rowGap: 1 }}>
        <SearchForm
          filterProps={filterProps}
          setFilterProps={setFilterProps}
          isLoading={isLoading}
        />
        <Table
          rowHeight={60}
          titleRowSx={{ rowHeight: 60 }}
          columns={columns.current}
          rows={marketRows}
          rowSx={{ rowStyle: "white" }}
          currentSortOption={currentSortOption}
          onRowClick={(row) => navigate(`${RoutesNames.ViewTokens}?id=${row.id}`)}
        />
        {totalCount > count && (
          <Paginator
            sx={{ mt: 1 }}
            page={page}
            setPage={setPage}
            amount={Math.ceil(totalCount / count)}
          />
        )}
      </Flex>
      {!marketRows?.length && !pageLoading && (
        <NoData
          text={
            !marketRows ? "Нет токенов на вторичном рынке" : "Токены по вашему запросу не найдены"
          }
        />
      )}
      <NewOrderModal
        open={!!newOrderModalType}
        onClose={onCloseNewOrderModal}
        type={newOrderModalType}
        tokenTicker={tokenTicker}
        contract={contract}
        tokenID={tokenID}
      />
    </>
  );
};

export default SecondaryMarket;
