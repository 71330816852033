import { Token } from "@front-packages/dfa-gql-api";
import React from "react";
import { Badge, Dialog, Flex, Input, RowEntityProps } from "Theme";
import { useAppSelector } from "store";
import { formattingDate, getStatusBadgeProps } from "helpers";

interface IMainModalProps {
  open: boolean;
  isMakingContract: boolean;
  createOrder(): Promise<void>;
  onClose(): void;
  amount: number;
  setAmount(amount: number): void;
  token: Token;
  isLoading: boolean;
}
const CreateOrderModal: React.FC<IMainModalProps> = ({
  open,
  isMakingContract,
  createOrder,
  onClose,
  amount,
  setAmount,
  token,
  isLoading,
}) => {
  const { balance } = useAppSelector((state) => state.customer);

  const error =
    (amount && amount * token.nominal > token.maxOrderLimit && "Сумма заявки превышена") ||
    (amount && amount * token.nominal < token.minOrderLimit && "Сумма заявки ниже минимальной") ||
    "none";

  return (
    <Dialog
      open={open}
      maxWidth="md"
      type="info"
      confirmText="подтвердить"
      onConfirm={createOrder}
      onClose={onClose}
      withMissClick
      disabled={!amount || error !== "none"}
      loading={!isMakingContract ? isLoading : isMakingContract}
      title={!isMakingContract ? "Заявка на покупку актива" : "Идет подготовка договора..."}
      titleBadge={<Badge sx={{ ml: "auto" }} {...getStatusBadgeProps(token.status, "Token")} />}
      subTitle={
        !isMakingContract
          ? "Для отправки заявки на покупку выпуска, введите количество и подпишите заявку"
          : "Заявка принята на проверку. Идет подготовка договора... Пожалуйста, подождите."
      }
    >
      {!isMakingContract && (
        <Flex sx={{ rowGap: 0.5, direction: "column" }}>
          <RowEntityProps
            sx={{ mb: 1 }}
            color="info"
            title="Доступный баланс"
            value={`${balance?.fiat?.balance?.amount} RUB`}
            rows={1}
          />
          <RowEntityProps rows={1} title="Тикер" value={token.ticker} />
          <RowEntityProps rows={1} title="Эмитент" value={token.issuer} />
          <RowEntityProps
            rows={1}
            title="Дата старта"
            value={formattingDate(token.investStartDate)}
          />
          <RowEntityProps
            rows={1}
            title="Сумма выпуска"
            value={`${token.maxIssueLimit || 0} RUB`}
          />
          <RowEntityProps
            rows={1}
            title="Мин. сумма заявки"
            value={`${token.minOrderLimit || 0} RUB`}
          />
          <RowEntityProps
            rows={1}
            title="Макс. сумма заявки"
            value={`${token.maxOrderLimit || 0} RUB`}
          />
          <RowEntityProps rows={1} title="Номинал" value={`${token.nominal} RUB`} />
          <Flex sx={{ mt: 1, columnGap: 1, justify: "space-between" }}>
            <Input
              color="info"
              sx={{ width: "40%" }}
              placeholder="Количество"
              value={amount}
              onChange={setAmount}
            />
            <Input
              color="info"
              fullWidth
              placeholder="Сумма (RUB)"
              value={amount ? amount * token.nominal : "0"}
              onChange={undefined}
              error={error}
            />
          </Flex>
        </Flex>
      )}
    </Dialog>
  );
};

export default CreateOrderModal;
