import React, { useRef } from "react";
import { base64ToPdf } from "helpers";
import { Dialog, Flex } from "Theme";
import { PDFDoc } from "components";

interface IDVPBillModalProps {
  onClose(): void;
  onConfirm(): void;
  base64File: string;
  investFinishDate: string;
  open: boolean;
}
const DVPBillModal: React.FC<IDVPBillModalProps> = ({
  onClose,
  onConfirm,
  base64File,
  investFinishDate,
  open,
}) => {
  const pdfDoc = useRef<Blob>(base64ToPdf(base64File));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Счет на оплату - поставка против платежа"
      subTitle={`Заявка зарегистрирована. Внесите денежные средства на номинальный счет платформы до ${investFinishDate} Вы можете скачать счет сейчас или позже в разделе «Заявки»`}
      maxWidth="xxl"
      onConfirm={onConfirm}
      confirmText="Скачать"
      cancelText="Закрыть"
    >
      <Flex sx={{ overflowY: "scroll", height: "420px", width: "100%", m: "0 auto" }}>
        {pdfDoc.current && <PDFDoc pdfDoc={pdfDoc.current} />}
      </Flex>
    </Dialog>
  );
};

export default DVPBillModal;
