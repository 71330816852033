import { Flex, TabsBar } from "Theme";
import { getStatusBadgeProps } from "helpers";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { resetHeader, setHeader } from "store/global";
import useTabs from "./useTabs";

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { fullName, businessEntityType, status } = useAppSelector(
    (state) => state.customer.company
  );
  const { currentTab, setCurrenTab, tabs } = useTabs();

  useEffect(() => {
    dispatch(
      setHeader({
        title: "Профиль клиента",
        subTitle: `${businessEntityType} ${fullName}`,
        badge: { ...getStatusBadgeProps(status, "Company") },
      })
    );
    // getToken().then(() => dispatch(setPageLoading(false)));
    return () => {
      dispatch(resetHeader());
    };
  }, []);
  return (
    <Flex
      sx={{
        direction: "column",
        width: "100%",
        height: "100%",
        flex: "1 1 0%",
      }}
    >
      <TabsBar tabs={tabs} currentTab={currentTab} setTab={setCurrenTab} />
    </Flex>
  );
};

export default Profile;
