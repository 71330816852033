import { Bip39 } from "@cosmjs/crypto";
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { delay } from "@front-packages/dfa-helpers";

const getWalletFromFileKey = async (file: File): Promise<DirectSecp256k1HdWallet> => {
  if (!file) return null;
  let wallet: Promise<DirectSecp256k1HdWallet>;
  let isError = false;
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = async () => {
    const key = JSON.parse(`[${(reader.result as string).split(":")[0]}]`);
    if (Array.isArray(key) && key.length === 32) {
      const a: any = Bip39.encode(new Uint8Array(key));
      console.log(a);
      wallet = DirectSecp256k1HdWallet.fromMnemonic(a.data);
    } else isError = true;
  };
  const checkWallet = async () => {
    await delay(100);
    if (!wallet && !isError) await checkWallet();
  };
  await checkWallet();
  return isError ? null : wallet;
};

export default getWalletFromFileKey;
