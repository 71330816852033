import { DeactivationAPI } from "@front-packages/dfa-gql-api";
import useDialogState from "components/Dialogs/useDialogState";
import { useErrors } from "hooks";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { addNotification } from "store/notifications";
import { Dialog, MFADialog } from "Theme";

interface IDeactivateModalProps {
  isOpen: boolean;
  handleCloseDeactivateModal(): void;
}

const DeactivateModal: React.FC<IDeactivateModalProps> = ({
  isOpen,
  handleCloseDeactivateModal,
}) => {
  const dispatch = useAppDispatch();
  const { company } = useAppSelector((state) => state.customer);
  const { setError } = useErrors();
  const { state, getMfa, setState, cleanMfa } = useDialogState();
  const { mfa, loading } = state;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onClose = () => {
    handleCloseDeactivateModal();
    cleanMfa();
  };
  const onConfirm = async (e) => {
    setIsLoading(true);
    e.stopPropagation();
    const { response, error } = await DeactivationAPI.CreateDeactivationOrder({
      form: { clientID: company.id, mfaCode: mfa.code, mfaUUID: mfa.sessionID },
    });
    if (error) setError({ error, key: Math.random() });
    // TODO: добавить нотификацию ???
    if (response) {
      dispatch(
        addNotification({
          message: "Заявка на деактивацию аккаунта создана",
          type: "success",
          position: "right",
        })
      );
    }
    onClose();
    setIsLoading(false);
  };

  if (mfa) {
    return (
      <MFADialog
        open
        onClose={cleanMfa}
        descOperation="Выполняется операция по созданию заявки на деактивацию учетной записи"
        code={mfa.code}
        setCode={setState.code}
        onConfirm={(e) => onConfirm(e)}
        loading={isLoading}
        disabled={isLoading}
      />
    );
  }
  return (
    <Dialog
      open={isOpen}
      title="Деактивация учетной записи"
      subTitle="Вы действительно хотите деактивировать свою учетную запись?"
      // TODO: Добавить entityType, entityID, methodName когда будут на мидле
      onConfirm={() => getMfa("company", company.id, "CreateDeactivationOrder")}
      onClose={onClose}
      confirmText="Деактивировать"
      type="error"
      loading={loading}
      disabled={loading}
    />
  );
};

export default DeactivateModal;
