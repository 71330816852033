import styled, { css } from "styled-components";
import { ColorType, IAdaptive, MarginType, SizeType } from "../../types";
import { adaptiveStyles, marginStyles, sizeStyles } from "../../styles";

export type AlertType = "primary" | "info" | "success" | "warning" | "error" | "somePrimary";
export interface IStyledAlert extends IAdaptive {
  type?: AlertType;
  sx?: MarginType & SizeType;
}

interface IExtStyledAlert extends IStyledAlert {
  withAction: boolean;
}

export const StyledAlert = styled.div<IExtStyledAlert>`
  width: 100%;
  display: flex;
  position: relative;
  backdrop-filter: blur(50px);
  padding: 12px 15px;
  border-radius: 20px;
  align-items: flex-start;
  column-gap: 10px;
  transition: transform 0.2s ease-in-out;
  ${({ theme, type, withAction }) => css`
    box-shadow: 0 5px 10px ${theme.palette.black.main}${theme.palette.opacity[3]};
    background-image: ${theme.palette[type as ColorType].gradient[10]};
    border: 1px solid ${theme.palette[type as ColorType].light}33;
    cursor: ${withAction && "pointer"};
    &:hover {
      transform: ${withAction && " translateY(-0.2rem)"};
      cursor: ${withAction && "pointer"};
      box-shadow: ${withAction && "0 10px 19px #00000015"};
    }
  `}
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;
StyledAlert.defaultProps = {
  type: "success",
};
