import styled, { css, keyframes } from "styled-components";
import { ColorType, MarginType, PositionType } from "../../types";
import { marginStyles, positionStyles } from "../../styles";

export interface ISquareLoader {
  size?: number;
  sx?: MarginType & PositionType;
  color1?: ColorType;
  color2?: ColorType;
}

const spin = keyframes`
  0%,
  100% {
    box-shadow: .2em 0 0 0 currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0 currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
`;

const Loader = styled.span<ISquareLoader>`
  ${({ theme, color1, color2, size }) => css`
    width: ${size}px;
    height: ${size}px;
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    color: ${theme.palette[color1].light};
    :before,
    :after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s ${spin} linear infinite;
    }
    :after {
      color: ${theme.palette[color2].light};
      transform: rotateY(70deg);
      animation-delay: 0.4s;
    }
  `}
  ${({ sx }) => positionStyles(sx)};
  ${({ sx }) => marginStyles(sx)};
`;

Loader.defaultProps = {
  size: 50,
  color1: "success",
  color2: "info",
};

export default Loader;
