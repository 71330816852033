import React, {
  ChangeEvent,
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useState,
} from "react";
import {
  StyledTextArea,
  IStyledTextArea,
  TextAreaWrap,
  TextAreaError,
  TextAreaLabel,
} from "./TextArea";

export type OnChangeType = (value: string | number) => void;
interface ITextAreaProps extends IStyledTextArea {
  value: string | number;
  onChange: Dispatch<SetStateAction<string | number>> | OnChangeType;
  placeholder?: string;
  maxLength?: number;
  name?: string;
  rows?: number;
}

const TextArea = forwardRef(
  (
    { value, onChange, placeholder, color = "primary", isRequired, ...props }: ITextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value);
    const handleFocus = () => setIsFocus(true);
    const handleBlur = () => setIsFocus(false);
    return (
      <TextAreaWrap {...{ ...props, color }}>
        <StyledTextArea
          ref={ref}
          onChange={handleOnChange}
          value={value || ""}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={props.readonly}
          {...{ ...props, color }}
        />
        {placeholder && (
          <TextAreaLabel
            isRequired={isRequired}
            focus={isFocus || !!value}
            color={color}
            error={props.error}
            title={placeholder}
          >
            {placeholder}
          </TextAreaLabel>
        )}
        {props.error !== "none" && (
          <TextAreaError
            title={typeof props.error === "string" ? props.error : undefined}
            {...{ ...props, color: "error" }}
          >
            {props.error}
          </TextAreaError>
        )}
      </TextAreaWrap>
    );
  }
);

export default TextArea;
