import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import { deleteCookie } from "@front-packages/dfa-helpers";

type OnBoardingStepType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export interface IAccountState {
  isAuth: boolean;
  onBoardingStep: OnBoardingStepType;
  isAccountIssuer: boolean;
  openCustomerInfo: boolean;
  openSideBar: boolean;
  issuesRedeemFilter: boolean;
}

const initialState: IAccountState = {
  isAuth: false,
  onBoardingStep: 0,
  isAccountIssuer: !!window.localStorage.getItem("isIssuer"),
  openCustomerInfo: false,
  openSideBar: false,
  issuesRedeemFilter: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setIsAuth(state, { payload }: PayloadAction<boolean>) {
      if (!payload) {
        deleteCookie(CookieNamesEnum.token);
        deleteCookie(CookieNamesEnum.refreshToken);
        window.localStorage.removeItem("isIssuer");
        state.isAccountIssuer = false;
        state.onBoardingStep = 0;
        // window.location.pathname = RoutesNames.SignIn;
      }
      state.isAuth = payload;
    },
    switchOpenCustomerInfo(state) {
      state.openCustomerInfo = !state.openCustomerInfo;
    },
    switchOpenSideBar(state) {
      state.openSideBar = !state.openSideBar;
    },
    setOpenSideBar(state, { payload }: PayloadAction<boolean>) {
      state.openSideBar = payload;
    },
    switchInvestorAccount(state) {
      window.localStorage.removeItem("isIssuer");
      state.isAccountIssuer = false;
    },
    switchIssuerAccount(state) {
      window.localStorage.setItem("isIssuer", "true");
      state.isAccountIssuer = true;
    },
    setOnBoardingStep(state, { payload }: PayloadAction<OnBoardingStepType>) {
      state.onBoardingStep = payload;
    },
    setIssuesRedeemFilter(state, { payload }: PayloadAction<boolean>) {
      state.issuesRedeemFilter = payload;
    },
  },
});

export const {
  setIsAuth,
  switchIssuerAccount,
  switchInvestorAccount,
  switchOpenCustomerInfo,
  switchOpenSideBar,
  setOnBoardingStep,
  setIssuesRedeemFilter,
  setOpenSideBar,
} = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
