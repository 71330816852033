import { ITheme } from "./types";
import { helpers, issuerPalette, investorPalette, typography, shadows, device } from "./base";

const Theme = (isIssuerTheme: boolean): ITheme => ({
  palette: isIssuerTheme ? issuerPalette : investorPalette,
  helpers,
  typography,
  shadows,
  device,
});

export default Theme;
