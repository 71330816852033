import styled, { css } from "styled-components";
import { ColorType, FlexType, IAdaptive, MarginType, PaddingType, SizeType } from "../../types";
import { adaptiveStyles, marginStyles, paddingStyles, sizeStyles } from "../../styles";

export interface IInfoCard extends IAdaptive {
  bgColor?: ColorType;
  bgWhite?: boolean;
  sx?: MarginType & SizeType & PaddingType & FlexType;
  filled?: boolean;
  withBorder?: boolean;
  fullWidth?: boolean;
}
// TODO: transition раскомментировать, если надо будет добавлять hover.
export const StyledInfoCard = styled.div<IInfoCard>`
  display: flex;
  padding: 15px 10px;
  column-gap: 5px;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  max-height: max-content;
  position: relative;
  box-shadow: 0 3px 10px #00000010;
  ${({ theme, filled = true, bgColor = "primary", withBorder = true, bgWhite, fullWidth }) => css`
    background-color: ${filled && !bgWhite && theme.palette[bgColor].light}${theme.palette.opacity[5]};
    background-color: ${bgWhite && "white"};
    border: ${withBorder &&
    !bgWhite &&
    `1px solid ${theme.palette[bgColor].light}${theme.palette.opacity[10]}`};
    color: ${filled && !bgWhite ? theme.palette.white.main : theme.palette.text.main};
    max-width: ${fullWidth ? "100%" : "max-content"};
  `}
  flex: ${({ sx }) => sx?.flex};
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => paddingStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
`;
