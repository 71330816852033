import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import { ActiveButton, Delimiter, IStyledPaginator, Wrap } from "./Paginator";
import { ChevronIcon } from "../../icons";
import Flex from "../Flex";
import Text from "../Text";
import Input from "../Input";

interface IPaginatorProps extends IStyledPaginator {
  page: number;
  amount: number;
  setPage(page: number): void;
  withInput?: boolean;
}

const Paginator: React.FC<IPaginatorProps> = ({ amount, page, setPage, withInput, ...props }) => {
  const [pages, setPages] = useState<Array<number>>(null);
  const [renderPages, setRenderPages] = useState<Array<number>>(null);
  const [input, setInput] = useState<string>("");
  const inputRef = useRef<HTMLDivElement>(null);

  const prevPage = () => setPage(page - 1);
  const nextPage = () => setPage(page + 1);
  const handleSetInput = (val) => {
    if (val !== "0" && pages.includes(+val)) setInput(val);
    else if (val === "0") setInput("1");
    else setInput(`${amount}`);
  };

  useEffect(() => {
    const newPages = [];
    for (let i = 0; i < amount; i++) newPages.push(i);
    setPages(newPages);
    if (amount <= 10) {
      setRenderPages(null);
    } else {
      if (page < 3) setRenderPages(newPages.slice(0, 4));
      if (page === 3) setRenderPages(newPages.slice(2, page + 3));
      if (page > 3 && page < amount - 4) setRenderPages(newPages.slice(page - 2, page + 3));
      if (page === amount - 1) setRenderPages(newPages.slice(page - 3, amount));
      if (page === amount - 2) setRenderPages(newPages.slice(page - 2, amount));
      if (page === amount - 3) setRenderPages(newPages.slice(page - 1, amount));
      if (page === amount - 4) setRenderPages(newPages.slice(page - 2, amount - 2));
    }
  }, [page, amount]);
  useEffect(() => {
    const inputPage = (e) => {
      if (e.charCode === 13) setPage(+e.target.value - 1);
    };
    if (inputRef.current) inputRef.current.addEventListener("keypress", inputPage);
  }, [inputRef.current]);
  if (!amount || amount <= 1) return null;
  return (
    <Wrap {...props}>
      {pages && page !== pages[0] && (
        <Button
          onlyIcon
          onClick={prevPage}
          iconStart={<ChevronIcon size="medium" rotate="left" />}
        />
      )}
      {!renderPages &&
        pages &&
        pages.map((el, i) => (
          <ActiveButton key={`page-${el}`} active={page === el} withMargin={i !== 0}>
            <Button onClick={() => setPage(el)} variant="text" sx={{ size: "MD" }}>{`${
              el + 1
            }`}</Button>
          </ActiveButton>
        ))}
      {renderPages && (
        <>
          {renderPages[0] !== 0 && (
            <>
              <ActiveButton active={page === 0}>
                <Button onClick={() => setPage(0)} variant="text" sx={{ size: "MD" }}>
                  1
                </Button>
              </ActiveButton>
              <Delimiter>...</Delimiter>
            </>
          )}
          {renderPages.map((el, i) => (
            <ActiveButton active={page === el} withMargin={i !== 0} key={`page-${el}`}>
              <Button variant="text" onClick={() => setPage(el)} sx={{ size: "MD" }}>{`${
                el + 1
              }`}</Button>
            </ActiveButton>
          ))}
          {renderPages[renderPages.length - 1] !== amount - 1 && (
            <>
              <Delimiter>...</Delimiter>
              <ActiveButton active={page === amount - 1} withMargin>
                <Button onClick={() => setPage(amount - 1)} variant="text" sx={{ size: "MD" }}>
                  {amount.toString()}
                </Button>
              </ActiveButton>
            </>
          )}
        </>
      )}
      {pages && page !== pages[pages.length - 1] && (
        <Button
          onClick={nextPage}
          sx={{ ml: 0.5 }}
          onlyIcon
          iconStart={<ChevronIcon size="medium" rotate="right" />}
        />
      )}
      {withInput && (
        <Flex ref={inputRef} sx={{ align: "center", ml: "auto" }}>
          <Text sx={{ mr: 0.5 }}>Перейти на</Text>
          <Input
            value={input}
            onChange={handleSetInput}
            sx={{ p: "4.5px 10px", maxWidth: "80px", maxHeight: "36px" }}
          />
          <Text sx={{ ml: 0.5 }}>страницу</Text>
        </Flex>
      )}
    </Wrap>
  );
};

export default Paginator;
