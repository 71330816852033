import React, { useEffect, useState } from "react";
import { RedeemAPI } from "@front-packages/dfa-gql-api";
import { TokenStatus } from "constants/in_lib";
import { formattingDate } from "helpers";
import { useErrors } from "hooks";
import { RowsType } from "Theme";
import { useAppSelector } from "store";
import { RedeemJournalsColumnsType, RedeemJournalsItemsColumnsType } from "../../types";
import OpenModalBtn from "../OpenModalBtn";

const useRedeemJournalsTable = (tokenID: string) => {
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const { setError } = useErrors();

  const [journalNumber, setJournalNumber] = useState<string>(null);
  const [countOfJournals, setCountOfJournals] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [rows, setRows] = useState<RowsType<RedeemJournalsColumnsType>>([]);

  const [openJournalItemsModal, setOpenJournalItemsModal] = useState<boolean>(false);
  const [journalItemsRows, setJournalItemsRows] = useState<
    RowsType<RedeemJournalsItemsColumnsType>
  >([]);
  const [journalItemsLoading, setJournalItemsLoading] = useState<boolean>(false);
  const getJournalItems = async (id: string, nominal: number, journNumber: string) => {
    setJournalNumber(journNumber);
    setJournalItemsLoading(true);
    const data = await RedeemAPI.GetRedeemJournalItems({
      opts: { count: 10, page: 0, journalID: id },
    });
    if (data?.error) setError({ error: data.error, key: Math.random() });
    if (data?.response) {
      setJournalItemsRows(
        data.response.journalItems?.map((item) => {
          return {
            id: item.id,
            investor: item?.investor?.fullName,
            amount: `${item.tokensRedeemAmount} ШТ`,
            sum: `${nominal * item.tokensRedeemAmount} RUB`,
          };
        })
      );
    }
    setJournalItemsLoading(false);
  };
  const onOpenJournalItemsModal = async (id: string, nominal: number, journNumber: string) => {
    await getJournalItems(id, nominal, journNumber);
    setOpenJournalItemsModal(true);
  };
  const onCloseJournalItemsModal = () => setOpenJournalItemsModal(false);

  const getJournals = async () => {
    setLoading(true);
    const { response, error } = await RedeemAPI.GetRedeemJournal({
      opts: { count: 10, page: 0, tokenID },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setCountOfJournals(response.count);
      setRows(
        response.journal?.map((j) => {
          return {
            id: j.id,
            number: `№ ${j.regNumber}`,
            createdDate: formattingDate(j.createDate),
            status: TokenStatus[j.status],
            actions: (
              <OpenModalBtn
                onOpen={() =>
                  onOpenJournalItemsModal(
                    j.id,
                    j.redeemedFiatAmount / j.redeemedTokenAmount,
                    j.regNumber
                  )
                }
              />
            ),
          };
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    tokenID && isAccountIssuer && getJournals();
  }, [tokenID]);

  return {
    countOfJournals,
    rows,
    loading,
    journalItemsRows,
    journalItemsLoading,
    journalNumber,
    openJournalItemsModal,
    onCloseJournalItemsModal,
  };
};

export default useRedeemJournalsTable;
