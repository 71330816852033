import { useState } from "react";
import {
  GetTokensResponse,
  TokensAPI,
  GetTokensOpts,
  TokenStatusGql,
} from "@front-packages/dfa-gql-api";
import { setPageLoading } from "store/global";
import { useAppDispatch, useAppSelector } from "store";
import { useErrors } from "hooks";

type GetTokensType = (opts: GetTokensOpts) => Promise<number>;
export type UseTokensStateType = () => {
  tokens: GetTokensResponse;
  getTokens: GetTokensType;
};

export const useTokensState: UseTokensStateType = () => {
  const dispatch = useAppDispatch();
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const { setError } = useErrors();
  const [tokens, setTokens] = useState<GetTokensResponse>(null);

  const getTokens: GetTokensType = async (opts) => {
    dispatch(setPageLoading(true));
    const { response, error } = await TokensAPI.GetTokens({
      opts: {
        ...opts,
        tokenStatuses: isAccountIssuer
          ? opts.tokenStatuses
          : [
              TokenStatusGql.Started,
              // TokenStatusGql.Canceled,
              // TokenStatusGql.Issued,
              // TokenStatusGql.InRedeem,
              // TokenStatusGql.Redeemed,
            ],
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setTokens(response);
      return response.count;
    }
    return null;
  };

  return { tokens, getTokens };
};
