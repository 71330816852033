import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import { getDate } from "helpers";
import { Button, Flex, Text } from "Theme";
import { setIsReaded, setOpenNotificationsCenter } from "store/notificationsCenter";
import { RoutesNames } from "routes";
import { NotificationStatusGql, NotificationsAPI } from "@front-packages/dfa-gql-api";
import { DiamondIcon, DocumentIcon, WalletIcon } from "Theme/icons";
import { IIcon } from "Theme/icons/default";
import {
  IStyledNotificationItem,
  StyledImgWraper,
  StyledNotificationItem,
} from "./NotificationItem";

interface INotificationItem extends IStyledNotificationItem {
  id: string;
  message: string;
  // color?: ColorType;
  // TODO: дополнить, как будет готова апишка
  type: "company" | "token" | "deposit" | "withdraw" | "representative" | "order";
  createdDate: Date | string;
  isReaded: boolean;
  isLast?: boolean;
  objectID?: string;
}

const NotificationItem: React.FC<INotificationItem> = ({
  message,
  id,
  color = "primary",
  type,
  createdDate,
  isReaded,
  isLast = false,
  objectID,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const markAsReaded = (e) => {
    e.stopPropagation();
    // TODO: добавить апишку для смены статуса
    if (!isReaded) dispatch(setIsReaded({ id, isReaded: true }));
    NotificationsAPI.UpdateNotificationsStatus({
      notificationIDs: [id],
      status: NotificationStatusGql.ReadNotificationStatus,
    });
  };
  // const onClickOrdersHistoryBtn = () => {
  //   dispatch(setOpenNotificationsCenter(false));
  //   navigate("/briefcase/orders-history");
  // };

  const goToIssue = (e) => {
    navigate(`${RoutesNames.ViewIssues}?id=${objectID}`);
    markAsReaded(e);
    dispatch(setOpenNotificationsCenter(false));
  };

  const getImg = () => {
    const defaultProps: IIcon = {
      color: "primary",
      size: "medium",
      opacity: 40,
    };
    switch (type) {
      case "token":
        return <DiamondIcon {...defaultProps} />;
      case "deposit":
        return <WalletIcon {...defaultProps} />;
      case "withdraw":
        return <WalletIcon {...defaultProps} />;
      case "order":
        return <DocumentIcon {...defaultProps} />;
      default:
        return <DocumentIcon {...defaultProps} />;
    }
  };

  return (
    <StyledNotificationItem color={color} isLast={isLast} isReaded={isReaded}>
      {!isReaded && (
        <Flex
          sx={{
            position: "absolute",
            top: "5px",
            left: "10px",
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          bgColor="primary"
          onClick={(e) => markAsReaded(e)}
        />
      )}
      <Flex sx={{ columnGap: 1 }}>
        {/* <Flex
          bgColor={color}
          sx={{ width: "50px", height: "50px", borderRadius: "50px", shrink: 0 }}
        /> */}
        <StyledImgWraper>{getImg()}</StyledImgWraper>
        <Flex sx={{ direction: "column", rowGap: 0.7 }} rows={2}>
          <Text opacity={70} sx={{ weight: "normal" }} variant="h6" color="secondary">
            {getDate(createdDate)}
          </Text>
          <Text sx={{ size: "SM" }} rows={2} title={message}>
            {message}
          </Text>
          {type === "token" && (
            <Flex sx={{ columnGap: 1 }}>
              {/* <Button sx={{ size: "XXS" }} color="primary">
                Подписать
              </Button> */}
              <Button sx={{ size: "XXS" }} color="primary" onClick={goToIssue}>
                Перейти к выпуску
              </Button>
            </Flex>
          )}
          {/* {type === "ordersHistory" && (
            <Button sx={{ size: "XXS" }} onClick={onClickOrdersHistoryBtn}>
              Перейти к списку поручений
            </Button>
          )} */}
          {/* <Text opacity={70} sx={{ weight: "normal" }} variant="h6" color="secondary">
            {getDate(createdDate)}
          </Text> */}
        </Flex>
      </Flex>
    </StyledNotificationItem>
  );
};

export default NotificationItem;
