import React from "react";
import { IStyledAlert, StyledAlert, AlertType } from "./Alert";
import Text from "../Text";
import Flex from "../Flex";
import Button from "../Button";
import {
  CircleCheckIcon,
  ErrorIcon,
  HelpIcon,
  IconCross,
  ReportIcon,
  WarningIcon,
  VerifiedIcon,
} from "../../icons";
import { ColorType } from "../../types";

interface IAlertProps extends IStyledAlert {
  title: string;
  show: boolean;
  text?: string;
  action?(): void;
  icon?: React.ReactNode | "none";
  onClose?(): void;
}

const getIcon = (type: AlertType): React.ReactNode => {
  switch (type) {
    case "success":
      return <CircleCheckIcon opacity={70} size="medium" color="success" />;
    case "warning":
      return <WarningIcon size="medium" color="warning" />;
    case "error":
      return <ReportIcon opacity={70} size="medium" color="error" />;
    case "info":
      return <ErrorIcon opacity={70} size="medium" color="info" />;
    case "primary":
      return <VerifiedIcon opacity={70} size="medium" color="primary" />;
    case "somePrimary":
      return <VerifiedIcon opacity={70} size="medium" color="somePrimary" />;
    default:
      return <HelpIcon opacity={70} size="medium" color={type as ColorType} />;
  }
};

const Alert: React.FC<IAlertProps> = ({ icon, show, onClose, text, action, title, ...props }) => {
  const { type = "success" } = props;

  if (!show) return null;
  return (
    <StyledAlert {...props} withAction={!!action} onClick={action || undefined}>
      {onClose && (
        <Button
          onlyIcon
          onClick={onClose}
          iconStart={<IconCross opacity={70} size="small" color={type as ColorType} light />}
          sx={{ position: "absolute", top: "12px", right: "15px" }}
        />
      )}
      {icon || getIcon(type)}
      <Flex sx={{ direction: "column" }}>
        <Text variant="h5" color={type as ColorType} sx={{ mb: 0.5 }}>
          {title}
        </Text>
        {text && <Text light>{text}</Text>}
      </Flex>
    </StyledAlert>
  );
};

export default Alert;
