import styled, { css } from "styled-components";

export const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  ${({ theme }) => css`
    ${theme.device.tablet} {
      flex-direction: column;
      row-gap: 1rem;
    }
  `}
`;

export const Controls = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-left: auto;
`;
