import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { notificationsReducer } from "./notifications";
import { customerReducer } from "./customer";
import { globalReducer } from "./global";
import { accountReducer } from "./account";
import { dialogsReducer } from "./dialogs";
import { notificationsCenterReducer } from "./notificationsCenter";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    notifications: notificationsReducer,
    customer: customerReducer,
    global: globalReducer,
    dialogs: dialogsReducer,
    notificationsCenter: notificationsCenterReducer,
  },
});

type RootState = ReturnType<typeof store.getState>;
type AddDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
