import { css } from "styled-components";
import {
  AdaptiveStyle,
  BackgroundType,
  BorderType,
  CursorType,
  FlexType,
  GridItemType,
  GridType,
  MarginType,
  PaddingType,
  PositionType,
  SizeType,
} from "../types";
import { device } from "../base";

export const marginStyles = (sx: MarginType) =>
  css`
    margin: ${sx?.m};
    margin-top: ${sx?.mt && sx?.mt !== "auto" && `${sx?.mt}rem`};
    margin-right: ${sx?.mr && sx?.mr !== "auto" && `${sx?.mr}rem`};
    margin-bottom: ${sx?.mb && sx?.mb !== "auto" && `${sx?.mb}rem`};
    margin-left: ${sx?.ml && sx?.ml !== "auto" && `${sx?.ml}rem`};
    margin-top: ${sx?.mt === "auto" && sx?.mt};
    margin-right: ${sx?.mr === "auto" && sx?.mr};
    margin-bottom: ${sx?.mb === "auto" && sx?.mb};
    margin-left: ${sx?.ml === "auto" && sx?.ml};
  `;
export const paddingStyles = (sx: PaddingType) =>
  css`
    padding: ${sx?.p};
    padding-top: ${sx?.pt && sx?.pt !== "auto" && `${sx?.pt}rem`};
    padding-right: ${sx?.pr && sx?.pr !== "auto" && `${sx?.pr}rem`};
    padding-bottom: ${sx?.pb && sx?.pb !== "auto" && `${sx?.pb}rem`};
    padding-left: ${sx?.pl && sx?.pl !== "auto" && `${sx?.pl}rem`};
    padding-top: ${sx?.pt === "auto" && sx?.pt};
    padding-right: ${sx?.pr === "auto" && sx?.pr};
    padding-bottom: ${sx?.pb === "auto" && sx?.pb};
    padding-left: ${sx?.pl === "auto" && sx?.pl};
  `;
export const sizeStyles = (sx: SizeType) =>
  css`
    width: ${sx?.width};
    min-width: ${sx?.minWidth};
    max-width: ${sx?.maxWidth};
    height: ${sx?.height};
    min-height: ${sx?.minHeight};
    max-height: ${sx?.maxHeight};
  `;
export const bgStyles = (sx: BackgroundType) =>
  css`
    background: ${sx?.bg};
    background-color: ${sx?.bgColor};
    background-image: ${sx?.bgImage};
  `;
export const borderStyles = (sx: BorderType) =>
  css`
    border: ${sx?.border};
    border-radius: ${sx?.borderRadius};
    border-bottom-right-radius: ${sx?.borderRadiusBottomRight};
    border-bottom-left-radius: ${sx?.borderRadiusBottomLeft};
    border-top-left-radius: ${sx?.borderRadiusTopLeft};
    border-top-right-radius: ${sx?.borderRadiusTopRight};
    border-left: ${sx?.borderLeft};
    border-right: ${sx?.borderRight};
    border-top: ${sx?.borderTop};
    border-bottom: ${sx?.borderBottom};
  `;
export const positionStyles = (sx: PositionType) =>
  css`
    position: ${sx?.position};
    top: ${sx?.top};
    bottom: ${sx?.bottom};
    right: ${sx?.right};
    left: ${sx?.left};
    transform: ${sx?.transform};
  `;
export const flexStyles = (sx: FlexType) =>
  css`
    display: ${(sx?.direction ||
      sx?.justify ||
      sx?.align ||
      sx?.flex ||
      sx?.wrap ||
      sx?.rowGap ||
      sx?.columnGap) &&
    "flex"};
    flex-direction: ${sx?.direction};
    justify-content: ${sx?.justify};
    justify-self: ${sx?.justifySelf};
    align-items: ${sx?.align};
    align-self: ${sx?.alignSelf};
    flex: ${sx?.flex};
    flex-wrap: ${sx?.wrap};
    gap: ${sx?.gap && `${sx?.gap}rem`};
    row-gap: ${sx?.rowGap && `${sx?.rowGap}rem`};
    column-gap: ${sx?.columnGap && `${sx?.columnGap}rem`};
  `;
export const gridStyles = (sx: GridType) =>
  css`
    gap: ${sx?.gap && `${sx?.gap}rem`};
    row-gap: ${sx?.rowGap && `${sx?.rowGap}rem`};
    column-gap: ${sx?.columnGap && `${sx?.columnGap}rem`};
  `;
export const gridItemStyles = (
  gridArea: GridItemType[keyof Pick<GridItemType, "gridArea">],
  placeSelf: GridItemType[keyof Pick<GridItemType, "placeSelf">],
  flexColumnGap: GridItemType[keyof Pick<GridItemType, "flexColumnGap">]
) =>
  css`
    grid-area: ${gridArea && gridArea};
    place-self: ${placeSelf && placeSelf};
    column-gap: ${flexColumnGap && `${flexColumnGap}rem`};
  `;
export const cursorStyles = (sx: CursorType) =>
  css`
    cursor: ${sx?.cursor};
  `;
export const adaptiveStyles = (adaptiveSX: AdaptiveStyle) => {
  const stylesArray = adaptiveSX
    ? Object.keys(adaptiveSX).map(
        (type) => css`
          ${device[type]} {
            ${marginStyles(adaptiveSX[type])}
            ${paddingStyles(adaptiveSX[type])}
            ${sizeStyles(adaptiveSX[type])}
            ${bgStyles(adaptiveSX[type])}
            ${borderStyles(adaptiveSX[type])}
            ${positionStyles(adaptiveSX[type])}
            ${flexStyles(adaptiveSX[type])}
            display: ${adaptiveSX[type]?.display};
            line-height: ${adaptiveSX[type]?.lineHeight || 1};
            text-decoration: ${adaptiveSX[type]?.decoration};
            text-transform: ${adaptiveSX[type]?.textTransform};
            text-decoration: ${adaptiveSX[type]?.textDecoration};
            text-align: ${adaptiveSX[type]?.textAlign};
            white-space: ${adaptiveSX[type]?.whiteSpace};
            font-size: ${adaptiveSX[type]?.cssSize &&
            typeof adaptiveSX[type]?.cssSize === "number" &&
            `${adaptiveSX[type].cssSize}px`};
            font-size: ${adaptiveSX[type]?.cssSize &&
            typeof adaptiveSX[type]?.cssSize !== "number" &&
            `${adaptiveSX[type].cssSize}`};
            grid-template-columns: ${adaptiveSX[type]?.templateColumns};
            grid-template-rows: ${adaptiveSX[type]?.templateRows};
            grid-template-areas: ${adaptiveSX[type]?.areas};
            place-self: ${adaptiveSX[type]?.placeSelf};
          }
        `
      )
    : [];
  const styles = [];
  stylesArray.forEach((style) => {
    styles.push(...style);
  });
  return styles;
};
