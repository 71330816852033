import { Breakpoints } from "../../constants/in_lib";

export interface IDevices {
  mobileS: string;
  mobile: string;
  tablet: string;
  tabletL: string;
  laptop: string;
  laptopM: string;
  laptopL: string;
  desktop: string;
}

export const device: IDevices = {
  mobileS: `@media (max-width: ${Breakpoints.mobileS}px)`,
  mobile: `@media (max-width: ${Breakpoints.mobile}px)`,
  tablet: `@media (max-width: ${Breakpoints.tablet}px)`,
  tabletL: `@media (max-width: ${Breakpoints.tabletL}px)`,
  laptop: `@media (max-width: ${Breakpoints.laptop}px)`,
  laptopM: `@media (max-width: ${Breakpoints.laptopM}px)`,
  laptopL: `@media (max-width: ${Breakpoints.laptopL}px)`,
  desktop: `@media (max-width: ${Breakpoints.desktop}px)`,
};
