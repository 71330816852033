import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EncodeObject } from "@cosmjs/proto-signing";
import { DatePicker, Dialog, Flex, Input, RowEntityProps, Text } from "Theme";
import { MarketAPI, MarketOrderTypeGql } from "@front-packages/dfa-gql-api";
import SigningDialog from "components/Dialogs/Signing";
import { useAppSelector } from "store";
import { amountWithSpaces } from "helpers";
import { useErrors } from "hooks";
import { OrderModalType } from "../../../types";

interface INewOrderModalProps {
  open: boolean;
  onClose(): void;
  type: OrderModalType;
  tokenTicker: string;
  contract: string;
  tokenID: string;
  queryAfterTx?(): Promise<void>;
}

const NewOrderModal: React.FC<INewOrderModalProps> = ({
  open,
  onClose,
  type,
  tokenTicker,
  contract,
  tokenID,
  queryAfterTx,
}) => {
  const { setError } = useErrors();
  const { balance } = useAppSelector((store) => store.customer);
  const [amount, setAmount] = useState<number>(null);
  const [fiatPrice, setFiatPrice] = useState<number>(null);
  const [expireDate, setExpireDate] = useState<Date | string>(null);
  const [channelId, setChannelId] = useState<string>("");
  const [marketOrderId, setMarketOrderId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const color = type === "buy" ? "success" : "warning";

  const createMarketOrder = async () => {
    const { response, error } = await MarketAPI.CreateMarketOrder({
      createMarketOrder: {
        expireDate: new Date(expireDate),
        fiatPrice,
        orderType:
          type === "buy"
            ? MarketOrderTypeGql.BuyMarketOrderType
            : MarketOrderTypeGql.SellMarketOrderType,
        tokenAmount: amount,
        tokenTicker,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) return response;
    return undefined;
  };

  const getChannelId = async () => {
    const { response, error } = await MarketAPI.GetChannel();
    if (error) setError({ error, key: Math.random() });
    if (response)
      setChannelId(
        response?.channels?.find((channel) => channel.state === "STATE_OPEN")?.channelID
      );
  };

  const getMessage = (sender: string): EncodeObject => {
    const ibcId = uuidv4();
    return {
      typeUrl: "/rr.bcpcore.wasm.MsgExecuteContract",
      value: {
        sender,
        contract,
        msg: btoa(
          JSON.stringify({
            market_send_register_order: {
              port: "market",
              channel_id: channelId,
              timeout_timestamp: (Date.now() + 60000) * 1000000, // nanosec
              current_time: Date.now() * 1000000, // nanosec
              order_id: marketOrderId,
              order_type: type === "sell" ? 0 : 1,
              token_price: fiatPrice,
              amount,
              close_date: new Date(expireDate).getTime() / 1000, // sec
              transaction_guid: uuidv4(),
              ibc_id: ibcId,
            },
          })
        ),
        ibcId,
      },
    };
  };

  const onConfirm = async () => {
    setIsLoading(true);
    // TODO: на мидле должны поменять метод и он должен возвращать ид
    const id = await createMarketOrder();
    if (id) {
      setMarketOrderId(id);
      await getChannelId();
    }
    setIsLoading(false);
  };

  const onCloseNewOrderDialog = () => {
    onClose();
    setAmount(null);
    setFiatPrice(null);
    setExpireDate(null);
  };

  const onCloseSigningDialog = () => {
    onCloseNewOrderDialog();
    setChannelId("");
    setMarketOrderId("");
  };

  if (channelId && marketOrderId && contract)
    return (
      <SigningDialog
        // TODO: точно ли правильно и то и то добавлять?! Если мидл поменяет - убрать tokenID
        ids={{ orderID: marketOrderId, tokenID }}
        getMessage={getMessage}
        open={!!channelId}
        close={onCloseSigningDialog}
        queryAfterTx={queryAfterTx}
        // operationName="по подписанию заявки на приобретению"
      />
    );

  return (
    <Dialog
      open={open}
      onClose={onCloseNewOrderDialog}
      type={color}
      title={`Новая заявка на ${type === "buy" ? "покупку" : "продажу"}`}
      confirmText="разместить заявку"
      onConfirm={onConfirm}
      loading={isLoading}
    >
      <Flex sx={{ direction: "column", rowGap: 1 }}>
        <RowEntityProps title="Тикер" value={tokenTicker} />
        <RowEntityProps
          title="Доступные денежные средства"
          value={`${amountWithSpaces(balance?.fiat?.balance?.amount || 0)} RUB`}
        />
        <Flex sx={{ columnGap: 1, flex: "1 1 2" }}>
          <Input
            placeholder="Количество"
            value={amount}
            onChange={setAmount}
            colored
            color={color}
          />
          <Input
            placeholder="Цена за ед."
            value={fiatPrice}
            onChange={setFiatPrice}
            colored
            color={color}
          />
          <DatePicker
            placeholder="Действие до"
            value={expireDate}
            onChange={setExpireDate}
            colored
            color={color}
            sx={{ minWidth: "200px" }}
          />
        </Flex>
        <Text color={color}>
          Заявка на общую сумму {amount && fiatPrice ? amount * fiatPrice : 0} RUB
        </Text>
      </Flex>
    </Dialog>
  );
};

export default NewOrderModal;
