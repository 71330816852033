import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const VerifiedIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  contrast,
  opacity,
  sx,
}) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        opacity={opacity ? opacity / 100 : undefined}
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        fill="currentColor"
        viewBox="0 96 960 960"
      >
        <path d="m336 1028-82-140-163-34 19-158L7 576l103-119-19-159 163-33 82-141 144 66 145-66 82 141 162 33-19 159 103 119-103 120 19 158-162 34-82 140-145-66-144 66Zm41-122 103-45 106 45 65-97 113-29-11-116 77-88-77-90 11-116-113-27-67-97-104 44-106-44-65 97-112 27 10 116-77 90 77 88-10 118 112 27 68 97Zm103-330Zm-43 141 235-233-54-49-181 180-94-100-55 54 149 148Z" />
      </svg>
    </Text>
  );
};

export default VerifiedIcon;
