const splitString = (title: string, shift: number): [string, string?] => {
  if (!title) return [""];
  if (!title.includes(" ")) return [title];
  const trimmed = title.trim();
  const indices = [];
  let i = trimmed.indexOf(" ");
  while (i !== -1) {
    indices.push(i);
    i = trimmed.indexOf(" ", i + 1);
  }
  const from = indices[shift - 1];
  const firstPart = trimmed.substring(0, trimmed.indexOf(" ", from));
  const secondPart = trimmed.substring(firstPart.length);
  return [firstPart, secondPart];
};

export default splitString;
