import { ArchiveIcon, CollectionsIcon, DifferenceIcon, PDFIcon } from "Theme/icons";
import { IIcon } from "Theme/icons/default";
import { ColorType } from "Theme/types";
import React from "react";
import { IFileIconType } from "./types";

interface IInfoCardIcon extends IIcon {
  type: IFileIconType;
  color?: ColorType;
}
const InfoCardIcon: React.FC<IInfoCardIcon> = ({ type, color = "primary", ...props }) => {
  const defaultProps: IIcon = {
    light: true,
    color,
    opacity: 50,
    size: "medium",
  };
  switch (type) {
    case "pdf":
      return <PDFIcon {...defaultProps} {...props} />;
    case "img":
      return <CollectionsIcon {...defaultProps} {...props} />;
    case "archive":
      return <ArchiveIcon {...defaultProps} {...props} />;
    case "document":
      return <DifferenceIcon {...defaultProps} {...props} />;
    default:
      return <DifferenceIcon {...defaultProps} {...props} />;
  }
};

export default InfoCardIcon;
