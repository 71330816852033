/* eslint-disable @typescript-eslint/no-unused-vars */
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import { getCookie } from "@front-packages/dfa-helpers";
import { downloadBlobHelper } from "helpers";
import React from "react";
import Button from "../Button";
import Flex from "../Flex";
import Text from "../Text";
import { DeleteIcon } from "../../icons";
import FileIcon from "./FileIcon";
import { IFileIconType } from "./types";
import Card from "../Card";

interface IFileProps {
  name: string;
  id?: string;
  url?: string;
  onDelete?(id: string): void;
}

const File: React.FC<IFileProps> = ({ name, id, url, onDelete }) => {
  const getFile = async () => {
    if (url) {
      const blobFile = await fetch(`${process.env.REACT_APP_MIDDLE_API}${url}`, {
        headers: {
          Authorization: `Bearer ${getCookie(CookieNamesEnum.token)}`,
        },
      }).then((data) => data.blob());
      downloadBlobHelper(blobFile, name);
    }
  };
  const getFileIconType = (): IFileIconType => {
    const fileExtention = name.slice(name.lastIndexOf(".") + 1);
    switch (fileExtention) {
      case "jpeg" || "jpg" || "png":
        return "img";
      case "pdf":
        return "pdf";
      case "zip" || "rar":
        return "archive";
      default:
        return "document";
    }
  };
  return (
    // <Flex
    //   borderColor="primary"
    //   onClick={url && getFile}
    //   sx={{
    //     position: "relative",
    //     width: "120px",
    //     height: "120px",
    //     align: "center",
    //     justify: "center",
    //     direction: "row",
    //     columnGap: 1,
    //     borderRadius: "10px",
    //     border: "1px solid red",
    //     p: "10px",
    //     maxWidth: "250px",
    //     cursor: url && "pointer",
    //     hover: url && {
    //       transform: "translateY(-0.2rem)",
    //       boxShadow: "0 10px 19px #00000015",
    //     },
    //   }}
    // >
    //   <Flex rows={1} sx={{ direction: "column", width: "100%", align: "center" }}>
    //     <FileIcon type={getFileIconType()} />
    //     <Text sx={{ maxWidth: "100px" }} rows={1} title={name} color="primary">
    //       {name}
    //     </Text>
    //   </Flex>
    //   {onDelete && (
    //     <Button
    //       variant="text"
    //       onClick={() => onDelete(id)}
    //       iconStart={<DeleteIcon color="error" sx={{ cursor: "pointer" }} />}
    //       onlyIcon
    //       sx={{ position: "absolute", top: "5px", right: "5px" }}
    //     />
    //   )}
    // </Flex>
    <Card
      title="Файл"
      onClick={url && getFile}
      bgWhite
      sx={{ wrap: "wrap", gap: 1, width: "200px" }}
      titleSx={{ color: "primary", bgColor: "primary" }}
    >
      <Flex rows={1} sx={{ direction: "column", width: "100%", align: "center", rowGap: 1 }}>
        <FileIcon type={getFileIconType()} size="large" />
        <Text sx={{ maxWidth: "100px", size: "XS" }} rows={1} title={name} color="secondary">
          {name}
        </Text>
      </Flex>
      {onDelete && (
        <Button
          variant="text"
          onClick={() => onDelete(id)}
          iconStart={<DeleteIcon color="error" sx={{ cursor: "pointer" }} />}
          onlyIcon
          sx={{ position: "absolute", top: "5px", right: "5px" }}
        />
      )}
    </Card>
  );
};

export default File;
