import React, { useEffect } from "react";
import { RepresentativeStatusGql } from "@front-packages/dfa-gql-api";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "routes";
import { useAppSelector } from "../../../store";
import { Flex, Loader } from "../../../Theme";
import useSteps from "./useSteps";

const OnBoarding: React.FC = () => {
  const navigate = useNavigate();
  const { onBoardingStep, isAuth } = useAppSelector((state) => state.account);
  const representativeStatus = useAppSelector((state) => state.customer?.representative?.status);
  const stepComponents = useSteps();

  useEffect(() => {
    if (isAuth && representativeStatus === RepresentativeStatusGql.Active)
      navigate(RoutesNames.Briefcase);
  }, []);

  if (stepComponents === 0)
    return (
      <Flex sx={{ width: "100%", height: "100%" }}>
        <Loader />
      </Flex>
    );

  return (
    <Flex
      sx={{ direction: "column", width: "80%", minWidth: "500px", rowGap: 1, align: "start" }}
      adaptiveSX={{
        laptop: { minWidth: "400px" },
        tablet: { minWidth: "300px" },
        mobile: { minWidth: "90%" },
      }}
    >
      {stepComponents[onBoardingStep - 1]}
    </Flex>
  );
};

export default OnBoarding;
