import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import DefaultDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../Input";
import "./datePicker.css";
import { ColorType, MarginType, SizeType } from "../../types";
import { marginStyles, sizeStyles } from "../../styles";
import { CalendarIcon } from "../../icons";
import backspace from "./backspace.svg";

interface IStyle {
  fullWidth?: boolean;
  color?: ColorType;
  sx?: SizeType & MarginType;
}
interface IDatePickerProps extends IStyle {
  placeholder?: string;
  value?: string | any;
  onChange(date: Date | string): void;
  minDate?: Date | string;
  maxDate?: Date | string;
  isRequired?: boolean;
  withTime?: boolean;
  colored?: boolean;
}

const Wrap = styled.div<IStyle>`
  ${({ fullWidth, theme, color }) => css`
    width: ${fullWidth ? "100%" : "max-content"};
    color: ${theme.palette[color].main};
  `}
  ${({ sx }) => marginStyles(sx)}
  ${({ sx }) => sizeStyles(sx)}
`;
Wrap.defaultProps = {
  color: "primary",
};

const DatePicker: React.FC<IDatePickerProps> = ({
  value,
  onChange,
  placeholder,
  minDate,
  maxDate,
  isRequired,
  withTime,
  colored = false,
  ...props
}) => {
  const [time, setTime] = useState("");
  const handleChangeTime = (e) => setTime(e.target.value);
  const handleOnChange = (date: Date) => {
    if (date) {
      const changedDateArr = date.toString().split(" ");
      changedDateArr.splice(4, 1, `${time || "00:00"}:00`);
      onChange(`${new Date(changedDateArr.join(" ")).toISOString().split(".")[0]}Z`);
    } else onChange(null);
  };
  const handleCloseCalendar = () => {
    if (time && !value) {
      minDate ? handleOnChange(new Date(minDate)) : handleOnChange(new Date());
    }
  };

  useEffect(() => {
    time && value && handleOnChange(new Date(value));
  }, [time]);

  useEffect(() => {
    const calendarButtons: any = document.querySelectorAll(".react-datepicker__close-icon");
    const datepickerWrappers: any = document.querySelectorAll(".react-datepicker-wrapper");
    if (calendarButtons)
      calendarButtons.forEach((btn) => {
        btn.style.background = `url("${backspace}") center center no-repeat`;
      });
    if (datepickerWrappers)
      datepickerWrappers.forEach((wrapper) => {
        wrapper.style.width = "100%";
      });
  });
  return (
    <Wrap {...props}>
      <DefaultDatePicker
        locale="ru"
        dateFormat={withTime ? "d MMMM yyyy, p" : "d MMMM yyyy"}
        selected={value ? new Date(value) : null}
        onChange={handleOnChange}
        isClearable
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        placeholderText={placeholder}
        onCalendarClose={handleCloseCalendar}
        customInput={
          <Input
            sx={{ pr: 2 }}
            fullWidth
            hideCursor
            value={undefined}
            onChange={undefined}
            color={props.color}
            isRequired={isRequired}
            icon={!value ? <CalendarIcon /> : undefined}
            colored={colored}
          />
        }
      >
        {withTime && (
          <div>
            <span style={{ marginRight: "10px" }}>Время:</span>
            <input type="time" value={time} onChange={handleChangeTime} />
          </div>
        )}
      </DefaultDatePicker>
    </Wrap>
  );
};

export default DatePicker;
