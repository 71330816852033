import { ForwardedRef } from "react";
import styled, { css } from "styled-components";
import {
  paddingStyles,
  marginStyles,
  sizeStyles,
  bgStyles,
  borderStyles,
  positionStyles,
  adaptiveStyles,
  cursorStyles,
} from "../../styles";
import {
  BackgroundType,
  BorderType,
  ColorType,
  CursorType,
  GridType,
  IAdaptive,
  MarginType,
  PaddingType,
  PositionType,
  SaturationType,
  ShadowsType,
  SizeType,
} from "../../types";

export interface IStyledGrid extends IAdaptive {
  bgColor?: ColorType | "white";
  bgType?: "contain" | "gradient" | "blur";
  bgSaturation?: SaturationType;
  animation?: any;
  rows?: number;
  sx?: MarginType &
    PaddingType &
    GridType &
    SizeType &
    BackgroundType &
    BorderType &
    PositionType &
    CursorType &
    ShadowsType & { zIndex?: number };
}

interface IStyledGridWithRef extends IStyledGrid {
  ref?: ForwardedRef<HTMLDivElement>;
}

export const StyledGrid = styled.div<IStyledGridWithRef>`
  ${({ sx, bgType, bgColor, bgSaturation, theme, rows, animation }) => css`
    display: grid;
    z-index: ${sx?.zIndex};
    gap: ${sx?.gap}rem;
    row-gap: ${sx?.rowGap}rem;
    column-gap: ${sx?.columnGap}rem;
    grid-template-columns: ${sx?.templateColumns};
    grid-template-rows: ${sx?.templateRows};
    grid-template-areas: ${sx?.areas};
    place-items: ${sx?.placeItems};
    justify-content: ${sx?.justify};
    background-image: ${bgColor &&
    bgColor !== "white" &&
    bgType === "gradient" &&
    theme.palette[bgColor].gradient[bgSaturation]};
    background: ${bgColor &&
    bgColor !== "white" &&
    bgType === "contain" &&
    theme.palette[bgColor].main};
    background: ${bgColor === "white" && bgColor};
    background: ${bgType === "blur" && "rgba(255, 255, 255, 0.9)"};
    color: ${bgColor && theme.palette.text.contrast};
    box-shadow: ${sx?.shadow === "left" && theme.shadows.left};
    box-shadow: ${sx?.shadow === "primary" && theme.shadows.primary};
    box-shadow: ${sx?.shadow === "large" && theme.shadows.large};
    text-overflow: ${rows === 1 && "ellipsis"};
    overflow: ${rows && "hidden"};
    display: ${rows > 1 && "-webkit-box"};
    -webkit-box-orient: ${rows > 1 && "vertical"};
    white-space: ${rows === 1 && "nowrap"};
    ${animation};
  `};
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => paddingStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ sx }) => bgStyles(sx)};
  ${({ sx }) => borderStyles(sx)};
  ${({ sx }) => positionStyles(sx)};
  ${({ sx }) => cursorStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;
