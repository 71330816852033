/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "rr.bcpcore.market";

export enum OrderType {
  Sale = 0,
  Purchase = 1,
  UNRECOGNIZED = -1,
}

export function orderTypeFromJSON(object: any): OrderType {
  switch (object) {
    case 0:
    case "Sale":
      return OrderType.Sale;
    case 1:
    case "Purchase":
      return OrderType.Purchase;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderType.UNRECOGNIZED;
  }
}

export function orderTypeToJSON(object: OrderType): string {
  switch (object) {
    case OrderType.Sale:
      return "Sale";
    case OrderType.Purchase:
      return "Purchase";
    case OrderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MsgSendRegisterOrder {
  creator: string;
  senderAddr: string;
  port: string;
  channelId: string;
  timeoutTimestamp: number;
  orderId: string;
  currentTime: number;
  orderType: OrderType;
  ticker: string;
  tokenPrice: number;
  amount: number;
  closeDate: number;
  transactionGuid: string;
  ibcId: string;
}

export interface MsgSendRegisterOrderResponse {
}

export interface MsgSendSignAndProcess {
  creator: string;
  port: string;
  channelId: string;
  timeoutTimestamp: number;
  orderId: string;
  ibcId: string;
}

export interface MsgSendSignAndProcessResponse {
}

/** this line is used by starport scaffolding # proto/tx/message */
export interface MsgSendReject {
  creator: string;
  port: string;
  channelID: string;
  timeoutTimestamp: number;
  orderId: string;
  ibcId: string;
}

export interface MsgSendRejectResponse {
}

function createBaseMsgSendRegisterOrder(): MsgSendRegisterOrder {
  return {
    creator: "",
    senderAddr: "",
    port: "",
    channelId: "",
    timeoutTimestamp: 0,
    orderId: "",
    currentTime: 0,
    orderType: 0,
    ticker: "",
    tokenPrice: 0,
    amount: 0,
    closeDate: 0,
    transactionGuid: "",
    ibcId: "",
  };
}

export const MsgSendRegisterOrder = {
  encode(message: MsgSendRegisterOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.senderAddr !== "") {
      writer.uint32(18).string(message.senderAddr);
    }
    if (message.port !== "") {
      writer.uint32(26).string(message.port);
    }
    if (message.channelId !== "") {
      writer.uint32(34).string(message.channelId);
    }
    if (message.timeoutTimestamp !== 0) {
      writer.uint32(40).uint64(message.timeoutTimestamp);
    }
    if (message.orderId !== "") {
      writer.uint32(50).string(message.orderId);
    }
    if (message.currentTime !== 0) {
      writer.uint32(56).uint64(message.currentTime);
    }
    if (message.orderType !== 0) {
      writer.uint32(64).int32(message.orderType);
    }
    if (message.ticker !== "") {
      writer.uint32(74).string(message.ticker);
    }
    if (message.tokenPrice !== 0) {
      writer.uint32(80).uint64(message.tokenPrice);
    }
    if (message.amount !== 0) {
      writer.uint32(88).uint64(message.amount);
    }
    if (message.closeDate !== 0) {
      writer.uint32(96).uint64(message.closeDate);
    }
    if (message.transactionGuid !== "") {
      writer.uint32(106).string(message.transactionGuid);
    }
    if (message.ibcId !== "") {
      writer.uint32(114).string(message.ibcId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgSendRegisterOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSendRegisterOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.senderAddr = reader.string();
          break;
        case 3:
          message.port = reader.string();
          break;
        case 4:
          message.channelId = reader.string();
          break;
        case 5:
          message.timeoutTimestamp = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.orderId = reader.string();
          break;
        case 7:
          message.currentTime = longToNumber(reader.uint64() as Long);
          break;
        case 8:
          message.orderType = reader.int32() as any;
          break;
        case 9:
          message.ticker = reader.string();
          break;
        case 10:
          message.tokenPrice = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 12:
          message.closeDate = longToNumber(reader.uint64() as Long);
          break;
        case 13:
          message.transactionGuid = reader.string();
          break;
        case 14:
          message.ibcId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSendRegisterOrder {
    return {
      creator: isSet(object.creator) ? String(object.creator) : "",
      senderAddr: isSet(object.senderAddr) ? String(object.senderAddr) : "",
      port: isSet(object.port) ? String(object.port) : "",
      channelId: isSet(object.channelId) ? String(object.channelId) : "",
      timeoutTimestamp: isSet(object.timeoutTimestamp) ? Number(object.timeoutTimestamp) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      currentTime: isSet(object.currentTime) ? Number(object.currentTime) : 0,
      orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
      ticker: isSet(object.ticker) ? String(object.ticker) : "",
      tokenPrice: isSet(object.tokenPrice) ? Number(object.tokenPrice) : 0,
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      closeDate: isSet(object.closeDate) ? Number(object.closeDate) : 0,
      transactionGuid: isSet(object.transactionGuid) ? String(object.transactionGuid) : "",
      ibcId: isSet(object.ibcId) ? String(object.ibcId) : "",
    };
  },

  toJSON(message: MsgSendRegisterOrder): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.senderAddr !== undefined && (obj.senderAddr = message.senderAddr);
    message.port !== undefined && (obj.port = message.port);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.timeoutTimestamp !== undefined && (obj.timeoutTimestamp = Math.round(message.timeoutTimestamp));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.currentTime !== undefined && (obj.currentTime = Math.round(message.currentTime));
    message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.tokenPrice !== undefined && (obj.tokenPrice = Math.round(message.tokenPrice));
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    message.closeDate !== undefined && (obj.closeDate = Math.round(message.closeDate));
    message.transactionGuid !== undefined && (obj.transactionGuid = message.transactionGuid);
    message.ibcId !== undefined && (obj.ibcId = message.ibcId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MsgSendRegisterOrder>, I>>(object: I): MsgSendRegisterOrder {
    const message = createBaseMsgSendRegisterOrder();
    message.creator = object.creator ?? "";
    message.senderAddr = object.senderAddr ?? "";
    message.port = object.port ?? "";
    message.channelId = object.channelId ?? "";
    message.timeoutTimestamp = object.timeoutTimestamp ?? 0;
    message.orderId = object.orderId ?? "";
    message.currentTime = object.currentTime ?? 0;
    message.orderType = object.orderType ?? 0;
    message.ticker = object.ticker ?? "";
    message.tokenPrice = object.tokenPrice ?? 0;
    message.amount = object.amount ?? 0;
    message.closeDate = object.closeDate ?? 0;
    message.transactionGuid = object.transactionGuid ?? "";
    message.ibcId = object.ibcId ?? "";
    return message;
  },
};

function createBaseMsgSendRegisterOrderResponse(): MsgSendRegisterOrderResponse {
  return {};
}

export const MsgSendRegisterOrderResponse = {
  encode(_: MsgSendRegisterOrderResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgSendRegisterOrderResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSendRegisterOrderResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSendRegisterOrderResponse {
    return {};
  },

  toJSON(_: MsgSendRegisterOrderResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MsgSendRegisterOrderResponse>, I>>(_: I): MsgSendRegisterOrderResponse {
    const message = createBaseMsgSendRegisterOrderResponse();
    return message;
  },
};

function createBaseMsgSendSignAndProcess(): MsgSendSignAndProcess {
  return { creator: "", port: "", channelId: "", timeoutTimestamp: 0, orderId: "", ibcId: "" };
}

export const MsgSendSignAndProcess = {
  encode(message: MsgSendSignAndProcess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.port !== "") {
      writer.uint32(18).string(message.port);
    }
    if (message.channelId !== "") {
      writer.uint32(26).string(message.channelId);
    }
    if (message.timeoutTimestamp !== 0) {
      writer.uint32(32).uint64(message.timeoutTimestamp);
    }
    if (message.orderId !== "") {
      writer.uint32(42).string(message.orderId);
    }
    if (message.ibcId !== "") {
      writer.uint32(50).string(message.ibcId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgSendSignAndProcess {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSendSignAndProcess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.port = reader.string();
          break;
        case 3:
          message.channelId = reader.string();
          break;
        case 4:
          message.timeoutTimestamp = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.orderId = reader.string();
          break;
        case 6:
          message.ibcId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSendSignAndProcess {
    return {
      creator: isSet(object.creator) ? String(object.creator) : "",
      port: isSet(object.port) ? String(object.port) : "",
      channelId: isSet(object.channelId) ? String(object.channelId) : "",
      timeoutTimestamp: isSet(object.timeoutTimestamp) ? Number(object.timeoutTimestamp) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      ibcId: isSet(object.ibcId) ? String(object.ibcId) : "",
    };
  },

  toJSON(message: MsgSendSignAndProcess): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.port !== undefined && (obj.port = message.port);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.timeoutTimestamp !== undefined && (obj.timeoutTimestamp = Math.round(message.timeoutTimestamp));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.ibcId !== undefined && (obj.ibcId = message.ibcId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MsgSendSignAndProcess>, I>>(object: I): MsgSendSignAndProcess {
    const message = createBaseMsgSendSignAndProcess();
    message.creator = object.creator ?? "";
    message.port = object.port ?? "";
    message.channelId = object.channelId ?? "";
    message.timeoutTimestamp = object.timeoutTimestamp ?? 0;
    message.orderId = object.orderId ?? "";
    message.ibcId = object.ibcId ?? "";
    return message;
  },
};

function createBaseMsgSendSignAndProcessResponse(): MsgSendSignAndProcessResponse {
  return {};
}

export const MsgSendSignAndProcessResponse = {
  encode(_: MsgSendSignAndProcessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgSendSignAndProcessResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSendSignAndProcessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSendSignAndProcessResponse {
    return {};
  },

  toJSON(_: MsgSendSignAndProcessResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MsgSendSignAndProcessResponse>, I>>(_: I): MsgSendSignAndProcessResponse {
    const message = createBaseMsgSendSignAndProcessResponse();
    return message;
  },
};

function createBaseMsgSendReject(): MsgSendReject {
  return { creator: "", port: "", channelID: "", timeoutTimestamp: 0, orderId: "", ibcId: "" };
}

export const MsgSendReject = {
  encode(message: MsgSendReject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.port !== "") {
      writer.uint32(18).string(message.port);
    }
    if (message.channelID !== "") {
      writer.uint32(26).string(message.channelID);
    }
    if (message.timeoutTimestamp !== 0) {
      writer.uint32(32).uint64(message.timeoutTimestamp);
    }
    if (message.orderId !== "") {
      writer.uint32(42).string(message.orderId);
    }
    if (message.ibcId !== "") {
      writer.uint32(50).string(message.ibcId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgSendReject {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSendReject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.port = reader.string();
          break;
        case 3:
          message.channelID = reader.string();
          break;
        case 4:
          message.timeoutTimestamp = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.orderId = reader.string();
          break;
        case 6:
          message.ibcId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSendReject {
    return {
      creator: isSet(object.creator) ? String(object.creator) : "",
      port: isSet(object.port) ? String(object.port) : "",
      channelID: isSet(object.channelID) ? String(object.channelID) : "",
      timeoutTimestamp: isSet(object.timeoutTimestamp) ? Number(object.timeoutTimestamp) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      ibcId: isSet(object.ibcId) ? String(object.ibcId) : "",
    };
  },

  toJSON(message: MsgSendReject): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.port !== undefined && (obj.port = message.port);
    message.channelID !== undefined && (obj.channelID = message.channelID);
    message.timeoutTimestamp !== undefined && (obj.timeoutTimestamp = Math.round(message.timeoutTimestamp));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.ibcId !== undefined && (obj.ibcId = message.ibcId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MsgSendReject>, I>>(object: I): MsgSendReject {
    const message = createBaseMsgSendReject();
    message.creator = object.creator ?? "";
    message.port = object.port ?? "";
    message.channelID = object.channelID ?? "";
    message.timeoutTimestamp = object.timeoutTimestamp ?? 0;
    message.orderId = object.orderId ?? "";
    message.ibcId = object.ibcId ?? "";
    return message;
  },
};

function createBaseMsgSendRejectResponse(): MsgSendRejectResponse {
  return {};
}

export const MsgSendRejectResponse = {
  encode(_: MsgSendRejectResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgSendRejectResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSendRejectResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSendRejectResponse {
    return {};
  },

  toJSON(_: MsgSendRejectResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MsgSendRejectResponse>, I>>(_: I): MsgSendRejectResponse {
    const message = createBaseMsgSendRejectResponse();
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  SendRegisterOrder(request: MsgSendRegisterOrder): Promise<MsgSendRegisterOrderResponse>;
  SendSignAndProcess(request: MsgSendSignAndProcess): Promise<MsgSendSignAndProcessResponse>;
  /** this line is used by starport scaffolding # proto/tx/rpc */
  SendReject(request: MsgSendReject): Promise<MsgSendRejectResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SendRegisterOrder = this.SendRegisterOrder.bind(this);
    this.SendSignAndProcess = this.SendSignAndProcess.bind(this);
    this.SendReject = this.SendReject.bind(this);
  }
  SendRegisterOrder(request: MsgSendRegisterOrder): Promise<MsgSendRegisterOrderResponse> {
    const data = MsgSendRegisterOrder.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.market.Msg", "SendRegisterOrder", data);
    return promise.then((data) => MsgSendRegisterOrderResponse.decode(new _m0.Reader(data)));
  }

  SendSignAndProcess(request: MsgSendSignAndProcess): Promise<MsgSendSignAndProcessResponse> {
    const data = MsgSendSignAndProcess.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.market.Msg", "SendSignAndProcess", data);
    return promise.then((data) => MsgSendSignAndProcessResponse.decode(new _m0.Reader(data)));
  }

  SendReject(request: MsgSendReject): Promise<MsgSendRejectResponse> {
    const data = MsgSendReject.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.market.Msg", "SendReject", data);
    return promise.then((data) => MsgSendRejectResponse.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
