import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const ReceiptLongIcon: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M228-80q-45.834 0-77.917-32.083T118-190v-56.666q0-14.166 9.583-23.749 9.583-9.584 23.75-9.584h91.334V-853q0-9.667 8-10.333 8-.667 16 7.333l36 36 48-48q5.091-5.333 11.879-5.333 6.787 0 12.121 5.333L422-820l48-48q5.091-5.333 11.879-5.333 6.788 0 12.121 5.333l48 48 48-48q5.091-5.333 11.879-5.333 6.788 0 12.121 5.333l48 48 48-48q5.091-5.333 11.879-5.333 6.788 0 12.121 5.333l48 48 36-36q8-8 16-7.5t8 10.681V-190q0 45.834-32.083 77.917T732-80H228Zm502.936-66.666q18.602 0 30.5-12.167T773.334-190v-594.001H311.333v504.002h346.634q14.2 0 23.783 9.584 9.584 9.583 9.584 23.749v56.563q0 19.103 10.5 31.27 10.5 12.167 29.102 12.167ZM392.333-628.001q-13.666 0-23-9.833Q360-647.667 360-661.667q0-14 9.583-23.5 9.584-9.5 23.75-9.5h173.334q14.166 0 23.75 9.617Q600-675.433 600-661.217q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H392.333Zm0 129.334q-13.666 0-23-9.834Q360-518.334 360-532.334q0-14 9.583-23.5 9.584-9.499 23.75-9.499h173.334q14.166 0 23.75 9.616Q600-546.1 600-531.883q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H392.333Zm296.334-129.334q-13.667 0-23.5-9.833t-9.833-23.5q0-13.667 9.833-23.5t23.5-9.833q13.666 0 23.5 9.833 9.833 9.833 9.833 23.5t-9.833 23.5q-9.834 9.833-23.5 9.833Zm0 126q-13.667 0-23.5-9.833t-9.833-23.5q0-13.666 9.833-23.5 9.833-9.833 23.5-9.833 13.666 0 23.5 9.833Q722-549 722-535.334q0 13.667-9.833 23.5-9.834 9.833-23.5 9.833Z" />
      </svg>
    </Text>
  );
};

export default ReceiptLongIcon;
