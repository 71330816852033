import styled, { css } from "styled-components";
import type * as CSS from "csstype";
import { ColorType, MarginType, SizeType } from "../../types";
import { marginStyles, sizeStyles } from "../../styles";

export interface IStyledTabsBar {
  sx?: MarginType;
  color?: ColorType;
  align?: CSS.Property.TextAlign;
}

export interface IContainer {
  containerSx?: SizeType & MarginType;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  ${({ containerSx }) => sizeStyles(containerSx)}
  ${({ containerSx }) => marginStyles(containerSx)}
`;

export const Wrap = styled.div<IStyledTabsBar>`
  width: max-content;
  position: relative;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  ${({ align }) => css`
    margin: ${align === "center" && "0 auto"};
    margin-left: ${align === "end" && "auto"};
    align-self: ${align === "start" && "flex-start"};
  `}
  ${({ sx }) => marginStyles(sx)}
`;
Wrap.defaultProps = {
  color: "primary",
};

interface IStyledTab extends IStyledTabsBar {
  active: boolean;
}

export const StyledTabBackground = styled.div<{ position: number; width: number }>`
  position: absolute;
  top: 6px;
  border: none;
  padding: 3.5px 10px;
  height: 30px;
  transition-duration: 0.5s;
  ${({ position, width }) => css`
    width: ${width}px;
    left: calc(${position}px + 6px);
  `}
`;
StyledTabBackground.defaultProps = {
  color: "primary",
};
export const StyledTab = styled.button<IStyledTab>`
  position: relative;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 10px 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
  transition-duration: 0.5s;
  ${({ theme, active, color }) => css`
    border-bottom: 2px solid
      ${active
        ? theme.palette.primary.main
        : `${theme.palette.primary.light}${theme.palette.opacity[25]}`};
    font-size: ${theme.typography.size.SM};
    text-transform: uppercase;
    background: ${active && `${theme.palette.primary.main}${theme.palette.opacity[3]}`};
    color: ${active
      ? theme.palette[color].main
      : `${theme.palette.primary.light}${theme.palette.opacity[25]}`};
    font-weight: ${theme.typography.weight.medium};
  `}
`;
StyledTab.defaultProps = {
  color: "primary",
};
