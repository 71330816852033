import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const ATIcon: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 39)}
        height={getSize(size, 38)}
        fill="none"
      >
        <path
          fill="url(#a)"
          fillOpacity={0.25}
          d="M19.5.25C9.15.25.75 8.65.75 19s8.4 18.75 18.75 18.75h9.375V34H19.5c-8.137 0-15-6.863-15-15s6.863-15 15-15 15 6.863 15 15v2.681c0 1.482-1.331 2.944-2.813 2.944-1.48 0-2.812-1.462-2.812-2.944V19c0-5.175-4.2-9.375-9.375-9.375s-9.375 4.2-9.375 9.375 4.2 9.375 9.375 9.375a9.32 9.32 0 0 0 6.637-2.756c1.22 1.669 3.32 2.756 5.55 2.756 3.694 0 6.563-3 6.563-6.694V19C38.25 8.65 29.85.25 19.5.25Zm0 24.375A5.617 5.617 0 0 1 13.875 19a5.617 5.617 0 0 1 5.625-5.625A5.617 5.617 0 0 1 25.125 19a5.617 5.617 0 0 1-5.625 5.625Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0.75}
            x2={38.25}
            y1={37.75}
            y2={0.25}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#398151" />
            <stop offset={1} stopColor="#35C691" />
          </linearGradient>
        </defs>
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 32)}
        height={getSize(size, 32)}
        fill="none"
      >
        <path
          fill="currentColor"
          d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2Zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5Z"
        />
      </svg> */}
    </Text>
  );
};

export default ATIcon;
