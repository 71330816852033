import React, { useState } from "react";
import { useAuth } from "../../../../hooks";
import { Button, ColorType, Dialog, Flex, Text } from "../../../../Theme";
import { PowerIcon } from "../../../../Theme/icons";

interface IOnBoardingInfoProps {
  title?: string;
  subtitle?: string;
  color: ColorType;
  hideLogOutButton?: boolean;
  errorText?: string;
  button?: {
    onClick(): any | Promise<any>;
    text: string;
    disabled?: boolean;
  };
  children?: React.ReactNode;
}

const OnBoardingInfo: React.FC<IOnBoardingInfoProps> = ({
  title,
  subtitle,
  color = "info",
  button,
  hideLogOutButton,
  errorText,
  children,
}) => {
  const { logout } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const onConfirm = async () => {
    setIsLoading(true);
    await button.onClick();
    setIsLoading(false);
  };
  return (
    <>
      <Flex
        sx={{ direction: "column", align: "center", gap: 1, m: "auto", maxWidth: "800px" }}
        adaptiveSX={{
          laptopM: { maxWidth: "600px" },
          tablet: { maxWidth: "400px" },
          mobile: { maxWidth: "90%" },
        }}
      >
        {title && (
          <Text variant="h2" sx={{ textAlign: "center", weight: "normal" }} color={color}>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text
            color="secondary"
            sx={{ size: "MD", textTransform: "initial", textAlign: "center" }}
          >
            {subtitle}
          </Text>
        )}
        {errorText && (
          <Text color="error" sx={{ size: "MD", textTransform: "initial", textAlign: "center" }}>
            {errorText}
          </Text>
        )}
        {children}
        {button && (
          <Button disabled={button?.disabled} color={color} loading={isLoading} onClick={onConfirm}>
            {button.text}
          </Button>
        )}
      </Flex>
      {!hideLogOutButton && (
        <Button
          sx={{
            m: "auto",
            p: "10px",
            borderRadius: "50%",
            position: "absolute",
            bottom: "30px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          iconStart={<PowerIcon size="medium" />}
          title="Прервать онбординг"
          onlyIcon
          background="error"
          onClick={openDialog}
          color="warning"
        />
      )}
      <Dialog
        open={open}
        type="warning"
        maxWidth="xs"
        title="Вы точно хотите прервать онбординг?"
        subTitle="При прерывании онбординга, Вам придется часть шагов выполнять заново."
        onClose={closeDialog}
        onConfirm={logout}
      />
    </>
  );
};

export default OnBoardingInfo;
