import React from "react";
import { DepositOrderStatus } from "@front-packages/dfa-gql-api";
import { Button, Checkbox, Flex, NoData, Paginator, Table } from "Theme";
import useDepositOrders from "./useDepositOrders";

const DepositOrders: React.FC = () => {
  const {
    handleChangeOrderStatus,
    orderStatus,
    rows,
    totalCount,
    columns,
    currentSortOption,
    sortDescent,
    count,
    setCount,
    page,
    setPage,
  } = useDepositOrders();
  return (
    <Flex sx={{ width: "100%", direction: "column", rowGap: 1 }}>
      <Flex sx={{ columnGap: 2, width: "100%", mt: 2 }}>
        <Checkbox
          onChange={() => handleChangeOrderStatus(DepositOrderStatus.WaitingPaymentDepositStatus)}
          name="waiting"
          label="Ожидают оплаты"
          checked={orderStatus.includes(DepositOrderStatus.WaitingPaymentDepositStatus)}
        />
        <Checkbox
          onChange={() => handleChangeOrderStatus(DepositOrderStatus.CompletedDepositStatus)}
          name="completed"
          label="Исполненные"
          checked={orderStatus.includes(DepositOrderStatus.CompletedDepositStatus)}
        />
        <Checkbox
          onChange={() => handleChangeOrderStatus(DepositOrderStatus.RejectedDepositStatus)}
          name="rejected"
          label="Отклоненные"
          checked={orderStatus.includes(DepositOrderStatus.RejectedDepositStatus)}
        />
      </Flex>
      {rows?.length || totalCount ? (
        <>
          <Table
            rowHeight={60}
            rowSx={{ rowStyle: "white" }}
            titleRowSx={{ rowHeight: 60 }}
            columns={columns.current}
            rows={rows}
            currentSortOption={currentSortOption}
            isUpArrow={sortDescent}
          />
          {totalCount && totalCount >= count ? (
            <Button
              variant="outline"
              sx={{ width: "300px", m: "0 auto" }}
              onClick={() => setCount((prevCount) => prevCount + 10)}
            >
              Показать еще
            </Button>
          ) : null}
          {totalCount > 10 ? (
            <Paginator
              page={page}
              amount={Math.ceil(totalCount / 10)}
              setPage={setPage}
              withInput
            />
          ) : null}
        </>
      ) : (
        <NoData text={!totalCount ? "Нет заявок" : "Заявок по вашему запросу не найдено"} />
      )}
    </Flex>
  );
};

export default DepositOrders;
