import React from "react";
import { useNavigate } from "react-router-dom";
import { Token, TokenStatusGql } from "@front-packages/dfa-gql-api";
import { Badge, Card, EntityProps, Grid, GridItem } from "Theme";
import { daysRest, formattingDate, getStatusBadgeProps, getAmountWithWordDay } from "helpers";
import { useAppSelector } from "store";
import { RoutesNames } from "routes";
import { TokenShortType } from "constants/in_lib";
import styles from "./styles";

export interface IIssuerTokenCard {
  token: Token;
}

const IssuerTokenCard: React.FC<IIssuerTokenCard> = ({ token }) => {
  const navigate = useNavigate();
  const { pageLoading } = useAppSelector((state) => state.global);
  const { id, investStartDate, investFinishDate, ticker, status, rightsType, maxIssueLimit } =
    token;
  const daysToCompletion = daysRest(investFinishDate);

  const goToIssue = () => {
    if (status === TokenStatusGql.Draft) navigate(`${RoutesNames.EditIssues}?id=${id}`);
    else navigate(`${RoutesNames.ViewIssues}?id=${id}`);
  };

  return (
    <Card bgWhite onClick={goToIssue} sx={styles.cardSx} adaptiveSX={styles.cardAdaptiveSx}>
      <Grid sx={styles.gridSx} adaptiveSX={styles.gridAdaptivSx}>
        <GridItem gridArea="a">
          <EntityProps isLoading={pageLoading} title="Тикер" value={ticker} />
        </GridItem>
        <GridItem
          gridArea="b"
          placeSelf="stretch end"
          flex
          flexColumnGap={0.5}
          adaptiveSX={{ laptopL: { placeSelf: "stretch end" } }}
        >
          <EntityProps
            isLoading={pageLoading}
            value={
              <Badge
                sx={{ minHeight: "30px" }}
                isLoading={pageLoading}
                color="info"
                label={TokenShortType[rightsType] || "не указан"}
              />
            }
          />
          <EntityProps
            isLoading={pageLoading}
            value={
              <Badge
                isLoading={pageLoading}
                {...getStatusBadgeProps(status, "Token")}
                sx={{ minHeight: "30px" }}
              />
            }
          />
        </GridItem>
        <GridItem
          gridArea="c"
          adaptiveSX={{
            laptopL: { placeSelf: "stretch end" },
            tabletL: { placeSelf: "stretch start" },
          }}
        >
          <EntityProps
            isLoading={pageLoading}
            title="Дата старта"
            value={investStartDate ? formattingDate(investStartDate) : "Не назначена"}
          />
        </GridItem>
        <GridItem
          gridArea="d"
          adaptiveSX={{
            tabletL: { placeSelf: "stretch end" },
            tablet: { placeSelf: "stretch start" },
            mobile: { placeSelf: "stretch end" },
          }}
        >
          <EntityProps
            isLoading={pageLoading}
            title={daysToCompletion >= 0 ? "До завершения" : "Прошло"}
            value={getAmountWithWordDay(Math.abs(daysToCompletion))}
          />
        </GridItem>
        <GridItem
          gridArea="e"
          placeSelf="stretch end"
          adaptiveSX={{
            laptopL: { placeSelf: "stretch start" },
            tablet: { placeSelf: "stretch end" },
            mobile: { placeSelf: "stretch start" },
          }}
        >
          <EntityProps
            isLoading={pageLoading}
            title="Сумма выпуска"
            value={`${maxIssueLimit || 0} RUB`}
          />
        </GridItem>
      </Grid>
    </Card>
  );
};

export default IssuerTokenCard;
