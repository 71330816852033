import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const IconBill: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx, title }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-credit-card"
        cursor={sx?.cursor || "auto"}
      >
        <rect x={1} y={4} width={22} height={16} rx={2} ry={2} />
        <path d="M1 10h22" />
        <title>{title}</title>
      </svg>
    </Text>
  );
};

export default IconBill;
