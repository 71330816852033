import { useLocation, useNavigate } from "react-router-dom";
import { RoutesNames } from "../../routes";
import { useAppSelector } from "../../store";

type UseCheckPathnameType = () => { checkPathname(): void };
export const useCheckPathname: UseCheckPathnameType = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAccountIssuer, isAuth } = useAppSelector((state) => state.account);

  const checkPathname = () => {
    if (isAccountIssuer && isAuth) {
      if (pathname === RoutesNames.Marketplace || pathname === RoutesNames.ViewIssues)
        navigate(RoutesNames.Issues);
      if (pathname === RoutesNames.SecondaryMarket || pathname === RoutesNames.Orders)
        navigate(RoutesNames.Briefcase);
    }
    if (!isAccountIssuer && isAuth) {
      if (
        pathname === RoutesNames.Issues ||
        pathname === RoutesNames.EditIssues ||
        pathname === RoutesNames.ViewIssues
      )
        navigate(RoutesNames.Marketplace);
    }
  };
  return { checkPathname };
};

export default useCheckPathname;
