import React, { useEffect, useRef } from "react";
import { Button, Flex } from "Theme";
import { IconCross } from "Theme/icons";
import { useAppDispatch, useAppSelector } from "store";
import { switchOpenCustomerInfo } from "store/account";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "routes";
import { StyledCustomerInfo } from "./CustomerInfo";
import RepresentativeName from "./components/RepresentativeName";
import RepresentativeInfo from "./components/RepresentativeInfo";
import CompanyName from "./components/CompanyName";
import BalanceIteraction from "./components/BalanceIteraction";

const CustomerInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openCustomerInfo } = useAppSelector((state) => state.account);
  const handleClose = () => dispatch(switchOpenCustomerInfo());

  const goToProfilePage = () => {
    navigate(RoutesNames.Profile);
    handleClose();
  };

  const ref = useRef(null);

  // const missClick = (e: SyntheticEvent) => {
  //   if (ref.current && openCustomerInfo && !ref.current.contains(e.currentTarget)) handleClose();
  // };

  useEffect(() => {
    function openListener(e) {
      if (ref.current && openCustomerInfo && !ref.current.contains(e.target)) handleClose();
    }
    document.addEventListener("click", openListener);
    return () => document.removeEventListener("click", openListener);
  }, [openCustomerInfo]);

  return (
    <StyledCustomerInfo
      open={openCustomerInfo}
      adaptiveSX={{ mobile: { width: "100%" } }}
      // onClick={missClick}
      ref={ref}
    >
      <Flex
        sx={{ width: "100%", justify: "flex-end", columnGap: 1, mb: 1, align: "center" }}
        // ref={ref}
      >
        <Button
          onClick={handleClose}
          iconStart={<IconCross size="medium" color="primary" />}
          onlyIcon
        />
      </Flex>
      <RepresentativeName />
      <CompanyName />
      <BalanceIteraction />
      <RepresentativeInfo />
      <Button
        onClick={goToProfilePage}
        variant="text"
        sx={{ size: "SM", justifySelf: "flex-end", mb: 1, mt: "auto" }}
      >
        Перейти в профиль
      </Button>
    </StyledCustomerInfo>
  );
};

export default CustomerInfo;
