import React from "react";
import { ColorType } from "Theme/types";
import { IInfoCard, StyledInfoCard } from "./InfoCard";
import EntityProps from "../EntityProps";
import InfoCardIcon from "./InfoCardIcon";
import { InfoCardIconType } from "./types";

export interface IInfoCardProps extends IInfoCard {
  title: string;
  value: React.ReactNode;
  textMaxWidth?: string;
  type?: InfoCardIconType;
  color?: ColorType;
  btn?: React.ReactNode;
}

const InfoCard: React.FC<IInfoCardProps> = ({
  type,
  title,
  value,
  btn,
  color = "primary",
  textMaxWidth = "100%",
  ...props
}) => {
  return (
    <StyledInfoCard bgColor={color} {...props}>
      {type && <InfoCardIcon type={type} color={color} />}
      <EntityProps
        sxTitle={{ color: "secondary", textTransform: "uppercase", size: "XS" }}
        color={color}
        title={title}
        value={value}
        sx={{ maxWidth: textMaxWidth, justify: "center" }}
      />
      {btn && btn}
    </StyledInfoCard>
  );
};

export default InfoCard;
