import { ForwardedRef } from "react";
import styled, { css } from "styled-components";
import { ColorType, MarginType, PaddingType, SizeType, TypographyType } from "../../types";
import { marginStyles, paddingStyles, sizeStyles } from "../../styles";

interface IDefaultStyled {
  error?: string | boolean | "none";
  isRequired?: boolean;
}
interface IStyledLabel extends IDefaultStyled {
  focus: boolean;
}
export interface IStyledTextArea extends IDefaultStyled {
  sx?: MarginType & SizeType & TypographyType & PaddingType;
  fullWidth?: boolean;
  color?: ColorType;
  readonly?: boolean;
}
interface IStyledTextAreaWithRef extends IStyledTextArea {
  ref?: ForwardedRef<HTMLTextAreaElement>;
}

export const StyledTextArea = styled.textarea<IStyledTextAreaWithRef>`
  padding: 15px 20px;
  border-radius: 10px;
  resize: none;
  height: 100%;
  ${({ theme, color, error, sx }) => css`
    text-align: ${sx?.textAlign};
    font-size: ${theme.typography.size[sx?.size || "SM"]};
    font-weight: ${theme.typography.weight[sx?.weight]};
    background-color: ${`${theme.palette.white}`};
    border: 1px solid ${`${theme.palette[color].light}33`};
    color: ${theme.palette[color].light};
    width: 100%;
    border-color: ${error && error !== "none" && `${theme.palette.error.light}25 !important`};
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid ${`${theme.palette[color].light}50`};
      -webkit-text-fill-color: inherit;
      -webkit-box-shadow: 0 0 0 1000px ${theme.palette.background[color]} inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 174, 255, 0.04) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(0, 174, 255, 0.03) 100%
      );
    }
    &:-webkit-autofill + span {
      font-size: ${theme.typography.size.SM};
      top: 0;
    }
    &:focus {
      border: 1px solid ${theme.palette[color].light};
    }
    &::placeholder {
      color: ${`${theme.palette[color].light}90`};
    }
  `}
  ${({ sx }) => paddingStyles(sx)}
`;
export const TextAreaWrap = styled.label<IStyledTextArea>`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.01);
  ${({ theme, color, fullWidth }) => css`
    font-size: ${theme.typography.size.SM};
    width: ${fullWidth && "100%"};
    color: ${theme.palette[color].light};
    &:focus {
      color: 1px solid ${theme.palette[color].light};
    }
  `}
  ${({ sx }) => sizeStyles(sx)}
  ${({ sx }) => marginStyles(sx)}
`;
export const TextAreaLabel = styled.span<IStyledLabel>`
  position: absolute;
  left: 10px;
  max-width: 80%;
  white-space: nowrap;
  top: 20%;
  transform: translateY(-50%);
  transition: top 100ms;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  -moz-user-select: none;
  user-select: none;
  ${({ theme, focus, isRequired, error, color }) => css`
    background: ${!focus ? "white" : `linear-gradient(0deg, white 51%, transparent 49%)`};
    color: ${error && error !== "none"
      ? `${theme.palette.error.main}80 !important`
      : `${theme.palette[color].main}90`};
    font-size: ${focus ? theme.typography.size.XS : theme.typography.size.SM};
    top: ${focus && 0};
    font-weight: ${focus && theme.typography.weight.regular};
    padding: ${focus && "0 2px"};
    &:before {
      display: ${isRequired && !focus ? "block" : "none"};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-image: ${theme.palette.warning.gradient[75]};
    }
  `}
`;
export const TextAreaError = styled.span<IStyledTextArea>`
  position: absolute;
  left: 20px;
  bottom: 2px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  min-height: 15px;
  ${({ theme }) => css`
    font-size: ${theme.typography.size.XS};
    color: ${theme.palette.error.main};
  `}
`;
