import { MarketAPI } from "@front-packages/dfa-gql-api";
import { useErrors } from "hooks/errors";
import { useState } from "react";

const useGetChannel = () => {
  const { setError } = useErrors();
  const [channelId, setChannelId] = useState<string>(null);
  const [isChannelIdLoading, setIsChannelIdLoading] = useState<boolean>(false);

  const getChannelId = async () => {
    setIsChannelIdLoading(true);
    const { response, error } = await MarketAPI.GetChannel();
    if (error) setError({ error, key: Math.random() });
    if (response)
      setChannelId(
        response?.channels?.find((channel) => channel.state === "STATE_OPEN")?.channelID
      );
    setIsChannelIdLoading(false);
  };

  return { channelId, setChannelId, getChannelId, isChannelIdLoading };
};

export default useGetChannel;
