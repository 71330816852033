import React from "react";
import { IIcon } from "./default";
import { Text } from "../components";

const AttachFile: React.FC<IIcon> = ({ color = "inherit", contrast, sx }) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ m: "0 auto", justify: "center", align: "center", ...sx }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5a2.5 2.5 0 0 1 5 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5a2.5 2.5 0 0 0 5 0V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z" />
      </svg>
    </Text>
  );
};

export default AttachFile;
