import { linearGradient } from "./helpers";

export type GradientType = {
  100: string;
  75: string;
  50: string;
  25: string;
  10: string;
  3: string;
};

export type ColorOpacityType = {
  100: string;
  75: string;
  50: string;
  25: string;
  10: string;
  5: string;
};

export type PaletteOptions = {
  main: string;
  light?: string;
  lighter?: string;
  dark?: string;
  gradient?: GradientType;
};
// export type PaletteCommonOptions = {
//   white: string;
//   black: string;
// };
export type PaletteExtraOptions = Partial<PaletteOptions> & {};
export type BackgroundOptions = {
  default: string;
  primary: string;
  secondary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
  text: string;
};
export type PaletteTextOptions = Partial<PaletteOptions> & {
  contrast: string;
  contrastHover: string;
};
export interface IPalette {
  type: string;
  white: PaletteOptions;
  black: PaletteOptions;
  primary: PaletteOptions;
  somePrimary: PaletteOptions;
  secondary: PaletteOptions;
  info: PaletteOptions;
  success: PaletteOptions;
  warning: PaletteOptions;
  error: PaletteOptions;
  grey: PaletteExtraOptions;
  background: BackgroundOptions;
  text: PaletteTextOptions;
  opacity: {
    100: "FF";
    75: "BF";
    50: "80";
    25: "40";
    15: "26";
    10: "1A";
    5: "0D";
    3: "08";
  };
}

export const investorPalette: IPalette = {
  opacity: {
    100: "FF",
    75: "BF",
    50: "80",
    25: "40",
    15: "26",
    10: "1A",
    5: "0D",
    3: "08",
  },
  type: "investor",
  white: {
    main: "#f9f9f9",
    light: "#fff",
  },
  black: {
    main: "#292929",
  },
  primary: {
    main: "#04577D",
    light: "#068DC9",
    lighter: "#04577D1A",
    gradient: {
      100: linearGradient("#04577D", "#068DC9", 45),
      75: linearGradient("rgba(4, 87, 125, 0.75) 0%", "rgba(6, 141, 201, 0.75) 100%", 45),
      50: linearGradient("rgba(4, 87, 125, 0.5) 0%", "rgba(6, 141, 201, 0.5) 100%", 45),
      25: linearGradient("rgba(4, 87, 125, 0.25) 0%", "rgba(6, 141, 201, 0.25) 100%", 45),
      10: linearGradient("rgba(4, 87, 125, 0.10) 0%", "rgba(6, 141, 201, 0.10) 100%", 45),
      3: linearGradient("rgba(4, 87, 125, 0.03) 0%", "rgba(6, 141, 201, 0.03) 100%", 45),
    },
  },
  somePrimary: {
    main: "#398151",
    light: "#35C691",
    gradient: {
      100: linearGradient("#398151", "#35C691", 45),
      75: linearGradient("rgba(57, 129, 81, 0.75) 0%", "rgba(53, 198, 145, 0.75) 100%", 45),
      50: linearGradient("rgba(57, 129, 81, 0.50) 0%", "rgba(53, 198, 145, 0.50) 100%", 45),
      25: linearGradient("rgba(57, 129, 81, 0.25) 0%", "rgba(53, 198, 145, 0.25) 100%", 45),
      10: linearGradient("rgba(57, 129, 81, 0.10) 0%", "rgba(53, 198, 145, 0.10) 100%", 45),
      3: linearGradient("rgba(57, 129, 81, 0.03) 0%", "rgba(53, 198, 145, 0.03) 100%", 45),
    },
  },
  secondary: {
    main: "#7b809f",
    light: "#8f93a9",
    gradient: {
      100: linearGradient("#7b809a", "#8f93a9", 45),
      75:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.75) 0%", "rgba(181, 181, 181, 0.75) 100%", 45)}`,
      50:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.50) 0%", "rgba(181, 181, 181, 0.50) 100%", 45)}`,
      25:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.25) 0%", "rgba(181, 181, 181, 0.25) 100%", 45)}`,
      10:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.10) 0%", "rgba(181, 181, 181, 0.10) 100%", 45)}`,
      3:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.03) 0%", "rgba(181, 181, 181, 0.03) 100%", 45)}`,
    },
  },
  info: {
    main: "#2E76AC",
    light: "#65BDFF",
    gradient: {
      100: linearGradient("#2E76AC", "#65BDFF", 45),
      75: linearGradient("rgba(46, 118, 172, 0.75) 0%", "rgba(101, 189, 255, 0.75) 100%", 45),
      50: linearGradient("rgba(46, 118, 172, 0.50) 0%", "rgba(101, 189, 255, 0.50) 100%", 45),
      25: linearGradient("rgba(46, 118, 172, 0.25) 0%", "rgba(101, 189, 255, 0.25) 100%", 45),
      10: linearGradient("rgba(46, 118, 172, 0.1) 0%", "rgba(101, 189, 255, 0.1) 100%", 45),
      3: linearGradient("rgba(46, 118, 172, 0.03) 0%", "rgba(101, 189, 255, 0.03) 100%", 45),
    },
  },
  success: {
    main: "#038A6B",
    light: "#45D6B4",
    gradient: {
      100: linearGradient("#038A6B", "#45D6B4", 45),
      75: linearGradient("rgba(3, 138, 107, 0.75) 0%", "rgba(69, 214, 180, 0.75) 100%", 45),
      50: linearGradient("rgba(3, 138, 107, 0.50) 0%", "rgba(69, 214, 180, 0.50) 100%", 45),
      25: linearGradient("rgba(3, 138, 107, 0.25) 0%", "rgba(69, 214, 180, 0.25) 100%", 45),
      10: linearGradient("rgba(3, 138, 107, 0.10) 0%", "rgba(69, 214, 180, 0.10) 100%", 45),
      3: linearGradient("rgba(3, 138, 107, 0.03) 0%", "rgba(69, 214, 180, 0.03) 100%", 45),
    },
  },
  warning: {
    main: "#C9471A",
    light: "#FC8C08",
    gradient: {
      100: linearGradient("#C9471A", "#FC8C08", 45),
      75: linearGradient("rgba(201, 71, 26, 0.75) 0%", "rgba(252, 140, 8, 0.75) 100%", 45),
      50: linearGradient("rgba(201, 71, 26, 0.5) 0%", "rgba(252, 140, 8, 0.5) 100%", 45),
      25: linearGradient("rgba(201, 71, 26, 0.25) 0%", "rgba(252, 140, 8, 0.25) 100%", 45),
      10: linearGradient("rgba(201, 71, 26, 0.1) 0%", "rgba(252, 140, 8, 0.1) 100%", 45),
      3: linearGradient("rgba(201, 71, 26, 0.03) 0%", "rgba(252, 140, 8, 0.03) 100%", 45),
    },
  },
  error: {
    main: "#BD172F",
    light: "#D65A6C",
    gradient: {
      100: linearGradient("#C9102B", "#FF2E4D", 45),
      75: linearGradient("rgba(201, 16, 43, 0.75) 0%", "rgba(255, 46, 77, 0.75) 100%", 45),
      50: linearGradient("rgba(201, 16, 43, 0.50) 0%", "rgba(255, 46, 77, 0.50) 100%", 45),
      25: linearGradient("rgba(201, 16, 43, 0.25) 0%", "rgba(255, 46, 77, 0.25) 100%", 45),
      10: linearGradient("rgba(201, 16, 43, 0.10) 0%", "rgba(255, 46, 77, 0.10) 100%", 45),
      3: linearGradient("rgba(201, 16, 43, 0.03) 0%", "rgba(255, 46, 77, 0.03) 100%", 45),
    },
  },
  grey: {},
  background: {
    default: "#fff",
    primary: "#e5f1f6",
    secondary: "#f1f3f7",
    info: "#eef6fc",
    success: "#ebf6f5",
    warning: "#f8f3ed",
    error: "#f8edf1",
    text: "#f0f2f6",
  },
  text: {
    main: "#344767",
    light: "#7b809a",
    contrast: "#ecefff",
    contrastHover: "#ecefffcc",
  },
};

export const issuerPalette: IPalette = {
  opacity: {
    100: "FF",
    75: "BF",
    50: "80",
    25: "40",
    15: "26",
    10: "1A",
    5: "0D",
    3: "08",
  },
  type: "issuer",
  white: {
    main: "#f9f9f9",
    light: "#fff",
  },
  black: {
    main: "#292929",
  },
  primary: {
    main: "#398151",
    light: "#35C691",
    gradient: {
      100: linearGradient("#398151", "#35C691", 45),
      75: linearGradient("rgba(57, 129, 81, 0.75) 0%", "rgba(53, 198, 145, 0.75) 100%", 45),
      50: linearGradient("rgba(57, 129, 81, 0.50) 0%", "rgba(53, 198, 145, 0.50) 100%", 45),
      25: linearGradient("rgba(57, 129, 81, 0.25) 0%", "rgba(53, 198, 145, 0.25) 100%", 45),
      10: linearGradient("rgba(57, 129, 81, 0.10) 0%", "rgba(53, 198, 145, 0.10) 100%", 45),
      3: linearGradient("rgba(57, 129, 81, 0.03) 0%", "rgba(53, 198, 145, 0.03) 100%", 45),
    },
  },
  somePrimary: {
    main: "#04577D",
    light: "#068DC9",
    gradient: {
      100: linearGradient("#04577D", "#068DC9", 45),
      75: linearGradient("rgba(4, 87, 125, 0.75) 0%", "rgba(6, 141, 201, 0.75) 100%", 45),
      50: linearGradient("rgba(4, 87, 125, 0.5) 0%", "rgba(6, 141, 201, 0.5) 100%", 45),
      25: linearGradient("rgba(4, 87, 125, 0.25) 0%", "rgba(6, 141, 201, 0.25) 100%", 45),
      10: linearGradient("rgba(4, 87, 125, 0.10) 0%", "rgba(6, 141, 201, 0.10) 100%", 45),
      3: linearGradient("rgba(4, 87, 125, 0.03) 0%", "rgba(6, 141, 201, 0.03) 100%", 45),
    },
  },
  secondary: {
    main: "#7b809f",
    light: "#8f93a9",
    gradient: {
      100: linearGradient("#7b809a", "#8f93a9", 45),
      75:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.75) 0%", "rgba(181, 181, 181, 0.75) 100%", 45)}`,
      50:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.50) 0%", "rgba(181, 181, 181, 0.50) 100%", 45)}`,
      25:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.25) 0%", "rgba(181, 181, 181, 0.25) 100%", 45)}`,
      10:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.10) 0%", "rgba(181, 181, 181, 0.10) 100%", 45)}`,
      3:
        `${linearGradient("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)", 0)}` +
        ", " +
        `${linearGradient("rgba(79, 79, 79, 0.03) 0%", "rgba(181, 181, 181, 0.03) 100%", 45)}`,
    },
  },
  info: {
    main: "#2E76AC",
    light: "#65BDFF",
    gradient: {
      100: linearGradient("#2E76AC", "#65BDFF", 45),
      75: linearGradient("rgba(46, 118, 172, 0.75) 0%", "rgba(101, 189, 255, 0.75) 100%", 45),
      50: linearGradient("rgba(46, 118, 172, 0.50) 0%", "rgba(101, 189, 255, 0.50) 100%", 45),
      25: linearGradient("rgba(46, 118, 172, 0.25) 0%", "rgba(101, 189, 255, 0.25) 100%", 45),
      10: linearGradient("rgba(46, 118, 172, 0.1) 0%", "rgba(101, 189, 255, 0.1) 100%", 45),
      3: linearGradient("rgba(46, 118, 172, 0.03) 0%", "rgba(101, 189, 255, 0.03) 100%", 45),
    },
  },
  success: {
    main: "#398151",
    light: "#35C691",
    gradient: {
      100: linearGradient("#398151", "#35C691", 45),
      75: linearGradient("rgba(57, 129, 81, 0.75) 0%", "rgba(53, 198, 145, 0.75) 100%", 45),
      50: linearGradient("rgba(57, 129, 81, 0.50) 0%", "rgba(53, 198, 145, 0.50) 100%", 45),
      25: linearGradient("rgba(57, 129, 81, 0.25) 0%", "rgba(53, 198, 145, 0.25) 100%", 45),
      10: linearGradient("rgba(57, 129, 81, 0.10) 0%", "rgba(53, 198, 145, 0.10) 100%", 45),
      3: linearGradient("rgba(57, 129, 81, 0.03) 0%", "rgba(53, 198, 145, 0.03) 100%", 45),
    },
  },
  warning: {
    main: "#C9471A",
    light: "#FC8C08",
    gradient: {
      100: linearGradient("#C9471A", "#FC8C08", 45),
      75: linearGradient("rgba(201, 71, 26, 0.75) 0%", "rgba(252, 140, 8, 0.75) 100%", 45),
      50: linearGradient("rgba(201, 71, 26, 0.5) 0%", "rgba(252, 140, 8, 0.5) 100%", 45),
      25: linearGradient("rgba(201, 71, 26, 0.25) 0%", "rgba(252, 140, 8, 0.25) 100%", 45),
      10: linearGradient("rgba(201, 71, 26, 0.1) 0%", "rgba(252, 140, 8, 0.1) 100%", 45),
      3: linearGradient("rgba(201, 71, 26, 0.03) 0%", "rgba(252, 140, 8, 0.03) 100%", 45),
    },
  },
  error: {
    main: "#C9102B",
    light: "#FF2E4D",
    gradient: {
      100: linearGradient("#C9102B", "#FF2E4D", 45),
      75: linearGradient("rgba(201, 16, 43, 0.75) 0%", "rgba(255, 46, 77, 0.75) 100%", 45),
      50: linearGradient("rgba(201, 16, 43, 0.50) 0%", "rgba(255, 46, 77, 0.50) 100%", 45),
      25: linearGradient("rgba(201, 16, 43, 0.25) 0%", "rgba(255, 46, 77, 0.25) 100%", 45),
      10: linearGradient("rgba(201, 16, 43, 0.10) 0%", "rgba(255, 46, 77, 0.10) 100%", 45),
      3: linearGradient("rgba(201, 16, 43, 0.03) 0%", "rgba(255, 46, 77, 0.03) 100%", 45),
    },
  },
  grey: {},
  background: {
    default: "#fff",
    primary: "#ebf6f1",
    secondary: "#f1f3f7", // todo: заменить
    info: "#eef6fc", // todo: заменить
    success: "#ebf6f5", // todo: заменить
    warning: "#f8f3ed", // todo: заменить
    error: "#f8edf1", // todo: заменить
    text: "#f0f2f6", // todo: заменить
  },
  text: {
    main: "#344767",
    light: "#7b809a",
    contrast: "#ecefff",
    contrastHover: "#ecefffcc",
  },
};
