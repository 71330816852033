import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Flex, Text } from "Theme";
import { RoutesNames } from "routes";
import { useAppSelector } from "store";
import { Wrap } from "./RightAuthLayoutComponent";
import Dot from "./Dot";
import { getImage, getOnBoardingText, getOnBoardingTitle } from "./onBoardingInfo";

const RightAuthLayoutComponent: React.FC = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isSetPass = !!searchParams.get("set-pass");
  const { onBoardingStep } = useAppSelector((state) => state.account);

  return (
    <Wrap id="RightAuthLayoutComponent">
      {getImage(pathname, onBoardingStep)}
      <Text
        variant="h2"
        color="contrast"
        sx={{ textAlign: "center", mb: 1, mt: 1.5, weight: "regular" }}
      >
        {pathname === RoutesNames.SignIn && "Личный кабинет представителя"}
        {pathname === RoutesNames.Invite && !isSetPass && "Проверка представителя"}
        {pathname === RoutesNames.Invite && isSetPass && "Установка пароля"}
        {pathname === RoutesNames.OnBoarding && getOnBoardingTitle(onBoardingStep)}
      </Text>
      <Text
        variant="h5"
        color="contrast"
        sx={{ textAlign: "center", lineHeight: 1.4, weight: "normal" }}
      >
        {pathname === RoutesNames.SignIn &&
          "Личный кабинет представителя позволяет Вам осуществлять инвестиции в цифровые финансовые активы от имени организации"}
        {pathname === RoutesNames.Invite &&
          !isSetPass &&
          "Для начала работы с Платформой, нам нужно убедиться, что Вы являетесь действующим представителем организации. Введите номер телефона представителя"}
        {pathname === RoutesNames.Invite &&
          isSetPass &&
          "Проверка представителя успешно завершена. Вы впервые входите в учетную запись. Задайте пароль от личного кабинета представитля"}
        {pathname === RoutesNames.OnBoarding && getOnBoardingText(onBoardingStep)}
      </Text>
      {pathname === RoutesNames.OnBoarding && (
        <Flex sx={{ mt: 2, justify: "center" }}>
          {[1, 2, 3, 4, 5, 6, 7].map((step) => (
            <Dot
              key={`dot-${step}`}
              type={step === onBoardingStep || onBoardingStep === 8 ? "filled" : "outline"}
              sx={{ mr: step !== 7 ? 1 : 0 }}
            />
          ))}
        </Flex>
      )}
    </Wrap>
  );
};

export default RightAuthLayoutComponent;
