import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const DocumentIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        fill="none"
      >
        <path
          fill="currentColor"
          // fillOpacity={0.2}
          d="M14.713.75H3.25a2.507 2.507 0 0 0-2.5 2.5v17.5c0 1.375 1.125 2.5 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V9.287c0-.662-.262-1.3-.738-1.762l-6.037-6.038A2.458 2.458 0 0 0 14.712.75ZM7 15.75h10c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25H7c-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Zm0-5h10c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25H7c-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Zm0-5h6.25c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25H7c-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Z"
        />
      </svg>
    </Text>
  );
};

export default DocumentIcon;
