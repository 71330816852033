import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const RequestQuote: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        viewBox="0 0 48 48"
        fill="currentColor"
      >
        <path d="M23.9 36.5h1.55v-2h3.45q.35 0 .575-.225.225-.225.225-.575v-6.2q0-.35-.225-.575-.225-.225-.575-.225h-7.65v-4.65h8.45V20.5h-4.25v-2H23.9v2h-3.45q-.3 0-.525.225-.225.225-.225.525v6.25q0 .3.225.525.225.225.525.225h7.7v4.7H19.7v1.55h4.2ZM12.75 42q-1.15 0-1.95-.8t-.8-1.95V8.75q0-1.15.8-1.95t1.95-.8h16.7L38 14.55v24.7q0 1.15-.8 1.95t-1.95.8Zm15.5-26.95v-7.5h-15.5q-.45 0-.825.375-.375.375-.375.825v30.5q0 .45.375.825.375.375.825.375h22.5q.45 0 .825-.375.375-.375.375-.825v-24.2Zm-16.7-7.5v7.5-7.5 32.9-32.9Z" />
      </svg>
    </Text>
  );
};

export default RequestQuote;
