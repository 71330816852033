import styled, { css } from "styled-components";
import {
  BorderType,
  ButtonVariants,
  ColorType,
  FlexType,
  IAdaptive,
  IDefaultPropsType,
  MarginType,
  PaddingType,
  PositionType,
  ShadowsType,
  SizeType,
  TypographyType,
} from "../../types";
import {
  adaptiveStyles,
  borderStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  sizeStyles,
} from "../../styles";
import { FontSize } from "../../base";

export interface IStyledButton extends IAdaptive, IDefaultPropsType {
  sx?: MarginType &
    FlexType &
    SizeType &
    PaddingType &
    PositionType &
    TypographyType &
    BorderType & { size?: keyof FontSize } & ShadowsType;
  variant?: ButtonVariants;
  color?: ColorType;
  light?: boolean;
  disabled?: boolean;
  animation?: boolean;
  cardMode?: boolean;
}

interface IIconWrap {
  onlyIcon: boolean;
  isEnd?: boolean;
  background?: ColorType;
  iconSx?: PaddingType & BorderType;
}
// TODO: доделать size
export const StyledButton = styled.button<IStyledButton>`
  height: 40px;
  position: relative;
  display: flex;
  cursor: pointer;
  padding: ${({ sx }) => (sx?.size === "XXS" ? "10px 15px" : "13px 30px")};
  border-radius: ${({ cardMode }) => (cardMode ? `15px` : `10px`)};
  transition: all 0.2s ease-in-out;
  text-transform: ${({ variant }) => (variant === "link" ? "initial" : "uppercase")};
  font-size: ${({ theme, variant, sx }) =>
    variant === "link" ? theme.typography.size.SM : theme.typography.size[sx?.size || "XS"]};
  font-weight: ${({ theme, variant }) => variant !== "link" && theme.typography.weight.bold};
  ${({ sx }) => css`
    flex: ${sx?.flex};
    flex-direction: ${sx?.direction || "row"};
    justify-content: ${sx?.justify || "center"};
    align-items: ${sx?.align || "center"};
    text-transform: ${sx?.textTransform};
  `};
  ${({ variant, theme, color, disabled, animation }) =>
    variant === "contained" &&
    css`
      border: none;
      box-shadow: ${color !== "white" ? `0 2px 3px rgba(0, 0, 0, 0.2)` : `0 5px 19px #00000010`};
      background-color: ${color !== "white" ? theme.palette[color].light : "#fff"};
      color: ${color === "white" ? theme.palette.text.main : theme.palette.white.main};
      &:hover {
        transform: ${!disabled && animation && " translateY(-0.2rem)"};
        box-shadow: ${color !== "white" ? `0 3px 5px rgba(0, 0, 0, 0.3)` : `0 10px 19px #00000015`};
      }
      &:active {
        background-image: ${color !== "white" && theme.palette[color].gradient[75]};
        background-color: ${color === "white" && "#fff"};
      }
      &:disabled {
        cursor: unset;
        background: ${color === "white"
          ? "#ccc"
          : theme.helpers.rgba(theme.palette[color].main, 0.4)};
      }
    `};
  ${({ variant, theme, color, disabled }) =>
    variant === "outline" &&
    css`
      background: ${theme.palette[color].light}${theme.palette.opacity[5]};
      border: 1px solid ${theme.palette[color].main}${theme.palette.opacity[25]};
      color: ${theme.palette[color].main};
      &:hover {
        color: ${!disabled && theme.helpers.rgba(theme.palette[color].main, 0.8)};
        border-color: ${!disabled && `${theme.palette[color].main}${theme.palette.opacity[75]}`};
      }
      &:active {
        border-color: ${theme.palette[color].light};
        color: ${theme.palette[color].light};
        background-color: ${theme.palette[color].light}${theme.palette.opacity[10]};
      }
      &:disabled {
        cursor: unset;
        color: ${theme.helpers.rgba(theme.palette[color].main, 0.4)};
        border-color: ${theme.helpers.rgba(theme.palette[color].main, 0.4)};
      }
    `};
  ${({ variant, theme, color, disabled, light }) =>
    variant === "text" &&
    css`
      padding: unset;
      background: transparent;
      border: none;
      color: ${theme.palette[color][light ? "light" : "main"]};
      &:hover {
        color: ${!disabled &&
        theme.helpers.rgba(theme.palette[color][light ? "light" : "main"], 0.8)};
      }
      &:active {
        color: ${theme.palette[color].light};
      }
      &:disabled {
        cursor: unset;
        color: ${theme.helpers.rgba(theme.palette[color].main, 0.8)};
      }
    `};
  ${({ variant, theme, color, disabled }) =>
    variant === "link" &&
    css`
      width: max-content;
      padding: unset;
      text-decoration: underline;
      background: transparent;
      border: none;
      color: ${theme.palette[color].light};
      &:hover {
        color: ${!disabled && theme.helpers.rgba(theme.palette[color].main, 0.8)};
      }
      &:active {
        color: ${theme.palette[color].light};
      }
      &:disabled {
        cursor: unset;
        color: ${theme.helpers.rgba(theme.palette[color].main, 0.8)};
      }
    `};
  ${({ sx }) => marginStyles(sx)};
  // TODO: посмотреть, нужен ли paddingStyles для остальных типов кнопок
  ${({ sx }) => paddingStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ sx }) => positionStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
  ${({ sx }) => css`
    box-shadow: ${sx?.boxShadow};
  `};
`;
export const IconWrap = styled.span<IIconWrap>`
  margin-right: ${({ onlyIcon, isEnd }) => !onlyIcon && !isEnd && "5px"};
  margin-left: ${({ onlyIcon, isEnd }) => !onlyIcon && isEnd && "5px"};
  background: ${({ background, theme }) => background && theme.palette[background].gradient[10]};
  ${({ iconSx }) => paddingStyles(iconSx)};
  ${({ iconSx }) => borderStyles(iconSx)};
`;
StyledButton.defaultProps = {
  variant: "contained",
  color: "primary",
};
