import React from "react";
import { useLocation } from "react-router-dom";
import { Badge, Flex, Text } from "Theme";
import { RoutesNames } from "routes";

interface IFooterProps {
  contrast?: boolean;
  auth?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ contrast, auth }) => {
  const { pathname } = useLocation();
  const isRelease = process.env.REACT_APP_ENVIRONMENT === "RELEASE";
  const isOnBoarding = pathname === RoutesNames.OnBoarding;

  return (
    <Flex
      sx={{
        mt: "auto",
        justify: "space-between",
        direction: !isRelease && auth && !isOnBoarding ? "row-reverse" : "row",
        align: "flex-end",
        width: "100%",
        p: !auth ? "20px 0 10px" : undefined,
      }}
      adaptiveSX={{ tablet: { align: "center" } }}
    >
      <Text
        variant="h6"
        color={contrast ? "contrast" : "text"}
        sx={{
          ml: auth && !pathname.includes("on-boarding") ? "auto" : undefined,
          mr: auth && pathname.includes("on-boarding") ? "auto" : undefined,
        }}
        link="https://isdfa.ru/"
      >
        © {new Date().getFullYear()}. СМАРТ
      </Text>
      {!isRelease && (
        <Flex sx={{ align: "center", gap: 1 }} adaptiveSX={{ tablet: { direction: "column" } }}>
          <Badge
            label={process.env.REACT_APP_VERSION}
            color={isOnBoarding || auth ? "white" : "info"}
          />
          {!auth && (
            <>
              <Badge
                label={process.env.REACT_APP_CORE_API}
                color={isOnBoarding || auth ? "white" : "info"}
              />
              <Badge
                label={process.env.REACT_APP_MIDDLE_API}
                color={isOnBoarding || auth ? "white" : "info"}
              />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default Footer;
