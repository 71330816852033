import React, {
  ChangeEvent,
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useState,
} from "react";
import { StyledInput, IStyledInput, InputWrap, InputError, InputLabel, IconWrap } from "./Input";

type OnChangeType = (value: string | number) => void;
interface IInputProps extends IStyledInput {
  value: string | number;
  onChange: Dispatch<SetStateAction<string | number>> | OnChangeType;
  placeholder?: string;
  type?: "number" | "text" | "password" | "tel" | "email";
  maxLength?: number;
  name?: string;
  icon?: React.ReactNode;
  onKeyDown?(e: any): void;
  onFocus?(e?: any): void;
  onBlur?(e?: any): void;
}

const Input = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      type,
      color = "primary",
      maxLength,
      icon,
      onKeyDown,
      onFocus,
      onBlur,
      isRequired,
      ...props
    }: IInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [key, setKey] = useState(null);
    const [prevValLength, setPrevValLength] = useState(0);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (type === "tel") {
        const inputVal = e.target.value;
        const currentLength = inputVal.replace(/\D/g, "").length;
        setPrevValLength(currentLength);
        let formatVal = "";
        if (inputVal.length !== e.target.selectionStart) {
          const start = e.target.selectionStart - 1;
          const end = e.target.selectionStart;
          const numInputVal: string = inputVal.replace(/\D/g, "");
          if (numInputVal.length >= 11 && key !== "Backspace" && key !== "Delete") {
            onChange(
              `+7 (${numInputVal.slice(1, 4)}) ${numInputVal.slice(4, 7)}-${numInputVal.slice(
                7,
                9
              )}-${numInputVal.slice(9, 11)}`
            );
            return;
          }
          if (
            inputVal &&
            (Number.isInteger(+inputVal[start]) ||
              ((key === "Backspace" || key === "Delete") &&
                (inputVal[start] === ")" ||
                  inputVal[start] === "-" ||
                  inputVal[start] === "(" ||
                  inputVal[start] === " ")))
          ) {
            onChange(
              `${inputVal.slice(0, start)}${inputVal.slice(start, end)}${inputVal.slice(end)}`
            );
          }
          return;
        }
        const numVal =
          currentLength - prevValLength > 2 && currentLength <= 10
            ? `7${inputVal}`.replace(/\D/g, "")
            : inputVal.replace(/\D/g, "");
        if (!numVal) e.target.value = "";
        const firstSymbols = "+7";
        formatVal = `${firstSymbols} `;
        if (numVal.length === 1 && key !== "Backspace" && key !== "Delete")
          formatVal = `+7 (${numVal}`;
        if (numVal.length > 1) formatVal += `(${numVal.substring(1, 4)}`;
        if (numVal.length >= 5) formatVal += `) ${numVal.substring(4, 7)}`;
        if (numVal.length >= 8) formatVal += `-${numVal.substring(7, 9)}`;
        if (numVal.length >= 10) formatVal += `-${numVal.substring(9, 11)}`;
        onChange(formatVal);
      } else if (onChange) {
        try {
          onChange(typeof value === "string" ? e.target.value : +e.target.value);
        } catch (error) {
          console.log(error);
        }
      }
    };
    const handleKeyDown = (e: any) => {
      const val = e.target.value;
      setKey(e.key);
      if (type === "tel" && e.keyCode === 8 && (val.replace(/\D/g, "").length === 1 || val === "+"))
        onChange("");
    };
    const handleFocus = (e) => {
      setIsFocus(true);
      if (onFocus) onFocus(e);
    };
    const handleBlur = (e) => {
      setIsFocus(false);
      if (onBlur) onBlur(e);
    };
    const label = type === "tel" ? "Номер телефона" : placeholder;
    return (
      <InputWrap {...{ ...props, color }}>
        <StyledInput
          maxLength={type === "tel" ? 18 : maxLength}
          ref={ref}
          type={type}
          onInput={handleOnChange}
          onKeyDown={type === "tel" ? handleKeyDown : onKeyDown}
          value={value || ""}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...{ ...props, color }}
        />
        {(placeholder || type === "tel") && (
          <InputLabel
            focus={isFocus || !!value}
            color={color}
            error={props.error}
            title={label}
            isRequired={isRequired}
            disabled={props.disabled}
            colored={props.colored}
          >
            {label}
          </InputLabel>
        )}
        {props.error !== "none" && (
          <InputError
            title={typeof props.error === "string" ? props.error : undefined}
            {...{ ...props, color: "error" }}
          >
            {props.error}
          </InputError>
        )}
        {icon && <IconWrap>{icon}</IconWrap>}
      </InputWrap>
    );
  }
);

export default Input;
