import { useState } from "react";

enum BadgeNamesEnum {
  ticker = "ticker",
  issuer = "issuer",
  investor = "investor",
  hideComplete = "hideComplete",
  hideCanceled = "hideCanceled",
  amount = "amount",
  orderSum = "orderSum",
  orderStatuses = "orderStatuses",
  createdDate = "createdDate",
  issueAmount = "issueAmount",
  startDate = "startDate",
  completionDate = "completionDate",
  tokenStatuses = "tokenStatuses",
  orderStatus = "orderStatus",
  tokenTicker = "tokenTicker",
}

const useSearchBadges = (state: any, setState: any, onSearch: any) => {
  const [badges, setBadges] = useState<Array<string>>([]);
  const handleSetBadge = () => {
    if (state && Object.keys(state).length) {
      const newBadges = Object.keys(state).filter((key) => {
        let isReturn = !!state[key];
        if (key === "page" || key === "count" || key === "clientType") isReturn = false;
        return isReturn;
      });
      setBadges(newBadges);
    }
  };
  const handleDelBadge = async (badge: string) => {
    const newState = { ...state, [badge]: undefined };
    setBadges(badges.filter((el) => el !== badge));
    setState(newState);
    await onSearch(newState);
  };
  const resetBadges = () => setBadges([]);

  const getBadgeTitle = (badge: string) => {
    switch (badge) {
      case BadgeNamesEnum.ticker:
        return "тикер";
      case BadgeNamesEnum.tokenTicker:
        return "тикер";
      case BadgeNamesEnum.issuer:
        return "эмитент";
      case BadgeNamesEnum.investor:
        return "инвестор";
      case BadgeNamesEnum.hideComplete:
        return "скрыть завершенные";
      case BadgeNamesEnum.hideCanceled:
        return "скрыть отмененные";
      case BadgeNamesEnum.amount:
        return "количество";
      case BadgeNamesEnum.orderSum:
        return "сумма";
      case BadgeNamesEnum.orderStatuses:
        return "статус";
      case BadgeNamesEnum.tokenStatuses:
        return "статус";
      case BadgeNamesEnum.createdDate:
        return "дата создания";
      case BadgeNamesEnum.issueAmount:
        return "количество выпусков";
      case BadgeNamesEnum.startDate:
        return "дата старта";
      case BadgeNamesEnum.completionDate:
        return "дата завершения";
      case BadgeNamesEnum.orderStatus:
        return "статус заявки";
      default:
        return badge;
    }
  };

  return { badges, handleSetBadge, handleDelBadge, resetBadges, getBadgeTitle };
};

export default useSearchBadges;
