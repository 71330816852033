export default {
  cardSx: {
    width: "49%",
    minHeight: "135px",
  },
  cardAdaptiveSx: { tabletL: { width: "48%" }, tablet: { width: "100%" } },
  gridSx: {
    areas: `
      "a a b" 
      "c d e"
    `,
    templateColumns: "1.5fr 1fr 1fr",
    rowGap: 1,
  },
  gridAdaptivSx: {
    laptopL: {
      areas: `
        "e b b" 
        "a a c" 
        "d . ."
      `,
    },
    tabletL: {
      areas: `
        "e b b" 
        "a a a" 
        "c d d"
      `,
    },
    tablet: {
      areas: `
        "a a b" 
        "c d e"
      `,
    },
    mobile: {
      areas: `
        "e b b" 
        "a a a" 
        "c d d"
      `,
    },
  },
};
