import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TokensAPI } from "@front-packages/dfa-gql-api";
import { RightsTypeEnum, TokenTypeEnum } from "@front-packages/dfa-constants";
import { resetHeader, setHeader, setPageLoading } from "store/global";
import { useAppDispatch } from "store";
import {
  Button,
  DatePicker,
  Dialog,
  Flex,
  Input,
  MFADialog,
  Select,
  Text,
  TextArea,
  DropZone,
} from "Theme";
import { useErrors } from "hooks";
import { RoutesNames } from "routes";
import { TokenRightType } from "constants/in_lib";
import { useStateEditIssue, useValidateIssue } from "../hooks";
import LoadedFiles from "../components/LoadedFiles";

const rowStyle = { width: "100%", gap: 1 };
const adaptiveStyles: any = { tablet: { direction: "column", rowGap: 1 } };
const EditIssue: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setError } = useErrors();
  const {
    isEdited,
    getToken,
    saveEditForm,
    reset,
    state: { tokenForm, tokenDefault },
    setState,
    loaders,
    allowForVerification,
    files,
    loadedFiles,
  } = useStateEditIssue();
  const { validateErrors, validator } = useValidateIssue();

  const [openDelModal, setOpenDelModal] = useState<boolean>(false);
  const [code, setCode] = useState<string>(null);
  const [sessionID, setSessionID] = useState<string>(null);
  const [loadingDel, setLoadingDel] = useState<boolean>(false);
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false);

  const delCode = () => setCode(null);
  const toggleDelModal = () => setOpenDelModal(!openDelModal);

  const deleteIssue = async () => {
    setLoadingDel(true);
    const { response, error } = await TokensAPI.DeleteToken({ tokenID: tokenDefault.id });
    if (error) setError({ error, key: Math.random() });
    setLoadingDel(false);
    toggleDelModal();
    if (response) navigate(RoutesNames.Issues);
  };

  const openModal2FA = async () => {
    setLoadingApproval(true);
    const { response, error } = await TokensAPI.Send2FAToTokenApproval({
      tokenID: tokenDefault.id,
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setCode(response?.code);
      setSessionID(response?.sessionID);
    }
    setLoadingApproval(false);
  };

  const sendToApproval = async () => {
    setLoadingApproval(true);
    const { response, error } = await TokensAPI.Verify2FAToTokenApproval({
      tokenID: tokenDefault.id,
      code,
      sessionID,
    });
    if (error) setError({ error, key: Math.random() });
    delCode();
    setLoadingApproval(false);
    if (response) {
      setTimeout(() => {
        navigate(RoutesNames.Issues);
      });
    }
  };

  useEffect(() => {
    validator(tokenForm);
  }, [
    tokenForm.minOrderLimit,
    tokenForm.maxOrderLimit,
    tokenForm.nominal,
    tokenForm.amount,
    tokenForm.minIssueLimit,
    tokenForm.interest,
  ]);

  useEffect(() => {
    dispatch(setHeader({ title: "Редактирование выпуска" }));
    getToken().then(() => dispatch(setPageLoading(false)));
    return () => {
      dispatch(resetHeader());
    };
  }, []);

  return (
    <>
      <Flex
        sx={{ width: "100%", m: "2rem auto 0", wrap: "wrap", rowGap: 1 }}
        adaptiveSX={{ laptopL: { width: "100%" } }}
      >
        {/* todo: not date */}
        {/* <Flex sx={{ width: "100%", mb: 2 }}> */}
        {/*  <EntityProps */}
        {/*    sx={{ mr: 1 }} */}
        {/*    color="primary" */}
        {/*    title="Создан" */}
        {/*    value={formattingDate(tokenDefault?.createDate)} */}
        {/*  /> */}
        {/* </Flex> */}
        <Text variant="h4" sx={{ mt: 1, width: "100%", weight: "regular" }}>
          Параметры выпуска
        </Text>
        <Flex adaptiveSX={adaptiveStyles} sx={rowStyle}>
          <Flex
            sx={{ width: "100%", gap: 1 }}
            adaptiveSX={{ tablet: { width: "100%" }, mobile: { direction: "column" } }}
          >
            {/* <Flex sx={{ flex: 1 }}> */}
            <DatePicker
              isRequired
              withTime
              value={tokenForm.investStartDate || null}
              onChange={setState.plannedPublicAndStartDate}
              minDate={new Date()}
              maxDate={tokenForm.investFinishDate || undefined}
              fullWidth
              placeholder="Начало сбора заявок"
            />
            {/* </Flex> */}
            {/* <Flex sx={{ flex: 1 }}> */}
            <DatePicker
              isRequired
              withTime
              value={tokenForm.investFinishDate || null}
              onChange={setState.investFinishDate}
              minDate={tokenForm.investStartDate || new Date()}
              fullWidth
              placeholder="Окончание сбора заявок"
            />
            {/* </Flex> */}
            {/* <Flex sx={{ flex: 1 }}> */}
            <DatePicker
              isRequired
              withTime
              value={tokenForm.plannedPublicDate || null}
              onChange={setState.plannedPublicAndStartDate}
              minDate={tokenForm.investStartDate || new Date()}
              maxDate={tokenForm.investFinishDate || undefined}
              fullWidth
              placeholder="Дата публикации"
            />
            {/* </Flex> */}
            {/* <Flex sx={{ flex: 1 }}>
              <DatePicker
                withTime
                value={null}
                onChange={null}
                minDate={tokenForm.investStartDate || new Date()}
                maxDate={tokenForm.investFinishDate || undefined}
                fullWidth
                placeholder="Размещение на втор. рынке"
              />
            </Flex> */}
          </Flex>
        </Flex>
        <Text variant="h4" sx={{ mt: 1, width: "100%", weight: "regular" }}>
          Параметры токена
        </Text>
        <Flex adaptiveSX={adaptiveStyles} sx={rowStyle}>
          <Flex sx={{ flex: 1 }}>
            <Select
              required
              fullWidth
              disabled
              placeholder="Тип токена"
              onChange={setState.tokenType}
              value={tokenForm.tokenType}
              options={Object.keys(TokenTypeEnum).map((key) => ({
                description: key,
                value: key,
              }))}
            />
          </Flex>
          <Flex sx={{ flex: 1 }}>
            <Input
              isRequired
              fullWidth
              value={tokenForm.nominal}
              onChange={setState.nominal}
              placeholder="Номинал"
              error={validateErrors?.nominal}
            />
          </Flex>
          <Flex sx={{ flex: 1 }}>
            <Input
              isRequired
              fullWidth
              value={tokenForm.amount}
              onChange={setState.amount}
              placeholder="Количество"
              disabled={!tokenForm.nominal}
              error={!!tokenForm.nominal && !tokenForm.amount && "Необходимо указать количество"}
            />
          </Flex>
          <Flex sx={{ flex: 1 }}>
            <Input
              fullWidth
              value={tokenForm.maxIssueLimit}
              onChange={setState.maxIssueLimit}
              placeholder="Объем выпуска"
              disabled
            />
          </Flex>
        </Flex>
        <Flex adaptiveSX={adaptiveStyles} sx={rowStyle}>
          <Flex adaptiveSX={{ mobile: { direction: "column" } }} sx={{ flex: 1, gap: 1 }}>
            <Flex sx={{ flex: 1 }}>
              <Input
                isRequired
                fullWidth
                value={tokenForm.minIssueLimit}
                onChange={setState.minIssueLimit}
                placeholder="Минимальный объем выкупа"
                disabled={!tokenForm.maxIssueLimit}
                error={validateErrors?.minIssueLimit}
              />
            </Flex>
            <Flex sx={{ flex: 1 }}>
              <Input
                isRequired
                fullWidth
                value={tokenForm.interest}
                onChange={setState.interest}
                placeholder="Ставка дохода"
                error={validateErrors?.interest}
              />
            </Flex>
            <Flex sx={{ flex: 1 }}>
              <Input
                isRequired
                fullWidth
                value={tokenForm.minOrderLimit}
                onChange={setState.minOrderLimit}
                placeholder="Мин. сумма заявки"
                error={validateErrors?.minOrderLimit}
              />
            </Flex>
            <Flex sx={{ flex: 1 }}>
              <Input
                fullWidth
                isRequired
                value={tokenForm.maxOrderLimit}
                onChange={setState.maxOrderLimit}
                placeholder="Макс. сумма заяки"
                error={validateErrors?.maxOrderLimit}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          sx={{ width: "100%", gap: 1 }}
          adaptiveSX={{ tablet: { width: "100%" }, mobile: { direction: "column" } }}
        >
          {/* <Flex sx={{ flex: 1 }}> */}
          <DatePicker
            isRequired
            withTime
            value={tokenForm.marketDate || null}
            onChange={setState.marketDate}
            minDate={tokenForm.investFinishDate || new Date()}
            fullWidth
            placeholder="Дата вывода на вторичный рынок"
          />
          {/* </Flex> */}
          {/* <Flex sx={{ flex: 1 }}> */}
          <DatePicker
            isRequired
            withTime
            value={tokenForm.redemptionDate || null}
            onChange={setState.redemptionDate}
            minDate={
              tokenForm.marketDate ||
              tokenForm.investFinishDate ||
              tokenForm.investStartDate ||
              new Date()
            }
            fullWidth
            placeholder="Дата погашения"
          />
          {/* </Flex> */}
          {/* <Flex sx={{ flex: 1 }}> */}
          <DatePicker
            withTime
            value={tokenForm.redemptionFinishDate || null}
            onChange={setState.redemptionFinishDate}
            minDate={
              tokenForm.redemptionDate ||
              tokenForm.marketDate ||
              tokenForm.investFinishDate ||
              tokenForm.investStartDate ||
              new Date()
            }
            fullWidth
            placeholder="Дата окончательного погашения"
          />
          {/* </Flex> */}
        </Flex>
        <Text variant="h4" sx={{ weight: "regular" }}>
          Юридические сведения
        </Text>
        <Select
          required
          fullWidth
          placeholder="Вид прав"
          onChange={setState.rightsType}
          value={tokenForm.rightsType}
          options={Object.keys(RightsTypeEnum).map((key) => ({
            description: TokenRightType[key],
            value: RightsTypeEnum[key],
          }))}
        />
        <Flex adaptiveSX={adaptiveStyles} sx={rowStyle}>
          <Flex sx={{ flex: 1 }}>
            <TextArea
              fullWidth
              isRequired
              rows={4}
              placeholder="Объем прав в соответствии с ч. 2 ст. 1 №259-ФЗ"
              value={tokenForm.scopeRights}
              onChange={setState.scopeRights}
            />
          </Flex>
          <Flex sx={{ flex: 1 }}>
            <TextArea
              fullWidth
              isRequired
              rows={4}
              placeholder="Ограничения"
              value={tokenForm.limits}
              onChange={setState.limits}
            />
          </Flex>
        </Flex>
        <Flex adaptiveSX={adaptiveStyles} sx={rowStyle}>
          <Flex sx={{ flex: 1 }}>
            <TextArea
              isRequired
              fullWidth
              rows={4}
              placeholder="Обеспечение выпуска"
              value={tokenForm.issueTerms}
              onChange={setState.issueTerms}
            />
          </Flex>
          <Flex sx={{ flex: 1 }}>
            <TextArea
              isRequired
              fullWidth
              rows={4}
              placeholder="Условия выпуска"
              value={tokenForm.provision}
              onChange={setState.provision}
            />
          </Flex>
        </Flex>
        <Text variant="h4" sx={{ mt: 1, width: "100%", weight: "regular" }}>
          Загрузить файлы
        </Text>
        <DropZone
          files={files}
          placeholder="Нажмите на эту область или перетащите файлы с юридическими сведениями"
        />
        {loadedFiles?.length ? <LoadedFiles loadedFiles={loadedFiles} /> : null}
        <Flex sx={{ ...rowStyle, justify: "flex-end" }}>
          <Button onClick={reset} variant="text" color="secondary">
            Отменить изменения
          </Button>
        </Flex>
        <Flex
          adaptiveSX={{ mobile: { direction: "column" } }}
          sx={{ ...rowStyle, justify: "flex-start", mt: 1 }}
        >
          <Button
            onClick={openModal2FA}
            disabled={isEdited || !allowForVerification}
            adaptiveSX={{ mobile: { mr: 0 } }}
            color="primary"
            loading={loadingApproval}
          >
            {isEdited && "Сначала сохраните изменения"}
            {!isEdited &&
              (allowForVerification ? "Отправить на проверку" : "Заполните все обязательные поля")}
          </Button>
          <Button
            onClick={saveEditForm}
            loading={loaders.edit}
            variant="outline"
            color="warning"
            disabled={
              !!validateErrors?.minRedemptionRate ||
              !!validateErrors?.minOrderLimit ||
              !!validateErrors?.maxOrderLimit ||
              (!!tokenForm.nominal && !tokenForm.amount) ||
              !!validateErrors?.nominal ||
              !!validateErrors?.minIssueLimit ||
              !!validateErrors?.interest
            }
          >
            Сохранить
          </Button>
          <Button onClick={toggleDelModal} color="error" variant="outline">
            Удалить
          </Button>
        </Flex>
      </Flex>
      <MFADialog
        descOperation="Выполняется подтверждение личности"
        open={!!code}
        onClose={delCode}
        onConfirm={sendToApproval}
        setCode={setCode}
        code={code}
        loading={loadingApproval}
      />
      <Dialog
        maxWidth="xs"
        type="error"
        open={openDelModal}
        title="Вы точно хотите удалить выпуск?"
        cancelText="нет"
        confirmText="да"
        onConfirm={deleteIssue}
        onClose={toggleDelModal}
        loading={loadingDel}
      />
    </>
  );
};

export default EditIssue;
