import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={72}
      viewBox="0 96 960 960"
      fill="currentColor"
    >
      <path d="M706 411v-81h-80v-22h80v-80h22v80h80v22h-80v81h-22ZM298 749h332L530 616 428 742l-65-75-65 82ZM152 884V268h400v143h74v74h142v399H152Z" />
    </svg>
  );
}

export default UploadIcon;
