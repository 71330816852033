import React, { useEffect, useRef, useState } from "react";
import { formattingDate } from "helpers";
import { Button, ColumnsType, Flex, RowsType, Text } from "Theme";
import { IconPortfolio } from "Theme/icons";
import {
  SortOptionGql,
  TokenStatusGql,
  TokensAPI,
  UserClientType,
} from "@front-packages/dfa-gql-api";
import { useAppDispatch, useAppSelector } from "store";
import { useErrors } from "hooks";
import { setPageLoading } from "store/global";
import { IFilterProps, OrderModalType } from "./types";

type Columns = "ticker" | "issuer" | "nominal" | "dateOfIssue" | "orders" | "actions";
// TODO: Когда на мидле появится АПИ - поменять.
// type ISortOption =
//   | "tickerAlphabetical"
//   | "tickerAlphabeticalRevert"
//   | "issuerAlphabetical"
//   | "issuerAlphabeticalRevert"
//   | "nominalAscending"
//   | "nominalDescending"
//   | "dateOfIssueEarly"
//   | "dateOfIssueLate";

const useSecondaryMarketData = () => {
  const dispatch = useAppDispatch();
  const isAccountIssuer = useAppSelector((state) => state.account.isAccountIssuer);
  const { setError } = useErrors();
  const [currentSortOption, setCurrentSortOption] = useState<SortOptionGql>(
    SortOptionGql.TickerAlphabeticalOrder
  );
  const [marketRows, setMarketRows] = useState<RowsType<Columns>>(null);
  const [totalCount, setTotalCount] = useState<number>(null);
  const [newOrderModalType, setNewOrderModalType] = useState<OrderModalType>(null);
  const [tokenTicker, setTokenTicker] = useState<string>(null);
  const [contract, setContract] = useState<string>(null);
  const [tokenID, setTokenID] = useState<string>(null);

  const [page, setPage] = useState<number>(0);
  const [count] = useState<number>(10);
  const [currentCount, setCurrentCount] = useState(0);
  const [filterProps, setFilterProps] = useState<IFilterProps>({
    ticker: "",
    issuer: "",
    isInMyBriefcase: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onOpenNewOrderModal = (
    e,
    type: OrderModalType,
    currentTokenTicker: string,
    currentContract: string,
    currentTokenID: string
  ) => {
    e.stopPropagation();
    setNewOrderModalType(type);
    setTokenTicker(currentTokenTicker);
    setContract(currentContract);
    setTokenID(currentTokenID);
  };
  const onCloseNewOrderModal = () => {
    setNewOrderModalType(null);
    setTokenTicker(null);
    setContract(null);
    setTokenID(null);
  };

  const getMarketTokens = async () => {
    dispatch(setPageLoading(true));
    setIsLoading(true);
    const { response, error } = await TokensAPI.GetTokens({
      opts: {
        page,
        count,
        clientType: isAccountIssuer ? UserClientType.Emitent : UserClientType.Investor,
        tokenStatuses: [TokenStatusGql.InMarket],
        ...filterProps,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setTotalCount(response?.count);
      setCurrentCount(response?.tokens?.length);
      setMarketRows(
        response?.tokens?.map((m) => {
          return {
            id: m.id,
            ticker: m.ticker,
            issuer: m.issuer,
            nominal: m.nominal,
            dateOfIssue: formattingDate(m.redemptionDate),
            orders: (
              // TODO: Добавить в АПИ данные по заявкам
              <Flex>
                {m.marketStats?.buying || m.marketStats?.selling ? (
                  <>
                    <Text color="success">{m.marketStats?.buying || 0}</Text>
                    <Text>/</Text>
                    <Text color="error">{m.marketStats?.selling || 0}</Text>
                  </>
                ) : (
                  <Text>-</Text>
                )}
              </Flex>
              // <Flex>
              //   <Text color="success">10</Text>
              //   <Text>/</Text>
              //   <Text color="error">5</Text>
              // </Flex>
            ),
            actions: (
              <Flex>
                <Flex sx={{ width: "40px" }}>
                  {m.isInMyBriefcase && <IconPortfolio size="medium" />}
                </Flex>
                <Flex sx={{ columnGap: 1 }}>
                  <Button
                    variant="link"
                    onClick={(e) =>
                      onOpenNewOrderModal(e, "buy", m.ticker, m.smartContractAddress, m.id)
                    }
                    color="success"
                  >
                    Купить
                  </Button>
                  {m.isInMyBriefcase && (
                    <Button
                      variant="link"
                      onClick={(e) =>
                        onOpenNewOrderModal(e, "sell", m.ticker, m.smartContractAddress, m.id)
                      }
                      color="error"
                    >
                      Продать
                    </Button>
                  )}
                </Flex>
              </Flex>
            ),
          };
        })
      );
    }
    setIsLoading(false);
    dispatch(setPageLoading(false));
  };

  const columns = useRef<ColumnsType<Columns>>([
    {
      label: "тикер",
      field: "ticker",
      proportion: 3,
      color: "primary",
      sortOption: {
        type: "alpha",
        value: {
          normal: "tickerAlphabetical",
          revert: "tickerAlphabeticalRevert",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.TickerAlphabeticalOrder
              ? SortOptionGql.TickerReverseAlphabeticalOrder
              : SortOptionGql.TickerAlphabeticalOrder
          );
        },
      },
    },
    {
      label: "эмитент",
      field: "issuer",
      proportion: 2,
      sortOption: {
        type: "alpha",
        value: {
          normal: "issuerAlphabetical",
          revert: "issuerAlphabeticalRevert",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.IssuerAlphabeticalOrder
              ? SortOptionGql.IssuerReverseAlphabeticalOrder
              : SortOptionGql.IssuerAlphabeticalOrder
          );
        },
      },
    },
    {
      label: "номинал",
      field: "nominal",
      proportion: 1,
      sortOption: {
        type: "number",
        value: {
          normal: "nominalAscending",
          revert: "nominalDescending",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.SumAscending
              ? SortOptionGql.SumDescending
              : SortOptionGql.SumAscending
          );
        },
      },
    },
    {
      label: "дата погашения",
      field: "dateOfIssue",
      proportion: 2,
      sortOption: {
        type: "any",
        value: {
          normal: "dateOfIssueEarly",
          revert: "dateOfIssueLate",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.FinishDateEarly
              ? SortOptionGql.FinishDateLate
              : SortOptionGql.FinishDateEarly
          );
        },
      },
    },
    { label: "заявки", field: "orders", proportion: 1 },
    { label: "действия", field: "actions", proportion: 3 },
  ]);

  useEffect(() => {
    getMarketTokens();
  }, [filterProps, page]);

  return {
    columns,
    marketRows,
    currentSortOption,
    newOrderModalType,
    onCloseNewOrderModal,
    tokenTicker,
    contract,
    tokenID,
    filterProps,
    setFilterProps,
    isLoading,
    page,
    setPage,
    totalCount,
    count,
    currentCount,
  };
};

export default useSecondaryMarketData;
