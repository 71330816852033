import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const WalletIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        fill="none"
      >
        <path
          fill="currentColor"
          // fillOpacity={0.2}
          d="M8.875 17V7a2.5 2.5 0 0 1 2.5-2.5h11.25V3.25c0-1.375-1.125-2.5-2.5-2.5h-17.5a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V19.5h-11.25a2.5 2.5 0 0 1-2.5-2.5Zm3.75-10c-.688 0-1.25.563-1.25 1.25v7.5c0 .688.563 1.25 1.25 1.25h11.25V7h-11.25Zm3.75 6.875A1.872 1.872 0 0 1 14.5 12c0-1.037.838-1.875 1.875-1.875 1.038 0 1.875.838 1.875 1.875a1.872 1.872 0 0 1-1.875 1.875Z"
        />
      </svg>
    </Text>
  );
};

export default WalletIcon;
