import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const DifferenceIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path fill="none" d="M0 0h20v20H0z" />
        <path d="M3.5 17.5H13V19H3.5c-.83 0-1.5-.67-1.5-1.5V6h1.5v11.5zM17 5v9.5c0 .83-.67 1.5-1.5 1.5h-9c-.83 0-1.5-.67-1.5-1.5v-12C5 1.67 5.67 1 6.5 1H13l4 4zm-1.5.62L12.38 2.5H6.5v12h9V5.62zm-3.75.88V5h-1.5v1.5h-1.5V8h1.5v1.5h1.5V8h1.5V6.5h-1.5zm1.5 4.5h-4.5v1.5h4.5V11z" />
      </svg>
    </Text>
  );
};

export default DifferenceIcon;
