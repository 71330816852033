import React, { useEffect, useRef, useState } from "react";
import { formattingDate } from "helpers";
import { Button, ColumnsType, Flex, RowsType, Text } from "Theme";
import {
  Authorship,
  MarketAPI,
  MarketOrderStatusGql,
  MarketOrderTypeGql,
} from "@front-packages/dfa-gql-api";
import { useErrors } from "hooks";
import { OrderModalType } from "../../types";

type Columns = "price" | "amount" | "sum" | "action";
// TODO: Когда на мидле появится АПИ - поменять.
type ISortOption =
  | "amountAscending"
  | "amountDescending"
  | "sumAscending"
  | "sumDescending"
  | "priceAscending"
  | "priceDescending"
  | "actionBeforeEarly"
  | "actionBeforeLate";

// TODO: Как появится АПИ, в пропсы передавать функцию по получению данных
const useMarketOrdersData = (
  type: OrderModalType,
  tokenTicker: string,
  onOpen: (type: OrderModalType, orderId?: string, isMy?: boolean) => void
) => {
  const { setError } = useErrors();

  const [currentSortOption, setCurrentSortOption] = useState<ISortOption>("amountAscending");
  const [page, setPage] = useState<number>(0);
  const [count] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(null);
  const [rows, setRows] = useState<RowsType<Columns>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onlyMyOrders, setOnlyMyOrders] = useState<boolean>(false);

  const columns = useRef<ColumnsType<Columns>>([
    {
      label: "цена",
      field: "price",
      proportion: 1,
      color: "primary",
      sortOption: {
        type: "number",
        value: {
          normal: "priceAscending",
          revert: "priceDescending",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === "priceAscending" ? "priceDescending" : "priceAscending"
          );
        },
      },
    },
    {
      label: "Кол-во",
      field: "amount",
      proportion: 1,
      sortOption: {
        type: "alpha",
        value: {
          normal: "amountAscending",
          revert: "amountDescending",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === "amountAscending" ? "amountDescending" : "amountAscending"
          );
        },
      },
    },
    {
      label: "Сумма",
      field: "sum",
      proportion: 1,
      sortOption: {
        type: "number",
        value: {
          normal: "sumAscending",
          revert: "sumDescending",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === "sumAscending" ? "sumDescending" : "sumAscending"
          );
        },
      },
    },
    {
      label: "действие до",
      field: "action",
      proportion: 4,
      sortOption: {
        type: "any",
        value: {
          normal: "actionBeforeEarly",
          revert: "actionBeforeLate",
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === "actionBeforeEarly" ? "actionBeforeLate" : "actionBeforeEarly"
          );
        },
      },
    },
  ]);

  const getMarketOrders = async () => {
    setIsLoading(true);
    const { response, error } = await MarketAPI.GetMarketOrders({
      opts: {
        page,
        count,
        tokenTicker,
        orderStatus: MarketOrderStatusGql.RegisteredMarketOrderStatus,
        orderType:
          type === "buy"
            ? MarketOrderTypeGql.BuyMarketOrderType
            : MarketOrderTypeGql.SellMarketOrderType,
        authorship: onlyMyOrders ? Authorship.Own : Authorship.All,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setRows(
        response?.MarketOrders?.map((m) => {
          return {
            id: m.id,
            price: m.fiatPrice,
            amount: m.tokenAmount,
            sum: m.fiatSum,
            action: (
              <Flex sx={{ columnGap: 3, align: "center" }}>
                <Flex>
                  <Text>{formattingDate(m.expireDate, null, "short")}</Text>
                </Flex>
                {!m.isOwnedByGivenAuthor && (
                  <Flex>
                    <Button
                      // TODO: добавить в onOpen id и по нему отправлять запрос в самой модалке для получения стейта, а isMyOrder убрать
                      onClick={() => onOpen(type, m.id, m.isOwnedByGivenAuthor)}
                      variant="link"
                      color={type === "buy" ? "success" : "error"}
                    >
                      принять
                    </Button>
                  </Flex>
                )}
              </Flex>
            ),
          };
        })
      );
      setTotalCount(response.totalCount);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    tokenTicker && getMarketOrders();
  }, [page, count, tokenTicker, onlyMyOrders]);

  return {
    columns,
    rows,
    currentSortOption,
    page,
    setPage,
    pagesAmount: Math.ceil(totalCount / +count),
    isLoading,
    getMarketOrders,
    onlyMyOrders,
    setOnlyMyOrders,
  };
};

export default useMarketOrdersData;
