import { UseFilesResult } from "hooks/files";
import React, { useRef, useState, ChangeEvent } from "react";
import Flex from "../Flex";
import Text from "../Text";
import { CursorType } from "../../types";
import { UploadIcon } from "../../icons";
import Button from "../Button";
import StyledDropZone from "./DropZone";
import File from "../File";
import CircleLoader from "../CircleLoader";

interface IDropZoneProps {
  files: UseFilesResult;
  placeholder: string;
}

function DropZone({ files, placeholder }: IDropZoneProps) {
  const uploadBtnRef = useRef(null);
  const [isActiveZone, setIsActiveZone] = useState<boolean>(false);
  const [isDropped, setIsDropped] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const onDragStart = (e) => {
    e.preventDefault();
    setIsActiveZone(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setIsActiveZone(false);
  };
  const onDragOver = (e) => {
    e.preventDefault();
    setIsActiveZone(true);
  };
  const onDrop = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsActiveZone(false);
    await files.dropUpload(e.dataTransfer.files);
    setLoading(false);
    setIsDropped(true);
  };
  const deleteFile = (key: string) => {
    setIsDropped(false);
    files.del(key);
  };
  const uploadByClick = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    await files.upload(e);
    setLoading(false);
  };
  return (
    <Flex sx={{ direction: "column", rowGap: 1, width: "100%" }}>
      <StyledDropZone
        onDragStart={(e) => onDragStart(e)}
        onDragLeave={(e) => onDragLeave(e)}
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDrop(e)}
        active={isActiveZone}
        cursor={!isDropped ? ("pointer" as CursorType) : ("auto" as CursorType)}
        bgColor="primary"
      >
        <label
          htmlFor="upload-files"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <input
            ref={uploadBtnRef}
            id="upload-files"
            multiple
            type="file"
            accept="text/*, image/*, application/pdf,doc,docx"
            onChange={uploadByClick}
            hidden
          />
          <Flex sx={{ columnGap: 1, align: "center" }}>
            <Button
              onClick={() => uploadBtnRef?.current?.click()}
              iconStart={<UploadIcon />}
              variant="text"
              color="success"
            />
            {loading && <CircleLoader size={50} />}
          </Flex>
          <Text color="secondary" sx={{ mt: 1 }}>
            {placeholder}
          </Text>
        </label>
      </StyledDropZone>
      <Flex sx={{ columnGap: 1 }}>
        {Object.entries(files.data).map((file) => (
          <Flex key={file[0]} sx={{ align: "center", justify: "center" }}>
            {/* {!file[1].name && (
              <Flex sx={{ direction: "column" }}>
                <DFALinearProgress
                  value={file[1].progress}
                  label={file[1].name}
                  type={file[1].error ? "error" : undefined}
                  onClick={() => files.del(file[1].key)}
                />
                <Text>Файлы загружаются...</Text>
              </Flex>
            )} */}
            {file[1].name && <File name={file[1].name} onDelete={deleteFile} id={file[1].key} />}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default DropZone;
