import styled, { css } from "styled-components";
import { ColorType, IDefaultPropsType, ScaleType } from "../../types";

export interface IDialogWrap {
  opacity: 0 | 1;
  color: ColorType;
}
export interface IStyledDialog extends IDefaultPropsType {
  maxWidth?: ScaleType | "xxl";
}

export const DialogWrap = styled.div<IDialogWrap>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-image: ${({ theme, color }) => theme.palette[color].gradient[25]};
  backdrop-filter: blur(15px);
  transition: opacity 300ms;
  opacity: ${({ opacity }) => opacity};
`;

export const StyledDialog = styled.div<IStyledDialog>`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 20px;
  z-index: 1001;
  flex-direction: column;
  box-shadow: 0 25px 40px rgba(0, 0, 0, 0.15);
  ${({ theme, maxWidth }) => css`
    background-color: white;
    width: ${maxWidth === "xxl" && "1200px"};
    width: ${maxWidth === "xl" && "1000px"};
    width: ${maxWidth === "lg" && "800px"};
    width: ${maxWidth === "md" && "600px"};
    width: ${maxWidth === "sm" && "500px"};
    width: ${maxWidth === "xs" && "400px"};
    ${theme.device.laptop} {
      width: ${maxWidth === "xxl" && "1000px"};
    }
    ${theme.device.tablet} {
      width: 75%;
    }
    ${theme.device.mobile} {
      width: 95%;
    }
  `};
`;
StyledDialog.defaultProps = {
  maxWidth: "md",
};
