import React, { useEffect } from "react";
import { Flex, Text, Button } from "Theme";
import { DiagramIcon, PaymentCardIcon, CircleDollarOutline, RefreshIcon } from "Theme/icons";
import { useAppDispatch, useAppSelector } from "store";
import { setOpenWithdrawOrder, setOpenDepositOrder } from "store/dialogs";
import { useGetBalance } from "hooks";
import { BalanceCard } from "components";
import useChangeHeader from "../useChangeHeader";
import { DFABalance, Repayment } from "./widgets";

const Briefcase: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAccountIssuer } = useAppSelector((state) => state.account);

  const openDepositRequestDialog = () => dispatch(setOpenDepositOrder(true));
  const openWithdrawOrderDialog = () => dispatch(setOpenWithdrawOrder(true));

  const getBalance = useGetBalance();

  useChangeHeader();
  useEffect(() => {
    getBalance();
  }, []);
  return (
    <>
      <Flex sx={{ justify: "space-between", align: "flex-end", width: "100%", mt: 4 }}>
        <Text variant="h4">Действия</Text>
        <Button variant="text">Показать все</Button>
      </Flex>
      <Flex
        sx={{ justify: "space-between", align: "center", width: "100%", mt: 2, gap: 1 }}
        adaptiveSX={{ tablet: { direction: "column" } }}
      >
        <Button
          cardMode
          iconEnd={<CircleDollarOutline rotate="right" size="medium" color="primary" />}
          sx={{
            width: "33%",
            justify: "space-between",
            minHeight: "80px",
          }}
          adaptiveSX={{ tablet: { width: "100%" } }}
          animation
          color="white"
          onClick={openDepositRequestDialog}
        >
          Пополнить счет
        </Button>
        <Button
          cardMode
          iconEnd={<PaymentCardIcon rotate="right" size="medium" color="primary" />}
          sx={{
            width: "33%",
            justify: "space-between",
            minHeight: "80px",
          }}
          adaptiveSX={{ tablet: { width: "100%" } }}
          animation
          color="white"
          onClick={openWithdrawOrderDialog}
        >
          Вывести средства
        </Button>
        <Button
          cardMode
          iconEnd={<DiagramIcon rotate="right" size="medium" color="primary" />}
          adaptiveSX={{ tablet: { width: "100%" } }}
          sx={{
            width: "33%",
            justify: "space-between",
            minHeight: "80px",
          }}
          animation
          color="white"
        >
          Справки и отчеты
        </Button>
      </Flex>
      <Flex sx={{ mt: 2, direction: "column", align: "start", width: "100%", rowGap: 1 }}>
        <Flex sx={{ align: "center", columnGap: 0.5 }}>
          <Text variant="h4">Сведения</Text>
          <Button
            iconStart={<RefreshIcon size="medium" color="info" />}
            onlyIcon
            onClick={getBalance}
          />
        </Flex>
        <Flex sx={{ gap: 1, width: "100%", wrap: "wrap" }}>
          <BalanceCard
            sx={{ minHeight: "210px", mb: 0.5, flex: 1, minWidth: "280px", maxWidth: "24%" }}
            adaptiveSX={{
              laptop: { maxWidth: "48.5%" },
              tablet: { maxWidth: "100%" },
            }}
            type="success"
            wmSize={50}
          />
          <DFABalance />
          {isAccountIssuer && <Repayment />}
        </Flex>
      </Flex>
    </>
  );
};

export default Briefcase;
