import { formattingDate, formattingPhoneNumber, getCitizenship } from "helpers";
import React, { useState } from "react";
import { useAppSelector } from "store";
import { Button, Card, EntityProps, Flex, InfoCard } from "Theme";
import RestoreKeyModal from "./RestoreKeyModal";

const Representative: React.FC = () => {
  const { representative } = useAppSelector((state) => state.customer);
  const [isOpenRestoreModal, setIsOpenRestoreModal] = useState<boolean>(false);
  const handleCloseRestoreModal = () => setIsOpenRestoreModal(false);
  const handleOpenRestoreModal = () => setIsOpenRestoreModal(true);
  return (
    <>
      <Flex
        sx={{
          direction: "column",
          mt: 1,
          justify: "space-between",
          height: "100%",
          flex: "1 1 0%",
        }}
      >
        <Flex sx={{ direction: "column" }}>
          <Flex sx={{ columnGap: 1 }}>
            <InfoCard
              bgWhite
              fullWidth
              type="person"
              title="ФИО"
              value={`${representative?.middleName} ${representative?.firstName} ${representative?.lastName}`}
            />
            <InfoCard
              bgWhite
              fullWidth
              type="docWithAngles"
              title="Гражданство"
              value={getCitizenship(representative?.citizenship)}
            />
          </Flex>
          <Flex sx={{ columnGap: 1, mt: 1 }}>
            <InfoCard
              bgWhite
              fullWidth
              type="phone"
              title="Телефон"
              value={formattingPhoneNumber(representative?.phoneNumber)}
            />
            <InfoCard
              bgWhite
              fullWidth
              type="event"
              title="Дата рождения"
              value={formattingDate(representative?.birthday)}
            />
            <InfoCard
              bgWhite
              fullWidth
              title="Адрес электронной почты"
              value={representative?.email}
              type="AT"
            />
            <InfoCard
              bgWhite
              fullWidth
              type="event"
              title="Зарегистрирован на платформе"
              value="DATE FROM MIDDLE"
            />
          </Flex>
          <Card sx={{ rowGap: 1, mt: 1 }} bgWhite>
            <Flex sx={{ justify: "space-between" }}>
              <EntityProps title="ИНН" value={representative?.inn} />
              <Button color="warning" variant="text">
                Сменить пароль учетной записи
              </Button>
            </Flex>
            <EntityProps title="СНИЛС" value={representative?.snils || "-"} />
            <EntityProps
              title="Документ удостоверяющий личность"
              value={`Пасспорт гражданина ${representative?.citizenship === "643" ? "РФ" : ""} ${
                representative?.PassportRU?.serial || "-"
              } ${representative?.PassportRU?.number || ""}`}
            />
            <EntityProps
              title="Адрес регистрации"
              value={representative?.PassportRU?.division || "-"}
            />
            <EntityProps title="Адрес места жительства" value="ADDRESS FROM MIDDLE" />
            {/* <EntityProps title="Зарегистрирован на платформе" value="DATE FROM MIDDLE" /> */}
          </Card>
        </Flex>
        <Flex sx={{ columnGap: 1, mt: 1 }}>
          <Button onClick={handleOpenRestoreModal} color="error" variant="outline">
            Восстановить ключи
          </Button>
          <Button color="success" variant="outline">
            Получить новые ключи
          </Button>
        </Flex>
      </Flex>
      <RestoreKeyModal
        isOpen={isOpenRestoreModal}
        handleCloseRestoreModal={handleCloseRestoreModal}
      />
    </>
  );
};

export default Representative;
