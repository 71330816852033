import React, { useRef, useState } from "react";
import { PDFDoc } from "../../../components";
import { Dialog, Flex } from "../../../Theme";
import pdf from "./oferta.pdf";

interface IOfferModal {
  open: boolean;
  onAgree(): void;
  onClose(): void;
}

const OfferModal: React.FC<IOfferModal> = ({ open, onAgree, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isRead, setIsRead] = useState<boolean>(false);
  const check = (e) => {
    e.persist();
    if (ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop < 1 && !isRead)
      setIsRead(true);
  };
  const onConfirm = () => {
    onAgree();
    onClose();
  };
  return (
    <Dialog
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      title="Оферта"
      confirmText="Соглашаюсь"
      maxWidth="xxl"
      disabled={!isRead}
    >
      <Flex
        ref={ref}
        onScroll={check}
        sx={{ overflowY: "scroll", height: "420px", width: "100%", m: "0 auto" }}
      >
        <PDFDoc pdfDoc={pdf} />
      </Flex>
    </Dialog>
  );
};

export default OfferModal;
