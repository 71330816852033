import React from "react";
import { useLocation } from "react-router-dom";
import { CircleLoader, Flex, Text } from "Theme";
import { RoutesNames } from "routes";

const NotFound: React.FC = () => {
  const { pathname } = useLocation();
  const isExist = Object.values(RoutesNames).find((el) => el === pathname);
  return (
    <Flex sx={{ align: "center", m: "auto 0" }}>
      {isExist ? (
        <CircleLoader />
      ) : (
        <>
          <Text variant="h1" sx={{ cssSize: 60, mr: 2 }}>
            404
          </Text>
          <Text variant="h1">Page not found</Text>
        </>
      )}
    </Flex>
  );
};

export default NotFound;
