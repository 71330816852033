export interface IAlertDataInfo {
  description?: string;
  badgeText?: string;
  type?: "warning" | "success" | "error" | "info";
}

const getAlertData = (value: string): IAlertDataInfo => {
  switch (value) {
    case "DEV": {
      return {
        type: "warning",
        badgeText: value,
        description:
          "Стенд разработчиков. Предназначен для тестирования функционала самими разработчиками",
      };
    }
    case "STABLE": {
      return {
        type: "info",
        badgeText: value,
        description:
          "Стабильный стенд. Для использования QA. Предназначен для тестирования функционала версии",
      };
    }
    case "RELEASE": {
      return {
        type: "success",
        badgeText: value,
        description: "Релиз стенд",
      };
    }
    default: {
      return {
        type: "error",
        badgeText: "LOCAL DEVELOPMENT",
        description:
          "Вероятнее всего, приложение запущено локально. Обратите внимание на адресную строку Вашего браузера",
      };
    }
  }
};

export default getAlertData;
