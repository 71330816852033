import React from "react";
import { Flex } from "Theme";
import { CircleArrowIcon } from "Theme/icons";
import { useAppDispatch, useAppSelector } from "store";
import { switchInvestorAccount, switchIssuerAccount } from "store/account";
import InvestorImg from "./img/investor.png";
import IssuerImg from "./img/emitent.png";
import { StyledButton, StyledImg } from "./StyledChangeAccount";

const ChangeAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const changeAccount = () => {
    if (isAccountIssuer) dispatch(switchInvestorAccount());
    else dispatch(switchIssuerAccount());
  };
  return (
    <Flex
      bgColor="somePrimary"
      bgSaturation={10}
      bgType="gradient"
      sx={{ p: "20px", borderRadius: "30px", minHeight: "150px", mt: "auto" }}
    >
      <StyledImg src={isAccountIssuer ? InvestorImg : IssuerImg} alt="Account" />
      <StyledButton onClick={changeAccount}>
        <CircleArrowIcon
          size="large"
          color="somePrimary"
          rotate={isAccountIssuer ? "left" : "right"}
        />
        {isAccountIssuer ? "Перейти в ЛК Инвестора" : "Перейти в ЛК Эмитента"}
      </StyledButton>
    </Flex>
  );
};

export default ChangeAccount;
