import styled, { css } from "styled-components";
import { IDefaultPropsType } from "Theme";

export const a = "";

export const Wrap = styled.div<IDefaultPropsType>`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => css`
    img {
      ${theme.device.laptop} {
        max-width: 250px;
      }
      ${theme.device.tablet} {
        max-width: 150px;
      }
    }
  `}
`;
