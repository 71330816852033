import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const DiamondIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M361.333-626.667 466.666-840h26.668l105.333 213.333H361.333Zm92 474.667-351-421.333h351V-152Zm53.334 0v-421.333h351L506.667-152ZM658-626.667 553.333-840H719q19 0 34.833 10 15.834 10 24.834 26.666l87.666 176.667H658Zm-564.333 0 87.666-176.667q9-16.666 24.834-26.666Q222-840 241-840h165.667L302-626.667H93.667Z" />
      </svg>
    </Text>
  );
};

export default DiamondIcon;
