import { useState } from "react";
import { base64ToPdf, downloadBlobHelper } from "helpers";

const useCreateDVPBillDialogState = () => {
  const [isDVPBillModal, setIsDVPBillModal] = useState<boolean>(false);
  const [isLoadingDVP, setIsLoadingDVP] = useState<boolean>(false);
  const [dvpBill, setDvpBill] = useState<string>("");

  const onCloseDVPBillModal = () => setIsDVPBillModal(false);
  const onDownloadDVP = (bill: string) => {
    const blobFile: Blob = base64ToPdf(bill);
    downloadBlobHelper(blobFile, "invoice.pdf");
  };
  return {
    isDVPBillModal,
    isLoadingDVP,
    dvpBill,
    setIsDVPBillModal,
    setIsLoadingDVP,
    setDvpBill,
    onCloseDVPBillModal,
    onDownloadDVP,
  };
};

export default useCreateDVPBillDialogState;
