import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { ErrorsEnum } from "@front-packages/dfa-constants";
import { setIsAuth } from "store/account";
import { addNotification } from "store/notifications";
import { useAppDispatch, useAppSelector } from "store";
import { setGlobalLoading } from "store/global";

type ErrorMessage = { error: string; key: number };

export interface IUseErrorsResult {
  errorMessage: string;
  setError: Dispatch<SetStateAction<ErrorMessage>>;
}

export const useErrors = (): IUseErrorsResult => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state) => state.account);
  const [errorMessage, setMessage] = useState<string>(null);
  const [error, setError] = useState<{ error: string; key: number }>({ error: "", key: 0 });
  useEffect(() => {
    const err = error.error.split(":")[0];
    switch (err) {
      case "Failed to fetch": {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message:
              "Нет связи с сервером. Попробуйте повторить запрос позже, если ошибка повторится: обратитесь в службу поддержки.",
          })
        );
        break;
      }
      case "no data": {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Нет данных для отображения, но мы уже работаем над этим.",
          })
        );
        break;
      }
      case "error processing request": {
        addNotification({
          type: "error",
          position: isAuth ? "right" : "left",
          message: "Ошибка обработки транзакции",
        });
        break;
      }
      case "no representative": {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Пользователь с данным номером не найден или уже прошел проверку",
          })
        );
        break;
      }
      case ErrorsEnum.InvalidRefreshToken: {
        dispatch(
          addNotification({
            type: "error",
            position: "left",
            message: "Закончилась текущая сессия. Пройдите авторизацию заново.",
          })
        );
        dispatch(setIsAuth(false));
        dispatch(setGlobalLoading(false));
        break;
      }
      case ErrorsEnum.FailedToExecuteOperation: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message:
              "Произошла непредвиденная ошибка на стороне сервера. Попробуйте повторить запрос позже или обратитесь в службу поддержки.",
          })
        );
        break;
      }
      case ErrorsEnum.InvalidFormatDate: {
        setMessage("Указана не верная ДАТА");
        break;
      }
      case ErrorsEnum.InvalidPhoneNumber: {
        setMessage("Указан не корректный НОМЕР ТЕЛЕФОНА");
        break;
      }
      case ErrorsEnum.InvalidVerificationCodeOrPhoneDoesntBelongToUser: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message:
              "Указан неверный код из СМС или указаный НОМЕР ТЕЛЕФОНА не принадлежит данному пользователю",
          })
        );
        break;
      }
      case ErrorsEnum.IncorrectPhoneNumberOrPassword: {
        setMessage("Неверный номер телефона или пароль");
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Неверный номер телефона или пароль",
          })
        );
        break;
      }
      case ErrorsEnum.UserWithThisPhoneNumberAlreadyExists: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Пользователь с таким номером телефона уже существует",
          })
        );
        break;
      }
      case ErrorsEnum.UserAlreadyBlockedByDate: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Пользователь уже заблокирован",
          })
        );
        break;
      }
      case ErrorsEnum.NoPermission: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Нет прав доступа",
          })
        );
        break;
      }
      case ErrorsEnum.UserIsNotThisClientType: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message:
              "Данный пользователь не является представителем ни одного из клиентов Платформы",
          })
        );
        break;
      }
      case ErrorsEnum.Unauthorized: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message: "Не авторизован",
          })
        );
        dispatch(setIsAuth(false));
        break;
      }
      case ErrorsEnum.UserIsNotAdministrator: {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message:
              "Данное действие может выполнить только администратор. Обратитесь в службу поддержки.",
          })
        );
        break;
      }
      case "invalid public date": {
        dispatch(
          addNotification({
            type: "error",
            position: isAuth ? "right" : "left",
            message:
              "Недопустимая дата публикации: она должна быть после даты создания и не должна быть пустой",
          })
        );
        break;
      }
      case "error broadcasting tx": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message:
              "Во время совершения транзакции что-то пошло не так. Повторите попытку позднее или обратитесь в службу поддержки.",
          })
        );
        break;
      }
      case "insufficient funds": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Недостаточно средств",
          })
        );
        break;
      }
      case "invalid max token limit": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Неуказаны номинал и количество",
          })
        );
        break;
      }
      case "invalid invest finish date": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Неуказана дата окончания сбора заявок",
          })
        );
        break;
      }
      case "invalid repayment minimum": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Неуказан минимальный процент выкупа",
          })
        );
        break;
      }
      case "order sum greater than balance": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Сумма заявки больше остатка денежных средств на балансе",
          })
        );
        break;
      }
      case "invalid interest: value must be from 0 to 100 (%)": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Ставка дохода должна быть от 0 до 100 %",
          })
        );
        break;
      }
      case "invalid format time in scheduler": {
        if (
          error.error ===
          "invalid format time in scheduler: must be greater than or equal to current"
        ) {
          dispatch(
            addNotification({
              type: "error",
              position: "right",
              message:
                "Введены невалидные данные. Введенная дата не может быть раньше, чем текущая.",
            })
          );
        }
        break;
      }
      case "invalid invest start date": {
        if (
          error.error ===
          "invalid invest start date: it must be after the created date and not empty"
        ) {
          dispatch(
            addNotification({
              type: "error",
              position: "right",
              message:
                "Введены невалидные данные. Дата начала сбора заявок не может быть после даты окончания сбора заявок.",
            })
          );
        }
        break;
      }
      case "payment details is not exists": {
        dispatch(
          addNotification({
            type: "error",
            position: "right",
            message: "Для пополнения необходимо добавить счет",
          })
        );
        break;
      }
      default: {
        // dispatch(clearNotifications());
        setMessage(null);
        break;
      }
    }
  }, [error]);

  return { errorMessage, setError };
};
