import React from "react";
import { Flex, RowEntityProps } from "Theme";
import * as Icons from "Theme/icons";
import { IIcon } from "Theme/icons/default";

interface IIconCardProps {
  icon: React.ReactNode;
  label: string;
}

const IconCard: React.FC<IIconCardProps> = ({ icon, label }) => {
  return (
    <Flex
      sx={{ border: "1px solid black", direction: "column", rowGap: 1, p: "5px", width: "280px" }}
    >
      <RowEntityProps title="Название" value={label} />
      {icon && icon}
    </Flex>
  );
};

const IconsKIT: React.FC = () => {
  const defaultStyles: IIcon = {
    size: "medium",
    color: "primary",
  };
  return (
    <Flex sx={{ wrap: "wrap", gap: 1 }}>
      <IconCard icon={<Icons.ATIcon {...defaultStyles} />} label="ATIcon" />
      <IconCard icon={<Icons.ArchiveIcon {...defaultStyles} />} label="ArchiveIcon" />
      <IconCard icon={<Icons.AttachFile {...defaultStyles} />} label="AttachFile" />
      <IconCard icon={<Icons.BuildingIcon {...defaultStyles} />} label="BuildingIcon" />
      <IconCard icon={<Icons.BurgerIcon {...defaultStyles} />} label="BurgerIcon" />
      <IconCard icon={<Icons.CalendarIcon {...defaultStyles} />} label="CalendarIcon" />
      <IconCard icon={<Icons.CheckIcon {...defaultStyles} />} label="CheckIcon" />
      <IconCard icon={<Icons.ChevronIcon {...defaultStyles} />} label="ChevronIcon" />
      <IconCard icon={<Icons.CircleArrowIcon {...defaultStyles} />} label="CircleArrowIcon" />
      <IconCard icon={<Icons.CircleCheckIcon {...defaultStyles} />} label="CircleCheckIcon" />
      <IconCard icon={<Icons.CircleDollarFilled {...defaultStyles} />} label="CircleDollarFilled" />
      <IconCard
        icon={<Icons.CircleDollarOutline {...defaultStyles} />}
        label="CircleDollarOutline"
      />
      <IconCard icon={<Icons.CloseIcon {...defaultStyles} />} label="CloseIcon" />
      <IconCard icon={<Icons.CollectionsIcon {...defaultStyles} />} label="CollectionsIcon" />
      <IconCard icon={<Icons.CopyIcon {...defaultStyles} />} label="CopyIcon" />
      <IconCard icon={<Icons.CorporateFareIcon {...defaultStyles} />} label="CorporateFareIcon" />
      <IconCard icon={<Icons.DeleteIcon {...defaultStyles} />} label="DeleteIcon" />
      <IconCard icon={<Icons.DiagramIcon {...defaultStyles} />} label="DiagramIcon" />
      <IconCard icon={<Icons.DiamondIcon {...defaultStyles} />} label="DiamondIcon" />
      <IconCard icon={<Icons.DifferenceIcon {...defaultStyles} />} label="DifferenceIcon" />
      <IconCard icon={<Icons.DocumentIcon {...defaultStyles} />} label="DocumentIcon" />
      <IconCard icon={<Icons.DocWithAnglesIcon {...defaultStyles} />} label="DocWithAnglesIcon" />
      <IconCard icon={<Icons.DotIcon {...defaultStyles} />} label="DotIcon" />
      <IconCard icon={<Icons.DownloadIcon {...defaultStyles} />} label="DownloadIcon" />
      <IconCard icon={<Icons.ErrorIcon {...defaultStyles} />} label="ErrorIcon" />
      <IconCard icon={<Icons.EventIcon {...defaultStyles} />} label="EventIcon" />
      <IconCard icon={<Icons.HashtagIcon {...defaultStyles} />} label="HashtagIcon" />
      <IconCard icon={<Icons.HelpIcon {...defaultStyles} />} label="HelpIcon" />
      <IconCard icon={<Icons.IconArrow {...defaultStyles} />} label="IconArrow" />
      <IconCard icon={<Icons.IconBill {...defaultStyles} />} label="IconBill" />
      <IconCard icon={<Icons.IconBriefcase {...defaultStyles} />} label="IconBriefcase" />
      <IconCard icon={<Icons.IconContract {...defaultStyles} />} label="IconContract" />
      <IconCard icon={<Icons.IconCross {...defaultStyles} />} label="IconCross" />
      <IconCard icon={<Icons.IconDollarFile {...defaultStyles} />} label="IconDollarFile" />
      <IconCard icon={<Icons.IconPortfolio {...defaultStyles} />} label="IconPortfolio" />
      <IconCard icon={<Icons.InfoIcon {...defaultStyles} />} label="InfoIcon" />
      <IconCard icon={<Icons.LockIcon {...defaultStyles} />} label="LockIcon" />
      <IconCard icon={<Icons.LogoutIcon {...defaultStyles} />} label="LogoutIcon" />
      <IconCard icon={<Icons.LongReceiptIcon {...defaultStyles} />} label="LongReceiptIcon" />
      <IconCard icon={<Icons.NotificationIcon {...defaultStyles} />} label="NotificationIcon" />
      <IconCard icon={<Icons.PDFIcon {...defaultStyles} />} label="PDFIcon" />
      <IconCard icon={<Icons.PaymentCardIcon {...defaultStyles} />} label="PaymentCardIcon" />
      <IconCard icon={<Icons.PercentIcon {...defaultStyles} />} label="PercentIcon" />
      <IconCard icon={<Icons.PersonIcon {...defaultStyles} />} label="PersonIcon" />
      <IconCard icon={<Icons.PhoneIcon {...defaultStyles} />} label="PhoneIcon" />
      <IconCard icon={<Icons.PowerIcon {...defaultStyles} />} label="PowerIcon" />
      <IconCard icon={<Icons.ReceiptIcon {...defaultStyles} />} label="ReceiptIcon" />
      <IconCard icon={<Icons.ReceiptLongIcon {...defaultStyles} />} label="ReceiptLongIcon" />
      <IconCard icon={<Icons.RefreshIcon {...defaultStyles} />} label="RefreshIcon" />
      <IconCard icon={<Icons.ReportIcon {...defaultStyles} />} label="ReportIcon" />
      <IconCard icon={<Icons.RequestQuote {...defaultStyles} />} label="RequestQuote" />
      <IconCard icon={<Icons.RoundIcon {...defaultStyles} />} label="RoundIcon" />
      <IconCard icon={<Icons.SliderArrow {...defaultStyles} />} label="SliderArrow" />
      <IconCard icon={<Icons.StoreIcon {...defaultStyles} />} label="StoreIcon" />
      <IconCard icon={<Icons.SuccessCheckIcon {...defaultStyles} />} label="SuccessCheckIcon" />
      <IconCard icon={<Icons.TollIcon {...defaultStyles} />} label="TollIcon" />
      <IconCard icon={<Icons.UnknownIcon {...defaultStyles} />} label="UnknownIcon" />
      <IconCard icon={<Icons.UserIcon {...defaultStyles} />} label="UserIcon" />
      <IconCard icon={<Icons.VerifiedIcon {...defaultStyles} />} label="VerifiedIcon" />
      <IconCard icon={<Icons.WalletIcon {...defaultStyles} />} label="WalletIcon" />
      <IconCard icon={<Icons.WarningIcon {...defaultStyles} />} label="WarningIcon" />
    </Flex>
  );
};

export default IconsKIT;
