import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button, ColorType, Flex, Text } from "Theme";
import { NotificationType, removeNotification } from "store/notifications";
import { IconCross } from "Theme/icons";
import { useAppDispatch } from "store";

interface INotificationItem extends NotificationType {
  id: number;
}
interface IStyledNotificationItem {
  opacity: number;
  color: ColorType;
}

const StyledNotificationItem = styled.div<IStyledNotificationItem>`
  border-radius: 20px;
  margin-top: 10px;
  overflow: hidden;
  ${({ opacity, color, theme }) => css`
    opacity: ${opacity};
    transition-delay: 500ms;
    border: ${`1px solid ${theme.palette[color].main}`};
  `}
`;

const CrossWrap = styled.div`
  background: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationItem: React.FC<INotificationItem> = ({ type, message, id }) => {
  const dispatch = useAppDispatch();
  const [o, setO] = useState<number>(0);
  const delNotification = () => {
    setO(0);
    setTimeout(() => {
      dispatch(removeNotification(id));
    }, 1100);
  };
  useEffect(() => {
    setO(1);
  }, []);
  return (
    <StyledNotificationItem opacity={o} color={type}>
      <Flex
        bgColor={type}
        bgType="gradient"
        bgSaturation={100}
        sx={{ p: "10px", direction: "column" }}
      >
        <Flex sx={{ justify: "space-between", align: "center" }}>
          <Text color="contrast" variant="h5" sx={{ ml: 0.6 }}>
            {type === "error" && "Ошибка"}
            {type === "warning" && "Предупреждение"}
            {type === "success" && "Уведомление"}
          </Text>
          <Flex sx={{ align: "center" }}>
            <Text color="contrast" variant="h6" sx={{ mr: 2.2 }}>
              Только что
            </Text>
            <CrossWrap>
              <Button
                onClick={delNotification}
                iconStart={<IconCross bold color={type} />}
                onlyIcon
              />
            </CrossWrap>
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={{ p: "10px 20px" }} bgType="blur">
        <Text sx={{ size: "SM" }}>{message}</Text>
      </Flex>
    </StyledNotificationItem>
  );
};

export default NotificationItem;
