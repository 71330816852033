import {
  TokenStatusGql,
  TokenOrderStatusSql,
  WithdrawOrderStatus as WithdrawOrderStatusEnum,
  MarketOrderStatusGql as MarketOrderStatusEnum,
  TokenRightsTypeGql,
} from "@front-packages/dfa-gql-api";

export const TokenPermissions = {
  monetary: "Денежные требования",
  monetaryOrDigitalEquivalent: "Денежные требования и (или) иные цифровые права",
};

export const TokenRightType = {
  DFA: "Цифровой финансовый актив",
  HDFA: "Гибридный цифровой финансовый актив",
};
// const DFA = "ЦФА";
// const HDFA = "ГЦФА";
export const TokenShortType = {
  // [DFA]: "ЦФА",
  // [HDFA]: "ГЦФА",
  // // TODO: временно
  // test_type: "test",
  [TokenRightsTypeGql.Dfa]: "ЦФА",
  [TokenRightsTypeGql.Hdfa]: "ГЦФА",
};

export const TokenPaymentMethod = {
  nominalRansom: "Выкуп по номиналу",
  otherRansom: "Поставка товаров, выполнение работ, оказания услуг",
};

export const WithdrawOrderStatus = {
  // [WithdrawOrderStatusEnum.Draft]: "черновик",
  [WithdrawOrderStatusEnum.Signing]: "на подписании",
  [WithdrawOrderStatusEnum.Cancelled]: "отклонено",
  [WithdrawOrderStatusEnum.RejectMiddle]: "отклонено",
  [WithdrawOrderStatusEnum.RejectAml]: "отклонено",
  [WithdrawOrderStatusEnum.RejectCore]: "отклонено",
  [WithdrawOrderStatusEnum.Initiating]: "инициализация",
  [WithdrawOrderStatusEnum.PaymentPreparing]: "подготовка платежа",
  [WithdrawOrderStatusEnum.SentToBank]: "отправлено в банк",
  [WithdrawOrderStatusEnum.RejectBank]: "отклонено",
  [WithdrawOrderStatusEnum.Completed]: "исполнено",
};
export const OrderStatus = {
  [TokenOrderStatusSql.Draft]: "новая",
  [TokenOrderStatusSql.Signing]: "на подписании",
  [TokenOrderStatusSql.Registered]: "зарегистрирована",
  [TokenOrderStatusSql.Scheduled]: "запланирована",
  [TokenOrderStatusSql.Completed]: "исполнена",
  [TokenOrderStatusSql.Canceled]: "отменена",
  [TokenOrderStatusSql.Refund]: "возврат",
  [TokenOrderStatusSql.RefundError]: "ошибка при возврате",
  [TokenOrderStatusSql.OnReject]: "на отклонении",
  [TokenOrderStatusSql.Rejected]: "отклонена",
};
export const TokenStatus = {
  [TokenStatusGql.Draft]: "новый",
  [TokenStatusGql.OnApproval]: "на проверке",
  [TokenStatusGql.Signing]: "на подписании",
  [TokenStatusGql.Initiating]: "инициализация",
  [TokenStatusGql.Scheduled]: "запланирован",
  [TokenStatusGql.Started]: "приём заявок",
  [TokenStatusGql.Issued]: "выпущен",
  [TokenStatusGql.InMarket]: "торговля",
  [TokenStatusGql.InRedeem]: "на погашении",
  [TokenStatusGql.Redeemed]: "погашен",
  [TokenStatusGql.Refund]: "возврат",
  [TokenStatusGql.Canceled]: "отменен",
};
export const MarketOrderStatus = {
  [MarketOrderStatusEnum.SigningMarketOrderStatus]: "на подписании",
  [MarketOrderStatusEnum.CompleteMarketOrderStatus]: "исполнена",
  [MarketOrderStatusEnum.RegisteredMarketOrderStatus]: "зарегистрирована",
  [MarketOrderStatusEnum.RejectMarketOrderStatus]: "отклонена",
};
export enum Breakpoints {
  mobileS = 530,
  mobile = 650,
  tablet = 850,
  tabletL = 1000,
  laptop = 1200,
  laptopM = 1400,
  laptopL = 1600,
  desktop = 1900,
}
