import { File as IFile } from "@front-packages/dfa-gql-api";
import React from "react";
import { Flex, Text, File } from "Theme";

interface ILoadedFilesProps {
  loadedFiles: IFile[];
}

const LoadedFiles: React.FC<ILoadedFilesProps> = ({ loadedFiles }) => {
  return (
    <Flex sx={{ direction: "column", rowGap: 1 }}>
      <Text variant="h4" sx={{ mt: 1, width: "100%", weight: "regular" }}>
        Загруженные файлы
      </Text>
      <Flex sx={{ columnGap: 1 }}>
        {loadedFiles.map((file) => (
          <File name={file.name} key={file.id} url={file.url} />
        ))}
      </Flex>
    </Flex>
  );
};

export default LoadedFiles;
