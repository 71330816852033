import React from "react";
import { Badge, Button, Flex, Text } from "Theme";
import { useAppDispatch, useAppSelector } from "store";
import { setOpenDepositOrder, setOpenWithdrawOrder } from "store/dialogs";
import { formattingDate } from "helpers";
import { BalanceCard } from "components";

const style: any = { justify: "space-between", width: "100%", mb: 0.5 };
const BalanceIteraction: React.FC = () => {
  const dispatch = useAppDispatch();
  const { representative, balance } = useAppSelector((state) => state.customer);
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const openDepositRequestDialog = () => dispatch(setOpenDepositOrder(true));
  const openWithdrawOrderDialog = () => dispatch(setOpenWithdrawOrder(true));
  return (
    <Flex sx={{ direction: "column", align: "center", width: "100%", mb: 0.5 }}>
      <BalanceCard
        sx={{ minHeight: "200px", mb: 0.5 }}
        type={isAccountIssuer ? "success" : "info"}
      />
      <Flex sx={{ justify: "space-between", width: "100%", wrap: "wrap", mb: 1, columnGap: 0.5 }}>
        <Button color="success" sx={{ flex: 1 }} onClick={openDepositRequestDialog}>
          Пополнить
        </Button>
        <Button sx={{ flex: 1 }} color="warning" onClick={openWithdrawOrderDialog}>
          Вывести
        </Button>
      </Flex>
      <Flex sx={{ ...style, align: "center" }}>
        <Text variant="h6" sx={{ size: "SM" }}>
          Статус
        </Text>
        <Badge color="success" label={representative?.status} />
      </Flex>
      <Flex sx={style}>
        <Text variant="h6" sx={{ size: "SM" }}>
          Обновлен
        </Text>
        <Text variant="h6" sx={{ size: "SM", weight: "normal" }}>
          {balance?.fiat.balance.lastUpdate
            ? formattingDate(balance.fiat.balance.lastUpdate, true)
            : "Нет данных"}
        </Text>
      </Flex>
    </Flex>
  );
};

export default BalanceIteraction;
