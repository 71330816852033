import React from "react";
import type * as CSS from "csstype";
import Text from "../Text";
import { IStyledEntityProps, StyledEntityProps } from "./EntityProps";
import { ColorType, SizeType, TypographyType } from "../../types";

interface IEntityProps extends IStyledEntityProps {
  title?: string;
  light?: boolean;
  value: React.ReactNode;
  color?: ColorType;
  align?: CSS.Property.TextAlign;
  isLoading?: boolean;
  sxValue?: TypographyType & SizeType & { rows?: number };
  sxTitle?: TypographyType & { color?: ColorType };
}

const EntityProps: React.FC<IEntityProps> = ({
  title,
  value,
  color,
  align,
  light,
  isLoading,
  sxValue,
  sxTitle,
  ...props
}) => {
  return (
    <StyledEntityProps {...props}>
      {title && (
        <Text
          color={sxTitle?.color}
          title={title}
          variant="h6"
          sx={{
            textTransform: `${sxTitle?.textTransform || "lowercase"}`,
            weight: "light",
            size: `${sxTitle?.size || "SM"}`,
            textAlign: sxTitle?.textAlign,
          }}
        >
          {title}
        </Text>
      )}
      {typeof value === "string" || typeof value === "number" ? (
        <Text
          title={value.toString()}
          variant="h6"
          rows={1}
          color={color}
          light={light}
          isLoading={isLoading}
          sx={{
            textTransform: "uppercase",
            weight: "medium",
            size: "SM",
            textAlign: align,
            ...sxValue,
          }}
        >
          {value}
        </Text>
      ) : (
        value
      )}
    </StyledEntityProps>
  );
};

export default EntityProps;
