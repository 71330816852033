import { TokenForm } from "@front-packages/dfa-gql-api";
import { useState } from "react";

interface IValidateErrors {
  minOrderLimit: string;
  maxOrderLimit: string;
  minRedemptionRate: string;
  nominal: string;
  minIssueLimit: string;
  interest: string;
}

interface IuseValidateIssue {
  validator(tokenForm: TokenForm): void;
  validateErrors: IValidateErrors;
}
const useValidateIssue = (): IuseValidateIssue => {
  const [validateErrors, setValidateErrors] = useState<IValidateErrors>(null);
  const validator = (tokenForm: TokenForm) => {
    const validateAtNominal = (value: number): boolean =>
      !!value && value >= tokenForm.nominal && value % tokenForm.nominal === 0;
    const errors = {
      minOrderLimit: null,
      maxOrderLimit: null,
      minRedemptionRate: null,
      nominal: null,
      minIssueLimit: null,
      interest: null,
    };
    if (tokenForm.interest > 100 || tokenForm.interest < 0)
      errors.interest = "Ставка дохода должна быть от 0 до 100 %";
    if (tokenForm.minOrderLimit && !validateAtNominal(tokenForm.minOrderLimit))
      errors.minOrderLimit = "Мин. сумма заявки должна быть кратна номиналу";
    else if (
      tokenForm.minOrderLimit &&
      tokenForm.minOrderLimit > tokenForm.nominal * tokenForm.amount
    )
      errors.minOrderLimit = `Мин. сумма заявки не должна превышать объем выпуска: ${
        tokenForm.nominal * tokenForm.amount
      }`;
    else errors.minOrderLimit = null;
    if (tokenForm.maxOrderLimit && !validateAtNominal(tokenForm.maxOrderLimit))
      errors.maxOrderLimit = "Макс. сумма заявки должна быть кратна номиналу";
    else if (
      tokenForm.maxOrderLimit &&
      tokenForm.maxOrderLimit > tokenForm.nominal * tokenForm.amount
    )
      errors.maxOrderLimit = `Макс. сумма заявки не должна превышать объем выпуска: ${
        tokenForm.nominal * tokenForm.amount
      }`;
    else errors.maxOrderLimit = null;
    if (tokenForm.nominal && tokenForm.nominal < 100)
      errors.nominal = "Значение номинала не может быть меньше 100";
    else errors.nominal = null;
    if (
      tokenForm.minIssueLimit &&
      tokenForm.minIssueLimit &&
      tokenForm.minIssueLimit > tokenForm.maxIssueLimit
    )
      errors.minIssueLimit = "Минимальный объем выкупа не может быть больше, чем Объем выпуска";
    else errors.minIssueLimit = null;
    setValidateErrors(errors);
  };

  return { validateErrors, validator };
};

export default useValidateIssue;
