import styled, { css } from "styled-components";
import { ColorType, MarginType, PaddingType, SizeType } from "../../types";
import { marginStyles, paddingStyles, sizeStyles } from "../../styles";

export interface IStyledSelect {
  error?: string | boolean | "none";
  sx?: PaddingType & MarginType & SizeType;
  color?: ColorType;
  fullWidth?: boolean;
  disabled?: boolean;
  colored?: boolean;
}
interface IExtStyledSelect extends IStyledSelect {
  ref: any;
  focus: boolean;
}
interface IDefaultStyled {
  isRequired?: boolean;
  error?: string | boolean | "none";
  disabled?: boolean;
  colored?: boolean;
}
interface IStyledLabel extends IDefaultStyled {
  focus: boolean;
  isPointer?: boolean;
}

export const WrapValue = styled.span`
  display: flex;
  width: 99%;
  overflow-y: hidden;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;
export const StyledSelect = styled.div<IExtStyledSelect>`
  position: relative;
  padding: 15px 40px 15px 20px;
  border-radius: 10px;
  min-width: 200px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.01);
  ${({ theme, color, error, fullWidth, focus, disabled, colored }) => css`
    font-size: ${theme.typography.size.SM};
    font-weight: ${theme.typography.weight.normal};
    background-color: ${colored &&
    !disabled &&
    `${theme.palette[color].main}${theme.palette.opacity[5]}`};
    background-color: ${disabled &&
    `${theme.palette.background[color]}${theme.palette.opacity[5]}`};
    background-color: ${!colored && !disabled && `${theme.palette.white.light}`};
    border: 1px solid ${`${theme.palette[color].light}${focus ? "" : "25"}`};
    color: ${disabled ? `${theme.palette[color].light}80` : theme.palette[color].light};
    width: ${fullWidth && "100%"};
    border-color: ${error && error !== "none" && `${theme.palette.error.light}25 !important`};
    border-bottom-right-radius: ${focus && 0};
    border-bottom-left-radius: ${focus && 0};
    user-select: ${disabled && "none"};
    pointer-events: ${disabled && "none"};
  `}
  ${({ sx }) => paddingStyles(sx)}
  ${({ sx }) => marginStyles(sx)}
  ${({ sx }) => sizeStyles(sx)}
`;

export const OpenButton = styled.div<{ open: boolean }>`
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  user-select: none;
  ${({ open }) =>
    open &&
    css`
      border-bottom-right-radius: 0;
    `}
`;

interface IStyledList {
  open: boolean;
  color: ColorType;
  factor: number;
  hideScrollBar?: boolean;
  colored?: boolean;
}
export const StyledList = styled.ul<IStyledList>`
  position: absolute;
  top: 101%;
  left: -1px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  opacity: ${({ open }) => (open ? 1 : 0)};
  z-index: ${({ open }) => (open ? 10 : -1)};
  height: ${({ open, factor }) => (open ? `${factor * 40}px` : 0)};
  width: calc(100% + 2px);
  max-height: 400px;
  overflow: ${({ hideScrollBar }) => (hideScrollBar ? "hidden" : "auto")};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
  transition-duration: 200ms;
  ${({ theme, color, colored }) => css`
    background: ${colored ? `${theme.palette.background[color]}` : `${theme.palette.white.light}`};
    border: 1px solid ${theme.palette[color].light};
    color: ${theme.palette[color].light};
  `}
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

export interface IStyledItem {
  checked: boolean;
  color: ColorType;
}
export const StyledItem = styled.li<IStyledItem>`
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 10px;
  ${({ theme, color, checked }) => css`
    color: ${checked ? theme.palette.white.main : theme.palette[color].light};
    background-image: ${checked && theme.palette[color].gradient[50]};
    &:hover {
      color: ${checked && theme.palette[color].light};
      font-weight: 500;
      background-image: ${theme.palette[color].gradient[25]};
    }
  `};
`;

export const SelectLabel = styled.span<IStyledLabel>`
  position: absolute;
  left: 10px;
  max-width: 80%;
  white-space: nowrap;
  top: 48%;
  transform: translateY(-50%);
  transition: top 100ms;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  -moz-user-select: none;
  user-select: none;
  ${({ theme, focus, error, isRequired, colored, color, isPointer, disabled }) => css`
    cursor: ${isPointer ? "pointer" : "text"};
    background: ${colored &&
    (!focus ? "transparent" : `linear-gradient(0deg, #f2f7f6 51%, transparent 49%)`)};
    background: ${!colored &&
    (!focus ? "transparent" : `linear-gradient(0deg, white 51%, transparent 49%)`)};
    padding: ${focus && "0 2px"};
    color: ${disabled && `${theme.palette[color]}50 !important`};
    color: ${error && error !== "none"
      ? `${theme.palette.error.main}80 !important`
      : `${theme.palette[color].main}90`};
    font-size: ${focus ? theme.typography.size.XS : theme.typography.size.SM};
    top: ${focus && "-1px"};
    font-weight: ${focus && theme.typography.weight.regular};
    &:before {
      display: ${isRequired && !focus ? "block" : "none"};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-image: ${theme.palette.warning.gradient[75]};
    }
  `}
`;
