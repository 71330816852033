import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import App from "./App";
import { store } from "./store";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

registerLocale("ru", ru);

const root = createRoot(document.getElementById("root"));
root.render(
  process.env.NODE_ENV === "development" ? (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  ) : (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  )
);
