import styled from "styled-components";

interface IStyledOption {
  active: boolean;
}
export const StyledOption = styled.button<IStyledOption>`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.palette.secondary.light : `${theme.palette.secondary.light}80`};
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const a = null;
