import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetHeader, setHeader } from "store/global";
// import { useAppSelector } from "store";

function useChangeHeader(title?: string, pageBackBtn?: string) {
  const dispatch = useDispatch();
  // const { company } = useAppSelector((state) => state.customer);

  // const getBadgeData = () => {
  //   if (company && company?.status && company.status === "Active")
  //     return {
  //       label: "Активный",
  //       color: "success",
  //     };
  //   return {
  //     label: "No data",
  //     color: "error",
  //   };
  // };

  useEffect(() => {
    dispatch(
      setHeader({
        title: title || "Управление портфелем",
        pageBackBtn: pageBackBtn && { label: "Назад" },
        // subTitle: company?.wallet?.address || "no data",
        // badge: getBadgeData(),
      })
    );
    return () => {
      dispatch(resetHeader());
    };
  }, []);
}

export default useChangeHeader;
