import styled, { css } from "styled-components";
import {
  ExtColorType,
  FlexType,
  IAdaptive,
  MarginType,
  PaddingType,
  PositionType,
  SizeType,
  TypographyType,
  BackgroundType,
  BorderType,
} from "../../types";
import {
  adaptiveStyles,
  bgStyles,
  borderStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  sizeStyles,
} from "../../styles";
import { OpacityType } from "../../icons/default";

const getOpacity = (opacity: OpacityType): string => {
  switch (opacity) {
    case 10:
      return "1a";
    case 20:
      return "33";
    case 30:
      return "4d";
    case 40:
      return "66";
    case 50:
      return "80";
    case 60:
      return "99";
    case 70:
      return "b3";
    case 80:
      return "cc";
    case 90:
      return "e6";
    default:
      return "";
  }
};

export interface IStyledText extends IAdaptive {
  opacity?: OpacityType;
  color?: ExtColorType | "contrast";
  light?: boolean;
  animation?: any;
  rows?: number;
  sx?: MarginType &
    PaddingType &
    TypographyType &
    PositionType &
    FlexType &
    SizeType &
    BackgroundType &
    BorderType;
  withHover?: boolean;
  isLoading?: boolean;
}

const defaultStyle = ({
  sx,
  theme,
  color,
  withHover,
  opacity,
  light,
  rows,
  animation,
  adaptiveSX,
  isLoading,
}: any) => css`
  ${animation};
  line-height: ${sx?.lineHeight || 1};
  text-decoration: ${sx?.decoration};
  text-transform: ${sx?.textTransform};
  text-decoration: ${sx?.textDecoration};
  text-align: ${sx?.textAlign};
  font-size: ${sx?.cssSize && typeof sx?.cssSize === "number" && `${sx.cssSize}px`};
  font-size: ${sx?.cssSize && typeof sx?.cssSize !== "number" && `${sx.cssSize}`};
  color: ${color === "contrast" && `${theme.palette.text.contrast}${getOpacity(opacity) || ""}`};
  color: ${color !== "contrast" &&
  color !== "inherit" &&
  `${theme.palette[color][light ? "light" : "main"]}${getOpacity(opacity) || ""}`};
  color: ${color === "inherit" && color};
  display: ${(sx?.justify || sx?.align || sx?.direction) && "flex"};
  justify-content: ${sx?.justify};
  align-items: ${sx?.align};
  flex-direction: ${sx?.direction};
  text-overflow: ${rows === 1 && "ellipsis"};
  overflow: ${rows && "hidden"};
  display: ${rows > 1 && "-webkit-box"};
  -webkit-box-orient: ${rows > 1 && "vertical"};
  white-space: ${sx?.whiteSpace};
  white-space: ${rows === 1 && "nowrap"};
  -webkit-line-clamp: ${rows && rows};
  &:hover {
    color: ${withHover &&
    (color === "contrast" ? theme.palette.text.contrastHover : theme.palette[color].light)};
  }
  ${isLoading &&
  css`
    min-width: 40px;
    width: 60%;
    max-width: 300px;
    background: ${color === "contrast" && `${theme.palette.text.contrast}`};
    background: ${
      color !== "contrast" &&
      color !== "inherit" &&
      `${theme.palette[color][light ? "light" : "main"]}`
    };
    background: ${color === "inherit" && color};
    z-index: 0;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    opacity: ${opacity || 0.3};
    &:after {
      content:'';
      top:0;
      transform:translateX(100%);
      width:100%;
      height:100%;
      position: absolute;
      z-index:1;
      animation: slide 1s infinite 3s;
      animation-delay: 0s;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(247, 250, 252, 0) 99%,rgba(247, 250, 252, 0.8) 100%); /* W3C */
      @keyframes slide {
        0% {
          transform:translateX(-100%);
        }
        100% {
          transform:translateX(100%);
        }
      }
    }}
  `}
  ${positionStyles(sx)};
  ${sizeStyles(sx)};
  ${marginStyles(sx)};
  ${paddingStyles(sx)};
  ${adaptiveStyles(adaptiveSX)};
  ${bgStyles(sx)};
  ${borderStyles(sx)};
`;

export const StyledSkeletonSpan = styled.div<IStyledText>`
  ${({ opacity, color = "text", theme, light, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "SM"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.normal};
    margin-left: 10px;
    min-width: 40px;
    min-height: 20px;
    width: 40%;
    max-width: 300px;
    z-index: 0;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    opacity: ${opacity || 0.2};
    background: ${color === "contrast" && `${theme.palette.text.contrast}`};
    background: ${
      color !== "contrast" &&
      color !== "inherit" &&
      `${theme.palette[color][light ? "light" : "main"]}`
    };
    background: ${color === "inherit" && color};
    &:after {
      content:'';
      top:0;
      transform:translateX(100%);
      width:100%;
      height:100%;
      position: absolute;
      z-index:1;
      animation: slide 1s infinite 3s;
      animation-delay: 0s;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(247, 250, 252, 0) 99%,rgba(247, 250, 252, 0.8) 100%); /* W3C */
      @keyframes slide {
        0% {
          transform:translateX(-100%);
        }
        100% {
          transform:translateX(100%);
        }
      }
  `}
`;

export const StyledSpan = styled.span<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "SM"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.normal};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledSpan.defaultProps = {
  color: "text",
};

export const StyledH1 = styled.h1<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "XXXL"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.bold};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledH1.defaultProps = {
  color: "text",
};

export const StyledH2 = styled.h2<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "XXL"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.bold};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledH2.defaultProps = {
  color: "text",
};

export const StyledH3 = styled.h3<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "XL"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.bold};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledH3.defaultProps = {
  color: "text",
};

export const StyledH4 = styled.h4<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "LG"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.bold};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledH4.defaultProps = {
  color: "text",
};

export const StyledH5 = styled.h5<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "MD"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.bold};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledH5.defaultProps = {
  color: "text",
};

export const StyledH6 = styled.h6<IStyledText>`
  ${({ theme, sx }) => css`
    font-size: ${theme.typography.size[sx?.size || "XS"]};
    font-weight: ${theme.typography.weight[sx?.weight] || theme.typography.weight.bold};
  `}
  ${(style) => defaultStyle(style)};
`;
StyledH6.defaultProps = {
  color: "text",
};

export const StyledLink = styled.a`
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
`;
