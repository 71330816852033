export default {
  cardSx: {
    width: "49%",
    minHeight: "190px",
  },
  cardAdaptiveSx: { tabletL: { width: "48%" }, tablet: { width: "100%" } },
  gridSx: {
    areas: `
      "a a a b b" 
      "c d d . e" 
      "f f . . g"
    `,
    templateColumns: "45px 1.5fr 1fr 0.6fr 150px",
    rowGap: 1,
  },
  gridAdaptivSx: {
    laptopL: {
      areas: `
        "c b b b b" 
        "a a a a e" 
        "d d d d f" 
        "g g . . ."
      `,
    },
    tabletL: {
      areas: `
        "c b b b b" 
        "a a a a a" 
        "d d d d d" 
        "f f . e e" 
        "g g g g g"
      `,
    },
    tablet: {
      areas: `
        "a a a b b" 
        "c d d . e" 
        "f f . . g"
      `,
    },
    mobile: {
      areas: `
        "c b b b b" 
        "a a a a e" 
        "d d d d f" 
        "g g . . ."
      `,
    },
    mobileS: {
      areas: `
        "c b b b b" 
        "a a a a a" 
        "d d d d d" 
        "f f . e e" 
        "g g g g g"
      `,
    },
  },
};
