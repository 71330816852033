import styled, { css } from "styled-components";
import { FlexType, MarginType } from "../../types";
import { flexStyles, marginStyles } from "../../styles";

export interface IStyledSearchPanel {
  sx?: FlexType & MarginType;
  fullwidth?: boolean;
  open?: boolean;
}

interface IStyledWrap extends IStyledSearchPanel {
  height: number;
}
export const SearchPanelWrap = styled.div<IStyledSearchPanel>`
  position: relative;
  height: 70px;
  ${({ theme, fullwidth }) => css`
    width: ${fullwidth ? "100%" : "51%"};
    ${theme.device.laptopL} {
      width: 70%;
    }
    ${theme.device.laptop} {
      width: 75%;
    }
    ${theme.device.tabletL} {
      width: 100%;
    }
  `}
  ${({ sx }) => marginStyles(sx)}
`;
export const SearchPanelHeader = styled.div<{ withOpenArrow?: boolean }>`
  display: flex;
  column-gap: 1rem;
  width: 100%;
  ${({ withOpenArrow = true }) => css`
    align-items: ${!withOpenArrow ? "flex-start" : "center"};
  `}
`;
export const Wrap = styled.div<IStyledWrap>`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  padding: 17px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
  transition: height 300ms;
  ${({ open, height }) => css`
    height: ${open ? `calc(${height}px + 100px)` : "80px"};
  `}
`;
export const FormWrap = styled.div<IStyledSearchPanel>`
  display: flex;
  overflow: hidden;
  margin-top: 18px;
  padding-top: 15px;
  height: 100%;
  width: 100%;
  gap: 1rem;
  ${({ open }) => css`
    opacity: ${open ? "1" : "0"};
  `}
  ${({ sx }) => flexStyles(sx)}
`;

export const SearchBadgesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  position: absolute;
  right: 0;
  top: 90px;
  column-gap: 10px;
  row-gap: 10px;
`;
