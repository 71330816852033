import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./style.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPDFDoc {
  pdfDoc: Blob | string;
}

const PDFDoc: React.FC<IPDFDoc> = ({ pdfDoc }) => {
  const [pages, setPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPages(numPages);
  };

  return (
    <Document
      options={{ workerSrc: "pdf.worker.js" }}
      renderMode="canvas"
      className="pdf"
      file={pdfDoc}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(pages), (_, index) => (
        <Page devicePixelRatio={3} key={`page-${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};

export default PDFDoc;
