import React from "react";
import { Input, SearchPanel, Select } from "Theme";
import { useAppSelector } from "store";
import { Breakpoints, MarketOrderStatus } from "constants/in_lib";
import { GetMarketOrdersOpts, MarketOrderStatusGql } from "@front-packages/dfa-gql-api";

export type FilterPropsType = Pick<GetMarketOrdersOpts, "orderStatus" | "tokenTicker">;
interface ISearchFormProps {
  state: FilterPropsType;
  setState(state: FilterPropsType): void;
  getOrders(opts: FilterPropsType): Promise<void>;
}

const SearchForm: React.FC<ISearchFormProps> = ({ state, setState, getOrders }) => {
  const { windowWidth } = useAppSelector((store) => store.global);
  const { orderStatus, tokenTicker } = state;

  const setTokenTicker = (value: string) => setState({ ...state, tokenTicker: value || undefined });
  const setOrderStatus = (value: MarketOrderStatusGql) =>
    setState({ ...state, orderStatus: value || undefined });

  const reset = async () => {
    const resetValue: FilterPropsType = {
      tokenTicker: undefined,
      orderStatus: undefined,
    };
    setState(resetValue);
    await getOrders(resetValue);
  };

  const handleGetOrders = async (newState?: any) => {
    newState ? await getOrders(newState) : await getOrders(state);
  };

  return (
    <SearchPanel
      fullwidth
      sx={{ direction: "column", mb: 1.3 }}
      onSearch={handleGetOrders}
      onReset={Object.values(state).filter((el) => !!el).length ? reset : undefined}
      hideHeaderChildren={windowWidth <= Breakpoints.tablet}
      headerChildren={
        <>
          <Input
            placeholder="Тикер"
            sx={{ height: "40px", width: "50%" }}
            value={tokenTicker || ""}
            onChange={setTokenTicker}
            colored
          />
          <Select
            colored
            // multiple
            options={Object.entries(MarketOrderStatus).map(([value, description]) => ({
              description,
              value,
            }))}
            placeholder="Статус"
            sx={{ maxHeight: "40px", width: "50%" }}
            value={orderStatus || undefined}
            onChange={setOrderStatus}
          />
        </>
      }
      badgesData={{ state, setState }}
      withOpenArrow={false}
    />
  );
};

export default SearchForm;
