import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CookieNamesEnum, regCleanPhone } from "@front-packages/dfa-constants";
import { AuthAPI, MfaParams, UserClientType } from "@front-packages/dfa-gql-api";
import { delay, setCookie } from "@front-packages/dfa-helpers";
import { useErrors } from "../errors";
import { setIsAuth } from "../../store/account";
import { setGlobalLoading } from "../../store/global";
import useGetInfo from "./useGetInfo";
import { RoutesNames } from "../../routes";

type AuthType<TResult = void> = () => Promise<TResult>;
type AuthWithValueType<TValue = unknown, FValue = unknown, TResult = void> = (
  code: TValue,
  sessionID: FValue
) => Promise<TResult>;
type SetState<TValue = string, TResult = void> = (str: TValue) => TResult;
type SendCodeType = (code: string, sessionID: string) => Promise<boolean>;

interface IAuth {
  (): {
    state: {
      phoneNumber: string;
      password: string;
      verifyPass: string;
      errorMessage: string;
      uid: string;
      isRemember: boolean;
    };
    setState: {
      password: SetState;
      verifyPass: SetState;
      phoneNumber: SetState;
      isRemember: SetState<boolean>;
    };
    logout: AuthType;
    login: AuthType<MfaParams>;
    sendCode: SendCodeType;
    registration: AuthType<boolean>;
    checkRepresentative: AuthWithValueType<string, string, boolean | "no representative">;
  };
}

const useAuth: IAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getInfo = useGetInfo();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [uid, setUid] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verifyPass, setVerifyPass] = useState<string>("");
  const [isRemember, setIsRemember] = useState<boolean>(true);
  const { setError, errorMessage } = useErrors();

  const logout: AuthType = async () => {
    dispatch(setGlobalLoading(true));
    const { response, error } = await AuthAPI.SignOut();
    if (error) {
      setError({ error, key: Math.random() });
      dispatch(setGlobalLoading(false));
      return;
    }
    if (response) {
      dispatch(setIsAuth(false));
      await delay(1000);
      navigate(RoutesNames.SignIn);
    }
    dispatch(setGlobalLoading(false));
  };

  const registration: AuthType<boolean> = async () => {
    const { response, error } = await AuthAPI.SignUp({
      password,
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      isRepresentative: true,
    });
    if (error) {
      setError({ error, key: Math.random() });
      return false;
    }
    return response;
  };

  const sendCode: SendCodeType = async (code, sessionID) => {
    let isSuccess: boolean;
    const Verify2FACode = await AuthAPI.Verify2FACode({
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      code,
      uid,
      clientType: UserClientType.Representative,
      sessionID,
    });
    if (Verify2FACode.error) {
      setError({ error: Verify2FACode.error, key: Math.random() });
      return false;
    }
    if (Verify2FACode.response) {
      const { accessToken, refreshToken } = Verify2FACode.response;
      const expires = new Date(Date.now() + 3600000);
      setCookie(CookieNamesEnum.token, accessToken, !isRemember && { expires });
      setCookie(CookieNamesEnum.refreshToken, refreshToken, !isRemember && { expires });
      isSuccess = await getInfo();
      if (!isSuccess) await logout();
      dispatch(setIsAuth(isSuccess));
    }
    return isSuccess;
  };

  const checkRepresentative: AuthWithValueType<
    string,
    string,
    boolean | "no representative"
  > = async (code, sessionID) => {
    const Verify2FACodeRepresentative = await AuthAPI.Verify2FACodeRepresentative({
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      code,
      sessionID,
    });
    if (Verify2FACodeRepresentative.error) {
      setError({ error: Verify2FACodeRepresentative.error, key: Math.random() });
      return "no representative";
    }
    if (Verify2FACodeRepresentative.response) {
      const { response, error } = await AuthAPI.RepresentativeIsRegistered({
        phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      });
      if (error) {
        setError({ error, key: Math.random() });
        return "no representative";
      }
      return response;
    }
    setError({ error: "no representative", key: Math.random() });
    return "no representative";
  };

  const login: AuthType<MfaParams> = async () => {
    const { response: SignIn, error } = await AuthAPI.SignIn({
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      password,
    });
    if (error) {
      setError({ error, key: Math.random() });
      return {
        code: "",
        sessionID: "",
      };
    }
    setUid(SignIn);
    const Send2FACode = await AuthAPI.Send2FACode({
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      uid: SignIn,
    });
    if (Send2FACode.error) {
      setError({ error: Send2FACode.error, key: Math.random() });
      return {
        code: "",
        sessionID: "",
      };
    }
    return Send2FACode.response;
  };

  return {
    state: { phoneNumber, password, errorMessage, verifyPass, uid, isRemember },
    setState: {
      isRemember: setIsRemember,
      password: setPassword,
      phoneNumber: setPhoneNumber,
      verifyPass: setVerifyPass,
    },
    login,
    logout,
    sendCode,
    registration,
    checkRepresentative,
  };
};

export default useAuth;
