import React from "react";
import { countList } from "@front-packages/dfa-constants";
import Flex from "../Flex";
import Text from "../Text";
import SortOptionIcon, { SortType } from "./components/sortOptionIcon";
import { StyledOption } from "./Styles";
import { MarginType, TypographyType } from "../../types";
import { ISelectOption, Select } from "../Select";

export type ISortOption = {
  label?: string;
  type?: SortType;
  value: {
    normal: string;
    revert: string;
  };
  onClick?(): void;
};
interface IFilterBar {
  options: Array<ISortOption>;
  current: string;
  isTablet: boolean;
  count?: string;
  setCount?(count: string): void;
  sx?: MarginType & TypographyType;
}

const FilterBar: React.FC<IFilterBar> = ({ options, sx, current, count, setCount, isTablet }) => {
  const selectOptionsNormal: Array<ISelectOption> = options
    ? options.map(({ value, label }) => ({
        value: value.normal,
        description: `${label} (по возрастанию)`,
      }))
    : [];
  const selectOptionsRevert: Array<ISelectOption> = options
    ? options.map(({ value, label }) => ({
        value: value.revert,
        description: `${label} (по убыванию)`,
      }))
    : [];
  const selectOptions = [...selectOptionsNormal, ...selectOptionsRevert].sort((a, b) => {
    if (a.value > b.value) return 1;
    if (a.value < b.value) return -1;
    return 0;
  });
  const onChangeSelect = (value: string) => {
    const checkedOption = options.find(
      (opt) => opt.value.normal === value || opt.value.revert === value
    );
    checkedOption.onClick();
  };
  return (
    <Flex sx={{ width: "100%", justify: "space-between", ...sx }}>
      {!isTablet && (
        <Flex>
          {options &&
            options.map((opt) => (
              <StyledOption
                key={opt.value.normal}
                onClick={opt.onClick}
                active={current === opt.value.normal || current === opt.value.revert}
              >
                <SortOptionIcon
                  active={current === opt.value.normal || current === opt.value.revert}
                  type={opt.type || "alpha"}
                  isUp={current === opt.value.revert}
                />
                <Text
                  sx={{
                    weight: "bold",
                    textTransform: sx?.textTransform || "capitalize",
                    ml: current === opt.value.normal || current === opt.value.revert ? -0.3 : 0.3,
                  }}
                  color="inherit"
                >
                  {opt.label}
                </Text>
              </StyledOption>
            ))}
        </Flex>
      )}
      {isTablet && options && (
        <Select
          fullWidth
          onChange={onChangeSelect}
          options={selectOptions}
          value={current}
          placeholder="Сортировать по"
        />
      )}
      {count !== undefined && (
        <Flex sx={{ align: "center" }} adaptiveSX={{ tablet: { display: "none" } }}>
          <Text sx={{ mr: 0.5 }}>Показать</Text>
          <Select
            hideScrollBar
            hideButton
            value={count}
            onChange={setCount}
            options={countList}
            sx={{ p: "4.5px 10px", minWidth: "50px", height: "30px" }}
          />
          <Text sx={{ ml: 0.5 }}>записей на странице</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default FilterBar;
