import { OfflineSigner, Registry } from "@cosmjs/proto-signing";
import { SigningStargateClient } from "@cosmjs/stargate";
import { MsgInstantiateContract, MsgExecuteContract } from "./types/wasm";
import { MsgSendReject, MsgSendSignAndProcess } from "./types/common";

const registry = new Registry([
  ["/rr.bcpcore.wasm.MsgInstantiateContract", MsgInstantiateContract],
  ["/rr.bcpcore.wasm.MsgExecuteContract", MsgExecuteContract],
  ["/rr.bcpcore.market.MsgSendSignAndProcess", MsgSendSignAndProcess],
  ["/rr.bcpcore.market.MsgSendReject", MsgSendReject],
]);

const getTxClient = (wallet: OfflineSigner): Promise<SigningStargateClient> =>
  SigningStargateClient.connectWithSigner(process.env.REACT_APP_CORE_API, wallet, {
    registry,
  });

export default getTxClient;
