import React from "react";
import Text from "../Text";
import { IStyledCheckbox, StyledCheckbox, StyledLabel } from "./Checkbox";
import CheckboxIcon from "./CheckboxIcon";

interface ICheckbox extends IStyledCheckbox {
  name: string;
  label?: string;
  onChange?(checked: boolean): void;
  checked?: boolean;
}
const Checkbox: React.FC<ICheckbox> = ({ name, checked, onChange, label, ...props }) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    if (!props.disabled && onChange) onChange(!checked);
  };
  return (
    <StyledLabel htmlFor={name} onClick={handleClick} {...props}>
      <StyledCheckbox name={name} id={name} />
      <CheckboxIcon checked={checked} />
      <Text color="inherit" variant="h6" sx={{ size: "SM", ml: 0.3, mt: 0.3 }}>
        {label}
      </Text>
    </StyledLabel>
  );
};

export default Checkbox;
