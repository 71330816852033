import React, { useEffect, useRef, useState } from "react";
import { FilterBar, Flex, NoData, Paginator } from "Theme";
import { useAppDispatch, useAppSelector } from "store";
import { resetHeader, setHeader, setPageLoading } from "store/global";
import { ISortOption } from "Theme/components/Filterbar";
import { SortOptionGql, UserClientType } from "@front-packages/dfa-gql-api";
import { Breakpoints } from "constants/in_lib";
import InvestorTokenCard from "./InvestorTokenCard";
import { IFilterProps, useFilterBadge, useTokensState } from "../hooks";
import SearchForm from "../components/SearchForm";

const initialFilterProps: IFilterProps = {
  issuer: undefined,
  ticker: undefined,
  issueAmount: undefined,
  startDate: undefined,
  completionDate: undefined,
};

const IssuesInvestor: React.FC = () => {
  const dispatch = useAppDispatch();
  const { tokens, getTokens } = useTokensState();
  const { setBadges } = useFilterBadge();
  const { windowWidth, pageLoading } = useAppSelector((state) => state.global);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<string>("10");
  const [filterProps, setFilterProps] = useState<IFilterProps>(initialFilterProps);
  const [sortOption, setSortOption] = useState<SortOptionGql>(
    SortOptionGql.IssuerAlphabeticalOrder
  );
  const firstCount = useRef<number>(null);
  const memoryFilterProps = useRef<IFilterProps>(initialFilterProps);

  const handleGetTokens = async (opts: IFilterProps) => {
    dispatch(setPageLoading(true));
    memoryFilterProps.current = opts;
    await getTokens({
      page,
      count: +count,
      clientType: UserClientType.Investor,
      sortOption,
      // TODO: Genapi
      ...(opts as any),
    });
    dispatch(setPageLoading(false));
  };

  useEffect(() => {
    setBadges(filterProps);
  }, [filterProps]);

  useEffect(() => {
    getTokens({
      page,
      count: +count,
      clientType: UserClientType.Investor,
      sortOption,
      // TODO: Genapi
      ...(memoryFilterProps.current as any),
    }).then((respCount) => {
      if (firstCount.current === null) firstCount.current = respCount;
      dispatch(setPageLoading(false));
    });
  }, [page, sortOption, count]);

  useEffect(() => {
    dispatch(setHeader({ title: "Маркетплейс токенов", subTitle: null }));
    return () => {
      dispatch(resetHeader());
    };
  }, []);

  const sortOptions: Array<ISortOption> = [
    {
      type: "alpha",
      label: "Эмитент",
      value: {
        normal: SortOptionGql.IssuerAlphabeticalOrder,
        revert: SortOptionGql.IssuerReverseAlphabeticalOrder,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.IssuerAlphabeticalOrder
            ? SortOptionGql.IssuerReverseAlphabeticalOrder
            : SortOptionGql.IssuerAlphabeticalOrder
        );
      },
    },
    {
      type: "alpha",
      label: "Тикер",
      value: {
        normal: SortOptionGql.TickerAlphabeticalOrder,
        revert: SortOptionGql.TickerReverseAlphabeticalOrder,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.TickerAlphabeticalOrder
            ? SortOptionGql.TickerReverseAlphabeticalOrder
            : SortOptionGql.TickerAlphabeticalOrder
        );
      },
    },
    {
      type: "number",
      label: "Сумма выпуска",
      value: {
        normal: SortOptionGql.TokenAmountAscending,
        revert: SortOptionGql.TokenAmountDescending,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.TokenAmountAscending
            ? SortOptionGql.TokenAmountDescending
            : SortOptionGql.TokenAmountAscending
        );
      },
    },
    {
      type: "any",
      label: "Дата старта",
      value: {
        normal: SortOptionGql.StartDateEarly,
        revert: SortOptionGql.StartDateLate,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.StartDateEarly
            ? SortOptionGql.StartDateLate
            : SortOptionGql.StartDateEarly
        );
      },
    },
    {
      type: "any",
      label: "Дата завершения",
      value: {
        normal: SortOptionGql.FinishDateEarly,
        revert: SortOptionGql.FinishDateLate,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.FinishDateEarly
            ? SortOptionGql.FinishDateLate
            : SortOptionGql.FinishDateEarly
        );
      },
    },
  ];

  return (
    <>
      {!!firstCount.current && (
        <Flex sx={{ width: "100%", justify: "flex-end" }}>
          <SearchForm state={filterProps} setState={setFilterProps} getTokens={handleGetTokens} />
        </Flex>
      )}
      {!!firstCount.current && !!tokens?.count && (
        <Flex
          sx={{
            direction: "column",
            gap: 2,
            align: "flex-start",
            width: "100%",
            wrap: "wrap",
            flex: "1 1 0%",
          }}
        >
          <FilterBar
            options={sortOptions}
            current={sortOption}
            count={count}
            setCount={setCount}
            isTablet={windowWidth <= Breakpoints.tablet}
          />
          <Flex
            sx={{ width: "100%", gap: 1, wrap: "wrap" }}
            adaptiveSX={{ tabletL: { justify: "space-between" } }}
          >
            {tokens?.tokens &&
              tokens.tokens.map((token) => <InvestorTokenCard key={token.id} token={token} />)}
          </Flex>
          {!!tokens?.count && (
            <Paginator
              page={page}
              amount={Math.ceil(tokens.count / +count)}
              setPage={setPage}
              withInput
            />
          )}
        </Flex>
      )}
      {!pageLoading && (!firstCount.current || !tokens?.count) && (
        <NoData
          text={!firstCount.current ? "Нет токенов" : "Токены по вашему запросу не найдены"}
        />
      )}
    </>
  );
};

export default IssuesInvestor;
