import React from "react";
import styled from "styled-components";
import { getSize, IconSizeType } from "../../../icons/default";
import Text from "../../Text";
import { MarginType } from "../../../types";

const StyledSortSvg = styled.svg`
  transform: rotate(180deg);
`;
const StyledArrowSvg = styled.svg<{ isUp: boolean; active: boolean }>`
  transform: ${({ isUp }) => `rotateX(${isUp ? "0" : "180deg"})`};
  margin-left: -7px;
  opacity: ${({ active }) => `${active ? 1 : 0}`};
`;
const WrapSortAlpha = styled.div`
  color: inherit;
  font-weight: 500;
  font-size: 0.7rem;
  text-align: center;
`;

export type SortType = "alpha" | "number" | "any";
export type SortOptionDefaultType = {
  type: SortType;
  isUp: boolean;
  active: boolean;
};
interface ISortOptionIcon extends SortOptionDefaultType {
  size?: IconSizeType;
  sx?: MarginType;
}

const SortOptionIcon: React.FC<ISortOptionIcon> = ({
  type = "any",
  active,
  size = "medium",
  isUp,
  sx,
}) => {
  return (
    <Text color="inherit" light sx={{ justify: "center", align: "center", ...sx }}>
      {type === "any" && (
        <StyledSortSvg
          xmlns="http://www.w3.org/2000/svg"
          width={getSize(size, 24)}
          height={getSize(size, 24)}
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
        </StyledSortSvg>
      )}
      {type === "alpha" && (
        <WrapSortAlpha>
          <div>А</div>
          <div>Я</div>
        </WrapSortAlpha>
      )}
      {type === "number" && (
        <WrapSortAlpha>
          <div>1</div>
          <div>2</div>
        </WrapSortAlpha>
      )}
      <StyledArrowSvg
        active={active}
        isUp={isUp}
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M11.5 7C11.5 6.72386 11.7239 6.5 12 6.5C12.2761 6.5 12.5 6.72386 12.5 7L11.5 7ZM12.5 21C12.5 21.2761 12.2761 21.5 12 21.5C11.7239 21.5 11.5 21.2761 11.5 21L12.5 21ZM12.5 7L12.5 21L11.5 21L11.5 7L12.5 7Z" />
        <path d="M11.7856 2.35729L9.72696 5.7884C9.40703 6.32162 9.79112 7 10.413 7L13.587 7C14.2089 7 14.593 6.32162 14.273 5.7884L12.2144 2.35729C12.1173 2.19545 11.8827 2.19545 11.7856 2.35729Z" />
      </StyledArrowSvg>
    </Text>
  );
};

export default SortOptionIcon;
