import React, { useRef } from "react";
import { ColumnsType, Flex, RowsType, Table, Text } from "Theme";
import { RedeemJournalsColumnsType } from "../../types";

interface IRedeemJournalsProps {
  loading: boolean;
  countOfJournals: number;
  rows: RowsType<RedeemJournalsColumnsType>;
}

const RedeemJournals: React.FC<IRedeemJournalsProps> = ({ loading, countOfJournals, rows }) => {
  const columns = useRef<ColumnsType<RedeemJournalsColumnsType>>([
    { label: "номер", field: "number", proportion: 4 },
    { label: "создан", field: "createdDate", proportion: 1 },
    { label: "статус", field: "status", proportion: 1 },
    { label: "действия", field: "actions", proportion: 1 },
  ]);
  return (
    <Flex sx={{ direction: "column", gap: 1, width: "100%" }}>
      <Text variant="h4" sx={{ weight: "regular", mt: 2 }}>
        Журналы погашения ({countOfJournals || 0})
      </Text>
      <Flex sx={{ width: "100%", maxHeight: "450px", overflowY: "scroll" }}>
        <Table
          rowHeight={40}
          columns={columns.current}
          rows={rows}
          rowSx={{ rowStyle: "white" }}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default RedeemJournals;
