import React from "react";
import { useTheme } from "styled-components";
import { ICircleLoader, StyledCircleLoader } from "./CircleLoader";
import { ColorType } from "../../types";
import Text from "../Text";

interface ICircleLoaderProps extends ICircleLoader {
  color?: ColorType;
  value?: number;
}

const CircleLoader: React.FC<ICircleLoaderProps> = ({
  color = "primary",
  size = 70,
  value,
  ...props
}) => {
  const theme = useTheme();
  const strokeWidth = 3;
  const radius = size / 2 - strokeWidth * 2;
  const circumference = 2 * Math.PI * radius;
  return (
    <StyledCircleLoader size={size} isSpinner={value === undefined} {...props}>
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={theme.palette[color].light}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={
            value === undefined
              ? circumference - 0.25 * circumference
              : circumference - (value / 100) * circumference
          }
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={`${theme.palette[color].light}40`}
          strokeWidth={strokeWidth}
          fill="transparent"
        />
      </svg>
      {!!value && (
        <Text
          color={color}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-45%, -45%)",
            cssSize: size / 5,
          }}
        >
          {value}%
        </Text>
      )}
    </StyledCircleLoader>
  );
};

export default CircleLoader;
