import React, { useEffect, useRef, useState } from "react";
import Dialog, { IDialogProps } from "../Dialog";
import Input from "../Input";
import Flex from "../Flex";

const replaceAt = (str, index, replacement) => {
  return str.substring(0, index) + replacement + str.substring(index + replacement.length);
};

interface IMFADialog extends Partial<IDialogProps> {
  descOperation: string;
  open: boolean;
  onClose(): void;
  onConfirm(e?): void;
  setCode(code: string): void;
  code: string;
}

const inputStyle: any = {
  width: "15%",
  size: "XXXL",
  weight: "light",
  textAlign: "center",
  p: "12.5px 0",
};

const MFADialog: React.FC<IMFADialog> = ({ code, descOperation, setCode, ...props }) => {
  const [value, setValue] = useState<string>("      ");
  const input1 = useRef<HTMLInputElement>();
  const input2 = useRef<HTMLInputElement>();
  const input3 = useRef<HTMLInputElement>();
  const input4 = useRef<HTMLInputElement>();
  const input5 = useRef<HTMLInputElement>();
  const input6 = useRef<HTMLInputElement>();
  const handleSetCode = (str: string, nextInput?: any, currentIndex?: number) => {
    if (str) {
      const trimStr = str.replace(/\D/g, "").replace(value[currentIndex], "");
      const newValue = replaceAt(value, currentIndex, trimStr);
      setValue(newValue);
      if (nextInput) nextInput.current.focus();
    }
  };
  const handleKeyDown = (e: any, prevInput?: any, currentIndex?: number) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      setValue(replaceAt(value, currentIndex, " "));
      if (prevInput && !e.target.value.trim()) prevInput.current.focus();
    }
  };
  useEffect(() => {
    if (code) setValue(code);
  }, [code]);
  useEffect(() => {
    if (!props.open) setValue("      ");
  }, [props.open]);
  useEffect(() => {
    setCode(value.trim());
  }, [value]);
  return (
    <Dialog
      id="MFADialog"
      title={props.loading ? "Выполнение операции" : "Введите проверочный код из СМС"}
      subTitle={
        props.loading
          ? descOperation
          : "Мы отправили Вам на телефон проверочный код для подтверждения личности."
      }
      confirmText="Отправить"
      disabled={!value.trim() || value.replace(" ", "").length < 6}
      {...props}
    >
      <Flex sx={{ justify: "space-between" }}>
        <Input
          colored
          color={props.type}
          ref={input1}
          maxLength={2}
          value={value[0]}
          sx={inputStyle}
          onChange={(string) => handleSetCode(string, input2, 0)}
          onKeyDown={(e) => handleKeyDown(e, undefined, 0)}
          hideCursor
        />
        <Input
          colored
          color={props.type}
          ref={input2}
          sx={inputStyle}
          value={value[1]}
          onChange={(string) => handleSetCode(string, input3, 1)}
          maxLength={2}
          onKeyDown={(e) => handleKeyDown(e, input1, 1)}
          hideCursor
        />
        <Input
          colored
          color={props.type}
          ref={input3}
          sx={inputStyle}
          value={value[2]}
          onChange={(string) => handleSetCode(string, input4, 2)}
          maxLength={2}
          onKeyDown={(e) => handleKeyDown(e, input2, 2)}
          hideCursor
        />
        <Input
          colored
          color={props.type}
          ref={input4}
          sx={inputStyle}
          value={value[3]}
          onChange={(string) => handleSetCode(string, input5, 3)}
          maxLength={2}
          onKeyDown={(e) => handleKeyDown(e, input3, 3)}
          hideCursor
        />
        <Input
          colored
          color={props.type}
          ref={input5}
          sx={inputStyle}
          value={value[4]}
          onChange={(string) => handleSetCode(string, input6, 4)}
          maxLength={2}
          onKeyDown={(e) => handleKeyDown(e, input4, 4)}
          hideCursor
        />
        <Input
          colored
          color={props.type}
          ref={input6}
          sx={inputStyle}
          value={value[5]}
          onChange={(str) => handleSetCode(str, undefined, 5)}
          maxLength={2}
          onKeyDown={(e) => handleKeyDown(e, input5, 5)}
          hideCursor
        />
      </Flex>
    </Dialog>
  );
};

export default MFADialog;
