import React, { useState } from "react";
import {
  AccordionContentWrap,
  AccordionHeaderWrap,
  AccordionWrap,
  IStyledAccordion,
} from "./Accordion";
import Button from "../Button";
import Text from "../Text";
import { ChevronIcon } from "../../icons";

interface IAccordionProps extends IStyledAccordion {
  title: string;
  titleLight?: boolean;
  children?: React.ReactNode;
}

const Accordion: React.FC<IAccordionProps> = ({ title, titleLight, children, ...props }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const toggleOpen = () => setOpened((prev) => !prev);
  return (
    <AccordionWrap opened={opened} {...props} onClick={props?.isInteractive && toggleOpen}>
      <AccordionHeaderWrap opened={opened}>
        <Text
          variant="h5"
          light={titleLight}
          title={title}
          color={props.filled ? "white" : props.bgColor || "primary"}
          rows={1}
        >
          {title}
        </Text>
        <Button
          onClick={toggleOpen}
          iconStart={<ChevronIcon size="medium" rotate={!opened ? "right" : "down"} />}
          onlyIcon
        />
      </AccordionHeaderWrap>
      {opened && <AccordionContentWrap opened={opened}>{children}</AccordionContentWrap>}
    </AccordionWrap>
  );
};

export default Accordion;
