import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const RefreshIcon: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8A5.87 5.87 0 0 1 6 12c0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M140-120q-24 0-42-18t-18-42v-191h311v60h180v-60h309v191q0 24-18 42t-42 18H140Zm311-251v-60h60v60h-60ZM80-431v-229q0-24 18-42t42-18h180v-100q0-24 18-42t42-18h200q24 0 42 18t18 42v100h180q24 0 42 18t18 42v229H571v-60H391v60H80Zm300-289h200v-100H380v100Z" />
      </svg> */}
    </Text>
  );
};

export default RefreshIcon;
