import React, { useEffect, useRef, useState } from "react";
import {
  TokenOrdersResponse,
  OrdersAPI,
  UserClientType,
  SortOptionGql,
} from "@front-packages/dfa-gql-api";
import { useErrors } from "hooks";
import { useAppDispatch, useAppSelector } from "store";
import { setPageLoading } from "store/global";
import { Badge, ColumnsType, Flex, NoData, Paginator, RowType, Table } from "Theme";
import { formattingDate, getStatusBadgeProps } from "helpers";
import SearchForm, { IFilterProps } from "../../components/SearchForm";
import { initialFilterProps } from "../../components/OrderModals";
import { Columns, OrderRowType, OrdersRowsType } from "./types";
import OrderModal from "../../components/OrderModal";

const PrimaryMarketOrders: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setError } = useErrors();
  const { isAccountIssuer } = useAppSelector((state) => state.account);
  const { pageLoading } = useAppSelector((state) => state.global);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<string>("10");
  const [orders, setOrders] = useState<TokenOrdersResponse>(null);
  const [filterProps, setFilterProps] = useState<IFilterProps>(initialFilterProps);
  const [currentSortOption, setCurrentSortOption] = useState<SortOptionGql>(
    SortOptionGql.TickerAlphabeticalOrder
  );
  const firstCount = useRef<number>(null);
  const memoryFilterProps = useRef<IFilterProps>(initialFilterProps);
  const [rows, setRows] = useState<OrdersRowsType>([]);
  const [currentOrder, setCurrentOrder] = useState<RowType<OrderRowType>>(null);
  // const [isMakingContract, setIsMakingContract] = useState<boolean>(false);

  const columns = useRef<ColumnsType<Columns>>([
    {
      label: "эмитент",
      field: "issuer",
      proportion: 3,
      sortOption: {
        type: "alpha",
        value: {
          normal: SortOptionGql.IssuerAlphabeticalOrder,
          revert: SortOptionGql.IssuerReverseAlphabeticalOrder,
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.IssuerAlphabeticalOrder
              ? SortOptionGql.IssuerReverseAlphabeticalOrder
              : SortOptionGql.IssuerAlphabeticalOrder
          );
        },
      },
    },
    {
      label: "тикер",
      field: "ticker",
      proportion: 4,
      sortOption: {
        type: "alpha",
        value: {
          normal: SortOptionGql.TickerAlphabeticalOrder,
          revert: SortOptionGql.TickerReverseAlphabeticalOrder,
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.TickerAlphabeticalOrder
              ? SortOptionGql.TickerReverseAlphabeticalOrder
              : SortOptionGql.TickerAlphabeticalOrder
          );
        },
      },
    },
    {
      label: "сумма заявки",
      field: "sum",
      proportion: 2,
      sortOption: {
        type: "number",
        value: {
          normal: SortOptionGql.TokenOrderAmountAscending,
          revert: SortOptionGql.TokenOrderAmountDescending,
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.TokenOrderAmountAscending
              ? SortOptionGql.TokenOrderAmountDescending
              : SortOptionGql.TokenOrderAmountAscending
          );
        },
      },
    },
    {
      label: "количество",
      field: "amount",
      proportion: 2,
      sortOption: {
        type: "number",
        value: {
          normal: SortOptionGql.TokenOrderAmountAscending,
          revert: SortOptionGql.TokenOrderAmountDescending,
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.TokenOrderAmountAscending
              ? SortOptionGql.TokenOrderAmountDescending
              : SortOptionGql.TokenOrderAmountAscending
          );
        },
      },
    },
    {
      label: "дата создания",
      field: "createdDate",
      proportion: 2,
      sortOption: {
        type: "any",
        value: {
          normal: SortOptionGql.CreatedDateEarly,
          revert: SortOptionGql.CreatedDateLate,
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.CreatedDateEarly
              ? SortOptionGql.CreatedDateLate
              : SortOptionGql.CreatedDateEarly
          );
        },
      },
    },
    {
      label: "статус",
      field: "status",
      proportion: 2,
      sortOption: {
        type: "any",
        value: {
          normal: SortOptionGql.OrderStatusAscending,
          revert: SortOptionGql.OrderStatusDescending,
        },
        onClick() {
          setCurrentSortOption((prev) =>
            prev === SortOptionGql.OrderStatusAscending
              ? SortOptionGql.OrderStatusDescending
              : SortOptionGql.OrderStatusAscending
          );
        },
      },
    },
  ]);

  const getOrders = async (opts: IFilterProps, isSwitch?: boolean) => {
    dispatch(setPageLoading(true));
    memoryFilterProps.current = opts;
    const { response, error } = await OrdersAPI.GetOrdersList({
      opts: {
        page: isSwitch ? 0 : page,
        count: isSwitch ? 10 : +count,
        clientType: isAccountIssuer ? UserClientType.Emitent : UserClientType.Investor,
        sortOption: currentSortOption,
        // TODO: Genapi. DateRange
        ...(opts as any),
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setOrders(response);
      setRows(
        response.orders?.map((order) => {
          return {
            id: order.id,
            issuer: order.issuer,
            ticker: order.ticker,
            sum: order.orderSum,
            amount: order.amount,
            createdDate: formattingDate(order.createDate),
            status: (
              <Badge
                sx={{ minHeight: "30px" }}
                isLoading={pageLoading}
                {...getStatusBadgeProps(order.status, "Order")}
              />
            ),
            investFinishDate: order.token.investFinishDate,
          };
        })
      );
      if (firstCount.current === null) firstCount.current = response.count;
    }
    if (opts) dispatch(setPageLoading(false));
  };

  useEffect(() => {
    if (!pageLoading)
      getOrders(memoryFilterProps.current).then(() => dispatch(setPageLoading(false)));
  }, [page, currentSortOption, count]);

  useEffect(() => {
    getOrders(initialFilterProps, true).then(() => dispatch(setPageLoading(false)));
    setPage(0);
    setCount("10");
    setFilterProps(initialFilterProps);
  }, [isAccountIssuer]);

  return (
    <>
      {!!firstCount.current && (
        <Flex sx={{ width: "100%", justify: "flex-end", mt: 2 }}>
          <SearchForm state={filterProps} setState={setFilterProps} getOrders={getOrders} />
        </Flex>
      )}
      {!!firstCount.current && !!orders?.count && (
        <Flex sx={{ width: "100%", direction: "column", rowGap: 2 }}>
          <Flex sx={{ overflow: "auto", maxWidth: "100%" }}>
            <Table
              rows={rows}
              columns={columns.current}
              currentSortOption={currentSortOption}
              onRowClick={(row) => {
                // setIsMakingContract(true);
                setCurrentOrder(row);
              }}
            />
          </Flex>
          <Paginator page={page} amount={Math.ceil(orders.count / +count)} setPage={setPage} />
        </Flex>
      )}
      {!pageLoading && (!firstCount.current || !orders?.count) && (
        <NoData text={!firstCount.current ? "Нет заявок" : "Заявок по вашему запросу не найдено"} />
      )}
      {currentOrder && (
        <OrderModal
          open={!!currentOrder}
          onClose={() => setCurrentOrder(null)}
          order={orders?.orders?.filter((order) => order.id === currentOrder?.id)[0]}
        />
      )}
      {/* <OrderModals
        order={currentOrder}
        setCurrentOrder={setCurrentOrder}
        getOrders={() =>
          getOrders(initialFilterProps, true).then(() => dispatch(setPageLoading(false)))
        }
        isMakingContract={isMakingContract}
        setIsMakingContract={setIsMakingContract}
      /> */}
    </>
  );
};

export default PrimaryMarketOrders;
