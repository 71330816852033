import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const CollectionsIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67 1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
      </svg>
    </Text>
  );
};

export default CollectionsIcon;
