import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const PercentIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M293.266-533.334q-55.266 0-94.266-39.067-39-39.067-39-94.333T199.067-761q39.068-39 94.333-39 55.266 0 94.266 39.067 39 39.068 39 94.333 0 55.266-39.067 94.266-39.067 39-94.333 39ZM293.255-600Q321-600 340.5-619.422q19.5-19.421 19.5-47.166 0-27.746-19.422-47.246-19.421-19.5-47.166-19.5-27.746 0-47.246 19.422-19.5 19.422-19.5 47.167t19.422 47.245Q265.51-600 293.255-600ZM666.6-160q-55.266 0-94.266-39.067-39-39.068-39-94.333 0-55.266 39.067-94.266 39.067-39 94.333-39T761-387.599q39 39.067 39 94.333T760.933-199q-39.068 39-94.333 39Zm-.012-66.666q27.746 0 47.246-19.422 19.5-19.422 19.5-47.167T713.912-340.5Q694.49-360 666.745-360T619.5-340.578Q600-321.157 600-293.412q0 27.746 19.422 47.246 19.421 19.5 47.166 19.5Zm-483.255 43.333q-9.666-9.667-9.666-23.333 0-13.667 9.666-23.334L730-776.667q9.667-9.666 23.334-9.666 13.666 0 23.333 9.666 9.666 9.667 9.666 23.333 0 13.667-9.666 23.334L230-183.333q-9.667 9.666-23.334 9.666-13.666 0-23.333-9.666Z" />
      </svg>
    </Text>
  );
};

export default PercentIcon;
