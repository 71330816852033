import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColorType } from "../Theme";

export type NotificationType = {
  message: string;
  type: ColorType;
  position?: "left" | "right";
};

export type NotificationTypeWithKey = NotificationType & {
  key: number;
};

interface IInitialState {
  position: "left" | "right";
  items: Array<NotificationTypeWithKey>;
}

const initialState: IInitialState = {
  position: "left",
  items: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification(state, { payload }: PayloadAction<NotificationType>) {
      const { position, ...newItems } = payload;
      state.position = position;
      state.items.push({
        ...newItems,
        key: Math.floor(Math.random() * 1000) + 1,
      });
    },
    removeNotification(state, { payload }: PayloadAction<number>) {
      state.items = state.items.filter((el) => el.key !== payload);
    },
    clearNotifications(state) {
      state.position = "left";
      state.items = [];
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } =
  notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
