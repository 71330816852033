import { AccountOperationAPI } from "@front-packages/dfa-gql-api";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { Dialog, Flex, Input, MFADialog } from "Theme";
import { useErrors, useGetBalance } from "hooks";
import { addNotification } from "store/notifications";
import { setOpenDepositOrder } from "store/dialogs";
import useDialogState from "../useDialogState";
import PDFViewDialog from "./PDFViewDialog";

function DepositRequestDialog() {
  const dispatch = useAppDispatch();
  const { setError } = useErrors();
  const getBalance = useGetBalance();
  const { company } = useAppSelector((state) => state.customer);
  const { openDepositOrder } = useAppSelector((state) => state.dialogs);
  const { state, getMfa, setState, cleanMfa } = useDialogState();
  const { sum, mfa, loading } = state;
  const [base64File, setBase64File] = useState<string>(null);
  const [openPDF, setOpenPDF] = useState<boolean>(false);

  const handleClose = async () => {
    setState.sum("");
    setState.loading(false);
    cleanMfa();
    setBase64File(null);
    setOpenPDF(false);
    if (openDepositOrder) dispatch(setOpenDepositOrder(false));
    await getBalance();
  };
  const confirm = async () => {
    setState.loading(true);
    const { error, response } = await AccountOperationAPI.CreateDepositOrder({
      form: {
        clientID: company.id,
        representativeID: company.representativeID,
        fiatAmount: sum,
        mfaUUID: mfa.sessionID,
        mfaCode: mfa.code,
      },
    });
    if (error) {
      setState.loading(false);
      setError({ error, key: Math.random() });
      handleClose();
      return;
    }
    if (response) {
      const invoice = await AccountOperationAPI.GenerateDepositOrderInvoice({
        depositOrderID: response.id,
      });
      setBase64File(invoice.response);
      setOpenPDF(true);
      dispatch(
        addNotification({
          message: "Заявка на пополнение баланса успешно создана, счет сформирован.",
          type: "success",
          position: "right",
        })
      );
    }
    // handleClose();
  };

  if (openPDF) return <PDFViewDialog onClose={handleClose} base64File={base64File} />;
  if (mfa)
    return (
      <MFADialog
        descOperation="Выполняется операция по созданию заявки на пополнение"
        open
        withMissClick={false}
        onClose={cleanMfa}
        onConfirm={confirm}
        setCode={setState.code}
        maxWidth="md"
        code={mfa.code}
        loading={loading}
      />
    );
  return (
    <Dialog
      open={openDepositOrder}
      title="Пополнение баланса"
      // TODO: пока что common, как на мидле добавят - исправить!!!!
      onConfirm={() => getMfa("common", company?.ownerID, "CreateDepositOrder")}
      confirmText="Сформировать счет"
      subTitle="Для подачи заявки на зачисление средств на адрес кошелька заполните необходимые поля и подтвердите операцию"
      onClose={handleClose}
      maxWidth="lg"
      disabled={!sum}
      loading={loading}
    >
      <Flex sx={{ direction: "column", rowGap: 1, width: "100%", m: "10px 0" }}>
        {/* <Flex sx={{ align: "center", columnGap: 1 }}>
          <Text sx={{ weight: "bold" }}>Адрес кошелька:</Text>
          <Text>{company?.wallet?.address || "no data"}</Text>
          <Badge
            label={company?.status || "no data"}
            color={company?.status === "Active" ? "success" : "error"}
          />
        </Flex> */}
        <Input
          colored
          placeholder="Сумма пополнения"
          value={sum}
          onChange={setState.sum}
          type="number"
        />
      </Flex>
    </Dialog>
  );
}

export default DepositRequestDialog;
