import { CursorType, ExtColorType, MarginType, PositionType } from "../types";

export type IconSizeType = "tinySmall" | "small" | "medium" | "large" | "largest";
export type RotateIconType = "up" | "down" | "right" | "left";
export type OpacityType = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90;
export interface IIcon {
  light?: boolean;
  opacity?: OpacityType;
  color?: ExtColorType;
  size?: IconSizeType;
  contrast?: boolean;
  bold?: boolean;
  rotate?: RotateIconType;
  sx?: MarginType & CursorType & PositionType;
  title?: string;
}

export const getSize = (size: IconSizeType, proportions: number): number => {
  switch (size) {
    case "tinySmall":
      return proportions / 2;
    case "small":
      return proportions / 1.5;
    case "large":
      return proportions * 2;
    case "largest":
      return proportions * 4;
    default:
      return proportions;
  }
};
