import { ChangeEvent, useState } from "react";
import { UploadAPI, UploadFilesPropsType } from "@front-packages/dfa-gql-api";
import { UseFilesResult, FilesDataType } from "./types";

function useFiles(): UseFilesResult {
  const [data, setData] = useState<FilesDataType>({});

  const setProgress = (index: number, progress: number) => {
    setData((prev) => ({
      ...prev,
      [index]: { ...prev[index], progress },
    }));
  };

  const upload = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadProps: UploadFilesPropsType = {
      initFiles: data,
      targetFiles: e.target.files,
      lastIndex: Object.keys(data).length,
      setProgress,
    };
    const result = await UploadAPI.MultipleUploadingFiles(uploadProps);
    setData(result);
  };

  const dropUpload = async (files) => {
    const uploadProps: UploadFilesPropsType = {
      initFiles: data,
      targetFiles: files,
      lastIndex: Object.keys(data).length,
      setProgress,
    };
    const result = await UploadAPI.MultipleUploadingFiles(uploadProps);
    setData(result);
  };

  const del = (key: string) => {
    const newFiles = {};
    Object.values(data)
      .filter((el) => el.key !== key)
      .forEach((el, i) => {
        newFiles[i] = el;
      });
    setData(newFiles);
  };

  const reset = () => setData({});

  return { data, upload, del, reset, dropUpload };
}

export default useFiles;
