import React from "react";
import { RepresentativeRole } from "@front-packages/dfa-gql-api";
import { Flex, Text, Button } from "Theme";
import { IconCross, InvestorLogo, IssuerLogo } from "Theme/icons";
import { useAppDispatch, useAppSelector } from "store";
import { switchOpenSideBar } from "store/account";
import { Breakpoints } from "constants/in_lib";
import { StyledSidebar } from "./Sidebar";
import SidebarListItems from "./SidebarlListItems";
import ChangeAccount from "./ChangeAccount";

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAccountIssuer, openSideBar } = useAppSelector((state) => state.account);
  const { windowWidth } = useAppSelector((state) => state.global);
  const { representativeRole = undefined } = useAppSelector(
    (state) => state.customer.company || {}
  );
  const close = () => dispatch(switchOpenSideBar());
  return (
    <StyledSidebar open={openSideBar} adaptiveSX={{ mobile: { width: "100%" } }}>
      <Flex
        sx={{ mb: 2, justify: "center", wrap: "wrap", direction: "column" }}
        adaptiveSX={{ laptopM: { direction: "column", align: "flex-start" } }}
      >
        <Flex adaptiveSX={{ laptopM: { width: "100%", mb: 0.3 } }}>
          {isAccountIssuer ? (
            <IssuerLogo link="https://isdfa.ru/" />
          ) : (
            <InvestorLogo link="https://isdfa.ru/" />
          )}
          {windowWidth <= Breakpoints.laptopM && (
            <Button
              sx={{ ml: "auto" }}
              iconStart={<IconCross size="medium" />}
              onlyIcon
              onClick={close}
            />
          )}
        </Flex>
        <Flex sx={{ mt: 0.5, justify: "space-between" }}>
          <Text
            variant="h6"
            color="secondary"
            link={`https://dfa-web.bcp.${process.env.REACT_APP_ENVIRONMENT.toLowerCase()}.stsoft.dev`}
          >
            {process.env.REACT_APP_VERSION.replace(
              `-${process.env.REACT_APP_ENVIRONMENT.toLowerCase()}`,
              ""
            ).replace("v", "версия ") || "version error"}
          </Text>
        </Flex>
      </Flex>
      <SidebarListItems />
      {representativeRole === RepresentativeRole.Emitent && <ChangeAccount />}
    </StyledSidebar>
  );
};

export default Sidebar;
