import { TransactionsAPI } from "@front-packages/dfa-gql-api";
import { useAppDispatch } from "store";
import { setBalance } from "store/customer";

const useGetBalance = () => {
  const dispatch = useAppDispatch();
  const getBalance = async () => {
    const balance = await TransactionsAPI.GetBalance();
    dispatch(setBalance(balance.response));
  };
  return getBalance;
};

export default useGetBalance;
