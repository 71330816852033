import React from "react";
import { Text } from "../components";
import { IIcon, getSize } from "./default";

const RoundIcon: React.FC<IIcon & { active?: boolean }> = ({
  color = "white",
  light = false,
  opacity = null,
  size = "small",
  active = false,
  sx,
}) => {
  return (
    <Text
      color={color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill={color}
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        {active && <circle cx={12} cy={12} r={5} />}
      </svg>
    </Text>
  );
};

export default RoundIcon;
