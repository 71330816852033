import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const CopyIcon: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 36)}
        width={getSize(size, 36)}
        viewBox="0 0 48 48"
        fill="currentColor"
      >
        <path d="M9 43.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.8h3v30.15h23.7v3Zm6-6q-1.2 0-2.1-.9-.9-.9-.9-2.1v-28q0-1.2.9-2.1.9-.9 2.1-.9h22q1.2 0 2.1.9.9.9.9 2.1v28q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h22v-28H15v28Zm0 0v-28 28Z" />
      </svg>
    </Text>
  );
};

export default CopyIcon;
