import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { switchInvestorAccount } from "store/account";
import { Alert, Button, Checkbox, Flex, Input, MFADialog, Text } from "../../../Theme";
import { useAuth, useValidate } from "../../../hooks";
import { RoutesNames } from "../../../routes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Breakpoints } from "../../../constants/in_lib";
import getAlertData from "./getAlertData";

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state, setState, ...auth } = useAuth();
  const {
    global: { windowWidth },
  } = useAppSelector((store) => store);
  const { invalidPhone } = useValidate({ phoneNumber: state.phoneNumber });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { description, badgeText, type } = getAlertData(process.env.REACT_APP_ENVIRONMENT);

  const login = async () => {
    setLoading(true);
    const { phoneNumber, password } = state;
    if (phoneNumber && password && !invalidPhone) {
      const { code: FACode, sessionID: FASessionID } = await auth.login();
      if (FACode && FASessionID) {
        setCode(FACode);
        setSessionID(FASessionID);
        setIsOpenModal(true);
        setLoading(false);
      }
    }
    setLoading(false);
  };
  const closeModal = () => {
    setIsOpenModal(false);
    setLoading(false);
  };
  const sendCode = async () => {
    setLoading(true);
    const success = await auth.sendCode(code, sessionID);
    if (!success) closeModal();
  };
  // const handleEnter = async (e: any) => {
  //   if (e.nativeEvent.charCode === 13) await handleLogin();
  // };

  const isDisabled = !state.phoneNumber || !state.password || invalidPhone;

  useEffect(() => {
    const lsIsIssuer = localStorage.getItem("isIssuer");
    if (lsIsIssuer) {
      dispatch(switchInvestorAccount());
    }
  }, []);
  return (
    <>
      <Flex
        sx={{ direction: "column", width: "50%", minWidth: "500px" }}
        adaptiveSX={{
          laptop: { minWidth: "400px" },
          tablet: { minWidth: "300px" },
          mobile: { minWidth: "90%" },
        }}
      >
        <Text color="primary" variant="h1" sx={{ mb: 0.5 }}>
          Вход {windowWidth <= Breakpoints.mobile && "в личный кабинет инвестора"}
        </Text>
        <Text sx={{ mb: 2.5 }}>Введите номер телефона и пароль</Text>
        <Input
          sx={{ mb: 1 }}
          error="none"
          type="tel"
          value={state.phoneNumber}
          onChange={setState.phoneNumber}
        />
        <Input
          error="none"
          sx={{ mb: 0.5 }}
          type="password"
          placeholder="Пароль"
          value={state.password}
          onChange={setState.password}
        />
        <Checkbox
          name="remember"
          label="Запомнить меня"
          sx={{ mb: 2.5 }}
          checked={state.isRemember}
          onChange={setState.isRemember}
        />
        <Button onClick={login} disabled={isDisabled} loading={loading} id="loginBtn">
          Войти в учетную запись представителя
        </Button>
        <Flex
          sx={{ align: "center", justify: "center", mt: 2 }}
          adaptiveSX={{ laptop: { direction: "column" } }}
        >
          <Text color="secondary" sx={{ mr: 0.3 }}>
            Входите впервые?
          </Text>
          <Button variant="link" id="checkBtn" onClick={() => navigate(RoutesNames.Invite)}>
            Проверить представителя
          </Button>
        </Flex>
        <Alert sx={{ mt: 3 }} title={badgeText} text={description} type={type as any} show />
      </Flex>
      <MFADialog
        loading={loading}
        open={isOpenModal}
        onClose={closeModal}
        onConfirm={sendCode}
        setCode={setCode}
        code={code}
        descOperation="Выполняется подтверждение номера телефона"
      />
    </>
  );
};

export default Auth;
