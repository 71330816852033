import styled, { css } from "styled-components";
import { ColorType } from "../../types";

interface IWrapperProgress {
  column?: boolean;
}
interface IBackgroundProgress extends IWrapperProgress {
  color?: ColorType;
  height?: "sm" | "md" | "lg";
}
export interface IStyledProgress extends IBackgroundProgress {
  value: number;
}

export const WrapperProgress = styled.div<IWrapperProgress>`
  display: flex;
  flex: 1;
  overflow: hidden;
  ${({ column }) => css`
    flex-direction: ${column && "column"};
    align-items: ${column ? "stretch" : "center"};
    justify-content: ${column ? "center" : "stretch"};
    row-gap: ${column && "0.3rem"};
    column-gap: ${!column && "1rem"};
  `}
`;
export const BackgroundProgress = styled.span<IBackgroundProgress>`
  display: inline-block;
  width: ${({ column }) => column && "100%"};
  flex: ${({ column }) => !column && 1};
  background: ${({ theme, color }) => theme.palette[color].gradient[25]};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  ${({ height }) => css`
    height: ${height === "sm" && "5px"};
    height: ${height === "md" && "10px"};
    height: ${height === "lg" && "15px"};
  `}
`;
export const FrontProgress = styled.span<IStyledProgress>`
  display: flex;
  border-radius: 10px;
  ${({ height }) => css`
    height: ${height === "sm" && "5px"};
    height: ${height === "md" && "10px"};
    height: ${height === "lg" && "15px"};
  `}
  ${({ theme, color, value }) => css`
    width: ${value}%;
    background: ${theme.palette[color].gradient[100]};
  `}
`;

BackgroundProgress.defaultProps = {
  color: "primary",
};
FrontProgress.defaultProps = {
  color: "primary",
};
