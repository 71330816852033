/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Token, TokensAPI } from "@front-packages/dfa-gql-api";
import {
  Button,
  Checkbox,
  CircleLoader,
  Flex,
  InfoCard,
  NoData,
  Paginator,
  Table,
  Text,
} from "Theme";
import { useAppDispatch, useAppSelector } from "store";
import { amountWithSpaces, formattingDate } from "helpers";
import { resetHeader, setHeader, setPageLoading } from "store/global";
import { RoutesNames } from "routes";
import { useErrors } from "hooks";
import { Breakpoints } from "constants/in_lib";
import { InfoIcon, WarningIcon } from "Theme/icons";
import useMarketOrdersData from "./useMarketOrdersData";
import { NewOrderModal, OrderInfoModal, useOrderModalsState } from "../Modals";

const ViewToken: React.FC = () => {
  const { search } = useLocation();
  const { setError } = useErrors();
  const navigate = useNavigate();

  const { windowWidth, pageLoading } = useAppSelector((state) => state.global);
  const newOrderModalState = useOrderModalsState();
  const orderInfoModalState = useOrderModalsState();
  const [tokenData, setTokenData] = useState<Token>(null);
  const buyOrders = useMarketOrdersData(
    "buy",
    tokenData?.ticker,
    orderInfoModalState.onOpenOrderModal
  );
  const sellOrders = useMarketOrdersData(
    "sell",
    tokenData?.ticker,
    orderInfoModalState.onOpenOrderModal
  );
  const dispatch = useAppDispatch();
  const tokenID = search.split("=")[1];

  const getTokenDataById = async () => {
    dispatch(setPageLoading(true));
    const { response, error } = await TokensAPI.GetTokenByID({ tokenID });
    if (error) setError({ error, key: Math.random() });
    if (response) setTokenData(response);
    dispatch(setPageLoading(false));
  };

  const navToViewIssue = () => navigate(`${RoutesNames.ViewIssues}?id=${tokenData.id}`);

  const queryAfterTx = async () => {
    await buyOrders.getMarketOrders();
    await sellOrders.getMarketOrders();
  };

  const handleSetOnlyMyOrders = (checked: boolean) => {
    buyOrders.setOnlyMyOrders(checked);
    sellOrders.setOnlyMyOrders(checked);
  };

  useEffect(() => {
    getTokenDataById();
  }, []);

  useEffect(() => {
    dispatch(
      setHeader({
        title: "Просмотр токена",
        subTitle: tokenData?.ticker,
        badge: {
          label: `${tokenData?.interest} %`,
          color: "success",
        },
        pageBackBtn: {
          label: "Назад",
        },
      })
    );
    return () => {
      dispatch(resetHeader());
    };
  }, [tokenData]);
  return (
    <>
      {tokenData ? (
        <Flex sx={{ direction: "column", rowGap: 1, width: "100%", mt: 2 }}>
          <Flex
            sx={{ columnGap: 1, align: "center" }}
            adaptiveSX={{ laptop: { wrap: "wrap", rowGap: 1, width: "100%" } }}
          >
            <InfoCard
              title="Номинал"
              value={`${amountWithSpaces(tokenData.nominal)} RUB`}
              type="diamond"
            />
            <InfoCard title="Эмитент" value={tokenData.issuer} type="corporateFareIcon" />
            <InfoCard
              title="Дата погашения"
              value={formattingDate(tokenData.redemptionDate)}
              type="event"
            />
            <InfoCard
              title="Объем выпуска"
              // TODO: Точно это объем выпуска?
              value={`${amountWithSpaces(tokenData.amount * tokenData.nominal)} RUB`}
              type="toll"
            />
            <Button
              adaptiveSX={{ laptop: { display: "none" } }}
              variant="link"
              iconStart={<InfoIcon size="tinySmall" color="info" />}
              sx={{ width: "100%", justify: "flex-start" }}
              onClick={navToViewIssue}
            >
              Детальная информация о выпуске
            </Button>
          </Flex>
          <Flex sx={{ align: "center" }}>
            <Checkbox
              name="OnlyMyOrders"
              label="Показать только мои заявки"
              onChange={handleSetOnlyMyOrders}
              checked={buyOrders.onlyMyOrders}
              sx={{ minWidth: "max-content" }}
            />
            {windowWidth <= Breakpoints.laptop && (
              <Button
                variant="link"
                iconStart={<InfoIcon size="tinySmall" color="info" />}
                sx={{ width: "100%", justify: "flex-end" }}
              >
                Посмотреть подробную информацию
              </Button>
            )}
          </Flex>
          <Flex
            sx={{ width: "100%", columnGap: 1, mt: 1 }}
            adaptiveSX={{ tablet: { direction: "column", rowGap: 1 } }}
          >
            <Flex
              sx={{ direction: "column", rowGap: 1, width: "50%" }}
              adaptiveSX={{ tablet: { width: "100%" } }}
            >
              <Flex sx={{ align: "center", columnGap: 1, width: "100%", minHeight: "40px" }}>
                <Text variant="h5">Заявки на покупку</Text>
                <Button onClick={() => newOrderModalState.onOpenOrderModal("buy")} color="success">
                  Купить
                </Button>
                {buyOrders.isLoading && <CircleLoader color="success" size={50} />}
              </Flex>
              {buyOrders?.rows?.length ? (
                <Flex sx={{ width: "100%", mt: 1, direction: "column", rowGap: 2 }}>
                  <Flex sx={{ overflow: "auto", maxWidth: "100%" }}>
                    <Table
                      rowHeight={60}
                      titleRowSx={{ rowHeight: 60 }}
                      columns={buyOrders.columns.current}
                      rows={buyOrders.rows}
                      currentSortOption={buyOrders.currentSortOption}
                    />
                  </Flex>
                  <Paginator
                    page={buyOrders.page}
                    setPage={buyOrders.setPage}
                    amount={buyOrders.pagesAmount}
                  />
                </Flex>
              ) : (
                !buyOrders.isLoading && <NoData sx={{ mt: 2 }} text="Нет заявок на покупку" />
              )}
            </Flex>
            <Flex
              sx={{ direction: "column", rowGap: 1, width: "50%" }}
              adaptiveSX={{ tablet: { width: "100%" } }}
            >
              <Flex sx={{ align: "center", columnGap: 1, width: "100%", minHeight: "40px" }}>
                <Text variant="h5">Заявки на продажу</Text>
                {tokenData.isInMyBriefcase && (
                  <Button onClick={() => newOrderModalState.onOpenOrderModal("sell")} color="error">
                    Продать
                  </Button>
                )}
                {sellOrders.isLoading && <CircleLoader color="error" size={50} />}
              </Flex>
              {sellOrders?.rows?.length ? (
                <Flex sx={{ width: "100%", mt: 1, direction: "column", rowGap: 2 }}>
                  <Flex sx={{ overflow: "auto", maxWidth: "100%" }}>
                    <Table
                      rowHeight={60}
                      titleRowSx={{ rowHeight: 60 }}
                      columns={sellOrders.columns.current}
                      rows={sellOrders.rows}
                      currentSortOption={sellOrders.currentSortOption}
                    />
                  </Flex>
                  <Paginator
                    page={sellOrders.page}
                    setPage={sellOrders.setPage}
                    amount={sellOrders.pagesAmount}
                  />
                </Flex>
              ) : (
                !sellOrders.isLoading && <NoData sx={{ mt: 2 }} text="Нет заявок на продажу" />
              )}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        !pageLoading && (
          <NoData icon={WarningIcon} type="warning" text="Не удалось получить данные токена" />
        )
      )}
      <NewOrderModal
        open={!!newOrderModalState.orderModalType}
        onClose={newOrderModalState.onCloseOrderModal}
        type={newOrderModalState.orderModalType}
        tokenTicker={tokenData?.ticker}
        contract={tokenData?.smartContractAddress}
        tokenID={tokenData?.id}
        queryAfterTx={queryAfterTx}
      />
      <OrderInfoModal
        open={!!orderInfoModalState.orderModalType}
        onClose={orderInfoModalState.onCloseOrderModal}
        type={orderInfoModalState.orderModalType}
        isMyOrder={orderInfoModalState.isMyOrder}
        token={tokenData}
        marketOrderId={orderInfoModalState.marketOrderId}
        marketOrderData={orderInfoModalState.marketOrderData}
        isLoading={orderInfoModalState.isLoading}
        queryAfterTx={queryAfterTx}
      />
    </>
  );
};

export default ViewToken;
