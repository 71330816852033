import styled, { css } from "styled-components";
import { marginStyles, MarginType } from "Theme";

interface IDotProps {
  type: "outline" | "filled";
  sx?: MarginType;
}

const Dot = styled.span<IDotProps>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  ${({ theme, type }) => css`
    border: 1px solid ${theme.palette.text.contrast};
    background: ${type === "filled" ? theme.palette.text.contrast : "transparent"};
  `}
  ${({ sx }) => marginStyles(sx)}
`;

export default Dot;
