import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SortOptionGql,
  TokenStatusGql,
  TokensAPI,
  UserClientType,
} from "@front-packages/dfa-gql-api";
// import { SortOption, TokenStatusEnum } from "@front-packages/dfa-constants";
import { Button, FilterBar, Flex, NoData, Paginator } from "Theme";
import { useAppDispatch, useAppSelector } from "store";
import { resetHeader, setHeader, setPageLoading } from "store/global";
import { ISortOption } from "Theme/components/Filterbar";
import { RoutesNames } from "routes";
import { useErrors } from "hooks";
import { Breakpoints } from "constants/in_lib";
import { setIssuesRedeemFilter } from "store/account";
import IssuerTokenCard from "./IssuerTokenCard";
import { IFilterProps, useTokensState } from "../hooks";
import SearchForm from "../components/SearchForm";

const IssuesIssuer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setError } = useErrors();
  const { fullName, businessEntityType } = useAppSelector((state) => state.customer.company);
  const { windowWidth, pageLoading } = useAppSelector((state) => state.global);
  const { isAccountIssuer, issuesRedeemFilter } = useAppSelector((state) => state.account);
  const [loading, setLoading] = useState<boolean>(false);
  const { tokens, getTokens } = useTokensState();
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<string>("10");
  const [sortOption, setSortOption] = useState<SortOptionGql>(
    SortOptionGql.TickerAlphabeticalOrder
  );

  const initialFilterProps: IFilterProps = {
    issuer: undefined,
    tokenStatuses: isAccountIssuer && issuesRedeemFilter ? [TokenStatusGql.InRedeem] : undefined,
    ticker: undefined,
    issueAmount: undefined,
    startDate: undefined,
    completionDate: undefined,
  };
  const [filterProps, setFilterProps] = useState<IFilterProps>(initialFilterProps);
  const firstCount = useRef<number>(null);
  const memoryFilterProps = useRef<IFilterProps>(initialFilterProps);

  const goToEditIssue = async () => {
    setLoading(true);
    const { response, error } = await TokensAPI.CreateToken();
    if (error) setError({ error, key: Math.random() });
    setLoading(false);
    if (response) navigate(`${RoutesNames.EditIssues}?id=${response.id}`);
  };
  const handleGetTokens = async (opts: IFilterProps) => {
    dispatch(setPageLoading(true));
    memoryFilterProps.current = opts;
    await getTokens({
      page,
      count: +count,
      clientType: UserClientType.Emitent,
      sortOption,
      // TODO: Genapi
      ...(opts as any),
    });
    dispatch(setPageLoading(false));
  };

  useEffect(() => {
    getTokens({
      page,
      count: +count,
      clientType: UserClientType.Emitent,
      sortOption,
      // TODO: Genapi
      ...(memoryFilterProps.current as any),
    }).then((respCount) => {
      if (firstCount.current === null) firstCount.current = respCount;
      dispatch(setPageLoading(false));
    });
  }, [page, sortOption, count]);
  useEffect(() => {
    dispatch(
      setHeader({ title: "Мои выпуски", subTitle: `Эмитент: ${businessEntityType} "${fullName}"` })
    );
    return () => {
      dispatch(resetHeader());
    };
  }, []);
  useEffect(() => {
    return () => {
      dispatch(setIssuesRedeemFilter(false));
    };
  }, []);

  const sortOptions: Array<ISortOption> = [
    {
      type: "alpha",
      label: "Тикер",
      value: {
        normal: SortOptionGql.TickerAlphabeticalOrder,
        revert: SortOptionGql.TickerReverseAlphabeticalOrder,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.TickerAlphabeticalOrder
            ? SortOptionGql.TickerReverseAlphabeticalOrder
            : SortOptionGql.TickerAlphabeticalOrder
        );
      },
    },
    {
      type: "number",
      label: "Сумма выпуска",
      value: {
        normal: SortOptionGql.TokenAmountAscending,
        revert: SortOptionGql.TokenAmountDescending,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.TokenAmountAscending
            ? SortOptionGql.TokenAmountDescending
            : SortOptionGql.TokenAmountAscending
        );
      },
    },
    {
      type: "any",
      label: "Дата старта",
      value: {
        normal: SortOptionGql.StartDateEarly,
        revert: SortOptionGql.StartDateLate,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.StartDateEarly
            ? SortOptionGql.StartDateLate
            : SortOptionGql.StartDateEarly
        );
      },
    },
    {
      type: "any",
      label: "Дата завершения",
      value: {
        normal: SortOptionGql.FinishDateEarly,
        revert: SortOptionGql.FinishDateLate,
      },
      onClick() {
        setSortOption(
          sortOption === SortOptionGql.FinishDateEarly
            ? SortOptionGql.FinishDateLate
            : SortOptionGql.FinishDateEarly
        );
      },
    },
  ];

  return (
    <>
      <Flex
        sx={{
          width: "100%",
          justify: "space-between",
          align: "center",
          mb: 1,
          minHeight: "70px",
        }}
        adaptiveSX={{ tabletL: { direction: "column" } }}
      >
        <Button loading={loading} onClick={goToEditIssue} adaptiveSX={{ laptop: { mr: "auto" } }}>
          Создать новый выпуск
        </Button>
        {!!firstCount.current && (
          <SearchForm state={filterProps} setState={setFilterProps} getTokens={handleGetTokens} />
        )}
      </Flex>
      {!!firstCount.current && !!tokens?.count && (
        <Flex
          sx={{
            direction: "column",
            gap: 2,
            align: "flex-start",
            width: "100%",
            wrap: "wrap",
            flex: "1 1 0%",
          }}
        >
          <FilterBar
            options={sortOptions}
            current={sortOption}
            count={count}
            setCount={setCount}
            isTablet={windowWidth <= Breakpoints.tablet}
          />
          <Flex sx={{ width: "100%", gap: 1, wrap: "wrap" }}>
            {tokens?.tokens &&
              tokens.tokens.map((token) => <IssuerTokenCard key={token.id} token={token} />)}
          </Flex>
          {!!tokens?.count && (
            <Paginator
              page={page}
              amount={Math.ceil(tokens.count / +count)}
              setPage={setPage}
              withInput
            />
          )}
        </Flex>
      )}
      {!pageLoading && (!firstCount.current || !tokens?.count) && (
        <NoData
          text={!firstCount.current ? "Нет выпусков" : "Выпуски по вашему запросу не найдены"}
        />
      )}
    </>
  );
};

export default IssuesIssuer;
