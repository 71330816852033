import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const ReportIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  contrast,
  opacity,
  sx,
}) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        opacity={opacity ? opacity / 100 : undefined}
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        fill="currentColor"
        viewBox="0 96 960 960"
      >
        <path d="M480 792q19.375 0 32.688-13.312Q526 765.375 526 746q0-19-13.312-33-13.313-14-32.688-14t-32.688 14Q434 727 434 746t13.312 32.5Q460.625 792 480 792Zm-36-140h72V354h-72v298ZM320 961 95 736.176V415l224.824-225H641l225 224.824V736L641.176 961H320Zm38.616-94H601l170-169.616V455L601.384 285H359L189 454.616V697l169.616 170ZM480 576Z" />
      </svg>
    </Text>
  );
};

export default ReportIcon;
