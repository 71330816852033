import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FilesAPI,
  File,
  Token,
  TokenForm,
  TokensAPI,
  TokenTypeGql,
  TokenRightsTypeGql,
  FileDocumentType,
} from "@front-packages/dfa-gql-api";
import { useErrors, useFiles, UseFilesResult } from "hooks";
import { useAppDispatch } from "store";
import { setHeader, setPageLoading } from "store/global";
import { getStatusBadgeProps } from "helpers";

type SetStateType<T> = (value: T) => void;
type ResultType = {
  state: {
    tokenDefault: Token;
    tokenForm?: TokenForm;
  };
  setState?: {
    maxIssueLimit: SetStateType<number>;
    minIssueLimit: SetStateType<number>;
    nominal: SetStateType<number>;
    investStartDate: SetStateType<Date>;
    investFinishDate: SetStateType<Date>;
    minOrderLimit: SetStateType<number>;
    maxOrderLimit: SetStateType<number>;
    interest: SetStateType<number>;
    plannedPublicDate: SetStateType<Date>;
    tokenType: SetStateType<TokenTypeGql>;
    redemptionDate: SetStateType<Date>;
    redemptionFinishDate: SetStateType<Date>;
    placementPeriod: SetStateType<number>;
    rightsType: SetStateType<TokenRightsTypeGql>;
    provision: SetStateType<string>;
    limits: SetStateType<string>;
    issueTerms: SetStateType<string>;
    scopeRights: SetStateType<string>;
    amount: SetStateType<number>;
    plannedPublicAndStartDate: SetStateType<Date>;
    marketDate: SetStateType<Date>;
  };
  loaders?: {
    edit: boolean;
  };
  allowForVerification: boolean;
  isEdited?: boolean;
  getToken(): Promise<void>;
  saveEditForm?(): Promise<boolean>;
  reset?(): void;
  files: UseFilesResult;
  loadedFiles: File[];
};
export type UseStateEditIssueType = (isView?: boolean) => ResultType;

export const useStateEditIssue: UseStateEditIssueType = (isView) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { setError } = useErrors();
  // const { businessEntityType } = useAppSelector((state) => state.customer.company);
  const files = useFiles();
  const [token, setToken] = useState<Token>(null);
  const [amount, setAmount] = useState<number>(null);
  const [maxIssueLimit, setMaxIssueLimit] = useState<number>(null);
  const [minIssueLimit, setMinIssueLimit] = useState<number>(null);
  const [nominal, setNominal] = useState<number>(null);
  const [investStartDate, setInvestStartDate] = useState<Date>(null);
  const [investFinishDate, setInvestFinishDate] = useState<Date>(null);
  const [minOrderLimit, setMinOrderLimit] = useState<number>(null);
  const [maxOrderLimit, setMaxOrderLimit] = useState<number>(null);
  const [interest, setInterest] = useState<number>(null);
  const [plannedPublicDate, setPlannedPublicDate] = useState<Date>(null);
  const [tokenType, setTokenType] = useState<TokenTypeGql>(null);
  const [redemptionDate, setRedemptionDate] = useState<Date>(null);
  const [redemptionFinishDate, setRedemptionFinishDate] = useState<Date>(null);
  const [marketDate, setMarketDate] = useState<Date>(null);
  const [placementPeriod, setPlacementPeriod] = useState<number>(null);
  const [rightsType, setRightsType] = useState<TokenRightsTypeGql>(null);
  const [provision, setProvision] = useState<string>(null);
  const [limits, setLimits] = useState<string>(null);
  const [issueTerms, setIssueTerms] = useState<string>(null);
  const [scopeRights, setScopeRights] = useState<string>(null);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [loadedFiles, setLoadedFiles] = useState<File[]>(null);

  const setTokenForm = (initial: Token) => {
    // todo: изменить значение TokenTypeEnum.TokenDVP на initial.tokenType, кодга потребуется
    setTokenType(TokenTypeGql.TokenDvp);
    setMaxIssueLimit(initial.maxIssueLimit);
    setMinIssueLimit(initial.minIssueLimit);
    setNominal(initial.nominal);
    setMinOrderLimit(initial.minOrderLimit);
    setMaxOrderLimit(initial.maxOrderLimit);
    setInterest(initial.interest);
    // TODO: Genapi
    setRightsType(initial.rightsType || TokenRightsTypeGql.Dfa);
    setProvision(initial.provision);
    setLimits(initial.limits);
    setIssueTerms(initial.issueTerms);
    setScopeRights(initial.scopeRights);
    if (initial?.investStartDate) setInvestStartDate(new Date(initial.investStartDate));
    if (initial?.investFinishDate) setInvestFinishDate(new Date(initial.investFinishDate));
    if (initial?.plannedPublicDate) setPlannedPublicDate(new Date(initial.plannedPublicDate));
    if (initial?.redemptionDate) setRedemptionDate(new Date(initial.redemptionDate));
    if (initial?.redemptionFinishDate)
      setRedemptionFinishDate(new Date(initial.redemptionFinishDate));
    if (initial?.marketDate) setMarketDate(new Date(initial.marketDate));
  };

  const handleSetRightsToken = (value: TokenRightsTypeGql) => setRightsType(value || null);
  const handleSetNominal = (value: number) => setNominal(value || null);
  const handleSetMinIssueLimit = (value: number) => setMinIssueLimit(value || null);
  const handleSetMaxIssueLimit = (value: number) => setMaxIssueLimit(value || null);
  const handleSetInterest = (value: number) => setInterest(value || null);
  const handleSetScopeRights = (value: string) => setScopeRights(value || null);
  const handleSetProvision = (value: string) => setProvision(value || null);
  const handleSetLimits = (value: string) => setLimits(value || null);
  const handleSetIssueTerms = (value: string) => setIssueTerms(value || null);
  const handleSetPlannedPublicAndStartDate = (date: Date) => {
    setInvestStartDate(date || null);
    setPlannedPublicDate(date || null);
  };
  const handleSetAmount = (value: number) => {
    setAmount(value);
    if (nominal) setMaxIssueLimit(value * nominal);
  };
  const handleSetTokenType = (value: TokenTypeGql) => {
    setTokenType(value);
  };

  const getTokenFiles = async (tokenID: string) => {
    const { response, error } = await FilesAPI.GetTokenFiles({ tokenID });
    if (error) setError({ error, key: Math.random() });
    if (response) setLoadedFiles(response);
  };

  const getToken = async (notSetHeader?: boolean) => {
    dispatch(setPageLoading(true));
    const tokenID = search.split("=")[1];
    const { response, error } = await TokensAPI.GetTokenByID({ tokenID });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setToken(response);
      if (!notSetHeader) {
        setTokenForm(response);
        dispatch(
          setHeader({
            // TODO: не удалять
            // title: `${isView ? "Выпуск" : "Редактирование"} ${response.ticker.toUpperCase()}`,
            title: `${isView ? "Информация о выпуске" : "Редактирование выпуска"}`,
            subTitle: response?.ticker,
            // subTitle: `${businessEntityType} "${response.issuer}"`,
            // TODO: не удалять
            badge: getStatusBadgeProps(response.status, "Token"),
            // badgePosition: "title",
          })
        );
      }
    }
    await getTokenFiles(tokenID);
  };
  const saveEditForm = async (): Promise<boolean> => {
    setEditLoading(true);
    const { response, error } = await TokensAPI.EditToken({
      tokenID: token.id,
      tokenForm: {
        rightsType: rightsType || undefined,
        amount: amount || undefined,
        investStartDate: investStartDate || undefined,
        investFinishDate: investFinishDate || undefined,
        plannedPublicDate: plannedPublicDate || undefined,
        redemptionDate: redemptionDate || undefined,
        redemptionFinishDate:
          redemptionFinishDate ||
          new Date(new Date(redemptionDate).getTime() + 864e5 * 30) ||
          undefined,
        // redemptionFinishDate: redemptionDate
        //   ? new Date(new Date(redemptionDate).getTime() + 864e5 * 30)
        //   : redemptionFinishDate || undefined,
        marketDate: marketDate || undefined,
        maxIssueLimit: maxIssueLimit || undefined,
        minIssueLimit: minIssueLimit || undefined,
        nominal: nominal || undefined,
        minOrderLimit: minOrderLimit || undefined,
        maxOrderLimit: maxOrderLimit || undefined,
        interest: interest || undefined,
        tokenType: tokenType || undefined,
        provision: provision || undefined,
        limits: limits || undefined,
        issueTerms: issueTerms || undefined,
        scopeRights: scopeRights || undefined,
      },
      files: Object.values(files.data).length
        ? Object.values(files.data).map((file) => {
            // TODO: Возможно, потом надо будет documentType добавить в АПИ по загрузке файла
            return {
              key: file.key,
              name: file.name,
              documentType: FileDocumentType.TokenIssueTerms,
            };
          })
        : [],
    });
    if (error) {
      await getToken(true);
      setError({ error, key: Math.random() });
    }
    setEditLoading(false);
    if (response) {
      await getToken(true);
      files.reset();
    }
    dispatch(setPageLoading(false));
    return !!response;
  };
  const editedInvStartDate: any =
    typeof investStartDate === "string" || !investStartDate
      ? investStartDate
      : investStartDate?.toISOString().replace(".000", "");
  const editedInvFinishDate: any =
    typeof investFinishDate === "string" || !investFinishDate
      ? investFinishDate
      : investFinishDate?.toISOString().replace(".000", "");
  const editedPlannedDate: any =
    typeof plannedPublicDate === "string" || !plannedPublicDate
      ? plannedPublicDate
      : plannedPublicDate?.toISOString().replace(".000", "");
  const editedRedemtionDate: any =
    typeof redemptionDate === "string" || !redemptionDate
      ? redemptionDate
      : redemptionDate?.toISOString().replace(".000", "");

  useEffect(() => {
    if (maxIssueLimit && nominal) setAmount(maxIssueLimit / nominal);
  }, [maxIssueLimit, nominal]);
  return {
    state: {
      tokenDefault: token,
      tokenForm: isView
        ? undefined
        : {
            amount,
            maxIssueLimit,
            minIssueLimit,
            nominal,
            investStartDate,
            investFinishDate,
            minOrderLimit,
            maxOrderLimit,
            interest,
            plannedPublicDate,
            tokenType,
            redemptionDate,
            redemptionFinishDate,
            placementPeriod,
            rightsType,
            provision,
            limits,
            issueTerms,
            scopeRights,
            marketDate,
          },
    },
    loaders: isView
      ? undefined
      : {
          edit: editLoading,
        },
    setState: isView
      ? undefined
      : {
          maxIssueLimit: handleSetMaxIssueLimit,
          minIssueLimit: handleSetMinIssueLimit,
          nominal: handleSetNominal,
          investStartDate: setInvestStartDate,
          investFinishDate: setInvestFinishDate,
          minOrderLimit: setMinOrderLimit,
          maxOrderLimit: setMaxOrderLimit,
          interest: handleSetInterest,
          plannedPublicDate: setPlannedPublicDate,
          tokenType: handleSetTokenType,
          redemptionDate: setRedemptionDate,
          redemptionFinishDate: setRedemptionFinishDate,
          placementPeriod: setPlacementPeriod,
          rightsType: handleSetRightsToken,
          provision: handleSetProvision,
          limits: handleSetLimits,
          issueTerms: handleSetIssueTerms,
          scopeRights: handleSetScopeRights,
          amount: handleSetAmount,
          plannedPublicAndStartDate: handleSetPlannedPublicAndStartDate,
          marketDate: setMarketDate,
        },
    getToken,
    saveEditForm,
    files,
    loadedFiles,
    reset() {
      setTokenForm(token);
    },
    allowForVerification:
      token &&
      !Object.keys(token).filter(
        (key) =>
          key !== "smartContractAddress" &&
          key !== "redemptionFinishDate" &&
          key !== "representativeID" &&
          key !== "createDate" &&
          token[key] === null
      ).length,
    isEdited: isView
      ? undefined
      : editedInvStartDate !== token?.investStartDate ||
        editedInvFinishDate !== token?.investFinishDate ||
        editedPlannedDate !== token?.plannedPublicDate ||
        editedRedemtionDate !== token?.redemptionDate ||
        maxIssueLimit !== token?.maxIssueLimit ||
        minIssueLimit !== token?.minIssueLimit ||
        nominal !== token?.nominal ||
        minOrderLimit !== token?.minOrderLimit ||
        maxOrderLimit !== token?.maxOrderLimit ||
        interest !== token?.interest ||
        tokenType !== token?.tokenType ||
        provision !== token?.provision ||
        limits !== token?.limits ||
        issueTerms !== token?.issueTerms ||
        scopeRights !== token?.scopeRights,
  };
};
