import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const PDFIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm12 6V9c0-.55-.45-1-1-1h-2v5h2c.55 0 1-.45 1-1zm-2-3h1v3h-1V9zm4 2h1v-1h-1V9h1V8h-2v5h1zm-8 0h1c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1H9v5h1v-2zm0-2h1v1h-1V9z" />
      </svg>
    </Text>
  );
};

export default PDFIcon;
