export const downloadHelper = (url: string, name: string) => {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", name);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadTextHelper = (fileName: string, text: string) => {
  downloadHelper(`data:none/plain;charset=utf-8,${encodeURIComponent(text)}`, fileName);
};

export const downloadBlobHelper = (blob: Blob, name: string) => {
  const url = URL.createObjectURL(blob);
  downloadHelper(url, name);
};
