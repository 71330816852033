import styled, { css } from "styled-components";
import { FlexType, MarginType, SizeType } from "../../types";
import { flexStyles, marginStyles, sizeStyles } from "../../styles";

export interface IStyledCheckbox {
  sx?: MarginType & SizeType & FlexType;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const StyledLabel = styled.label<IStyledCheckbox>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  width: max-content;
  ${({ theme, disabled, fullWidth }) => css`
    width: ${fullWidth && "100%"};
    pointer-events: ${disabled && "none"};
    user-select: ${disabled && "none"};
    color: ${disabled ? `${theme.palette.primary.main}50` : theme.palette.primary.main};
    & svg {
      fill: ${disabled ? `${theme.palette.primary.main}50` : theme.palette.primary.main};
    }
    &:hover {
      color: ${!disabled && theme.palette.primary.light};
      svg {
        fill: ${!disabled && theme.palette.primary.light};
      }
    }
  `}
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ sx }) => flexStyles(sx)};
`;
export const StyledCheckbox = styled.input`
  display: none;
`;
