import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { DirectSecp256k1HdWallet, EncodeObject } from "@cosmjs/proto-signing";
import * as tx3 from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { TransactionsAPI } from "@front-packages/dfa-gql-api";
import { TxStatusDict } from "@front-packages/dfa-constants";
import { delay } from "@front-packages/dfa-helpers";
import { useAppDispatch, useAppSelector } from "store";
import { Button, Dialog, MFADialog } from "Theme";
import { useErrors } from "hooks";
import { getWalletFromFileKey } from "helpers";
import { addNotification } from "store/notifications";
import { setPageLoading } from "store/global";
import getTxClient from "TxClient";
import useDialogState from "../useDialogState";

export type GetEntityTxType = (address: string) => EncodeObject;
export interface ITxParams {
  getMessage: GetEntityTxType;
  ids: {
    tokenID?: string;
    orderID?: string;
    withdrawOrderID?: string;
  };
}
interface ISigningDialogProps extends ITxParams {
  close(): void;
  open: boolean;
  queryAfterTx?(): Promise<void>;
  operationName?: string;
}

const SigningDialog: React.FC<ISigningDialogProps> = ({
  getMessage,
  ids,
  close,
  open,
  queryAfterTx,
  operationName,
}) => {
  const dispatch = useAppDispatch();
  const { wallet: customerWallet } = useAppSelector((state) => state.customer.company);
  const { state, getMfa, setState, cleanMfa } = useDialogState();
  const { mfa, loading } = state;
  const [wallet, setWallet] = useState<DirectSecp256k1HdWallet>(null);
  const [btnName, setBtnName] = useState<string>("выбрать приватный ключ");
  const [hash, setHash] = useState<string>(null);
  const [failGetWallet, setFailGetWallet] = useState<boolean>(false);
  const label = useRef<HTMLLabelElement>(null);
  const { setError } = useErrors();

  const handleClose = () => {
    setState.loading(false);
    cleanMfa();
    setWallet(null);
    setHash(null);
    setBtnName("выбрать приватный ключ");
    setFailGetWallet(false);
    if (open) close();
  };
  const handleClick = () => label.current.click();
  const getSuccessOperationName = () => {
    if (operationName) return operationName;
    if (ids?.orderID) return "по подписанию заявки";
    if (ids?.tokenID) return "по подписанию выпуска токена";
    if (ids?.withdrawOrderID) return "по созданию заякви на вывод средств";
    return "";
  };

  const getOperationId = () => {
    // TODO: Точно ли правильно, что может быть и tokenID и orderID???
    if (ids?.tokenID && ids?.orderID) return ids.tokenID;
    if (ids?.tokenID) return ids.tokenID;
    if (ids?.orderID) return ids.orderID;
    if (ids?.withdrawOrderID) return ids.withdrawOrderID;
    return "";
  };

  const getEntityType = () => {
    // TODO: Уточнить у мидла наименование. Точно ли правильно, что может быть и tokenID и orderID???
    if (ids?.tokenID && ids.orderID) return "dvp_token_order";
    if (ids?.tokenID) return "token";
    // if (ids?.orderID) {
    //   return !isMarket ? "dvp_token_order" : "market";
    // }
    if (ids?.orderID) return "dvp_token_order";
    if (ids?.withdrawOrderID) return "payment_details";
    return "common";
  };

  const checkTxStatus = async (): Promise<{ isCompleted: boolean; isFailed: boolean }> => {
    let isCompleted = false;
    let isFailed = false;
    const { response, error } = await TransactionsAPI.GetTxLogByHash({ hash });
    if (error) {
      setError({ error, key: Math.random() });
      setState.loading(false);
      return { isCompleted: false, isFailed: false };
    }
    if (response.status === TxStatusDict.InProgress || response.status === TxStatusDict.OnHold) {
      await delay(5000);
      const resp = await checkTxStatus();
      isFailed = resp.isFailed;
      isCompleted = resp.isCompleted;
    } else {
      isCompleted = response.status === TxStatusDict.Completed;
      isFailed = response.status === TxStatusDict.Failed;
    }
    return { isCompleted, isFailed };
  };
  const getWallet = async (e: ChangeEvent<HTMLInputElement>) => {
    setState.loading(true);
    setFailGetWallet(false);
    const fileNameArr = e.target.files[0]?.name.split(".");
    if (fileNameArr?.length === 1 || fileNameArr?.[fileNameArr.length - 1] === "stsoft") {
      setBtnName("подпись установлена");
      const newWallet = await getWalletFromFileKey(e.target.files[0]);
      if (newWallet) {
        const [acc] = await newWallet.getAccounts();
        if (acc.address !== customerWallet?.address) setFailGetWallet(true);
        else setWallet(newWallet);
      } else setFailGetWallet(true);
    } else {
      setBtnName("Неверная подпись. Повторите попытку.");
      setFailGetWallet(true);
    }
    setState.loading(false);
  };
  const getTx = async () => {
    try {
      const client = await getTxClient(wallet);
      const [acc] = await wallet.getAccounts();
      const fee = {
        gas: "10000000",
        amount: [
          {
            denom: "stake",
            amount: "0",
          },
        ],
      };
      const msgValue = getMessage(acc.address);
      const result = await client.sign(acc.address, [msgValue], fee, "");
      return btoa(String.fromCharCode.apply(null, tx3.TxRaw.encode(result).finish()));
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const sign = async () => {
    setState.loading(true);
    const tx = await getTx();

    if (tx) {
      const { response, error } = await TransactionsAPI.SendBroadcastTx({
        data: {
          mfaCode: mfa.code,
          mfaUUID: mfa.sessionID,
          params: {
            tx,
            ...ids,
          },
        },
      });
      if (error) {
        setError({ error, key: Math.random() });
        setState.loading(false);
        cleanMfa();
      }
      if (response) {
        dispatch(
          addNotification({
            message: "Подписание прошло успешно. Пожалуйста дождитесь исполнения транзакции.",
            type: "success",
            position: "right",
          })
        );
        setHash(response.result.hash);
      }
    } else {
      console.error("Не удалось сформировать транзакцию для подписания токена");
      setState.loading(false);
    }
  };

  useEffect(() => {
    if (hash)
      checkTxStatus().then(({ isCompleted, isFailed }) => {
        if (isCompleted)
          dispatch(
            addNotification({
              message: `Операция ${getSuccessOperationName()} прошла успешно`,
              type: "success",
              position: "right",
            })
          );
        if (isFailed)
          dispatch(
            addNotification({
              message: `Операция ${getSuccessOperationName()} отклонена`,
              type: "error",
              position: "right",
            })
          );
        if (isCompleted && queryAfterTx)
          queryAfterTx()
            .then(() => handleClose())
            .then(() => dispatch(setPageLoading(false)));
        else handleClose();
      });
  }, [hash]);

  if (mfa)
    return (
      <MFADialog
        open
        withMissClick={false}
        type="info"
        maxWidth="md"
        onClose={loading ? handleClose : cleanMfa}
        cancelText="назад"
        onConfirm={sign}
        setCode={setState.code}
        code={mfa.code}
        loading={loading}
        descOperation={`Выполняется операция ${getSuccessOperationName()}`}
      />
    );

  return (
    <Dialog
      open={open}
      title="Подписание"
      // TODO: мидл будет меняться
      onConfirm={() => getMfa(getEntityType(), getOperationId(), "SendBroadcastTx")}
      confirmText="подписать"
      cancelText="закрыть"
      onClose={handleClose}
      maxWidth="sm"
      loading={loading}
      disabled={!wallet}
      type="info"
    >
      <label ref={label} htmlFor="sign" id="forSign">
        <Button
          variant="outline"
          onClick={handleClick}
          sx={{ width: "100%" }}
          color={failGetWallet ? "error" : "info"}
          loading={loading}
          disabled={!!wallet}
        >
          {failGetWallet ? "Неверная подпись. Повторите попытку." : btnName}
        </Button>
        {/* <input accept=".stsoft" type="file" name="sign" id="sign" hidden onChange={getWallet} /> */}
        <input type="file" name="sign" id="sign" hidden onChange={getWallet} />
      </label>
    </Dialog>
  );
};

export default SigningDialog;
