import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const UnknownIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  light,
  contrast,
  sx,
  opacity,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
    >
      <svg
        opacity={opacity ? opacity / 100 : undefined}
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        fill="currentColor"
        viewBox="0 96 960 960"
      >
        <path d="M655 975q-80.51 0-137.255-56.745Q461 861.51 461 781q0-80.51 56.745-137.255Q574.49 587 655 587q80.51 0 137.255 56.745Q849 700.49 849 781q0 80.51-56.745 137.255Q735.51 975 655 975Zm1.135-49Q671 926 681 916.054q10-9.946 10-25.5T680.865 865.5q-10.135-9.5-25-9.5T631 865.878q-10 9.878-10 24.365Q621 906 631.135 916q10.135 10 25 10ZM630 827h52v-12.952Q682 803 688.5 793q6.5-10 15.227-17.4 15.806-13.541 26.54-28.071Q741 733 741 709.143q0-34.502-23.802-56.823Q693.396 630 655.813 630 627 630 603 648q-24 18-33 46.486L618.343 716Q620 699.6 631 689.3t25.927-10.3q13.921 0 23.497 8T690 709q0 10-7 19t-15 15.571Q661 749 651 758t-15 18.815q-4 8.362-5 15.367-1 7.006-1 16.347V827ZM502 431h189L502 245l189 186-189-186v186Zm-313 570q-39.8 0-66.9-27.1Q95 946.8 95 907V245q0-40.213 27.1-67.606Q149.2 150 189 150h363l234 234v175q-31-16-64.333-24-33.334-8-66.667-8-39.114 0-74.557 10.5Q545 548 514.628 567H279v71h166q-15 22-25.5 47.09Q409 710.18 405 737H279v71h123q7 60 38.5 110t83.5 83H189Z" />
      </svg>
    </Text>
  );
};

export default UnknownIcon;
