import React, { useRef } from "react";
import { Wrap, IconWrap, IIconWrapProps } from "./NoData";
import Text from "../Text";
import { UnknownIcon } from "../../icons";
import { IIcon } from "../../icons/default";

interface INoDataProps extends IIconWrapProps {
  text: string;
  icon?: React.FC<IIcon>;
}

const NoData: React.FC<INoDataProps> = ({ text, icon: Icon, ...props }) => {
  const iconProps = useRef<IIcon>({
    color: props?.type || "primary",
    size: "largest",
    light: true,
    opacity: 20,
  });
  return (
    <Wrap>
      <IconWrap {...props}>
        {Icon ? <Icon {...iconProps.current} /> : <UnknownIcon {...iconProps.current} />}
      </IconWrap>
      <Text
        sx={{ weight: "regular" }}
        opacity={70}
        variant="h5"
        color={props?.type || "primary"}
        light
      >
        {text}
      </Text>
    </Wrap>
  );
};

export default NoData;
