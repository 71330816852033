export default function (
  date: string | Date,
  withTime?: boolean,
  dateStyle?: "long" | "short"
): string {
  if (!date) return "";
  // TODO: При необходимости сделать кастомный dateStyle и для случаев с withTime
  if (!withTime) return new Date(date).toLocaleDateString("ru", { dateStyle: dateStyle || "long" });
  return `${new Date(date).toLocaleDateString("ru", { dateStyle: "short" })} ${new Date(
    date
  ).toLocaleTimeString("ru")}`;
}
