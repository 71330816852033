import React, { ReactNode } from "react";
import { IconWrap, IStyledButton, StyledButton } from "./Button";
import CircleLoader from "../CircleLoader";
import { ColorType } from "../../types";

interface IButtonProps extends IStyledButton {
  onClick?(e?: any): void;
  children?: ReactNode;
  loading?: boolean;
  onlyIcon?: boolean;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  title?: string;
  background?: ColorType;
}

const Button: React.FC<IButtonProps> = ({
  onClick,
  children,
  loading,
  disabled,
  variant,
  iconStart,
  iconEnd,
  onlyIcon,
  background,
  ...props
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled || loading}
      variant={onlyIcon ? "text" : variant}
      {...props}
    >
      {loading && (
        <CircleLoader
          size={30}
          color={props.color}
          sx={{ position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)" }}
        />
      )}
      {iconStart && (
        <IconWrap iconSx={props.sx} background={background} onlyIcon={onlyIcon}>
          {iconStart}
        </IconWrap>
      )}
      {children}
      {iconEnd && (
        <IconWrap iconSx={props.sx} isEnd onlyIcon={onlyIcon} background={background}>
          {iconEnd}
        </IconWrap>
      )}
    </StyledButton>
  );
};

export default Button;
