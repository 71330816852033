import styled from "styled-components";
import { MarginType } from "../../types";
import { marginStyles } from "../../styles";

export interface IStyledPaginator {
  sx?: MarginType;
}
export const Wrap = styled.div<IStyledPaginator>`
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  ${({ sx }) => marginStyles(sx)}
`;
export const Delimiter = styled.span`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.size.MD};
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const ActiveButton = styled.span<{ active: boolean; withMargin?: boolean }>`
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: ${({ withMargin }) => withMargin && "0.5rem"};
  background-image: ${({ theme, active }) => active && theme.palette.primary.gradient[10]};
`;
