import { useDispatch } from "react-redux";
import {
  AccountOperationAPI,
  CompaniesAPI,
  RepresentativeStatusGql,
  RepresentativesAPI,
  TransactionsAPI,
} from "@front-packages/dfa-gql-api";
import { deleteCookie } from "@front-packages/dfa-helpers";
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import {
  setBalance,
  setCompanyInfo,
  setPaymentDetails,
  setRepresentativeInfo,
} from "../../store/customer";
import { addNotification } from "../../store/notifications";
// import { useNotifications } from "../other";

type ReturnType = () => Promise<boolean>;
type UseGetInfoType = () => ReturnType;

const useGetInfo: UseGetInfoType = () => {
  const dispatch = useDispatch();
  // const { getNotifications } = useNotifications();

  const getInfo: ReturnType = async () => {
    const representative = await RepresentativesAPI.GetRepresentativeInfo();
    if (representative.error) {
      dispatch(
        addNotification({
          type: "error",
          message:
            "При попытке получить данные ПРЕДСТАВИТЕЛЯ, произошла непредвиденная ошибка на стороне сервера.",
        })
      );
      return false;
    }
    if (representative?.response) {
      dispatch(setRepresentativeInfo(representative.response));
      if (
        representative.response?.status === RepresentativeStatusGql.Deactivated ||
        representative.response?.status === RepresentativeStatusGql.Blocked
      ) {
        dispatch(
          addNotification({
            type: "error",
            message: `Данный представитель находится в статусе ${
              representative.response?.status === RepresentativeStatusGql.Deactivated
                ? "Деактивирован"
                : "Заблокирован"
            }.`,
          })
        );
        deleteCookie(CookieNamesEnum.token);
        deleteCookie(CookieNamesEnum.refreshToken);
        return false;
      }
      if (representative.response?.status === RepresentativeStatusGql.Active) {
        const company = await CompaniesAPI.GetCompanyInfo();
        const balance = await TransactionsAPI.GetBalance();
        // await getNotifications();
        if (company.error) {
          dispatch(
            addNotification({
              type: "error",
              message:
                "При попытке получить данные КОМПАНИИ, произошла непредвиденная ошибка на стороне сервера.",
            })
          );
          return false;
        }
        if (company.response) {
          dispatch(setCompanyInfo(company.response));
          const paymentDetails = await AccountOperationAPI.GetPaymentDetailsList({
            opts: {
              page: 0,
              count: 100,
              companyID: company.response.id,
            },
          });
          if (paymentDetails.error) {
            dispatch(
              addNotification({
                type: "error",
                message:
                  "При попытке получить данные БАНКОВСКИХ РЕКВИЗИТОВ, произошла непредвиденная ошибка на стороне сервера.",
              })
            );
            return false;
          }
          if (paymentDetails.response)
            dispatch(setPaymentDetails(paymentDetails.response.paymentDetails));
        }
        if (balance?.error)
          dispatch(
            addNotification({
              type: "error",
              message:
                "При попытке получить данные БАЛАНСА, произошла непредвиденная ошибка на стороне сервера.",
            })
          );
        if (balance?.response) dispatch(setBalance(balance.response));
      }
    }
    return true;
  };

  return getInfo;
};

export default useGetInfo;
