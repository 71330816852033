import React, { ForwardedRef, forwardRef } from "react";
import { IStyledGridItem, StyledGridItem } from "./GridItem";

interface IGridItemProps extends IStyledGridItem {
  children?: React.ReactNode;
  onClick?(e: any): void;
  onScroll?(e: any): void;
}

const GridItem = forwardRef(
  ({ children, ...props }: IGridItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <StyledGridItem ref={ref} {...props}>
        {children}
      </StyledGridItem>
    );
  }
);

export default GridItem;
