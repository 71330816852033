import React, { ForwardedRef, forwardRef } from "react";
import { IStyledGrid, StyledGrid } from "./Grid";

interface IGridProps extends IStyledGrid {
  children?: React.ReactNode;
  onClick?(e: any): void;
  onScroll?(e: any): void;
}

const Grid = forwardRef(({ children, ...props }: IGridProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <StyledGrid ref={ref} {...props}>
      {children}
    </StyledGrid>
  );
});

export default Grid;
