import { GeneralAPI, MfaParams } from "@front-packages/dfa-gql-api";
import { useState } from "react";
import { useErrors } from "hooks";

type UseLoadingAndMFAResultType = {
  state: {
    sum: string;
    mfa: MfaParams;
    loading: boolean;
  };
  setState: {
    sum(sum: string): void;
    code(code: string): void;
    loading(loading: boolean): void;
  };
  cleanMfa(): void;
  // TODO: Genapi EntityType
  getMfa(entityType: any, entityID: string, methodName: string): Promise<void>;
};
type UseLoadingAndMFAType = () => UseLoadingAndMFAResultType;

const useDialogState: UseLoadingAndMFAType = () => {
  const [sum, setSum] = useState("");
  const [mfa, setMfa] = useState<MfaParams>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setError } = useErrors();

  // TODO: Genapi EntityType
  const getMfa = async (entityType: any, entityID: string, methodName: string) => {
    setLoading(true);
    const { response, error } = await GeneralAPI.Request2FACode({
      entityType,
      entityID,
      methodName,
    });
    if (response) setMfa(response);
    if (error) setError({ error, key: Math.random() });
    setLoading(false);
  };

  return {
    getMfa,
    state: {
      sum,
      loading,
      mfa,
    },
    setState: {
      code(code: string) {
        setMfa({ ...mfa, code });
      },
      loading: setLoading,
      sum: setSum,
    },
    cleanMfa() {
      setMfa(null);
    },
  };
};

export default useDialogState;
