import React from "react";
import { IIcon } from "./default";
import { Text } from "../components";

interface IIssuerLogo extends IIcon {
  size?: "small" | "large";
  link?: string;
}

const IssuerLogo: React.FC<IIssuerLogo> = ({ link, size = "small", sx }) => {
  return (
    <Text link={link && link} sx={{ justify: "center", align: "center", ...sx }}>
      {size === "small" ? (
        // 224 36
        <svg xmlns="http://www.w3.org/2000/svg" width={208} height={41} fill="none">
          <rect width={100.133} height={35} y={2} fill="url(#a)" rx={17.5} />
          <path
            fill="#fff"
            d="M27.096 27.337V25h-9.842V12.327h2.698v10.298h5.985V12.327h2.717v10.298h1.14v4.712h-2.698ZM39.582 25h-2.698v-1.273c-1.938-.165-3.445-.684-4.522-1.558-1.064-.874-1.596-2.04-1.596-3.496 0-1.444.532-2.603 1.596-3.477 1.077-.874 2.584-1.4 4.522-1.577v-1.292h2.698v1.292c1.938.177 3.44.703 4.503 1.577 1.077.861 1.615 2.02 1.615 3.477 0 1.457-.538 2.622-1.615 3.496-1.064.874-2.565 1.393-4.503 1.558V25Zm2.49-4.56c.582-.456.873-1.045.873-1.767s-.291-1.311-.874-1.767c-.57-.456-1.4-.747-2.489-.874v5.282c1.09-.127 1.92-.418 2.49-.874Zm-7.677-3.534c-.57.456-.855 1.045-.855 1.767s.285 1.311.855 1.767c.57.456 1.4.747 2.49.874v-5.282c-1.09.127-1.92.418-2.49.874ZM58.797 25h-3.078l-.78-2.147h-5.433L48.708 25H45.63l4.902-12.673h3.382L58.797 25Zm-4.598-4.522-1.976-5.453-1.976 5.453h3.952Z"
          />
          <g filter="url(#b)">
            <path
              fill="#fff"
              d="M82.067 32.567c7.216 0 13.066-5.85 13.066-13.067 0-7.216-5.85-13.067-13.066-13.067C74.85 6.433 69 12.283 69 19.5c0 7.216 5.85 13.067 13.067 13.067Z"
            />
          </g>
          <path
            fill="#373737"
            d="M117.914 18.24c-2.027 0-3.72-.64-5.08-1.92-1.347-1.293-2.02-2.953-2.02-4.98 0-2.027.673-3.68 2.02-4.96 1.36-1.293 3.053-1.94 5.08-1.94 2.493 0 4.353 1.087 5.58 3.26l-2.44 1.2a3.584 3.584 0 0 0-1.3-1.38 3.25 3.25 0 0 0-1.84-.56c-1.214 0-2.214.413-3 1.24-.787.827-1.18 1.873-1.18 3.14 0 1.267.393 2.313 1.18 3.14.786.827 1.786 1.24 3 1.24.666 0 1.28-.18 1.84-.54a3.545 3.545 0 0 0 1.3-1.4l2.44 1.18c-1.267 2.187-3.127 3.28-5.58 3.28Zm10.01-.24h-2.74V4.66h2.84v8.62l6.18-8.62h2.92V18h-2.84V9.06l-6.36 8.94Zm18.291.24c-2.027 0-3.72-.64-5.08-1.92-1.347-1.293-2.02-2.953-2.02-4.98 0-2.027.673-3.68 2.02-4.96 1.36-1.293 3.053-1.94 5.08-1.94 2.493 0 4.353 1.087 5.58 3.26l-2.44 1.2a3.584 3.584 0 0 0-1.3-1.38 3.25 3.25 0 0 0-1.84-.56c-1.214 0-2.214.413-3 1.24-.787.827-1.18 1.873-1.18 3.14 0 1.267.393 2.313 1.18 3.14.786.827 1.786 1.24 3 1.24.666 0 1.28-.18 1.84-.54a3.545 3.545 0 0 0 1.3-1.4l2.44 1.18c-1.267 2.187-3.127 3.28-5.58 3.28Zm13.21-.24h-2.86V7.16h-3.9v-2.5h10.64v2.5h-3.88V18Zm15.141 0h-9.44V4.66h9.44v2.5h-6.6v2.82h6.46v2.5h-6.46v3.02h6.6V18Zm16.68 0h-2.86V8.66l-3.76 9.34h-1.24l-3.76-9.34V18h-2.84V4.66h3.98l3.24 8.06 3.24-8.06h4V18Zm15.089 0h-3.24l-.82-2.26h-5.72l-.84 2.26h-3.24l5.16-13.34h3.56l5.14 13.34Zm-4.84-4.76-2.08-5.74-2.08 5.74h4.16Z"
          />
          <path
            fill="#35C691"
            d="M113.698 35.144c-1.48 0-2.592-.656-3.336-1.968l1.464-.708c.176.344.432.624.768.84.344.216.724.324 1.14.324.608 0 1.128-.176 1.56-.528.432-.36.716-.832.852-1.416h-3.84v-1.5h3.804a2.434 2.434 0 0 0-.864-1.32c-.424-.328-.928-.492-1.512-.492-.408 0-.784.112-1.128.336a2.146 2.146 0 0 0-.78.828l-1.464-.72c.736-1.304 1.848-1.956 3.336-1.956 1.224 0 2.24.388 3.048 1.164.808.768 1.212 1.76 1.212 2.976s-.404 2.212-1.212 2.988c-.808.768-1.824 1.152-3.048 1.152ZM127.84 35h-1.716v-5.604L123.868 35h-.744l-2.256-5.604V35h-1.704v-8.004h2.388l1.944 4.836 1.944-4.836h2.4V35Zm3.234 0h-1.644v-8.004h1.704v5.172l3.708-5.172h1.752V35h-1.704v-5.364L131.074 35Zm10.662 0h-1.716v-6.504h-2.34v-1.5h6.384v1.5h-2.328V35Zm9.084 0h-5.664v-8.004h5.664v1.5h-3.96v1.692h3.876v1.5h-3.876V33.5h3.96V35Zm8.532 0h-1.716v-3.36h-3.78V35h-1.704v-8.004h1.704v3.144h3.78v-3.144h1.716V35Zm5.142 0h-1.716v-6.504h-2.34v-1.5h6.384v1.5h-2.328V35Z"
          />
          <defs>
            <linearGradient
              id="a"
              x1={0}
              x2={21.804}
              y1={37}
              y2={-25.379}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#398151" />
              <stop offset={1} stopColor="#35C691" />
            </linearGradient>
            <filter
              id="b"
              width={34.134}
              height={34.133}
              x={65}
              y={6.433}
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy={4} />
              <feGaussianBlur stdDeviation={2} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_414_7446" />
              <feBlend in="SourceGraphic" in2="effect1_dropShadow_414_7446" result="shape" />
            </filter>
          </defs>
        </svg>
      ) : (
        // 240 38
        <svg xmlns="http://www.w3.org/2000/svg" width={208} height={41} fill="none">
          <rect width={100.133} height={35} y={2} fill="url(#a)" rx={17.5} />
          <path
            fill="#fff"
            d="M27.096 27.337V25h-9.842V12.327h2.698v10.298h5.985V12.327h2.717v10.298h1.14v4.712h-2.698ZM39.582 25h-2.698v-1.273c-1.938-.165-3.445-.684-4.522-1.558-1.064-.874-1.596-2.04-1.596-3.496 0-1.444.532-2.603 1.596-3.477 1.077-.874 2.584-1.4 4.522-1.577v-1.292h2.698v1.292c1.938.177 3.44.703 4.503 1.577 1.077.861 1.615 2.02 1.615 3.477 0 1.457-.538 2.622-1.615 3.496-1.064.874-2.565 1.393-4.503 1.558V25Zm2.49-4.56c.582-.456.873-1.045.873-1.767s-.291-1.311-.874-1.767c-.57-.456-1.4-.747-2.489-.874v5.282c1.09-.127 1.92-.418 2.49-.874Zm-7.677-3.534c-.57.456-.855 1.045-.855 1.767s.285 1.311.855 1.767c.57.456 1.4.747 2.49.874v-5.282c-1.09.127-1.92.418-2.49.874ZM58.797 25h-3.078l-.78-2.147h-5.433L48.708 25H45.63l4.902-12.673h3.382L58.797 25Zm-4.598-4.522-1.976-5.453-1.976 5.453h3.952Z"
          />
          <g filter="url(#b)">
            <path
              fill="#fff"
              d="M82.067 32.567c7.216 0 13.066-5.85 13.066-13.067 0-7.216-5.85-13.067-13.066-13.067C74.85 6.433 69 12.283 69 19.5c0 7.216 5.85 13.067 13.067 13.067Z"
            />
          </g>
          <path
            fill="#373737"
            d="M117.914 18.24c-2.027 0-3.72-.64-5.08-1.92-1.347-1.293-2.02-2.953-2.02-4.98 0-2.027.673-3.68 2.02-4.96 1.36-1.293 3.053-1.94 5.08-1.94 2.493 0 4.353 1.087 5.58 3.26l-2.44 1.2a3.584 3.584 0 0 0-1.3-1.38 3.25 3.25 0 0 0-1.84-.56c-1.214 0-2.214.413-3 1.24-.787.827-1.18 1.873-1.18 3.14 0 1.267.393 2.313 1.18 3.14.786.827 1.786 1.24 3 1.24.666 0 1.28-.18 1.84-.54a3.545 3.545 0 0 0 1.3-1.4l2.44 1.18c-1.267 2.187-3.127 3.28-5.58 3.28Zm10.01-.24h-2.74V4.66h2.84v8.62l6.18-8.62h2.92V18h-2.84V9.06l-6.36 8.94Zm18.291.24c-2.027 0-3.72-.64-5.08-1.92-1.347-1.293-2.02-2.953-2.02-4.98 0-2.027.673-3.68 2.02-4.96 1.36-1.293 3.053-1.94 5.08-1.94 2.493 0 4.353 1.087 5.58 3.26l-2.44 1.2a3.584 3.584 0 0 0-1.3-1.38 3.25 3.25 0 0 0-1.84-.56c-1.214 0-2.214.413-3 1.24-.787.827-1.18 1.873-1.18 3.14 0 1.267.393 2.313 1.18 3.14.786.827 1.786 1.24 3 1.24.666 0 1.28-.18 1.84-.54a3.545 3.545 0 0 0 1.3-1.4l2.44 1.18c-1.267 2.187-3.127 3.28-5.58 3.28Zm13.21-.24h-2.86V7.16h-3.9v-2.5h10.64v2.5h-3.88V18Zm15.141 0h-9.44V4.66h9.44v2.5h-6.6v2.82h6.46v2.5h-6.46v3.02h6.6V18Zm16.68 0h-2.86V8.66l-3.76 9.34h-1.24l-3.76-9.34V18h-2.84V4.66h3.98l3.24 8.06 3.24-8.06h4V18Zm15.089 0h-3.24l-.82-2.26h-5.72l-.84 2.26h-3.24l5.16-13.34h3.56l5.14 13.34Zm-4.84-4.76-2.08-5.74-2.08 5.74h4.16Z"
          />
          <path
            fill="#35C691"
            d="M113.698 35.144c-1.48 0-2.592-.656-3.336-1.968l1.464-.708c.176.344.432.624.768.84.344.216.724.324 1.14.324.608 0 1.128-.176 1.56-.528.432-.36.716-.832.852-1.416h-3.84v-1.5h3.804a2.434 2.434 0 0 0-.864-1.32c-.424-.328-.928-.492-1.512-.492-.408 0-.784.112-1.128.336a2.146 2.146 0 0 0-.78.828l-1.464-.72c.736-1.304 1.848-1.956 3.336-1.956 1.224 0 2.24.388 3.048 1.164.808.768 1.212 1.76 1.212 2.976s-.404 2.212-1.212 2.988c-.808.768-1.824 1.152-3.048 1.152ZM127.84 35h-1.716v-5.604L123.868 35h-.744l-2.256-5.604V35h-1.704v-8.004h2.388l1.944 4.836 1.944-4.836h2.4V35Zm3.234 0h-1.644v-8.004h1.704v5.172l3.708-5.172h1.752V35h-1.704v-5.364L131.074 35Zm10.662 0h-1.716v-6.504h-2.34v-1.5h6.384v1.5h-2.328V35Zm9.084 0h-5.664v-8.004h5.664v1.5h-3.96v1.692h3.876v1.5h-3.876V33.5h3.96V35Zm8.532 0h-1.716v-3.36h-3.78V35h-1.704v-8.004h1.704v3.144h3.78v-3.144h1.716V35Zm5.142 0h-1.716v-6.504h-2.34v-1.5h6.384v1.5h-2.328V35Z"
          />
          <defs>
            <linearGradient
              id="a"
              x1={0}
              x2={21.804}
              y1={37}
              y2={-25.379}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#398151" />
              <stop offset={1} stopColor="#35C691" />
            </linearGradient>
            <filter
              id="b"
              width={34.134}
              height={34.133}
              x={65}
              y={6.433}
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy={4} />
              <feGaussianBlur stdDeviation={2} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_414_7446" />
              <feBlend in="SourceGraphic" in2="effect1_dropShadow_414_7446" result="shape" />
            </filter>
          </defs>
        </svg>
      )}
    </Text>
  );
};

export default IssuerLogo;
