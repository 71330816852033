import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAccountState {
  openDepositOrder: boolean;
  openWithdrawOrder: boolean;
}

const initialState: IAccountState = {
  openDepositOrder: false,
  openWithdrawOrder: false,
};

const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    setOpenDepositOrder(state, { payload }: PayloadAction<boolean>) {
      state.openDepositOrder = payload;
    },
    setOpenWithdrawOrder(state, { payload }: PayloadAction<boolean>) {
      state.openWithdrawOrder = payload;
    },
  },
});

export const { setOpenDepositOrder, setOpenWithdrawOrder } = dialogsSlice.actions;
export const dialogsReducer = dialogsSlice.reducer;
