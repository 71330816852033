import { flexStyles, marginStyles } from "Theme/styles";
import { ColorType, FlexType, MarginType } from "Theme/types";
import styled, { css } from "styled-components";

export interface IStyledAccordion {
  opened?: boolean;
  bgColor?: ColorType;
  bgWhite?: boolean;
  filled?: boolean;
  withBorder?: boolean;
  isInteractive?: boolean;
  sx?: FlexType & MarginType;
}

export interface IStyledAccordionHeader {
  opened: boolean;
}

export interface IStyledAccordionContent {
  opened: boolean;
}

export const AccordionWrap = styled.div<IStyledAccordion>`
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  padding: 10px 20px 20px 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
  ${({ opened }) => css`
    height: ${opened ? "auto" : "70px"};
  `}
  ${({ theme, filled, bgColor, withBorder, bgWhite, isInteractive }) => css`
    background-image: ${filled && !bgWhite && theme.palette[bgColor].gradient[100]};
    background-color: ${bgWhite && "white"};
    border: ${withBorder &&
    !bgWhite &&
    `1px solid ${theme.palette[bgColor].light}${theme.palette.opacity[25]}`};
    color: ${filled && !bgWhite ? theme.palette.white.main : theme.palette.text.main};
    &:hover {
      transform: ${isInteractive && " translateY(-0.2rem)"};
      cursor: ${isInteractive && "pointer"};
      box-shadow: ${isInteractive && "0 10px 19px #00000015"};
    }
  `}
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => flexStyles(sx)};
`;

export const AccordionHeaderWrap = styled.div<IStyledAccordionHeader>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${({ opened }) => css`
    height: ${opened ? "auto" : "100%"};
  `}
`;

export const AccordionContentWrap = styled.div<IStyledAccordionContent>`
  display: flex;
  width: 100%;
  ${({ opened }) => css`
    height: ${opened ? "auto" : 0};
  `}
`;
