import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { getCookie } from "@front-packages/dfa-helpers";
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import { GlobalStyles, Theme } from "Theme";
import { useAppDispatch, useAppSelector } from "store";
import { extractRoutes, routes, RoutesNames } from "routes";
import { GlobalNotifications } from "components";
import { ApolloProvider } from "@apollo/client";
import { GqlClient, RepresentativeStatusGql } from "@front-packages/dfa-gql-api";
import { useCheckAuth, useCheckPathname, useLiveActions } from "hooks";
import { setWindowWidth, setGlobalLoading } from "store/global";
import { NotFound } from "./pages";
import Layout from "./layout";

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { checkPathname } = useCheckPathname();
  const { authCheck } = useCheckAuth();
  const { pathname, search } = useLocation();
  const savedPathname = useRef<string>("/");
  const { isAuth, isAccountIssuer } = useAppSelector((state) => state.account);
  const representativeStatus = useAppSelector((state) => state.customer?.representative?.status);
  console.log(representativeStatus);

  useEffect(() => {
    savedPathname.current = `${pathname}${search || ""}`;
    if (getCookie(CookieNamesEnum.token) && getCookie(CookieNamesEnum.token) !== "null") {
      authCheck().then((resp) => {
        if (resp) dispatch(setGlobalLoading(false));
        else if (!pathname.includes("authenticate")) navigate(RoutesNames.SignIn);
        savedPathname.current = RoutesNames.Briefcase;
      });
    } else if (!getCookie(CookieNamesEnum.token)) {
      savedPathname.current = RoutesNames.Briefcase;
      if (!pathname.includes("authenticate")) navigate(RoutesNames.SignIn);
      dispatch(setGlobalLoading(false));
    }
  }, []);

  useLiveActions();
  console.log(savedPathname);
  useEffect(() => {
    const { current: savedPath } = savedPathname;
    if (isAuth) {
      if (representativeStatus === RepresentativeStatusGql.Active) {
        if (savedPath === "/") navigate(RoutesNames.Briefcase);
        else if (savedPath.includes("authenticate") && savedPath !== RoutesNames.OnBoarding)
          navigate(RoutesNames.Briefcase);
        // TODO: проверить условие ниже
        else if (!savedPath.includes("authenticate") || savedPath === RoutesNames.OnBoarding)
          navigate(savedPathname.current);
      } else navigate(RoutesNames.OnBoarding);
    } /* if (
      savedPath === "/" ||
      !savedPath.includes("authenticate") ||
      savedPath === RoutesNames.OnBoarding
    ) */ else navigate(RoutesNames.SignIn);
  }, [isAuth, representativeStatus]);

  useEffect(() => {
    if (
      pathname.includes("authenticate") &&
      isAuth &&
      representativeStatus === RepresentativeStatusGql.Active
    )
      navigate(RoutesNames.Briefcase);
  }, [pathname]);

  useEffect(() => {
    checkPathname();
  }, [isAccountIssuer]);

  useEffect(() => {
    dispatch(setWindowWidth(window.innerWidth));
    const resize = () => {
      dispatch(setWindowWidth(window.innerWidth));
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <ApolloProvider client={GqlClient}>
      <ThemeProvider theme={Theme(isAccountIssuer)}>
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<NotFound />} />
            {extractRoutes(routes).map(({ path, component, key, onlyIssuer, onlyInvestor }) =>
              (onlyIssuer && !isAccountIssuer) || (onlyInvestor && isAccountIssuer) ? null : (
                <Route
                  path={path}
                  index={path === RoutesNames.SignIn}
                  element={component}
                  key={key}
                />
              )
            )}
          </Route>
        </Routes>
        <GlobalNotifications />
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
