import React, { useRef, useState } from "react";
import { base64ToPdf } from "helpers";
import { Button, Checkbox, Dialog, Flex, Text } from "Theme";
import { PDFDoc } from "components";

interface IContractModalProps {
  onClose(): void;
  onConfirm(): Promise<void>;
  isChecked: boolean;
  isLoading: boolean;
  onCheck(): void;
  base64File: string;
  open: boolean;
}
const ContractModal: React.FC<IContractModalProps> = ({
  onClose,
  onConfirm,
  isChecked,
  isLoading,
  onCheck,
  base64File,
  open,
}) => {
  const [isRead, setIsRead] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const pdfDoc = useRef<Blob>(base64ToPdf(base64File));
  const pdfHref = URL.createObjectURL(pdfDoc.current);
  const onDownload = () => {
    const a = document.createElement("a");
    a.href = pdfHref;
    a.download = "contract.pdf";
    a.click();
  };
  const check = (e) => {
    e.persist();
    if (ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop < 1 && !isRead)
      setIsRead(true);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Договор купли-продажи цфа"
      maxWidth="xxl"
      disabled={!isChecked}
      onConfirm={onConfirm}
      loading={isLoading}
    >
      <Flex sx={{ align: "center", columnGap: 1, mb: 1, justify: "space-between" }}>
        <Text>
          Внимательно ознакомтесь с договором купли-продажи ЦФА и примите оферту. После чего следует
          подписать заявку.
        </Text>
        <Button sx={{ maxWidth: "200px" }} variant="text" onClick={onDownload}>
          Скачать договор
        </Button>
      </Flex>
      <Flex
        ref={ref}
        onScroll={check}
        sx={{ overflowY: "scroll", height: "420px", width: "100%", m: "0 auto" }}
      >
        {pdfDoc.current && <PDFDoc pdfDoc={pdfDoc.current} />}
      </Flex>
      <Checkbox
        name="Check"
        label="Я принимаю условия договора"
        checked={isChecked}
        onChange={onCheck}
        disabled={!isRead}
        sx={{ mt: 1 }}
      />
    </Dialog>
  );
};

export default ContractModal;
