import React from "react";
import {
  Container,
  Column,
  IStyledTable,
  Cell,
  IStyledColumn,
  Indicator,
  TitleCell,
} from "./Table";
import { ColorType, FlexType } from "../../types";
import Text from "../Text";
import { ISortOption } from "../Filterbar";
import SortOptionIcon from "../Filterbar/components/sortOptionIcon";

export type RowType<TKeys extends string> = { [key in TKeys]: any };
export type RowsType<TKeys extends string> = Array<RowType<TKeys>>;
export type ColumnType<TField = string> = IStyledColumn & {
  field: TField;
  label: string;
  indicatorColor?: ColorType | ((data: RowType<string>) => ColorType);
  indicatorPosition?: "start" | "end";
  sortOption?: ISortOption;
};
export type ColumnsType<TField = string> = Array<ColumnType<TField>>;
export interface ITitleRowSx {
  bgWhite?: boolean;
  rowHeight?: number;
  justify?: Pick<FlexType, "justify">;
}

interface ITable extends IStyledTable {
  columns: ColumnsType;
  rows: RowsType<string>;
  rowHeight?: number;
  color?: ColorType;
  onRowClick?(row: RowType<string>): void;
  loading?: boolean;
  notTitle?: boolean;
  titleRowSx?: ITitleRowSx;
  rowSx?: ITitleRowSx & { rowStyle?: "dark" | "white" | "alternation" };
  currentSortOption?: string;
  isUpArrow?: boolean;
}

const Table: React.FC<ITable> = ({
  columns,
  rows,
  titleRowSx,
  rowSx = { rowStyle: "white" },
  color = "primary",
  onRowClick,
  rowHeight,
  loading,
  notTitle,
  currentSortOption,
  isUpArrow,
  ...props
}) => {
  if (!columns || !rows) return null;
  const isUp = (revertValue) => {
    if (isUpArrow === false || isUpArrow === true) {
      return isUpArrow !== false;
    }
    return currentSortOption === revertValue;
  };
  return (
    <Container {...props}>
      {columns.map(({ label, field, ...column }, c) => (
        <Column
          firstCol={c === 0}
          lastCol={c === columns.length - 1}
          key={`column-${field}`}
          {...column}
        >
          {!notTitle && label && (
            <TitleCell
              height={titleRowSx?.rowHeight}
              key={`column_cell-${field}`}
              color={color || "primary"}
              firstCell={c === 0}
              endCell={c === columns.length - 1}
              bgWhite={titleRowSx?.bgWhite}
              justify={titleRowSx?.justify}
              onClick={column?.sortOption?.onClick}
            >
              <Text color={color}>{label}</Text>
              {currentSortOption && column?.sortOption && (
                <Text
                  color={
                    currentSortOption === column.sortOption.value.normal ||
                    currentSortOption === column.sortOption.value.revert
                      ? "primary"
                      : "secondary"
                  }
                >
                  <SortOptionIcon
                    type={column.sortOption.type}
                    isUp={isUp(column.sortOption.value.revert)}
                    // isUp={currentSortOption === column.sortOption.value.revert}
                    active={
                      currentSortOption === column.sortOption.value.normal ||
                      currentSortOption === column.sortOption.value.revert
                    }
                  />
                </Text>
              )}
            </TitleCell>
          )}
          {/* TODO: Сделать скелетон на loading */}
          {!loading &&
            rows?.map((row, i) => (
              <Cell
                height={rowHeight}
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                // eslint-disable-next-line react/no-array-index-key
                key={`row-${i}`}
                bgWhite={
                  (rowSx?.rowStyle === "alternation" && i % 2 === 0) || rowSx?.rowStyle === "white"
                }
                firstCell={c === 0}
                lastCellInCol={c === 0 && i === rows.length - 1}
                endCell={c === columns.length - 1}
                endRow={i === rows.length - 1}
                color={color || "primary"}
                justify={rowSx?.justify}
              >
                {column.indicatorPosition === "start" && (
                  <Indicator
                    position={column.indicatorPosition}
                    color={
                      typeof column.indicatorColor === "string"
                        ? column.indicatorColor
                        : column.indicatorColor(row)
                    }
                  />
                )}
                <Text color="secondary">{row[field]}</Text>
                {column.indicatorPosition === "end" && (
                  <Indicator
                    position={column.indicatorPosition}
                    color={
                      typeof column.indicatorColor === "string"
                        ? column.indicatorColor
                        : column.indicatorColor(row)
                    }
                  />
                )}
              </Cell>
            ))}
        </Column>
      ))}
    </Container>
  );
};

export default Table;

// {columns.map((column) => (
//   <>
//     <TitleColumn>{column.label}
//       {rows.map((row) => ( <Cell>{row[column.field]}</Cell>))}
//     </>
//     ))}
