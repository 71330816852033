import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Button, CircleLoader, Dialog, Flex, Text } from "Theme";
import { BurgerIcon, IconArrow, LogoutIcon, NotificationIcon, UserIcon } from "Theme/icons";
import { useAuth } from "hooks";
import { switchOpenCustomerInfo, switchOpenSideBar } from "store/account";
import { switchOpenNotificationsCenter } from "store/notificationsCenter";
import { useAppDispatch, useAppSelector } from "store";
import { splitString } from "helpers";
import { Breakpoints } from "constants/in_lib";
import getAlertData from "../../../pages/auth/Auth/getAlertData";
import { fadeInBottom, fadeInTop } from "../../animations";
import { Controls, StyledHeader } from "./Header";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { pageLoading, header, windowWidth } = useAppSelector((state) => state.global);
  const { notifications } = useAppSelector((state) => state.notificationsCenter);
  const { title, subTitle, badge, colorShift, badgePosition, pageBackBtn } = header;
  const [firstTitle, secondTitle] = splitString(title, colorShift);
  const [open, setOpen] = useState<boolean>(false);
  const { badgeText, type } = getAlertData(process.env.REACT_APP_ENVIRONMENT);

  const openNotificationsCenter = (e) => {
    e.stopPropagation();
    dispatch(switchOpenNotificationsCenter());
  };
  const openPersonalInfo = (e) => {
    e.stopPropagation();
    dispatch(switchOpenCustomerInfo());
  };
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const openSideBar = () => dispatch(switchOpenSideBar());

  const getUnreadNotifsAmount = () => {
    const undeadAmount = notifications?.filter((notif) => notif.isReaded === false).length;
    return undeadAmount > 9 ? "9+" : undeadAmount;
  };

  return (
    <>
      <StyledHeader>
        {windowWidth <= Breakpoints.laptopM && (
          <Flex
            sx={{ align: "flex-start", mr: 1 }}
            adaptiveSX={{ tablet: { mr: 0, columnGap: 1 } }}
          >
            <Button
              adaptiveSX={{ tablet: { mr: "auto" } }}
              onlyIcon
              iconStart={<BurgerIcon size="medium" />}
              onClick={openSideBar}
            />
            {windowWidth <= Breakpoints.tablet && (
              <>
                <Button
                  onlyIcon
                  onClick={openNotificationsCenter}
                  iconStart={
                    <NotificationIcon
                      notifAmount={getUnreadNotifsAmount()}
                      size="medium"
                      color="primary"
                    />
                  }
                />
                <Button
                  onlyIcon
                  onClick={openPersonalInfo}
                  iconStart={<UserIcon size="medium" color="primary" />}
                />
                <Button
                  onlyIcon
                  onClick={openDialog}
                  iconStart={<LogoutIcon size="medium" color="primary" />}
                  id="logoutBtn"
                />
              </>
            )}
          </Flex>
        )}
        <Flex
          sx={{
            direction: "column",
            rowGap: 0.5,
            width: "80%",
            position: "relative",
            minHeight: "75px",
          }}
          adaptiveSX={{ tablet: { width: "100%" } }}
          animation={fadeInTop}
        >
          {pageBackBtn?.label && (
            <Button
              onClick={() => navigate(-1)}
              color="primary"
              iconStart={<IconArrow rotate="left" size="medium" />}
              variant="text"
              sx={{ maxWidth: "max-content", size: "MD" }}
              light
            >
              {pageBackBtn.label}
            </Button>
          )}
          <Flex
            sx={{ align: "center", wrap: "wrap" }}
            key={`title-${pathname}`}
            // animation={fadeInTop}
          >
            <Text variant="h1" sx={{ weight: secondTitle ? "normal" : "bold" }}>
              {firstTitle}
            </Text>
            {secondTitle && (
              <Text variant="h1" color="primary" light sx={{ ml: 0.5 }}>
                {secondTitle}
              </Text>
            )}
            {badge?.label && !badge?.label.includes("undefined") && badgePosition === "title" && (
              <Badge
                label={badge.label}
                color={badge.color}
                sx={{ ml: 0.5 }}
                adaptiveSX={{ tablet: { ml: "auto" } }}
              />
            )}
            {pageLoading && <CircleLoader sx={{ ml: 1 }} isSpinner size={35} />}
          </Flex>
          <Flex
            sx={{ gap: 1, align: "center", wrap: "wrap" }}
            key={`subTitle-${pathname}`}
            animation={fadeInBottom}
          >
            <Text variant="h5" sx={{ weight: "normal" }} rows={1}>
              {subTitle}
            </Text>
            {badge?.label &&
              !badge?.label.includes("undefined") &&
              badgePosition === "subtitle" && <Badge label={badge.label} color={badge.color} />}
          </Flex>
        </Flex>
        {windowWidth > Breakpoints.tablet && (
          <Controls>
            <Badge label={badgeText} color={type} />
            <Flex onClick={(e) => e.stopPropagation()}>
              <Button
                onlyIcon
                onClick={openNotificationsCenter}
                iconStart={
                  <NotificationIcon
                    notifAmount={getUnreadNotifsAmount()}
                    size="medium"
                    color="primary"
                  />
                }
              />
            </Flex>
            <Button
              onlyIcon
              onClick={openPersonalInfo}
              iconStart={<UserIcon size="medium" color="primary" />}
            />
            <Button
              onlyIcon
              onClick={openDialog}
              iconStart={<LogoutIcon size="medium" color="primary" />}
              id="logoutBtn"
            />
          </Controls>
        )}
      </StyledHeader>
      <Dialog
        open={open}
        type="warning"
        maxWidth="xs"
        title="Вы точно хотите выйти?"
        subTitle="После выхода из учетной записи, Вам будет необходимо повторно авторизоваться"
        onClose={closeDialog}
        onConfirm={logout}
      />
    </>
  );
};

export default Header;
