import styled, { css } from "styled-components";
import { ColorType, CursorType } from "../../types";

interface IStyledDropZone {
  bgColor?: ColorType;
  active?: boolean;
  cursor?: CursorType;
}

const StyledDropZone = styled.div<IStyledDropZone>`
  ${({ bgColor, active, theme, cursor }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.2s ease-in-out;
    background-color: ${`${theme.palette[bgColor].main}${theme.palette.opacity[5]}${
      active ? "5E" : ""
    }`};
    border: 1px ${!active ? `solid` : `dashed`}
      ${`${theme.palette[bgColor][active ? "main" : "light"]}${active ? "5E" : "25"}`};
    border-radius: 15px;
    padding: 20px;
    height: 150px;
    cursor: ${cursor || "auto"};
  `};
`;
export default StyledDropZone;
