import React, { useEffect, useRef, useState } from "react";
import { File, FileDocumentType } from "@front-packages/dfa-gql-api";
import { getCookie } from "@front-packages/dfa-helpers";
import { CircleLoader, Dialog, Flex } from "Theme";
import { PDFDoc } from "components";
import { downloadBlobHelper } from "helpers";

interface IViewModalProps {
  open: boolean;
  onClose(): void;
  type: FileDocumentType;
  filesData: File[];
}
const ViewModal: React.FC<IViewModalProps> = ({ onClose, open, type, filesData }) => {
  const file = useRef<File>(filesData?.find((el) => el.documentType === type));
  const [pdf, setPdf] = useState<Blob>(null);
  const onDownload = async () => {
    downloadBlobHelper(pdf, file.current.name);
  };

  useEffect(() => {
    if (file?.current?.url) {
      fetch(`${process.env.REACT_APP_MIDDLE_API}${file.current.url}`, {
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      })
        .then((resp) => resp.arrayBuffer())
        .then((arrayBuffer) => {
          setPdf(new Blob([arrayBuffer], { type: "application/pdf" }));
        });
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={
        type === FileDocumentType.TokenOrderContract
          ? "Договор купли-продажи цфа"
          : "Счет на оплату"
      }
      maxWidth="xxl"
      onConfirm={onDownload}
      confirmText="Скачать"
    >
      <Flex sx={{ overflowY: "auto", height: "420px", width: "100%", m: "0 auto" }}>
        {pdf ? (
          <PDFDoc pdfDoc={pdf} />
        ) : (
          <Flex sx={{ width: "100%", height: "100%", justify: "center", align: "center" }}>
            <CircleLoader />
          </Flex>
        )}
      </Flex>
    </Dialog>
  );
};

export default ViewModal;
