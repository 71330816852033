import { DateRange, NumberRange } from "@front-packages/dfa-constants";
import { TokenStatusGql } from "@front-packages/dfa-gql-api";
import { useState } from "react";

export interface IFilterProps {
  issuer?: string;
  ticker?: string;
  issueAmount?: NumberRange;
  startDate?: DateRange;
  completionDate?: DateRange;
  tokenStatuses?: TokenStatusGql[];
}
type SetBadgeType = (filterProps: IFilterProps) => void;
type UseFilterBadge = () => { badges: Array<string>; setBadges: SetBadgeType };
export const useFilterBadge: UseFilterBadge = () => {
  const [badges, setBadges] = useState<Array<string>>([]);

  const handleSetBadges: SetBadgeType = (filterProps) => {
    const newBadges = Object.keys(filterProps).filter((key) => {
      let isReturn = !!filterProps[key];
      if (key === "completionDate" || key === "startDate")
        isReturn =
          !!filterProps[key] && (!!filterProps[key]?.firstDate || !!filterProps[key]?.lastDate);
      if (key === "issueAmount")
        isReturn = !!filterProps[key] && (!!filterProps[key]?.first || !!filterProps[key]?.last);
      return isReturn;
    });
    setBadges(newBadges);
  };

  // const handleDelBadge = (badge: string) => {
  //   setFilterProps({ ...filterProps, [badge]: undefined });
  //   setBadges(badges.filter((el) => el !== badge));
  // };

  return { badges, setBadges: handleSetBadges };
};
