import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const Logout: React.FC<IIcon> = ({ color = "inherit", size = "small", bold, contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={bold ? 2 : 1}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
        <polyline points="16 17 21 12 16 7" />
        <line x1="21" y1="12" x2="9" y2="12" />
      </svg>
    </Text>
  );
};

export default Logout;
