import React, { useEffect, useRef, useState } from "react";
import {
  SearchPanelWrap,
  SearchPanelHeader,
  IStyledSearchPanel,
  FormWrap,
  Wrap,
  SearchBadgesWrapper,
} from "./SearchPanel";
import Button from "../Button";
import Flex from "../Flex";
import { ChevronIcon } from "../../icons";
import useSearchBadges from "./useSearchBadges";
import Badge from "../Badge";

interface ISearchPanelProps extends IStyledSearchPanel {
  headerChildren?: React.ReactNode;
  hideHeaderChildren?: boolean;
  onSearch?(newState?: any): Promise<void> | void;
  onReset?(): Promise<void>;
  withOpenArrow?: boolean;
  badgesData?: { state: any; setState: any };
  isLoading?: boolean;
}

const SearchPanel: React.FC<ISearchPanelProps> = ({
  headerChildren,
  hideHeaderChildren,
  children,
  onSearch,
  onReset,
  withOpenArrow = true,
  badgesData,
  isLoading,
  ...props
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(null);
  const { badges, handleSetBadge, handleDelBadge, resetBadges, getBadgeTitle } = useSearchBadges(
    badgesData?.state,
    badgesData?.setState,
    onSearch
  );

  const form = useRef<any>(null);

  const toggleExpanded = () => setExpanded(!expanded);
  const handleOnSearch = async () => {
    if (expanded) setExpanded(false);
    await onSearch();
    badgesData?.state && handleSetBadge();
  };
  const handleOnReset = async () => {
    await onReset();
    badgesData?.state && resetBadges();
  };

  const handleEnter = async (e: any) => {
    if (e.nativeEvent.charCode === 13) {
      await onSearch();
      badgesData?.state && handleSetBadge();
    }
  };

  useEffect(() => {
    if (form.current) {
      const sum = [...form.current.children]
        .map((child) => child.offsetHeight)
        .reduce((acc, el) => acc + el, 0);
      setHeight(sum + 18 * form.current.children.length);
    }
  }, [form.current, children]);

  return (
    <SearchPanelWrap
      {...props}
      onKeyPress={handleEnter}
      sx={{ ...props?.sx, mb: badgesData && badges.length ? 3.5 : 1.5 }}
    >
      {badgesData?.state && badges.length && (
        <SearchBadgesWrapper>
          {badges?.map((badge) => (
            <Badge key={badge} label={getBadgeTitle(badge)} onClick={() => handleDelBadge(badge)} />
          ))}
        </SearchBadgesWrapper>
      )}
      <Wrap height={height} open={expanded} {...props}>
        <SearchPanelHeader withOpenArrow={withOpenArrow}>
          <Button onClick={toggleExpanded} color="text" variant="text">
            поиск
          </Button>
          {headerChildren && !expanded && !hideHeaderChildren && (
            <Flex sx={{ flex: 1, columnGap: 1 }}>{headerChildren}</Flex>
          )}
          {headerChildren && !expanded && !hideHeaderChildren && (
            <Button loading={isLoading} disabled={isLoading} onClick={handleOnSearch}>
              искать
            </Button>
          )}
          {withOpenArrow && (
            <Button
              onClick={toggleExpanded}
              sx={{
                ml: "auto",
                width: expanded || hideHeaderChildren ? "100%" : undefined,
                justify: expanded || hideHeaderChildren ? "flex-end" : undefined,
              }}
              iconStart={<ChevronIcon size="medium" rotate={expanded ? "up" : "down"} />}
              onlyIcon
            />
          )}
        </SearchPanelHeader>
        <FormWrap open={expanded} ref={form} {...props}>
          {children}
          <Flex sx={{ mt: 0.5, width: "100%" }}>
            <Button
              onClick={handleOnSearch}
              sx={{ mr: 1 }}
              loading={isLoading}
              disabled={isLoading}
            >
              искать
            </Button>
            {onReset && (
              <Button color="warning" variant="outline" onClick={handleOnReset} sx={{ mr: 1 }}>
                сбросить
              </Button>
            )}
            <Button variant="outline" color="secondary" onClick={toggleExpanded}>
              закрыть
            </Button>
          </Flex>
        </FormWrap>
      </Wrap>
    </SearchPanelWrap>
  );
};

export default SearchPanel;
