import { Token } from "@front-packages/dfa-gql-api";
import React, { useRef, useState } from "react";
import { Badge, ColumnsType, Dialog, Flex, RowsType, Table } from "Theme";

type Columns = "investor" | "amount" | "sum";
interface IJournalRedeemProps {
  open: boolean;
  onClose(): void;
  token: Token;
}
const JournalRedeem: React.FC<IJournalRedeemProps> = ({ onClose, open, token }) => {
  const [rows] = useState<RowsType<Columns>>(
    new Array(1).fill({ investor: "ООО «Инвестор»", amount: "4 шт", sum: "10 500 000 RUB" })
  );
  const columns = useRef<ColumnsType<Columns>>([
    { label: "инвестор", field: "investor", proportion: 4, color: "primary" },
    { label: "количество", field: "amount", proportion: 1 },
    { label: "сумма", field: "sum", proportion: 1 },
  ]);

  const close = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      title={`Журнал погашения [${token?.ticker}]`}
      titleBadge={<Badge sx={{ ml: "auto" }} label="№ 1" color="info" />}
      maxWidth="xxl"
      confirmText="Сформировать счет"
      cancelText="Закрыть"
    >
      <Flex sx={{ width: "100%", maxHeight: "450px", overflowY: "scroll" }}>
        <Table
          rowHeight={40}
          columns={columns.current}
          rows={rows}
          rowSx={{ rowStyle: "alternation" }}
        />
      </Flex>
    </Dialog>
  );
};

export default JournalRedeem;
