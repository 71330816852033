import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const User: React.FC<IIcon> = ({ color = "inherit", size = "small", bold, contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={bold ? 2 : 1}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    </Text>
  );
};

export default User;
