import React, { useEffect, useState } from "react";
import { FilterBar, Flex, Paginator, Table, TabsBar, Text } from "Theme";
import { TabType } from "Theme/components/TabsBar";
import { ISortOption } from "Theme/components/Filterbar";
import { useAppDispatch, useAppSelector } from "store";
import { setPageLoading } from "store/global";
import { Breakpoints } from "constants/in_lib";
import useChangeHeader from "../useChangeHeader";
import useOperationsTable from "./useOperationsTable";

const OrderHistory: React.FC = () => {
  useChangeHeader("Операции по лицевому счету");
  const dispatch = useAppDispatch();
  const {
    count,
    setCount,
    page,
    setPage,
    depositRows,
    columns,
    getDeposiOrdersList,
    withdrawRows,
    getWithdrawOrders,
    depositTotalCount,
    withdrawTotalCount,
  } = useOperationsTable();
  const { pageLoading, windowWidth } = useAppSelector((state) => state.global);
  const [currentSort, setCurrentSort] = useState<string>("sum");
  const [currentTab, setCurrenTab] = useState<number>(0);

  useEffect(() => {
    if (currentTab === 1) getWithdrawOrders().then(() => dispatch(setPageLoading(false)));
    else getDeposiOrdersList().then(() => dispatch(setPageLoading(false)));
  }, [count, page, currentTab]);

  useEffect(() => {
    setPage(0);
    setCount("10");
  }, [currentTab]);

  const tabs: Array<TabType> = [
    {
      id: 0,
      label: "Пополнения лицевого счета",
      node: depositRows?.length ? (
        <Table columns={columns.current} rows={depositRows} />
      ) : (
        <Text sx={{ mt: 1 }}>{!pageLoading && "Операции по пополнению не производились"}</Text>
      ),
    },
    {
      id: 1,
      label: "Вывод средств",
      node: withdrawRows?.length ? (
        <Table columns={columns.current} rows={withdrawRows} />
      ) : (
        <Text sx={{ mt: 1 }}>{!pageLoading && "Операции по выводу не производились"}</Text>
      ),
    },
  ];

  const sortOptions: Array<ISortOption> = [
    {
      type: "number",
      label: "Сумма",
      value: {
        normal: "sum",
        revert: "sumRevert",
      },
      onClick() {
        setCurrentSort(currentSort === "sum" ? "sumRevert" : "sum");
      },
    },
    {
      type: "any",
      label: "Дата",
      value: {
        normal: "date",
        revert: "dateRevert",
      },
      onClick() {
        setCurrentSort(currentSort === "date" ? "dateRevert" : "date");
      },
    },
  ];
  return (
    <Flex
      sx={{ direction: "column", rowGap: 1, width: "100%", mt: 3, flex: 1 }}
      adaptiveSX={{ tablet: { width: "100%" } }}
    >
      <FilterBar
        options={sortOptions}
        current={currentSort}
        count={count}
        setCount={setCount}
        isTablet={windowWidth <= Breakpoints.tablet}
      />
      <TabsBar tabs={tabs} currentTab={currentTab} setTab={setCurrenTab} />
      {!!depositTotalCount && currentTab === 0 && (
        <Paginator
          page={page}
          setPage={setPage}
          amount={Math.ceil(depositTotalCount / +count)}
          withInput
        />
      )}
      {!!withdrawTotalCount && currentTab === 1 && (
        <Paginator
          page={page}
          setPage={setPage}
          amount={Math.ceil(withdrawTotalCount / +count)}
          withInput
        />
      )}
    </Flex>
  );
};

export default OrderHistory;
