import React, { useEffect, useRef, useState } from "react";
import { Wrap, StyledTab, IStyledTabsBar, StyledTabBackground, IContainer } from "./TabsBar";

export type TabType = {
  label: string;
  node: React.ReactNode;
  id: number;
};
interface ITabsBarProps extends IStyledTabsBar, IContainer {
  tabs: Array<TabType>;
  currentTab: number;
  setTab(tab: number): void;
}

export const TabsBar: React.FC<ITabsBarProps> = ({ tabs, setTab, currentTab, ...props }) => {
  const [width, setWidth] = useState<number>(300);
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    setWidth(ref.current?.offsetWidth || 300);
  });
  return (
    <>
      <Wrap sx={{ mt: 1 }} {...props}>
        <StyledTabBackground width={width} position={currentTab * width} color={props.color} />
        {tabs.map((tab) => (
          <StyledTab
            title={tab.label}
            ref={ref}
            key={tab.id}
            onClick={() => setTab(tab.id)}
            active={tab.id === currentTab}
            {...props}
          >
            {tab.label}
          </StyledTab>
        ))}
      </Wrap>
      {tabs[currentTab].node}
    </>
  );
};
