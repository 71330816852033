import React, { useState } from "react";
import { Dialog, Flex, MFADialog, Text } from "Theme";
import { GeneralAPI, TokensAPI } from "@front-packages/dfa-gql-api";
import { useAppSelector } from "store";
import { useErrors } from "hooks";
import InvoiceModal from "./Invoice";

interface IRedeemInfoModalProps {
  open: boolean;
  onClose(): void;
  tokenID: string;
}

const RedeemInfoModal: React.FC<IRedeemInfoModalProps> = ({ onClose, open, tokenID }) => {
  const { company } = useAppSelector((state) => state.customer);
  const { setError } = useErrors();

  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const onOpenInvoice = () => setOpenInvoice(true);
  const [isLoad, setIsLoad] = useState(false);
  const [code, setCode] = useState<string>(null);
  const [sessionID, setSessionID] = useState<string>(null);
  const [base64Invoice, setBase64Invoice] = useState<string>(null);

  const [openMfa, setOpenMfa] = useState(false);
  const onOpenMfa = async () => {
    setIsLoad(true);
    const { response, error } = await GeneralAPI.Request2FACode({
      entityType: "token",
      entityID: company?.ownerID,
      methodName: "GenerateDVPTokenRedeemInvoice",
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setCode(response.code);
      setSessionID(response.sessionID);
    }
    setIsLoad(false);
    setOpenMfa(true);
  };
  const onConfirmMfa = async () => {
    setIsLoad(true);
    const { response, error } = await TokensAPI.GenerateDVPTokenRedeemInvoice({
      sessionID,
      code,
      tokenID,
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setBase64Invoice(response);
      setOpenMfa(false);
      onClose();
      onOpenInvoice();
    }
    setIsLoad(false);
  };

  if (open && openMfa)
    return (
      <MFADialog
        descOperation="Идет формирование счета... Дождитесь завершения операции"
        open={open}
        onClose={() => setOpenMfa(false)}
        onConfirm={onConfirmMfa}
        setCode={setCode}
        code={code}
        loading={isLoad}
        confirmText={isLoad ? "Идет отправка..." : "Отправить"}
      />
    );
  if (openInvoice)
    return (
      <InvoiceModal
        open={openInvoice && !!base64Invoice}
        onClose={() => setOpenInvoice(false)}
        base64File={base64Invoice}
      />
    );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Погашение токена"
      onConfirm={onOpenMfa}
      confirmText={isLoad ? "Формирование счета..." : "Сформировать счет"}
      cancelText="Закрыть"
      loading={isLoad}
    >
      <Flex sx={{ direction: "column", rowGap: 1 }}>
        <Text color="secondary">
          Для погашения Токена необходимо внести денежные средства на номинальный счет в
          соответствии с платежным документом.
        </Text>
      </Flex>
    </Dialog>
  );
};

export default RedeemInfoModal;
