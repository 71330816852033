import { IAdaptive, adaptiveStyles } from "Theme";
import styled, { css } from "styled-components";

export interface ICustomerInfo extends IAdaptive {
  open: boolean;
}

export const StyledCustomerInfo = styled.div<ICustomerInfo>`
  box-shadow: -20px 0 30px rgba(0, 0, 0, 0.07);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 35px;
  position: fixed;
  top: 0;
  width: 20%;
  min-width: 350px;
  height: 100%;
  z-index: 100;
  transition-duration: 300ms;
  ${({ theme, open }) => css`
    background: ${theme.palette.background.primary};
    right: ${open ? 0 : "-100%"};
  `}
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;
