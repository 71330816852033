import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TokenStatusGql, TokensAPI, UserClientType } from "@front-packages/dfa-gql-api";
import { useErrors } from "hooks";
import { Button, Card, CircleLoader, Flex, Table, ColumnsType, RowsType, RowType } from "Theme";
import { RoutesNames } from "routes";
import { setIssuesRedeemFilter } from "store/account";
import { useAppDispatch } from "store";

type Columns = "ticker" | "sum" | "amount" | "type" | "id";

const Repayment: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setError } = useErrors();
  const goToRepaymentTokens = () => {
    dispatch(setIssuesRedeemFilter(true));
    navigate(RoutesNames.Issues);
  };
  const columns = useRef<ColumnsType<Columns>>([
    {
      label: "тикер",
      field: "ticker",
      proportion: 4,
      color: "success",
      indicatorColor: (data: RowType<Columns>) => (data.type === "ЦФА" ? "info" : "success"),
      indicatorPosition: "start",
    },
    { label: "сумма", field: "sum", proportion: 2 },
    { label: "количество", field: "amount", proportion: 2 },
    {
      label: "тип",
      field: "type",
      proportion: 1,
    },
  ]);
  const [rows, setRows] = useState<RowsType<Columns>>(null);
  const [amount, setAmount] = useState<number>(0);
  const [isLoad, setIsLoad] = useState<boolean>(true);

  const getTokens = async () => {
    const { response, error } = await TokensAPI.GetTokens({
      opts: {
        page: 0,
        count: 10,
        tokenStatuses: [TokenStatusGql.InRedeem],
        clientType: UserClientType.Emitent,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response?.tokens) {
      const newRows: RowsType<Columns> = [];
      response.tokens.forEach((token) => {
        newRows.push({
          ticker: token.ticker,
          amount: token.amount,
          type: token.rightsType,
          sum: token.amount * token.nominal,
          id: token.id,
        });
      });
      setAmount(response.count);
      setRows(newRows);
    }
  };

  const goToToken = (data: RowType<Columns>) => {
    navigate(`${RoutesNames.ViewIssues}?id=${data.id}`);
  };

  useEffect(() => {
    getTokens().finally(() => setIsLoad(false));
  }, []);

  if (!amount && !isLoad) return null;

  return (
    <Card
      title={isLoad ? undefined : `Токены на погашении (${amount})`}
      type="primary"
      bgWhite
      sx={{ height: "210px", mb: 0.5, flex: 2, minWidth: "280px" }}
      adaptiveSX={{
        laptop: { minWidth: "400px", flex: 1 },
        tablet: { flex: 1, minWidth: "100%" },
      }}
    >
      {isLoad && (
        <Flex sx={{ justify: "center", align: "center", height: "100%" }}>
          <CircleLoader />
        </Flex>
      )}
      {!isLoad && (
        <>
          <Flex sx={{ overflow: "auto", maxHeight: "120px" }}>
            <Table
              color="success"
              rowHeight={40}
              rowSx={{ rowStyle: "white" }}
              titleRowSx={{ rowHeight: 40 }}
              onRowClick={goToToken}
              columns={columns.current}
              rows={rows}
            />
          </Flex>
          <Button
            variant="link"
            onClick={goToRepaymentTokens}
            sx={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Посмотреть все
          </Button>
        </>
      )}
    </Card>
  );
};

export default Repayment;
