import React, { useEffect, useRef, useState } from "react";
import { Badge, ColumnsType, RowsType } from "Theme";
import { useErrors } from "hooks";
import { useAppSelector } from "store";
import {
  AccountOperationAPI,
  DepositOrderSortField,
  DepositOrderStatus,
} from "@front-packages/dfa-gql-api";
import { formattingDate, getStatusBadgeProps } from "helpers";
import DownloadButton from "./DownloadButton";

type Columns =
  | "regNumber"
  | "fiatAmount"
  | "createdDate"
  // | "expirationDate"
  | "status"
  | "bill"
  | "id";

const useDepositOrders = () => {
  const { id: representativeID } = useAppSelector((state) => state.customer.representative);
  const { id: clientID } = useAppSelector((state) => state.customer.company);
  const { setError } = useErrors();

  const [rows, setRows] = useState<RowsType<Columns>>(null);
  const [totalCount, setTotalCount] = useState<number>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<number>(10);
  const [currentSortOption, setCurrentSortOption] = useState<DepositOrderSortField>(
    DepositOrderSortField.RegNumber
  );
  const [sortDescent, setSortDescent] = useState(false);
  const [orderStatus, setOrderStatus] = useState<DepositOrderStatus[]>([
    DepositOrderStatus.WaitingPaymentDepositStatus,
    DepositOrderStatus.CompletedDepositStatus,
    DepositOrderStatus.RejectedDepositStatus,
  ]);

  const handleChangeOrderStatus = (name: DepositOrderStatus) =>
    setOrderStatus((prev) => {
      if (prev.includes(name)) {
        const newArr = prev.filter((el) => el !== name);
        return newArr;
      }
      return [...prev, name];
    });

  const columns = useRef<ColumnsType<Columns>>([
    {
      label: "номер",
      field: "regNumber",
      proportion: 4,
      sortOption: {
        type: "number",
        value: {
          normal: !sortDescent && DepositOrderSortField.RegNumber,
          revert: sortDescent && DepositOrderSortField.RegNumber,
        },
        onClick() {
          setCurrentSortOption(DepositOrderSortField.RegNumber);
          setSortDescent((prev) => prev === false);
        },
      },
    },
    {
      label: "сумма",
      field: "fiatAmount",
      proportion: 2,
      sortOption: {
        type: "number",
        value: {
          normal: !sortDescent && DepositOrderSortField.FiatAmount,
          revert: sortDescent && DepositOrderSortField.FiatAmount,
        },
        onClick() {
          setCurrentSortOption(DepositOrderSortField.FiatAmount);
          setSortDescent((prev) => prev === false);
        },
      },
    },
    {
      label: "Дата создания",
      field: "createdDate",
      proportion: 2,
      sortOption: {
        type: "any",
        value: {
          normal: !sortDescent && DepositOrderSortField.CreatedDate,
          revert: sortDescent && DepositOrderSortField.CreatedDate,
        },
        onClick() {
          setCurrentSortOption(DepositOrderSortField.CreatedDate);
          setSortDescent((prev) => prev === false);
        },
      },
    },
    // { label: "Срок действия", field: "expirationDate", proportion: 2 },
    { label: "Статус", field: "status", proportion: 2 },
    { label: "счет", field: "bill", proportion: 2 },
  ]);

  const getDepositOrdersList = async () => {
    const { response, error } = await AccountOperationAPI.GetDepositOrdersList({
      opts: {
        page,
        count,
        representativeID,
        sortBy: currentSortOption,
        sortDescent,
        orderStatus,
        clientID,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      const newRows: RowsType<Columns> = [];
      response.orders?.forEach((order) => {
        newRows.push({
          id: order.id,
          regNumber: order.regNumber,
          fiatAmount: order.fiatAmount,
          createdDate: formattingDate(order.createdDate),
          // expirationDate: "mock",
          status: <Badge {...getStatusBadgeProps(order.depositOrderStatus, "Deposit")} />,
          bill: <DownloadButton depositOrderID={order.id} />,
        });
      });
      setRows(newRows);
      setTotalCount(response.totalCount);
    }
  };

  useEffect(() => {
    if (orderStatus.length) getDepositOrdersList();
    else {
      setRows([]);
      setTotalCount(0);
    }
    // TODO: убрать count
  }, [count, currentSortOption, sortDescent, orderStatus, page]);

  return {
    handleChangeOrderStatus,
    orderStatus,
    rows,
    totalCount,
    columns,
    currentSortOption,
    sortDescent,
    count,
    setCount,
    page,
    setPage,
  };
};

export default useDepositOrders;
