import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const DocWithAnglesIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 39)}
        height={getSize(size, 42)}
        fill="none"
      >
        <path
          fill="url(#a)"
          fillOpacity={0.25}
          d="M10.125 4.125H4.5V9.75H.75V.375h9.375v3.75ZM38.25 9.75V.375h-9.375v3.75H34.5V9.75h3.75ZM10.125 37.875H4.5V32.25H.75v9.375h9.375v-3.75ZM34.5 32.25v5.625h-5.625v3.75h9.375V32.25H34.5Zm-5.625-22.5h-18.75v22.5h18.75V9.75Zm3.75 22.5a3.761 3.761 0 0 1-3.75 3.75h-18.75a3.761 3.761 0 0 1-3.75-3.75V9.75A3.761 3.761 0 0 1 10.125 6h18.75a3.761 3.761 0 0 1 3.75 3.75v22.5Zm-7.5-18.75h-11.25v3.75h11.25V13.5Zm0 5.625h-11.25v3.75h11.25v-3.75Zm0 5.625h-11.25v3.75h11.25v-3.75Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0.75}
            x2={41.813}
            y1={41.625}
            y2={4.295}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#398151" />
            <stop offset={1} stopColor="#35C691" />
          </linearGradient>
        </defs>
      </svg>
    </Text>
  );
};

export default DocWithAnglesIcon;
