import styled, { css } from "styled-components";
import { FlexType, MarginType, SizeType } from "../../types";
import { marginStyles, flexStyles, sizeStyles } from "../../styles";

export interface IStyledEntityProps {
  sx?: MarginType & FlexType & SizeType;
  flex?: number;
  width?: string | number;
  maxWidth?: string | number;
}

export const StyledEntityProps = styled.div<IStyledEntityProps>`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  ${({ width, maxWidth, flex }) => css`
    flex: ${flex};
    width: ${typeof width === "number" && `${width}px`};
    width: ${typeof width === "string" && width};
    max-width: ${typeof maxWidth === "number" && `${maxWidth}px`};
    max-width: ${typeof maxWidth === "string" && maxWidth};
  `}
  ${({ sx }) => marginStyles(sx)}
  ${({ sx }) => flexStyles(sx)}
  ${({ sx }) => sizeStyles(sx)}
`;
