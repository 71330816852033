import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const CorporateFareIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M146.666-120q-27.5 0-47.083-19.583T80-186.666v-586.668q0-27.5 19.583-47.083T146.666-840h260.001q27.5 0 47.083 19.583t19.583 47.083v96.667h340.001q27.5 0 47.083 19.584Q880-637.5 880-610v423.334q0 27.5-19.583 47.083T813.334-120H146.666Zm0-66.666h260.001v-96.667H146.666v96.667Zm0-163.334h260.001v-96.667H146.666V-350Zm0-163.333h260.001V-610H146.666v96.667Zm0-163.334h260.001v-96.667H146.666v96.667Zm326.667 490.001h340.001V-610H473.333v423.334Zm80-260.001v-66.666h163.334v66.666H553.333Zm0 163.334V-350h163.334v66.667H553.333Z" />
      </svg>
    </Text>
  );
};

export default CorporateFareIcon;
