import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const ArchiveIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path fill="none" d="M0 0h20v20H0z" />
        <path d="M16.5 6H10L8 4H3.5C2.67 4 2 4.67 2 5.5v9c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5zm-13 8.5v-9h3.88l2 2h4.12V9H12v1.5h1.5V12H12v1.5h1.5V12H15v-1.5h-1.5V9H15V7.5h1.5v7h-13z" />
      </svg>
    </Text>
  );
};

export default ArchiveIcon;
