import { OrdersAPI } from "@front-packages/dfa-gql-api";
import React, { useEffect } from "react";
import { useErrors } from "hooks";
import { formattingDate } from "helpers";
import { Dialog, MFADialog, RowType } from "Theme";
import {
  useContractDialogState,
  useCreateDVPBillDialogState,
  useMFADialogState,
  ContractModal,
  DVPBillModal,
} from "../../marketplace/components/CreateOrder/Dialogs";
import { IFilterProps } from "./SearchForm";
import { OrderRowType } from "../tabs/PrimaryMarketOrders/types";

interface IOrderModalsProps {
  order: RowType<OrderRowType>;
  setCurrentOrder(order: RowType<OrderRowType>): void;
  isMakingContract: boolean;
  setIsMakingContract(isMakingContract: boolean): void;
  getOrders?(): Promise<void | { payload: boolean; type: "global/setPageLoading" }>;
}

export const initialFilterProps: IFilterProps = {
  ticker: undefined,
  issuer: undefined,
  hideComplete: undefined,
  hideCanceled: undefined,
  investorID: undefined,
  amount: undefined,
  orderSum: undefined,
  orderStatuses: undefined,
  createdDate: undefined,
};

const OrderModals: React.FC<IOrderModalsProps> = ({
  order,
  isMakingContract,
  setIsMakingContract,
  getOrders,
  setCurrentOrder,
}) => {
  const { setError } = useErrors();

  const { state, setState, getMfa, cleanMfa } = useMFADialogState();
  const {
    isChecked,
    isContractModal,
    base64Contract,
    setIsContractModal,
    setBase64FileContract,
    onCloseContractModal,
    onCheck,
  } = useContractDialogState();

  const {
    isDVPBillModal,
    setIsDVPBillModal,
    isLoadingDVP,
    setIsLoadingDVP,
    onCloseDVPBillModal,
    dvpBill,
    setDvpBill,
    onDownloadDVP,
  } = useCreateDVPBillDialogState();

  const makeContract = async () => {
    const { response, error } = await OrdersAPI.GenerateDVPSalesContract({ orderID: order?.id });
    if (response) {
      setBase64FileContract(response);
      setIsContractModal(true);
    }
    if (error) setError({ error, key: Math.random() });
    setIsMakingContract(false);
  };

  const onSignContract = async () => {
    setIsLoadingDVP(true);
    const { response, error } = await OrdersAPI.VerifySigningSalesContract({
      sessionID: state?.mfa?.sessionID,
      code: state?.mfa?.code,
      dvpOrderID: order?.id,
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      const { response: resp, error: err } = await OrdersAPI.GenerateDVPOrderInvoicePDF({
        dvpOrderID: order?.id,
      });
      if (err) setError({ error: err, key: Math.random() });
      if (resp) {
        cleanMfa();
        onCloseContractModal();
        setIsDVPBillModal(true);
        setDvpBill(resp);
        setCurrentOrder(null);
        if (getOrders) await getOrders();
      }
    }
    setIsLoadingDVP(false);
  };

  const onConfirmDVP = () => {
    onDownloadDVP(dvpBill);
    onCloseDVPBillModal();
  };

  useEffect(() => {
    if (isMakingContract) makeContract();
  }, [isMakingContract]);
  return (
    <>
      <Dialog
        open={isMakingContract}
        maxWidth="md"
        type="info"
        confirmText="подтвердить"
        onConfirm={() => ""}
        onClose={() => setIsMakingContract(false)}
        withMissClick
        disabled
        loading={isMakingContract}
        title="Идет подготовка договора..."
        subTitle="Заявка принята на проверку. Идет подготовка договора... Пожалуйста, подождите."
      />
      {!!base64Contract && (
        <ContractModal
          open={!!(isContractModal && !!base64Contract)}
          onClose={onCloseContractModal}
          isChecked={isChecked}
          // TODO: мидл будет меняться
          onConfirm={() => getMfa("dvp_token_order", order?.id, "CreateDVPOrder")}
          isLoading={state.loading}
          onCheck={onCheck}
          base64File={base64Contract}
        />
      )}
      <MFADialog
        open={!!state?.mfa?.code}
        withMissClick={false}
        type="info"
        maxWidth="md"
        onClose={cleanMfa}
        cancelText="назад"
        onConfirm={onSignContract}
        setCode={setState.code}
        code={state?.mfa?.code}
        descOperation="Идет регистрация Вашей заявки..."
        loading={isLoadingDVP}
      />
      {!!dvpBill && (
        <DVPBillModal
          open={isDVPBillModal}
          onClose={onCloseDVPBillModal}
          onConfirm={onConfirmDVP}
          base64File={dvpBill}
          investFinishDate={formattingDate(order.investFinishDate)}
        />
      )}
      {/* {order && orderModal && (
        <OrderModal open={orderModal} onClose={() => setOrderModal(false)} order={order} />
      )} */}
    </>
  );
};

export default OrderModals;
