import React, { useEffect, useRef, useState } from "react";
import { ContentWrap, ICard, StyledCard, TitleContentWrap, Watermark } from "./Card";
import Text from "../Text";
import { ColorType } from "../../types";

interface ICardProps extends ICard {
  onClick?(): void | Promise<void>;
  title?: string;
  titleLight?: boolean;
  titleSx?: { color?: ColorType; bgColor?: ColorType };
  children?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
  onMouseUp?: any;
  onMouseDown?: any;
  onMouseMove?: any;
  wmSize?: number;
}

const Card: React.FC<ICardProps> = ({
  children,
  ref,
  onClick,
  title,
  titleLight = false,
  titleSx,
  wmSize,
  ...props
}) => {
  const { type, watermark } = props;
  const forWatermarkSize = useRef<HTMLDivElement>();
  const [watermarkSize, setWatermarkSize] = useState<number>(undefined);

  useEffect(() => {
    if (forWatermarkSize.current) setWatermarkSize(forWatermarkSize.current.offsetWidth * 0.4);
  }, [forWatermarkSize.current]);
  return (
    <StyledCard ref={ref} isInteractive={!!onClick} onClick={onClick} {...props}>
      {title && (
        <TitleContentWrap bgColor={titleSx?.bgColor}>
          <Text
            variant="h5"
            light={titleLight}
            title={title}
            color={props.filled ? "white" : titleSx?.color || "text"}
            rows={1}
          >
            {title}
          </Text>
        </TitleContentWrap>
      )}
      <ContentWrap withWaterMark={!!watermark} ref={forWatermarkSize} {...props}>
        {/* {title && (
          <Text
            variant="h5"
            light={titleLight}
            title={title}
            sx={{ mb: 0.8 }}
            color={props.filled ? "white" : props.type || "text"}
            rows={1}
          >
            {title}
          </Text>
        )} */}
        {children}
      </ContentWrap>
      {watermark && (
        <Watermark size={wmSize || watermarkSize} color={type} contrast={type !== "white"}>
          {watermark}
        </Watermark>
      )}
    </StyledCard>
  );
};

export default Card;
