import React from "react";
import { Flex, Text } from "Theme";
import { useAppSelector } from "store";

const style: any = { textAlign: "center", width: "100%", textTransform: "capitalize" };
const RepresentativeName: React.FC = () => {
  const { representative } = useAppSelector((state) => state.customer);
  return (
    <Flex sx={{ direction: "column", mb: 1, width: "100%" }}>
      {/* TODO: потом вставить фото клиента */}
      <Flex
        bgColor="primary"
        sx={{
          width: "80px",
          height: "80px",
          borderRadius: "40px",
          m: "0 auto",
          mb: 1,
        }}
      />
      <Text rows={1} color="primary" variant="h4" sx={style}>
        {representative?.middleName}
      </Text>
      <Text rows={1} color="primary" variant="h4" sx={style}>
        {representative?.firstName}
      </Text>
    </Flex>
  );
};

export default RepresentativeName;
