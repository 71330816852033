import React from "react";
import {
  IStyledText,
  StyledSpan,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6,
  StyledLink,
  StyledSkeletonSpan,
} from "./Text";

interface ITextProps extends IStyledText {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";
  link?: string;
  children?: React.ReactNode;
  title?: string;
}

const Text: React.FC<ITextProps> = ({ variant = "span", link, children, ...props }) => {
  const content = link ? (
    <StyledLink href={link} target="_blank" rel="noreferrer">
      {children}
    </StyledLink>
  ) : (
    children
  );
  if (props.isLoading) {
    return <StyledSkeletonSpan />;
  }
  switch (variant) {
    case "h1": {
      return (
        <StyledH1 withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledH1>
      );
    }
    case "h2": {
      return (
        <StyledH2 withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledH2>
      );
    }
    case "h3": {
      return (
        <StyledH3 withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledH3>
      );
    }
    case "h4": {
      return (
        <StyledH4 withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledH4>
      );
    }
    case "h5": {
      return (
        <StyledH5 withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledH5>
      );
    }
    case "h6": {
      return (
        <StyledH6 withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledH6>
      );
    }
    default: {
      return (
        <StyledSpan withHover={!!link || props.withHover} {...props}>
          {content}
        </StyledSpan>
      );
    }
  }
};

export default Text;
