import styled, { css } from "styled-components";

export const StyledAuthLayout = styled.div`
  padding: 15px;
  background-image: ${({ theme }) => theme.palette.primary.gradient[3]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDefaultLayout = styled.div`
  padding: 28px 28px 0;
  margin-left: 20%;
  flex: 1;
  background-image: ${({ theme }) => theme.palette.primary.gradient[3]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => css`
    ${theme.device.laptopM} {
      margin-left: 0;
    }
  `}
`;

export const LoaderWrap = styled.div`
  padding: 28px;
  flex: 1;
  background-image: ${({ theme }) => theme.palette.primary.gradient[3]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
