import React from "react";
import { amountWithSpaces, formattingDate } from "helpers";
import {
  AdaptiveStyle,
  Card,
  ColorType,
  Flex,
  FlexType,
  MarginType,
  PaddingType,
  SizeType,
  Text,
} from "Theme";
import { LockIcon } from "Theme/icons";
import { useAppSelector } from "store";

interface IBalanceCardProps {
  wmSize?: number;
  type?: ColorType;
  sx?: MarginType & SizeType & PaddingType & FlexType;
  adaptiveSX?: AdaptiveStyle;
}

const BalanceCard: React.FC<IBalanceCardProps> = ({ type, wmSize, sx, adaptiveSX }) => {
  const { balance } = useAppSelector((state) => state.customer);

  return (
    <Card
      watermark="Денежные средства"
      wmSize={wmSize || 40}
      title="Денежные средства"
      type={type || "info"}
      filled
      sx={sx && sx}
      adaptiveSX={adaptiveSX && adaptiveSX}
    >
      <Flex
        sx={{
          align: "flex-end",
          justify: "flex-end",
          direction: "column",
          height: "100%",
        }}
      >
        <Text sx={{ mb: 0.3, size: "XS", weight: "bold" }} color="contrast">
          <Text sx={{ mr: 0.3, size: "XS", weight: "bold" }} color="inherit">
            Обновлен:
          </Text>
          {formattingDate(balance?.fiat.balance.lastUpdate, true)}
        </Text>
        <Text sx={{ mb: 0.3, size: "LG" }} color="contrast">
          {amountWithSpaces(balance?.fiat.balance.amount || 0)}
          <Text sx={{ ml: 0.3, size: "LG", weight: "bold" }} color="inherit">
            RUB
          </Text>
        </Text>
        <Text sx={{ mb: 1.25, direction: "row" }} color="contrast" opacity={90}>
          <LockIcon sx={{ mr: 0.3 }} />
          {amountWithSpaces(balance?.fiat.holds.reduce((sum, el) => sum + +el.amount, 0) || 0)}
          <Text sx={{ ml: 0.3, weight: "bold" }} color="inherit">
            RUB
          </Text>
        </Text>
      </Flex>
    </Card>
  );
};

export default BalanceCard;
