import React, { ForwardedRef, forwardRef } from "react";
import { IStyledFlex, StyledFlex } from "./Flex";

interface IFlexProps extends IStyledFlex {
  children?: React.ReactNode;
  onClick?(e: any): void;
  onScroll?(e: any): void;
}

const Flex = forwardRef(({ children, ...props }: IFlexProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <StyledFlex ref={ref} {...props}>
      {children}
    </StyledFlex>
  );
});

export default Flex;
