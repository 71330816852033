import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface INotification {
  createdDate: Date | string;
  id: string;
  message: string;
  type: "company" | "token" | "deposit" | "withdraw" | "representative" | "order";
  isReaded: boolean;
  objectID: string;
}

export interface INotificationsCenterState {
  openNotificationsCenter: boolean;
  notifications: INotification[];
  hasNew: boolean;
}

const initialState: INotificationsCenterState = {
  openNotificationsCenter: false,
  notifications: [],
  hasNew: false,
};

const notificationsCenterSlice = createSlice({
  name: "notificationsCenter",
  initialState,
  reducers: {
    setOpenNotificationsCenter(state, { payload }: PayloadAction<boolean>) {
      state.openNotificationsCenter = payload;
    },
    switchOpenNotificationsCenter(state) {
      state.openNotificationsCenter = !state.openNotificationsCenter;
    },
    setNotifications(state, { payload }: PayloadAction<INotification[]>) {
      state.notifications = payload;
    },
    updateNotifications(state, { payload }: PayloadAction<INotification[]>) {
      state.notifications = state?.notifications ? [...state.notifications, ...payload] : payload;
    },
    addNotification(state, { payload }: PayloadAction<INotification>) {
      state.notifications = [payload, ...state.notifications];
    },
    setIsReaded(state, { payload }: PayloadAction<Pick<INotification, "id" | "isReaded">>) {
      state.notifications.filter((notif) => notif.id === payload.id)[0].isReaded = payload.isReaded;
    },
    setAllIsReaded(state) {
      state.notifications.forEach((notif) => {
        notif.isReaded = true;
      });
    },
    setHasNewNotification(state, { payload }: PayloadAction<boolean>) {
      state.hasNew = payload;
    },
  },
});

export const {
  setOpenNotificationsCenter,
  switchOpenNotificationsCenter,
  setNotifications,
  setIsReaded,
  setAllIsReaded,
  updateNotifications,
  addNotification,
  setHasNewNotification,
} = notificationsCenterSlice.actions;
export const notificationsCenterReducer = notificationsCenterSlice.reducer;
