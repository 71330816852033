import styled, { css } from "styled-components";

export interface INotificationsCenter {
  open: boolean;
}

export const StyledNotificationsCenter = styled.div<INotificationsCenter>`
  box-shadow: -20px 0 30px rgba(0, 0, 0, 0.07);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 15px 0px;
  position: absolute;
  width: 25%;
  min-width: 557px;
  height: 60%;
  min-height: 400px;
  z-index: 100;
  transition-duration: 300ms;
  background: #fff;
  border-radius: 15px;
  right: 130px;
  top: 80px;
  ${({ theme, open }) => css`
    border: 1px solid ${theme.palette.primary.light}40;
    opacity: ${open ? "1" : "0"};
    z-index: ${open ? "100" : "-1"};
  `}
`;
