import styled, { css } from "styled-components";
import { ColorType } from "Theme";

export interface IStyledNotificationItem {
  isReaded: boolean;
  color?: ColorType;
  isLast?: boolean;
}

export const StyledNotificationItem = styled.div<IStyledNotificationItem>`
  position: relative;
  max-width: 100%;
  padding: 15px 20px;
  overflow: hidden;
  ${({ color, theme, isLast, isReaded }) => css`
    transition-delay: 500ms;
    border-bottom: ${!isLast && `1px solid ${theme.palette[color].light}26`};
    background-image: ${!isReaded && theme.palette.primary.gradient[3]};
  `}
`;

export const StyledImgWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  shrink: 0;
  ${({ theme }) => css`
    background-image: ${theme.palette.primary.gradient[10]};
  `}
`;
