import React from "react";
import { useAppSelector } from "store";
import { Text } from "Theme";

const CompanyName: React.FC = () => {
  const { company } = useAppSelector((state) => state.customer);

  return (
    <Text
      sx={{ mb: 1 }}
      rows={1}
      color="primary"
      title={`${company?.businessEntityType} "${company?.fullName}"`}
    >{`${company?.businessEntityType} "${company?.fullName}"`}</Text>
  );
};

export default CompanyName;
