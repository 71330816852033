import { IAdaptive, adaptiveStyles } from "Theme";
import styled, { css } from "styled-components";

export interface ISidebar extends IAdaptive {
  open: boolean;
}

export const StyledSidebar = styled.aside<ISidebar>`
  display: flex;
  flex-direction: column;
  background: white;
  width: 20%;
  min-width: 300px;
  position: fixed;
  height: 100%;
  padding: 30px;
  z-index: 1000;
  ${({ theme, open }) => css`
    ${theme.device.laptopM} {
      transition-duration: 300ms;
      left: ${open ? 0 : "-400px"};
    }
    ${theme.device.mobile} {
      transition-duration: 300ms;
      left: ${open ? 0 : "-635px"};
    }
  `}
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

export const SidebarTitle = styled.span<{ colored?: boolean }>`
  ${({ theme, colored }) => css`
    color: ${colored ? theme.palette.primary.light : theme.palette.text.main};
    font-weight: ${colored ? theme.typography.weight.medium : theme.typography.weight.normal};
    font-size: ${theme.typography.size.LG};
    ${theme.device.laptopL} {
      font-size: ${theme.typography.size.MD};
    }
  `}
`;
