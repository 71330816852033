import React from "react";
import { useAppSelector } from "store";
import { Badge, Card, EntityProps, Flex } from "Theme";

const Account: React.FC = () => {
  const { paymentDetails } = useAppSelector((state) => state.customer);

  return (
    <Flex sx={{ rowGap: 1, columnGap: 1 }}>
      {paymentDetails?.map((payment) => (
        <Card sx={{ rowGap: 1, mt: 1, width: "48%" }} bgWhite>
          <Flex sx={{ width: "100%", justify: "space-between" }}>
            <EntityProps title="Тип счёта" value={payment?.accountType} />
            <Badge label="Основные" color="info" />
          </Flex>
          <EntityProps title="БИК Банка" value={payment?.bik} />
          <EntityProps title="Наименование банка" value={payment?.bankName} />
          <EntityProps title="SWIFT" value={payment?.swiftCode} />
          <EntityProps title="ИНН банка" value={payment?.inn} />
          <EntityProps title="КПП банка" value={payment?.kpp} />
          <EntityProps title="Корр. счёт банка" value={payment?.corrAccount} />
          <EntityProps title="Статус Банка" value={payment?.bankStatus} />
          <EntityProps title="Номер счета" value={payment?.accountNumber} />
        </Card>
      ))}
    </Flex>
  );
};

export default Account;
