import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const CheckIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  contrast,
  // opacity,
  sx,
}) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 20)}
        fill="none"
      >
        <path
          fill="currentColor"
          d="M4.596 8.906 1.47 5.78.404 6.836l4.192 4.193 9-9L12.54.97 4.596 8.906Z"
        />
      </svg>
    </Text>
  );
};

export default CheckIcon;
