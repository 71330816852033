import { TokenOrder, OrdersAPI } from "@front-packages/dfa-gql-api";
import { formattingDate } from "helpers";
import { useErrors } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { ColumnsType, Flex, Paginator, RowsType, Table, Text } from "Theme";
import OrderModal from "../../../../orders/components/OrderModal";
import OpenModalBtn from "../OpenModalBtn";

interface IOrdersListProps {
  count: number;
  orders: TokenOrder[];
  page: number;
  setPage(page: number): void;
}

type Columns = "investor" | "createdDate" | "amount" | "sum" | "actions";

const OrdersList: React.FC<IOrdersListProps> = ({ count, orders, page, setPage }) => {
  const { setError } = useErrors();

  const columns = useRef<ColumnsType<Columns>>([
    { label: "инвестор", field: "investor", proportion: 4, color: "primary" },
    { label: "создана", field: "createdDate", proportion: 1 },
    { label: "количество", field: "amount", proportion: 1 },
    { label: "сумма", field: "sum", proportion: 1 },
    { label: "действия", field: "actions", proportion: 1 },
  ]);

  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [currentOrderData, setCurrentOrderData] = useState<TokenOrder>(null);
  const getOrderById = async (orderID: string) => {
    const { response, error } = await OrdersAPI.GetOrderByID({ orderID });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setCurrentOrderData(response);
      setOrderModal(true);
    }
  };

  const [rows] = useState<RowsType<Columns>>(
    orders?.map((o) => {
      return {
        investor: o.investor.fullName,
        createdDate: formattingDate(o.createDate),
        amount: `${o.amount} шт`,
        sum: `${o.orderSum} руб`,
        actions: <OpenModalBtn onOpen={() => getOrderById(o.id)} />,
      };
    })
  );

  useEffect(() => {
    return () => setCurrentOrderData(null);
  }, []);
  return (
    <>
      <Flex sx={{ direction: "column", gap: 1, width: "100%" }}>
        <Text variant="h4" sx={{ weight: "regular", mt: 2 }}>
          Заявки ({count})
        </Text>
        <Flex sx={{ width: "100%", maxHeight: "450px", overflowY: "scroll" }}>
          <Table
            rowHeight={40}
            columns={columns.current}
            rows={rows}
            rowSx={{ rowStyle: "white" }}
          />
        </Flex>
        {count > 10 && (
          <Paginator page={page} amount={Math.ceil(count / 10)} setPage={setPage} withInput />
        )}
      </Flex>
      {currentOrderData && (
        <OrderModal
          open={!!currentOrderData && orderModal}
          onClose={() => setOrderModal(false)}
          order={currentOrderData}
        />
      )}
    </>
  );
};

export default OrdersList;
