import React from "react";
import { Button, Card, Flex } from "Theme";
import { DownloadIcon } from "Theme/icons";

const DocItem: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Flex sx={{ justify: "space-between" }}>
      <Button variant="link">{title}</Button>
      <div style={{ cursor: "pointer" }}>
        <DownloadIcon color="primary" />
      </div>
    </Flex>
  );
};

const Documents: React.FC = () => {
  return (
    <Flex sx={{ width: "100%", columnGap: 1, mt: 1 }}>
      <Card bgWhite title="Клиент">
        <DocItem title="Первый документ" />
        <DocItem title="Второй документ" />
        <DocItem title="Третий документ" />
        <DocItem title="Четвертый документ" />
        <DocItem title="Пятый документ" />
      </Card>
      <Card bgWhite title="Представитель">
        <DocItem title="Первый документ" />
        <DocItem title="Второй документ" />
        <DocItem title="Третий документ" />
        <DocItem title="Четвертый документ" />
        <DocItem title="Пятый документ" />
      </Card>
    </Flex>
  );
};

export default Documents;
