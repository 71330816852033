import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const TollIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 40)}
        height={getSize(size, 40)}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M600-160q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Zm-351.666-22.666q-94-30.334-151.167-112.667T40-480q0-102.334 57.167-184.667t151.167-112.667q16.333-5.999 28.666 3.501 12.334 9.499 12.334 28.166 0 9.333-6.5 17.833-6.5 8.5-15.834 12.167-73 25-116.667 90.167-43.667 65.166-43.667 145.5t43.667 145.5Q194-269.333 267-244.333q9.334 3.667 15.834 11.667t6.5 18.333q0 18.333-12.334 28-12.333 9.666-28.666 3.667ZM600-480Zm0 253.334q104.667 0 179-74.334 74.334-74.333 74.334-179T779-659q-74.333-74.334-179-74.334T421-659q-74.334 74.333-74.334 179T421-301q74.333 74.334 179 74.334Z" />
      </svg>
    </Text>
  );
};

export default TollIcon;
