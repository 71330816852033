import { pxToRem } from "./helpers";

export interface FontSize {
  XXS: string;
  XS: string;
  SM: string;
  MD: string;
  LG: string;
  XL: string;
  XXL: string;
  XXXL: string;
}
export interface FontWeight {
  thin: 100;
  light: 300;
  normal: 400;
  regular: 500;
  medium: 600;
  bold: 700;
  extraBold: 900;
}

export interface ITypography {
  size: FontSize;
  weight: FontWeight;
}
export const typography: ITypography = {
  size: {
    XXS: pxToRem(10.4),
    XS: pxToRem(12),
    SM: pxToRem(16),
    MD: pxToRem(20),
    LG: pxToRem(24),
    XL: pxToRem(28),
    XXL: pxToRem(32),
    XXXL: pxToRem(36),
  },
  weight: {
    thin: 100,
    light: 300,
    normal: 400,
    regular: 500,
    medium: 600,
    bold: 700,
    extraBold: 900,
  },
};
