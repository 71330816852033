import React, { useEffect, useState } from "react";
import { Checkbox, Input, SearchPanel } from "Theme";
import { IFilterProps } from "../../types";

interface ISearchFormProps {
  filterProps: IFilterProps;
  setFilterProps(filterProps: IFilterProps): void;
  isLoading: boolean;
}

const SearchForm: React.FC<ISearchFormProps> = ({ filterProps, setFilterProps, isLoading }) => {
  const [ticker, setTicker] = useState<string>(filterProps.ticker || "");
  const [issuer, setIssuer] = useState<string>(filterProps.issuer || "");
  const [isInMyBriefcase, setIsInMyBriefcase] = useState<boolean>(false);

  const hadleClick = () => setFilterProps({ ticker, issuer, isInMyBriefcase });

  useEffect(() => {
    setTicker(filterProps?.ticker || ticker);
    setIssuer(filterProps?.issuer || issuer);
  }, [filterProps]);

  return (
    <SearchPanel
      isLoading={isLoading}
      withOpenArrow={false}
      fullwidth
      sx={{ align: "flex-start" }}
      headerChildren={
        <>
          <Input
            fullWidth
            colored
            placeholder="Тикер"
            sx={{ height: "40px" }}
            value={ticker}
            onChange={setTicker}
          />
          <Input
            fullWidth
            colored
            placeholder="Эмитент"
            sx={{ height: "40px" }}
            value={issuer}
            onChange={setIssuer}
          />
          <Checkbox
            name="hasInBreafcase"
            label="В портфеле"
            sx={{ maxWidth: "max-content", width: "100%", alignSelf: "center" }}
            checked={isInMyBriefcase}
            onChange={() => setIsInMyBriefcase((prev) => !prev)}
          />
        </>
      }
      onSearch={hadleClick}
      // TODO: потом доделать нормальное поведение бейджей. Сделать универсальное для всех
      // badgesData={{ state: filterProps, setState: setFilterProps }}
    />
  );
};

export default SearchForm;
