import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const PhoneIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 45)}
        height={getSize(size, 46)}
        fill="none"
      >
        <path
          fill="url(#a)"
          fillOpacity={0.25}
          d="M12.262 9.875c.113 1.669.394 3.3.844 4.856l-2.25 2.25a27.8 27.8 0 0 1-1.425-7.106h2.832ZM30.75 32.413c1.594.45 3.225.73 4.875.843v2.794a28.926 28.926 0 0 1-7.125-1.406l2.25-2.231ZM14.062 6.125H7.5A1.88 1.88 0 0 0 5.625 8c0 17.606 14.269 31.875 31.875 31.875A1.88 1.88 0 0 0 39.375 38v-6.544a1.88 1.88 0 0 0-1.875-1.875c-2.325 0-4.594-.375-6.694-1.069a1.575 1.575 0 0 0-.581-.093 1.92 1.92 0 0 0-1.331.543l-4.125 4.125c-5.306-2.718-9.656-7.05-12.356-12.356l4.125-4.125a1.882 1.882 0 0 0 .468-1.912A21.302 21.302 0 0 1 15.937 8a1.88 1.88 0 0 0-1.874-1.875Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={5.625}
            x2={39.375}
            y1={39.875}
            y2={6.125}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#398151" />
            <stop offset={1} stopColor="#35C691" />
          </linearGradient>
        </defs>
      </svg>
    </Text>
  );
};

export default PhoneIcon;
