/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { OrdersAPI } from "@front-packages/dfa-gql-api";
import {
  Badge,
  Card,
  Flex,
  Dialog,
  Text,
  Input,
  EntityProps,
  RowEntityProps,
} from "../../../../Theme";
import { amountWithSpaces, getStatusBadgeProps } from "../../../../helpers";
import { useAppDispatch } from "../../../../store";
import { setPageLoading } from "../../../../store/global";
import { useErrors } from "../../../../hooks";
import { addNotification } from "../../../../store/notifications";

interface IAssetsCard {
  // TODO: Genapi redeemData: RedeemOrder;
  redeemData: any;
  getRedeemData(): Promise<void>;
  isLoading?: boolean;
}

const AssetsCard = ({ redeemData, getRedeemData, isLoading }: IAssetsCard) => {
  const dispatch = useAppDispatch();
  const { setError } = useErrors();
  const [isOpenRedeemDialog, setIsOpenRedeemDialog] = useState<boolean>(false);
  const openRedeemDialog = () => setIsOpenRedeemDialog(true);
  const closeRedeemDialog = () => setIsOpenRedeemDialog(false);
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);

  const [amount, setAmount] = useState<string>("");
  const changeAmount = (val: string) => setAmount(val);

  const cancelRedeemDialog = () => {
    setIsOpenRedeemDialog(false);
    setIsCreateLoading(false);
    setAmount("");
  };

  // TODO: удалить после добавления на мидле
  const nominal = 10;

  // TODO: Genapi CreateRedeemOrder delete!
  const createRedeemOrder = async () => {
    setIsCreateLoading(true);
    // const { response, error } = await OrdersAPI.CreateRedeemOrder({
    //   // TODO: sum - +amount * nominal после изменений на мидле
    //   form: { ticker: redeemData.ticker, amount: +amount, sum: +amount * nominal },
    // });
    // if (response) {
    //   await getRedeemData().then(() => dispatch(setPageLoading(false)));
    //   dispatch(
    //     addNotification({
    //       message: "Заявка на погашение токена успешно создана",
    //       type: "success",
    //       position: "right",
    //     })
    //   );
    // }
    // if (error) setError({ error, key: Math.random() });
    setIsCreateLoading(false);
  };

  const onConfirm = async () => {
    await createRedeemOrder();
    cancelRedeemDialog();
  };

  const author = `${redeemData?.author?.lastName} ${redeemData?.author?.firstName} ${redeemData?.author?.secondName} (${redeemData?.author?.businessEntityType} ${redeemData?.author?.companyName})`;

  return (
    <>
      <Card
        bgWhite
        sx={{ align: "center", minHeight: "70px", width: "100%" }}
        adaptiveSX={{ mobile: { direction: "column-reverse" } }}
        onClick={openRedeemDialog}
      >
        <Flex
          sx={{ justify: "space-around", align: "center", width: "75%" }}
          adaptiveSX={{ mobile: { width: "100%" } }}
        >
          <EntityProps
            isLoading={isLoading}
            color="info"
            title="Тикер"
            value={redeemData?.ticker}
          />
          <EntityProps
            isLoading={isLoading}
            color="info"
            title="Стоимость"
            value={`${amountWithSpaces(redeemData?.sum)} RUB`}
          />
          <EntityProps
            isLoading={isLoading}
            color="info"
            title="Количество"
            value={`${redeemData?.amount} ШТ.`}
          />
        </Flex>
        <Flex
          adaptiveSX={{ mobile: { width: "100%", mb: 1 } }}
          sx={{ justify: "flex-end", align: "center", width: "25%" }}
        >
          <Badge
            {...getStatusBadgeProps(redeemData?.status, "RedeemOrder")}
            isLoading={isLoading}
          />
        </Flex>
      </Card>
      <Dialog
        title="Погашение токена"
        open={isOpenRedeemDialog}
        onClose={closeRedeemDialog}
        onConfirm={onConfirm}
        disabled={!amount}
        loading={isCreateLoading}
        titleBadge={
          <Badge sx={{ ml: "auto" }} {...getStatusBadgeProps(redeemData?.status, "RedeemOrder")} />
        }
        maxWidth="lg"
      >
        <Text color="secondary" sx={{ mb: 1, size: "MD" }}>
          Для подачи заявки на погашение токена, заполните необходимые поля
        </Text>
        <RowEntityProps sx={{ mb: 1 }} title="Тикер" value={redeemData?.ticker} />
        <RowEntityProps
          title="Автор заявки"
          value={author}
          sxValue={{ decoration: "underline" }}
          color="info"
        />
        <Flex sx={{ columnGap: 1, mt: 2 }}>
          <Input
            sx={{ width: "40%" }}
            placeholder="Количество"
            value={amount}
            onChange={changeAmount}
          />
          {/*  TODO: sum - +amount * nominal после изменений на мидле */}
          <Input
            fullWidth
            placeholder="Сумма заявки"
            value={(+amount * nominal).toString() || ""}
            onChange={changeAmount}
            disabled
          />
        </Flex>
      </Dialog>
    </>
  );
};

export default AssetsCard;
