import React from "react";
import { DateRange } from "@front-packages/dfa-constants";
import DatePicker from "../DatePicker";
import Flex from "../Flex";
import { MarginType, SizeType } from "../../types";

interface IDateRangePickerProps {
  label: string;
  dateRange: DateRange;
  sx?: MarginType & SizeType;
  setDateRange?(dateRange: DateRange): void;
  colored?: boolean;
  withTime?: boolean;
}

const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  dateRange,
  setDateRange,
  label,
  colored,
  withTime = false,
  sx,
}) => {
  const setFirstDate = (date: Date) => {
    setDateRange({
      ...dateRange,
      firstDate: date || ("" as any),
      lastDate: dateRange?.lastDate ? dateRange.lastDate : ("" as any),
    });
    if (!date && !dateRange.lastDate) setDateRange(null);
  };
  const setLastDate = (date: Date) => {
    setDateRange({
      ...dateRange,
      firstDate: dateRange?.firstDate ? dateRange.firstDate : ("" as any),
      lastDate: date || ("" as any),
    });
    if (!date && !dateRange.firstDate) setDateRange(null);
  };

  return (
    <Flex sx={{ width: "100%", direction: "column", ...sx }}>
      <Flex sx={{ align: "center", columnGap: 1 }}>
        <DatePicker
          colored={colored}
          fullWidth
          placeholder={`${label}, от`}
          value={dateRange?.firstDate}
          maxDate={dateRange?.lastDate}
          onChange={setFirstDate}
          withTime={withTime}
        />
        <DatePicker
          colored={colored}
          fullWidth
          placeholder={`${label}, до`}
          value={dateRange?.lastDate}
          minDate={dateRange?.firstDate}
          onChange={setLastDate}
          withTime={withTime}
        />
      </Flex>
    </Flex>
  );
};

export default DateRangePicker;
