import React, { useEffect, useState } from "react";
import {
  FileDocumentType,
  FilesAPI,
  File,
  TokenOrder,
  TokenOrderStatusSql,
} from "@front-packages/dfa-gql-api";
import { Badge, Button, Dialog, Flex, RowEntityProps } from "Theme";
import { IconContract, RequestQuote } from "Theme/icons";
import { formattingDate, getStatusBadgeProps } from "helpers";
import { useErrors } from "hooks";
import ViewModal from "./ViewModal";

interface IOrderModalProps {
  open: boolean;
  onClose(): void;
  order: TokenOrder;
}
const OrderModal: React.FC<IOrderModalProps> = ({ onClose, open, order }) => {
  const { setError } = useErrors();
  const [viewModal, setViewModal] = useState<FileDocumentType>(null);
  const [filesData, setFilesData] = useState<File[]>(null);

  const getOrderFiles = async () => {
    const { response, error } = await FilesAPI.GetOrderFiles({ orderID: order.id });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setFilesData(response);
    }
  };

  const onOpenViewModal = async (type: FileDocumentType) => {
    setViewModal(type);
  };

  const token = order?.token;

  useEffect(() => {
    getOrderFiles();
  }, []);

  if (viewModal) {
    return (
      <ViewModal
        open={!!viewModal}
        onClose={() => setViewModal(null)}
        type={viewModal}
        filesData={filesData}
      />
    );
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Данные о заявке"
      titleBadge={
        <Badge
          color="white"
          sx={{ ml: "auto" }}
          label={getStatusBadgeProps(order.status, "Order").label}
        />
      }
      maxWidth="sm"
      cancelText="Назад"
    >
      <Flex sx={{ rowGap: 0.5, direction: "column" }}>
        <RowEntityProps
          rows={1}
          title="Дата создания заявки"
          value={formattingDate(order.createDate)}
        />
        <RowEntityProps rows={1} title="Тикер" value={token.ticker} />
        <RowEntityProps rows={1} title="Эмитент" value={token.issuer} />
        <RowEntityProps
          rows={1}
          title="Дата выпуска"
          value={formattingDate(token.investStartDate)}
        />
        <RowEntityProps rows={1} title="Номинал" value={`${token.nominal} RUB`} />
        <RowEntityProps rows={1} title="Количество" value={`${token.amount} шт`} />
        <RowEntityProps rows={1} title="Сумма" value={`${token.amount * token.nominal} RUB`} />
        {order?.status !== TokenOrderStatusSql.Signing && filesData?.length ? (
          <Flex sx={{ columnGap: 1, mt: 1 }}>
            <Button
              variant="outline"
              sx={{ direction: "column", height: "95px", justify: "space-between" }}
              color="info"
              onClick={() => onOpenViewModal(FileDocumentType.TokenOrderContract)}
              iconStart={<IconContract size="large" />}
            >
              Договор
            </Button>
            <Button
              sx={{ direction: "column", height: "95px", justify: "space-between" }}
              variant="outline"
              color="info"
              onClick={() => onOpenViewModal(FileDocumentType.TokenOrderPayment)}
              iconStart={<RequestQuote size="large" />}
            >
              Счёт
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </Dialog>
  );
};

export default OrderModal;
