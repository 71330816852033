import {
  ATIcon,
  BuildingIcon,
  CorporateFareIcon,
  DiamondIcon,
  DifferenceIcon,
  DocWithAnglesIcon,
  EventIcon,
  HashtagIcon,
  LongReceiptIcon,
  PercentIcon,
  PersonIcon,
  PhoneIcon,
  TollIcon,
} from "Theme/icons";
import { IIcon } from "Theme/icons/default";
import { ColorType } from "Theme/types";
import React from "react";
import { InfoCardIconType } from "./types";

interface IInfoCardIcon {
  type: InfoCardIconType;
  color?: ColorType;
}
const InfoCardIcon: React.FC<IInfoCardIcon> = ({ type, color = "primary" }) => {
  const defaultProps: IIcon = {
    light: true,
    color,
    opacity: 50,
    size: "medium",
  };
  switch (type) {
    case "diamond":
      return <DiamondIcon {...defaultProps} />;
    case "corporateFareIcon":
      return <CorporateFareIcon {...defaultProps} />;
    case "event":
      return <EventIcon {...defaultProps} />;
    case "longReceipt":
      return <LongReceiptIcon {...defaultProps} />;
    case "toll":
      return <TollIcon {...defaultProps} />;
    case "percent":
      return <PercentIcon {...defaultProps} />;
    case "difference":
      return <DifferenceIcon {...defaultProps} />;
    case "building":
      return <BuildingIcon {...defaultProps} />;
    case "hashtag":
      return <HashtagIcon {...defaultProps} />;
    case "person":
      return <PersonIcon {...defaultProps} />;
    case "phone":
      return <PhoneIcon {...defaultProps} />;
    case "docWithAngles":
      return <DocWithAnglesIcon {...defaultProps} />;
    case "AT":
      return <ATIcon {...defaultProps} />;
    default:
      return <LongReceiptIcon {...defaultProps} />;
  }
};

export default InfoCardIcon;
