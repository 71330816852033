import React from "react";
import {
  AuthPage,
  IssuesInvestor,
  IssuesIssuer,
  InvitePage,
  BriefcaseControls,
  Assets,
  OrdersHistory,
  EditIssue,
  ViewIssue,
  TokensOrders,
  OnBoardingPage,
  SecondaryMarket as SecondaryMarketPage,
  ProfilePage,
  IconsKIT as IconsKITPage,
} from "./pages";
import { IIcon } from "./Theme/icons/default";
import { StoreIcon, IconBriefcase, LongReceiptIcon, IconDollarFile } from "./Theme/icons";
import ReceiptIcon from "./Theme/icons/receipt";
import ViewToken from "./pages/secondaryMarket/components/ViewToken";

export enum RoutesNames {
  SignIn = "/authenticate/sign-in",
  Invite = "/authenticate/invite",
  OnBoarding = "/authenticate/on-boarding",
  Marketplace = "/marketplace",
  Issues = "/issues",
  Orders = "/tokens-orders",
  EditIssues = "/issues/edit",
  ViewIssues = "/issues/view",
  Briefcase = "/briefcase",
  BriefcaseAssets = "/briefcase/assets",
  OrderHistory = "/briefcase/orders-history",
  SecondaryMarket = "/secondaryMarket",
  ViewTokens = "/secondaryMarket/view",
  Profile = "/profile",
  IconsKIT = "/uikit/icons",
}

export enum RoutesTypes {
  Auth,
  First,
  Second,
  NotMenu,
}

export type RouteType = {
  key: string;
  type: RoutesTypes;
  path: RoutesNames;
  component: React.ReactNode;
  onlyIssuer?: boolean;
  onlyInvestor?: boolean;
  name?: string;
  Icon?: React.FC<IIcon>;
  children?: Array<RouteType>;
};

export const routes: Array<RouteType> = [
  {
    key: "AuthPage",
    type: RoutesTypes.Auth,
    path: RoutesNames.SignIn,
    component: <AuthPage />,
  },
  {
    key: "InvitePage",
    type: RoutesTypes.Auth,
    path: RoutesNames.Invite,
    component: <InvitePage />,
  },
  {
    key: "OnBoardingPage",
    type: RoutesTypes.Auth,
    path: RoutesNames.OnBoarding,
    component: <OnBoardingPage />,
  },
  {
    key: "ProfilePage",
    type: RoutesTypes.NotMenu,
    path: RoutesNames.Profile,
    component: <ProfilePage />,
  },
  {
    Icon: IconBriefcase,
    key: "Briefcase",
    type: RoutesTypes.First,
    path: RoutesNames.Briefcase,
    name: "Портфель",
    component: <BriefcaseControls />,
    children: [
      {
        Icon: IconDollarFile,
        key: "Assets",
        type: RoutesTypes.Second,
        path: RoutesNames.BriefcaseAssets,
        name: "Активы",
        component: <Assets />,
      },
      {
        Icon: ReceiptIcon,
        key: "OrderHistory",
        type: RoutesTypes.Second,
        path: RoutesNames.OrderHistory,
        name: "Операции по ЛС",
        component: <OrdersHistory />,
      },
    ],
  },
  {
    key: "Marketplace",
    type: RoutesTypes.First,
    path: RoutesNames.Marketplace,
    name: "Маркетплейс",
    onlyInvestor: true,
    Icon: StoreIcon,
    component: <IssuesInvestor />,
  },
  {
    key: "SecondaryMarket",
    type: RoutesTypes.First,
    path: RoutesNames.SecondaryMarket,
    name: "Вторичный рынок",
    onlyInvestor: true,
    Icon: StoreIcon,
    component: <SecondaryMarketPage />,
  },
  {
    key: "Issues",
    type: RoutesTypes.First,
    path: RoutesNames.Issues,
    name: "Выпуски",
    onlyIssuer: true,
    Icon: StoreIcon,
    component: <IssuesIssuer />,
  },
  {
    key: "Orders",
    type: RoutesTypes.First,
    path: RoutesNames.Orders,
    name: "Заявки",
    onlyInvestor: true,
    Icon: LongReceiptIcon,
    component: <TokensOrders />,
  },
  {
    key: "EditIssues",
    type: RoutesTypes.NotMenu,
    path: RoutesNames.EditIssues,
    onlyIssuer: true,
    component: <EditIssue />,
  },
  {
    key: "ViewIssue",
    type: RoutesTypes.NotMenu,
    path: RoutesNames.ViewIssues,
    component: <ViewIssue />,
  },
  {
    key: "ViewTokens",
    type: RoutesTypes.NotMenu,
    path: RoutesNames.ViewTokens,
    component: <ViewToken />,
  },
  {
    key: "IconsKIT",
    type: RoutesTypes.NotMenu,
    path: RoutesNames.IconsKIT,
    component: <IconsKITPage />,
  },
];

export function extractRoutes(array: Array<RouteType>): Array<RouteType> {
  return array.reduce((acc, value) => {
    acc.push(value);
    if (value?.children) {
      acc = acc.concat(extractRoutes(value.children));
    }
    return acc;
  }, []);
}
