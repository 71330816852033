import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const CircleCheckIcon: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  contrast,
  opacity,
  sx,
}) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        opacity={opacity ? opacity / 100 : undefined}
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        width={getSize(size, 24)}
        fill="currentColor"
        viewBox="0 96 960 960"
      >
        <path d="m419 773 294-294-66-66-228 228-111-111-65 66 176 177Zm61.138 228q-88.138 0-165.625-33.084-77.488-33.083-135.417-91.012T88.084 741.625Q55 664.276 55 576.138 55 487 88.084 409.513q33.083-77.488 90.855-134.969 57.772-57.482 135.195-91.013Q391.557 150 479.779 150q89.221 0 166.827 33.454 77.605 33.453 135.012 90.802 57.407 57.349 90.895 134.877Q906 486.66 906 576q0 88.276-33.531 165.747-33.531 77.471-91.013 135.278-57.481 57.808-134.831 90.891Q569.276 1001 480.138 1001ZM480 907q138 0 234.5-96.372T811 576q0-138-96.5-234.5t-235-96.5q-137.5 0-234 96.5t-96.5 235q0 137.5 96.372 234T480 907Zm0-331Z" />
      </svg>
    </Text>
  );
};

export default CircleCheckIcon;
