import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "@front-packages/dfa-helpers";
import { RoundIcon, SliderArrow } from "Theme/icons";
import { RoutesNames } from "routes";
import { Button, Card, Flex, Text } from "../../../../Theme";
import { useAppSelector } from "../../../../store";
import { amountWithSpaces } from "../../../../helpers";

const DFABalance: React.FC = () => {
  const navigate = useNavigate();
  const { balance } = useAppSelector((state) => state.customer);
  const [count] = useState<number>(3);
  const [page, setPage] = useState<number>(0);
  const [pageIndexesArr, setPageIndexesArr] = useState([]);

  const goToPrevious = () => {
    const isFirstSlide = page === 0;
    const newIndex = isFirstSlide ? pageIndexesArr.length - 1 : page - 1;
    setPage(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = page === pageIndexesArr.length - 1;
    const newIndex = isLastSlide ? 0 : page + 1;
    setPage(newIndex);
  };

  useEffect(() => {
    if (balance?.token?.balance?.length) {
      const countOfPages = Math.ceil(balance.token.balance.length / count);
      const arr = [];
      for (let i = 0; i < countOfPages; i++) {
        arr.push(i);
      }
      setPageIndexesArr(arr);
    }
  }, [balance?.token?.balance?.length]);
  return (
    <Card
      watermark="ЦФА"
      title="ЦФА"
      type="info"
      filled
      sx={{ minHeight: "210px", mb: 0.5, flex: 1, minWidth: "390px", maxWidth: "30%" }}
      adaptiveSX={{
        laptop: { maxWidth: "48.5%" },
        tablet: { maxWidth: "100%" },
      }}
    >
      {balance?.token?.balance?.length ? (
        <>
          {pageIndexesArr.length > 1 ? (
            <>
              <Button
                iconStart={<SliderArrow rotate="left" color="white" size="medium" />}
                sx={{ position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)" }}
                onlyIcon
                onClick={goToPrevious}
              />
              <Button
                iconStart={<SliderArrow rotate="right" color="white" size="medium" />}
                sx={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}
                onlyIcon
                onClick={goToNext}
              />
            </>
          ) : null}
          <Flex
            sx={{
              direction: "column",
              height: "80%",
            }}
          >
            <Text sx={{ mb: 0.3, size: "SM", weight: "bold", mr: 0, ml: "auto" }} color="contrast">
              Обновлен:
              <Text sx={{ ml: 0.3, size: "SM" }} color="inherit">
                {balance?.token?.balance[0]?.lastUpdate
                  ? formatDate(balance.token.balance[0].lastUpdate, true)
                  : "Нет данных"}
              </Text>
            </Text>
            <Flex sx={{ direction: "column", rowGap: 1, mt: 1, ml: 1, mr: 1 }}>
              {balance?.token?.balance?.map(
                (item, i) =>
                  i >= page * count &&
                  i < page * count + count && (
                    <Flex key={item?.ticker} sx={{ columnGap: 1, justify: "space-between" }}>
                      <Text color="contrast">{item?.ticker}</Text>
                      <Text color="inherit">{amountWithSpaces(item?.amount)} ШТ</Text>
                    </Flex>
                  )
              )}
            </Flex>
            <Flex
              sx={{
                position: "absolute",
                bottom: 0,
                right: "50%",
                transform: "translate(50%)",
                direction: "column",
                mb: 1,
                rowGap: 0.5,
              }}
            >
              <Flex sx={{ columnGap: 0.5, justify: "center" }}>
                {pageIndexesArr?.length &&
                  pageIndexesArr.map((pageBtnIndex) => (
                    <Button
                      key={`${pageBtnIndex} ${Math.random()}`}
                      iconStart={<RoundIcon active={pageBtnIndex === page} size="tinySmall" />}
                      sx={{ size: "XXS", height: "max-content" }}
                      onlyIcon
                      onClick={() => setPage(pageBtnIndex)}
                    />
                  ))}
              </Flex>
              <Button
                color="white"
                sx={{ size: "XXS", p: 0, height: "max-content", weight: "thin" }}
                variant="link"
                onClick={() => navigate(RoutesNames.BriefcaseAssets)}
              >
                показать все
              </Button>
            </Flex>
          </Flex>
        </>
      ) : (
        <Text
          color="white"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            textAlign: "center",
          }}
        >
          У Вас в портфеле нет токенов
        </Text>
      )}
    </Card>
  );
};

export default DFABalance;
