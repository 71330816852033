import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { delay } from "@front-packages/dfa-helpers";
import ReactDOM from "react-dom";
import { StyledDialog, DialogWrap, IStyledDialog } from "./Dialog";
import { ColorType } from "../../types";
import Flex from "../Flex";
import Text from "../Text";
import Button from "../Button";

export interface IDialogProps extends IStyledDialog {
  open: boolean;
  title: string;
  onClose(e?): void;
  onConfirm?(e?): void;
  disabled?: boolean;
  withMissClick?: boolean;
  subTitle?: string;
  confirmText?: string;
  cancelText?: string;
  type?: ColorType;
  loading?: boolean;
  children?: React.ReactNode;
  titleBadge?: React.ReactNode;
}

const Dialog: React.FC<IDialogProps> = ({
  title,
  open,
  type = "primary",
  subTitle,
  confirmText,
  children,
  onClose,
  onConfirm,
  disabled,
  loading,
  withMissClick = true,
  cancelText,
  titleBadge,
  ...props
}) => {
  const [opacity, setOpacity] = useState<0 | 1>(0);
  const ref = useRef(null);

  const handleClose = () => {
    setOpacity(0);
    delay(300).then(() => onClose());
  };
  const missClick = (e: SyntheticEvent) => {
    if (ref.current && !ref.current.contains(e.target)) handleClose();
  };

  useEffect(() => {
    setOpacity(open ? 1 : 0);
    const body = document.querySelector("body");
    if (open) body.style.overflow = "hidden";
    else body.style.overflow = "auto";
  }, [open]);

  if (!open) return null;
  return ReactDOM.createPortal(
    <DialogWrap onClick={withMissClick ? missClick : undefined} opacity={opacity} color={type}>
      <StyledDialog ref={ref} {...props}>
        <Flex
          bgColor={type}
          sx={{
            mb: 1.2,
            p: "1rem",
            borderRadiusTopLeft: "20px",
            borderRadiusTopRight: "20px",
            align: "center",
          }}
        >
          <Text color="contrast" variant="h5">
            {title}
          </Text>
          {titleBadge && titleBadge}
        </Flex>
        {subTitle && <Flex sx={{ p: "0 30px 20px" }}>{subTitle}</Flex>}
        {children && (
          <Flex sx={{ p: "0 30px 20px", direction: "column", overflow: "auto" }}>{children}</Flex>
        )}
        <Flex sx={{ p: "0 1.4rem 1.4rem", justify: "flex-end" }}>
          {onConfirm && (
            <Button
              onClick={onConfirm}
              color={type}
              sx={{ mr: 0.8 }}
              disabled={disabled}
              loading={loading}
              id="confirmBtn"
            >
              {confirmText || "Подтвердить"}
            </Button>
          )}
          <Button
            variant="outline"
            disabled={loading}
            onClick={handleClose}
            color="secondary"
            id="cancelBtn"
          >
            {cancelText || "Отмена"}
          </Button>
        </Flex>
      </StyledDialog>
    </DialogWrap>,
    document.querySelector("#portal-dialog")
  );
};

export default Dialog;
