import React from "react";

interface ICheckboxIcon {
  checked: boolean;
}

const CheckboxIcon: React.FC<ICheckboxIcon> = ({ checked }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24">
      {checked ? (
        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
      ) : (
        <rect height="18" width="18" x="3" y="3" rx={5} />
      )}
    </svg>
  );
};

export default CheckboxIcon;
