import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CircleLoader, ColorType, Flex } from "Theme";
import { DepositRequestDialog, WithdrawOrderDialog } from "components";
import { useAppSelector } from "store";
import { RoutesNames } from "routes";
import { StyledAuthLayout, StyledDefaultLayout, LoaderWrap } from "./Layout";
import {
  Footer,
  SideBar,
  CustomerInfo,
  Header,
  RightAuthLayoutComponent,
  NotificationsCenter,
} from "./components";
import { fadeIn } from "./animations";

const Layout: React.FC = () => {
  const { pathname } = useLocation();
  const { account, global } = useAppSelector((state) => state);
  const { isAuth, onBoardingStep } = account;
  const { globalLoading } = global;

  const getBgColor = (): ColorType => {
    if (onBoardingStep === 1) return "primary";
    if (onBoardingStep === 2) return "warning";
    if (onBoardingStep === 3) return "success";
    if (onBoardingStep === 4) return "secondary";
    if (onBoardingStep === 5) return "success";
    if (onBoardingStep === 6) return "error";
    if (onBoardingStep === 7) return "warning";
    if (onBoardingStep === 8) return "success";
    return "primary";
  };

  if (globalLoading)
    return (
      <LoaderWrap>
        <CircleLoader />
      </LoaderWrap>
    );
  if (!isAuth)
    return (
      <StyledAuthLayout>
        <Flex sx={{ width: "100%", flex: 1 }}>
          <Flex sx={{ flex: 5, justify: "center", align: "center" }}>
            <Outlet />
          </Flex>
          <Flex
            bgColor="primary"
            sx={{
              flex: 4,
              direction: "column",
              justify: "center",
              align: "center",
              borderRadius: "20px",
              p: "20px",
              shadow: "left",
            }}
            adaptiveSX={{ mobile: { display: "none" } }}
          >
            <RightAuthLayoutComponent />
            <Footer contrast auth />
          </Flex>
        </Flex>
      </StyledAuthLayout>
    );
  if (isAuth && pathname === RoutesNames.OnBoarding)
    return (
      <StyledAuthLayout>
        <Flex
          sx={{ width: "100%", flex: 1, direction: "column", justify: "center", align: "center" }}
        >
          <Flex
            sx={{
              width: "100%",
              flex: 1,
              direction: pathname.includes("on-boarding") ? "row-reverse" : "row",
            }}
          >
            <Flex sx={{ flex: 5, justify: "center", align: "center", position: "relative" }}>
              <Outlet />
            </Flex>
            <Flex
              bgColor={getBgColor()}
              sx={{
                flex: 4,
                direction: "column",
                justify: "center",
                align: "center",
                borderRadius: "20px",
                p: "20px",
                shadow: "left",
              }}
              adaptiveSX={{ mobile: { display: "none" } }}
            >
              <RightAuthLayoutComponent />
              <Footer contrast auth />
            </Flex>
          </Flex>
        </Flex>
      </StyledAuthLayout>
    );
  return (
    <>
      <SideBar />
      <StyledDefaultLayout>
        <Flex
          sx={{ width: "95%", flex: 1, direction: "column", justify: "center", align: "center" }}
          adaptiveSX={{ laptop: { width: "100%" } }}
        >
          <Header />
          <Flex
            animation={fadeIn}
            key={`content-${pathname}`}
            sx={{ width: "100%", flex: 1, direction: "column", align: "center" }}
          >
            <Outlet />
          </Flex>
          <Footer />
          <WithdrawOrderDialog />
          <DepositRequestDialog />
        </Flex>
      </StyledDefaultLayout>
      <NotificationsCenter />
      <CustomerInfo />
    </>
  );
};

export default Layout;
