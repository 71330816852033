import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const PersonIcon: React.FC<IIcon> = ({
  color = "inherit",
  light = false,
  opacity = null,
  size = "small",
  contrast,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
      opacity={opacity}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 31)}
        height={getSize(size, 30)}
        fill="none"
      >
        <path
          fill="url(#a)"
          fillOpacity={0.25}
          d="M15.5 3.75a3.761 3.761 0 0 1 3.75 3.75 3.761 3.761 0 0 1-3.75 3.75 3.761 3.761 0 0 1-3.75-3.75 3.761 3.761 0 0 1 3.75-3.75Zm0 18.75c5.063 0 10.875 2.419 11.25 3.75H4.25c.431-1.35 6.206-3.75 11.25-3.75Zm0-22.5A7.498 7.498 0 0 0 8 7.5c0 4.144 3.356 7.5 7.5 7.5S23 11.644 23 7.5 19.644 0 15.5 0Zm0 18.75c-5.006 0-15 2.512-15 7.5V30h30v-3.75c0-4.988-9.994-7.5-15-7.5Z"
        />
        <defs>
          <linearGradient id="a" x1={0.5} x2={30.5} y1={30} y2={0} gradientUnits="userSpaceOnUse">
            <stop stopColor="#398151" />
            <stop offset={1} stopColor="#35C691" />
          </linearGradient>
        </defs>
      </svg>
    </Text>
  );
};

export default PersonIcon;
