import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const DotIcon: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        fill="currentColor"
        viewBox="0 96 960 960"
      >
        <path d="M480.191 804Q385 804 318.5 737.691q-66.5-66.308-66.5-161.5Q252 481 318.309 414.5q66.308-66.5 161.5-66.5Q575 348 641.5 414.309q66.5 66.308 66.5 161.5Q708 671 641.691 737.5q-66.308 66.5-161.5 66.5Z" />
      </svg>
    </Text>
  );
};

export default DotIcon;
