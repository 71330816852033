import {
  ClientStatus,
  DepositOrderStatus,
  MarketOrderStatusGql,
  RepresentativeStatusGql,
  TokenOrderStatusSql,
  TokenStatusGql,
  WithdrawOrderStatus,
} from "@front-packages/dfa-gql-api";
import { RepaymentStatusEnum, WalletStatusEnum } from "@front-packages/dfa-constants";
import { IBadgeProps } from "../Theme/components/Badge";

type StatusType =
  | "Company"
  | "Representative"
  | "Token"
  | "Order"
  | "MarketOrder"
  | "RedeemOrder"
  | "Withdraw"
  | "Deposit"
  | "Wallet";

const getStatusBadgeProps = (status: string, type?: StatusType): IBadgeProps => {
  switch (type) {
    case "Token":
      if (status === TokenStatusGql.Draft) return { label: "новый", color: "secondary" };
      if (status === TokenStatusGql.OnApproval) return { label: "на проверке", color: "warning" };
      if (status === TokenStatusGql.Signing) return { label: "на подписании", color: "info" };
      if (status === TokenStatusGql.Initiating) return { label: "инициализация", color: "info" };
      if (status === TokenStatusGql.Scheduled) return { label: "запланирован", color: "warning" };
      if (status === TokenStatusGql.Started) return { label: "приём заявок", color: "primary" };
      if (status === TokenStatusGql.Issued) return { label: "выпущен", color: "primary" };
      if (status === TokenStatusGql.InMarket) return { label: "торговля", color: "primary" };
      if (status === TokenStatusGql.InRedeem) return { label: "на погашении", color: "success" };
      if (status === TokenStatusGql.Redeemed) return { label: "погашен", color: "success" };
      if (status === TokenStatusGql.Canceled) return { label: "отменен", color: "error" };
      if (status === TokenStatusGql.Rejected) return { label: "отклонен", color: "error" };
      if (status === TokenStatusGql.Refund) return { label: "возврат", color: "warning" };
      return { label: "нет лэйбла", color: "error" };
    case "Order":
      if (status === TokenOrderStatusSql.Draft) return { label: "новая", color: "secondary" };
      if (status === TokenOrderStatusSql.Signing) return { label: "на подписании", color: "info" };
      if (status === TokenOrderStatusSql.Registered)
        return { label: "зарегистрирована", color: "success" };
      if (status === TokenOrderStatusSql.Scheduled)
        return { label: "запланирована", color: "warning" };
      if (status === TokenOrderStatusSql.Completed) return { label: "исполнена", color: "success" };
      if (status === TokenOrderStatusSql.Canceled) return { label: "отменена", color: "error" };
      if (status === TokenOrderStatusSql.OnReject)
        return { label: "на отклонении", color: "error" };
      if (status === TokenOrderStatusSql.Rejected) return { label: "отклонена", color: "error" };
      if (status === TokenOrderStatusSql.Refund) return { label: "возврат", color: "error" };
      if (status === TokenOrderStatusSql.RefundError)
        return { label: "ошибка возврата", color: "error" };
      return { label: "нет лэйбла", color: "error" };
    case "MarketOrder":
      if (status === MarketOrderStatusGql.SigningMarketOrderStatus)
        return { label: "на подписании", color: "info" };
      if (status === MarketOrderStatusGql.RegisteredMarketOrderStatus)
        return { label: "зарегистрирована", color: "success" };
      if (status === MarketOrderStatusGql.CompleteMarketOrderStatus)
        return { label: "исполнена", color: "success" };
      if (status === MarketOrderStatusGql.RejectMarketOrderStatus)
        return { label: "отклонена", color: "error" };
      return { label: "нет лэйбла", color: "error" };
    case "RedeemOrder":
      if (status === RepaymentStatusEnum.draft) return { label: "новая", color: "secondary" };
      if (status === RepaymentStatusEnum.signing) return { label: "на подписании", color: "info" };
      if (status === RepaymentStatusEnum.initiating) return { label: "отправлена", color: "info" };
      if (status === RepaymentStatusEnum.completed) return { label: "исполнена", color: "success" };
      if (status === RepaymentStatusEnum.canceled) return { label: "отменена", color: "error" };
      if (
        status === RepaymentStatusEnum.rejectedCore ||
        status === RepaymentStatusEnum.rejectedAML ||
        status === RepaymentStatusEnum.rejectedMiddle ||
        status === "on_reject"
      )
        return { label: "отклонена", color: "error" };
      return { label: "нет лэйбла", color: "error" };
    case "Withdraw":
      // if (status === WithdrawOrderStatus.Draft) return { label: "новый", color: "secondary" };
      if (status === WithdrawOrderStatus.Signing) return { label: "на подписании", color: "info" };
      if (status === WithdrawOrderStatus.Initiating)
        return { label: "инициализация", color: "info" };
      if (status === WithdrawOrderStatus.PaymentPreparing)
        return { label: "подготовка платежа", color: "info" };
      if (status === WithdrawOrderStatus.SentToBank) return { label: "отправлено", color: "info" };
      if (status === WithdrawOrderStatus.Completed) return { label: "исполнено", color: "warning" };
      if (
        status === WithdrawOrderStatus.RejectAml ||
        status === WithdrawOrderStatus.RejectBank ||
        status === WithdrawOrderStatus.RejectCore ||
        status === WithdrawOrderStatus.RejectMiddle
      )
        return { label: "отклонено", color: "error" };
      if (status === WithdrawOrderStatus.Cancelled) return { label: "отменено", color: "error" }; // TODO: cancelled??? должны исправить
      return { label: "нет лэйбла", color: "error" };
    case "Deposit":
      if (status === DepositOrderStatus.DraftDepositStatus)
        return { label: "Новая", color: "info" };
      if (status === DepositOrderStatus.WaitingPaymentDepositStatus)
        return { label: "Ожидает оплаты", color: "warning" };
      if (status === DepositOrderStatus.CompletedDepositStatus)
        return { label: "исполнена", color: "success" };
      if (status === DepositOrderStatus.RejectedDepositStatus)
        return { label: "отклонена", color: "error" };
      return { label: "нет лэйбла", color: "error" };
    // if (status === DepositRequestStatus.Draft) return { label: "на подписании", color: "info" };
    // if (status === DepositRequestStatus["2fa_required"])
    //   return { label: "подтверждение", color: "secondary" };
    // if (status === DepositRequestStatus.WaitingForPayment)
    //   return { label: "ожидает исполнения", color: "secondary" };
    // if (status === DepositRequestStatus.Initiating)
    //   return { label: "инициализация", color: "info" };
    // if (status === DepositRequestStatus.Completed)
    //   return { label: "исполнено", color: "success" };
    // if (
    //   status === DepositRequestStatus.RejectedAML ||
    //   status === DepositRequestStatus.RejectedCore ||
    //   status === DepositRequestStatus.RejectedMiddle
    // )
    //   return { label: "отклонено", color: "error" };
    // if (status === DepositRequestStatus.Cancelled) return { label: "отменено", color: "error" }; // TODO: Cancelled??? должны исправить
    // return { label: "нет лэйбла", color: "error" };
    case "Company":
      if (status === ClientStatus.New) return { label: "новый", color: "secondary" };
      if (status === ClientStatus.KybNeeded) return { label: "ожидает проверки", color: "info" };
      if (status === ClientStatus.KybInProcess) return { label: "на проверке", color: "warning" };
      if (status === ClientStatus.KybDone) return { label: "проверен", color: "success" };
      if (status === ClientStatus.Active) return { label: "активен", color: "success" };
      if (status === ClientStatus.Blocked) return { label: "заблокирован", color: "error" };
      if (status === ClientStatus.Deleted) return { label: "удалён", color: "error" };
      return { label: "нет лэйбла", color: "error" };
    case "Representative":
      if (status === RepresentativeStatusGql.New) return { label: "новый", color: "secondary" };
      if (status === RepresentativeStatusGql.Invited)
        return { label: "приглашен", color: "secondary" };
      if (status === RepresentativeStatusGql.KycNeeded)
        return { label: "ожидает проверки", color: "info" };
      if (status === RepresentativeStatusGql.KycInProcess)
        return { label: "идет проверка", color: "warning" };
      if (status === RepresentativeStatusGql.KycDone)
        return { label: "проверен", color: "success" };
      if (status === RepresentativeStatusGql.KycFailed)
        return { label: "отклонен", color: "error" };
      if (status === RepresentativeStatusGql.Initiating)
        return { label: "инициализация", color: "success" };
      if (status === RepresentativeStatusGql.Active) return { label: "активен", color: "success" };
      if (status === RepresentativeStatusGql.Blocked)
        return { label: "заблокирован", color: "success" };
      if (status === RepresentativeStatusGql.Deleted) return { label: "удалён", color: "success" };
      return { label: "нет лэйбла", color: "error" };
    case "Wallet":
      if (status === WalletStatusEnum.Active) return { label: "активный", color: "success" };
      if (status === WalletStatusEnum.Frozen) return { label: "заморожен", color: "warning" };
      if (status === WalletStatusEnum.Blocked) return { label: "заблокирован", color: "error" };
      if (status === WalletStatusEnum.Closed) return { label: "закрыт", color: "error" };
      return { label: "нет лэйбла", color: "error" };
    default:
      return { label: "нет лэйбла", color: "error" };
  }
};

export default getStatusBadgeProps;
