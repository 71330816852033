import { TabType, TabsBar } from "Theme/components/TabsBar";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { resetHeader, setHeader } from "store/global";
// import { Flex } from "Theme";
import { DepositOrders, PrimaryMarketOrders, SecondaryMarketOrders, WithdrawOrders } from "./tabs";

const TokensOrders: React.FC = () => {
  const dispatch = useAppDispatch();

  const [currentTab, setCurrenTab] = useState<number>(0);
  // const { businessEntityType, fullName } = useAppSelector((state) => state.customer.company);
  const { isAccountIssuer } = useAppSelector((state) => state.account);

  const tabs: Array<TabType> = [
    {
      id: 0,
      label: "Первичное приобретение",
      node: <PrimaryMarketOrders />,
    },
    {
      id: 1,
      label: "Вторичный рынок",
      node: <SecondaryMarketOrders />,
    },
    {
      id: 2,
      label: "Пополнения лицевого счета",
      node: <DepositOrders />,
    },
    {
      id: 3,
      label: "Вывод средств",
      node: <WithdrawOrders />,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeader({
        title: "Заявки",
        subTitle:
          "В этом разделе вы можете просматривать поданные заявки, управлять ими и скачивать документы",
        // subTitle: `${"Инвестор"}: ${businessEntityType} "${fullName}"`,
      })
    );
    return () => {
      dispatch(resetHeader());
    };
  }, [isAccountIssuer]);
  return <TabsBar currentTab={currentTab} setTab={setCurrenTab} tabs={tabs} align="start" />;
};

export default TokensOrders;
