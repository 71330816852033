import React from "react";
// import { CountryCodes } from "@front-packages/dfa-constants";
import { Flex, Text } from "Theme";
import { formattingPhoneNumber } from "helpers";
import { useAppSelector } from "store";

const style: any = { justify: "space-between", width: "100%", mb: 0.3 };
const RepresentativeInfo: React.FC = () => {
  const { representative } = useAppSelector((state) => state.customer);
  return (
    <Flex sx={{ mb: 1, direction: "column", width: "100%" }}>
      <Text variant="h5" sx={{ m: "0 auto", mb: 1 }}>
        Данные представителя
      </Text>
      <Flex sx={style}>
        <Text variant="h6" sx={{ size: "SM" }}>
          Телефон
        </Text>
        <Text variant="h6" sx={{ weight: "normal", size: "SM" }}>
          {formattingPhoneNumber(representative?.phoneNumber)}
        </Text>
      </Flex>
      <Flex sx={style}>
        <Text variant="h6" sx={{ size: "SM" }}>
          Email
        </Text>
        <Text variant="h6" sx={{ weight: "normal", size: "SM" }}>
          {representative?.email}
        </Text>
      </Flex>
    </Flex>
  );
};

export default RepresentativeInfo;
