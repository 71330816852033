import React from "react";
import { IIcon } from "./default";
import { Text } from "../components";

const DeleteIcon: React.FC<IIcon> = ({ color = "inherit", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} cursor={sx?.cursor || "auto"}>
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Text>
  );
};

export default DeleteIcon;
