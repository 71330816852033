import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const Success: React.FC<IIcon> = ({ color = "inherit", size = "small", contrast, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        viewBox="0 96 960 960"
        width={getSize(size, 24)}
        stroke="currentColor"
        fill="currentColor"
      >
        <path d="M480 984.131q-84.913 0-159.345-32.118t-129.491-87.177q-55.059-55.059-87.177-129.491Q71.869 660.913 71.869 576t32.118-159.345q32.118-74.432 87.177-129.491 55.059-55.059 129.491-87.177Q395.087 167.869 480 167.869q65 0 123 18.522t107.478 52.044l-66.369 67.369q-36.566-22.087-77.494-34.51Q525.687 258.87 480 258.87q-131.807 0-224.469 92.543Q162.87 443.957 162.87 576t92.661 224.587q92.66 92.543 224.467 92.543 131.806 0 224.469-92.543Q797.13 708.043 797.13 576q0-16.484-1.76-32.969-1.761-16.484-5.283-32.053l74.326-74.326q11.478 32.718 17.598 67.544 6.12 34.826 6.12 71.7 0 85.017-32.118 159.449-32.118 74.432-87.177 129.491-55.059 55.059-129.491 87.177Q564.913 984.131 480 984.131Zm-56.717-219.348L249.696 591.196l62.456-62.696 111.131 111.13 402.152-403.152 62.696 62.696-464.848 465.609Z" />
      </svg>
    </Text>
  );
};

export default Success;
