import styled from "styled-components";
import { ColorType, MarginType } from "../../types";
import { marginStyles } from "../../styles";

export interface IIconWrapProps {
  type?: ColorType;
  sx?: MarginType;
}

export const Wrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconWrap = styled.div<IIconWrapProps>`
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 20px;
  background-image: ${({ theme, type }) => theme.palette[type].gradient[3]};
  ${({ sx }) => marginStyles(sx)};
`;

IconWrap.defaultProps = {
  type: "primary",
};
