import { css, keyframes } from "styled-components";

const topDown = keyframes`
  from {
    position: absolute;
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0;
    position: relative
  }
`;
const downTop = keyframes`
  from {
    position: absolute;
    opacity: 0;
    bottom: -20px;
  }
  to {
    opacity: 1;
    bottom: 0;
    position: relative;
  }
`;
const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeIn = css`
  animation: ${opacity} 500ms;
  animation-delay: 250ms;
  animation-fill-mode: backwards;
`;

export const fadeInTop = css`
  animation: ${topDown} 500ms;
  animation-fill-mode: forwards;
`;
export const fadeInBottom = css`
  animation: ${downTop} 250ms;
  animation-delay: 250ms;
  animation-fill-mode: both;
`;
