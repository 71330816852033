import styled, { css, keyframes } from "styled-components";
import { MarginType, PositionType } from "../../types";
import { marginStyles, positionStyles } from "../../styles";

export interface ICircleLoader {
  size?: number;
  sx?: MarginType & PositionType;
  isSpinner?: boolean;
}

const spin = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`;

export const StyledCircleLoader = styled.span<ICircleLoader>`
  position: relative;
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `};
  > svg > circle {
    transition-duration: 1s;
    transform-origin: center;
    transform: rotate(-90deg);
    ${({ isSpinner }) =>
      isSpinner &&
      css`
        animation: 1s ${spin} infinite linear;
      `};
  }
  ${({ sx }) => positionStyles(sx)};
  ${({ sx }) => marginStyles(sx)};
`;
// export const StyledCircle = styled.circle<ICircle>`
//   stroke: ${({ theme, color }) => theme.palette[color].main};
//   fill: transparent;
// `;
