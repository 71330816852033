import React, { useState } from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const SliderArrow: React.FC<IIcon> = ({
  color = "inherit",
  size = "small",
  contrast,
  rotate = "right",
  sx,
}) => {
  let deg = 0;
  if (rotate === "left") deg = 180;
  const [isCursorOnArrow, setIsCursorOnArrow] = useState(false);
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        style={{ transform: `rotate(${deg}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 20 20"
        // eslint-disable-next-line no-useless-concat
        fill={isCursorOnArrow ? "currentColor" : "#F8FBFD66"}
        // fill="currentColor"
        enableBackground="new 0 0 24 24"
        onMouseEnter={() => setIsCursorOnArrow(true)}
        onMouseLeave={() => setIsCursorOnArrow(false)}
      >
        <path fill="none" d="M0 0h20v20H0z" />
        <path d="M4.59 16.59 6 18l8-8-8-8-1.41 1.41L11.17 10z" />
      </svg>
    </Text>
  );
};

export default SliderArrow;
