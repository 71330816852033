import React, { useEffect, useRef, useState } from "react";
import { horizontalScroll } from "@front-packages/dfa-helpers";
import {
  IStyledSelect,
  StyledList,
  StyledSelect,
  OpenButton,
  StyledItem,
  IStyledItem,
  WrapValue,
  SelectLabel,
} from "./Select";
import { ChevronIcon } from "../../icons";
import Checkbox from "../Checkbox";
import Text from "../Text";

export interface ISelectOption {
  value: string | number;
  description?: string | number;
}
interface IExtSelectOption extends ISelectOption, IStyledItem {
  onChange(val: string | number | Array<string | number>): void;
  multiple?: boolean;
  multipleState?: Array<string | number>;
}
const SelectOption: React.FC<IExtSelectOption> = ({
  value,
  multipleState,
  multiple,
  description,
  onChange,
  ...props
}) => {
  const handleClick = () => onChange(value);
  const handleChange = (checked?: boolean) => {
    if (checked) onChange([...multipleState, value]);
    else onChange(multipleState.filter((el) => el !== value));
  };
  return (
    <StyledItem onClick={!multiple ? handleClick : undefined} {...props}>
      {multiple ? (
        <Checkbox
          sx={{ width: "100%" }}
          name={description.toString()}
          label={description.toString()}
          checked={!!multipleState.find((el) => el === value)}
          onChange={handleChange}
        />
      ) : (
        description
      )}
    </StyledItem>
  );
};

interface ISelect extends IStyledSelect {
  onChange(val: string | number | Array<string | number>): void;
  options: Array<ISelectOption> | Array<string | number>;
  placeholder?: string;
  value?: string | number | Array<string | number>;
  hideButton?: boolean;
  required?: boolean;
  multiple?: boolean;
  hideScrollBar?: boolean;
}

export const Select: React.FC<ISelect> = ({
  value,
  multiple,
  options,
  placeholder,
  color = "primary",
  onChange,
  hideButton,
  required,
  hideScrollBar,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const input = useRef<HTMLInputElement>(null);
  const scrollBox = useRef<HTMLDivElement>(null);

  const closeOptionsList = (e) => {
    if (!input.current.contains(e.target) && input.current !== e.target) setOpen(false);
  };
  const handleChange = (val) => {
    onChange(val);
    if (!multiple) setOpen(false);
  };
  const openAndClose = () => {
    if (multiple) !open && setOpen(true);
    else setOpen(!open);
  };
  const close = () => setOpen(false);

  useEffect(() => {
    document.addEventListener("click", closeOptionsList);
    return () => {
      document.removeEventListener("click", closeOptionsList);
    };
  }, []);

  const handleScrollBox = (e: any) => horizontalScroll(e, scrollBox);

  const optionIsObject = typeof options[0] === "object";
  const currentDesc =
    optionIsObject && !multiple && value
      ? /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        options.find((el) => el.value === value)?.description
      : "";
  return (
    <StyledSelect
      ref={input}
      onClick={openAndClose}
      focus={open}
      color={color}
      colored={props?.colored}
      {...props}
    >
      <WrapValue
        ref={scrollBox}
        onWheel={handleScrollBox}
        onClick={close}
        title={currentDesc || value}
      >
        {!multiple && (currentDesc || value)}
        {multiple &&
          Array.isArray(value) &&
          value.map((el) => (
            <Text key={`option-badge-${el}`} sx={{ mr: 0.3, minWidth: "max-content" }}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore */}
              {options.find((opt) => opt.value === el).description},
            </Text>
          ))}
      </WrapValue>
      {!hideButton && (
        <OpenButton open={open} onClick={close}>
          <ChevronIcon
            rotate={open ? "up" : "down"}
            color={props.disabled ? "inherit" : color}
            size="medium"
          />
        </OpenButton>
      )}
      {placeholder && (
        <SelectLabel
          isRequired={required}
          focus={open || (Array.isArray(value) ? !!value.length : !!value)}
          color={color}
          error={props.error}
          isPointer
          disabled={props.disabled}
          colored={props?.colored}
        >
          {placeholder}
        </SelectLabel>
      )}
      <StyledList
        open={open}
        color={color}
        factor={options.length > 5 ? 5 : options.length}
        hideScrollBar={hideScrollBar}
        colored={props?.colored}
      >
        {options.map((option) => (
          <SelectOption
            color={color}
            onChange={handleChange}
            multipleState={Array.isArray(value) ? value : []}
            multiple={multiple}
            key={!optionIsObject ? `${option}-select_option` : `${option.value}-select_option`}
            value={!optionIsObject ? option : option.value}
            description={!optionIsObject ? option : option.description || option.value}
            checked={!optionIsObject ? option === value : value === option.value}
          />
        ))}
      </StyledList>
    </StyledSelect>
  );
};
