import { Dialog, Flex } from "Theme";
import PDFDoc from "components/PDFDoc";
import { base64ToPdf } from "helpers";
import React, { useRef } from "react";

interface IPDFViewDialogProps {
  onClose(): void;
  base64File: string;
}

const PDFViewDialog: React.FC<IPDFViewDialogProps> = ({ onClose, base64File }) => {
  const ref = useRef<HTMLDivElement>(null);
  const pdfDoc = useRef<Blob>(base64ToPdf(base64File));
  const pdfHref = URL.createObjectURL(pdfDoc.current);
  const onDownload = () => {
    const a = document.createElement("a");
    a.href = pdfHref;
    a.download = "depositOrderInvoice.pdf";
    a.click();
  };
  const onConfirm = () => {
    onDownload();
    onClose();
  };
  return (
    <Dialog
      open
      onClose={onClose}
      title="Счет на пополнение денежных средств"
      maxWidth="xxl"
      onConfirm={onConfirm}
      confirmText="Скачать"
      cancelText="Закрыть"
    >
      <Flex ref={ref} sx={{ overflowY: "scroll", height: "420px", width: "100%", m: "0 auto" }}>
        {pdfDoc.current && <PDFDoc pdfDoc={pdfDoc.current} />}
      </Flex>
    </Dialog>
  );
};

export default PDFViewDialog;
