import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &:focus-visible {
      outline: none;
    }
  }
  #root {
    position: relative;
  }
  body {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    min-height: 100vh;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  img {
    display: block;
    max-width: 100%;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  *::-webkit-scrollbar-track {
    background: #cccccc4d;
    border-radius: 20px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
  }
  
`;
