import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { Flex } from "Theme";
import { clearNotifications, removeNotification } from "store/notifications";
import NotificationItem from "./NotificationItem";

const GlobalNotifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const [timerId, setTimerId] = useState<any>(null);
  const { items, position } = useAppSelector((state) => state.notifications);

  useEffect(() => {
    if (items.length > 3) dispatch(removeNotification(items[0].key));
    if (items.length && !timerId) {
      const newTimerId = setTimeout(() => {
        dispatch(clearNotifications());
      }, 15000);
      setTimerId(newTimerId);
    }
    if (timerId && !items.length) {
      clearTimeout(timerId);
      setTimerId(null);
    }
  }, [items, timerId]);

  return (
    <Flex
      sx={{
        position: "fixed",
        bottom: "30px",
        [position]: "10px",
        width: "30%",
        direction: "column",
        zIndex: 100000,
      }}
    >
      {!!items.length &&
        items.map(({ key, ...el }) => (
          <NotificationItem key={`${key}${Math.random()}`} id={key} {...el} />
        ))}
    </Flex>
  );
};

export default GlobalNotifications;
