import chroma from "chroma-js";

export interface IBoxShadow {
  (
    offset: [number, number],
    radius: [number, number],
    color: string,
    opacity: number,
    inset?: string
  ): string;
}
export interface IHexToRgb {
  (color: string): string;
}
export interface ILinearGradient {
  (color: string, colorState: string, angle?: number): string;
}
export interface IPxToRem {
  (px: number, basePx?: number): string;
}
export interface IRgba {
  (color: string, opacity: number): string;
}
export interface IHelpers {
  rgba: IRgba;
  hexToRgb: IHexToRgb;
  boxShadow: IBoxShadow;
  linearGradient: ILinearGradient;
  pxToRem: IPxToRem;
}

export const pxToRem: IPxToRem = (px, basePx = 16) => `${px / basePx}rem`;
export const hexToRgb: IHexToRgb = (color) => chroma(color).rgb().join(", ");
export const rgba: IRgba = (color, opacity) => `rgba(${hexToRgb(color)}, ${opacity})`;
export const boxShadow: IBoxShadow = (offset, radius, color, opacity, inset = "") => {
  const [x, y] = offset;
  const [blur, spread] = radius;
  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity
  )}`;
};
export const linearGradient: ILinearGradient = (color, colorState, angle = 195) =>
  `linear-gradient(${angle}deg, ${color}, ${colorState})`;
export const helpers: IHelpers = {
  pxToRem,
  hexToRgb,
  rgba,
  boxShadow,
  linearGradient,
};
