import styled, { css } from "styled-components";
import { ColorType, IAdaptive, MarginType, SizeType } from "../../types";
import { adaptiveStyles, marginStyles, sizeStyles } from "../../styles";

export interface IStyledBadge extends IAdaptive {
  color?: ColorType;
  sx?: MarginType & SizeType;
  isLoading?: boolean;
  onClick?(): void;
}

export const StyledBadge = styled.span<IStyledBadge>`
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
  padding: 3px 10px;
  border-radius: 35px;
  vertical-align: baseline;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: lowercase;
  ${({ onClick }) => css`
    cursor: ${onClick && "pointer"};
  `};
  ${({ theme, color, isLoading }) => css`
    font-size: ${theme.typography.size.SM};
    background: ${color !== "white"
      ? `${theme.palette[color].main}${theme.palette.opacity[5]}`
      : `${theme.palette.white.main}${theme.palette.opacity[25]}`};
    border: ${`1px solid ${theme.palette[color].main}${theme.palette.opacity[5]}`};
    color: ${color !== "white" ? theme.palette[color].main : theme.palette.white.light};
    ${isLoading &&
    css`
      z-index: 0;
      position: relative;
      opacity: 0.3;
      height: 30px;
      color: ${theme.palette[color].main}${theme.palette.opacity[25]};
      &:after {
        content: "";
        top: 0;
        transform: translateX(100%);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        animation: slide 1s infinite 3s;
        animation-delay: 0s;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(247, 250, 252, 0) 99%,
          rgba(247, 250, 252, 0.8) 100%
        ); /* W3C */
        @keyframes slide {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      }
    `}
  `}
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

StyledBadge.defaultProps = {
  color: "primary",
};
