import { DefaultTheme } from "styled-components";

export type ShadowOptions = {
  color:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "text"
    | "white"
    | "somePrimary";
  theme: DefaultTheme;
};
export interface IShadows {
  left: string;
  primary: string;
  large: string;
}
export const shadows: IShadows = {
  primary: "0px 4px 11px #00000030",
  large: "0 25px 40px #00000015 ",
  left: "-10px 6px 15px #00000010",
};
