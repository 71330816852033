import React, { useState } from "react";
import { Token, OrdersAPI } from "@front-packages/dfa-gql-api";
import { MFADialog } from "Theme";
import { useErrors } from "hooks";
import { formattingDate } from "helpers";
import {
  useMFADialogState,
  useContractDialogState,
  useCreateDVPBillDialogState,
  CreateOrderModal,
  DVPBillModal,
  ContractModal,
} from "./Dialogs";

interface ICreateOrderProps {
  open: boolean;
  onClose(): void;
  token: Token;
}

const CreateOrder: React.FC<ICreateOrderProps> = ({ token, onClose, ...props }) => {
  const [dvpOrderID, setDvpOrderID] = useState<string>("");
  const { setError } = useErrors();
  const [amount, setAmount] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMakingContract, setIsMakingContract] = useState<boolean>(false);
  const { state, setState, getMfa, cleanMfa } = useMFADialogState();
  const {
    isChecked,
    onCheck,
    isContractModal,
    setIsContractModal,
    onCloseContractModal,
    base64Contract,
    setBase64FileContract,
  } = useContractDialogState();
  const handleCloseContractModal = () => {
    onCloseContractModal();
    setAmount(null);
  };

  const {
    isDVPBillModal,
    setIsDVPBillModal,
    isLoadingDVP,
    setIsLoadingDVP,
    onCloseDVPBillModal,
    dvpBill,
    setDvpBill,
    onDownloadDVP,
  } = useCreateDVPBillDialogState();

  const [newOrderID, setNewOrderID] = useState<string>("");

  const onCloseMainModal = () => {
    onClose();
  };

  const makeContract = async (orderID: string) => {
    const { response, error } = await OrdersAPI.GenerateDVPSalesContract({ orderID });
    if (response) {
      onCloseMainModal();
      setIsMakingContract(false);
      setIsContractModal(true);
      setBase64FileContract(response);
    }
    if (error) setError({ error, key: Math.random() });
  };

  const createOrder = async () => {
    setLoading(true);
    const { response, error } = await OrdersAPI.CreateDVPOrder({
      form: { tokenID: token?.id, amount, orderSum: token.nominal * amount },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setDvpOrderID(response?.id);
      setNewOrderID(response?.id);
      setIsMakingContract(true);
      await makeContract(response?.id);
    }
    setLoading(false);
  };

  const onSignContract = async () => {
    setIsLoadingDVP(true);
    const { response, error } = await OrdersAPI.VerifySigningSalesContract({
      sessionID: state?.mfa?.sessionID,
      code: state?.mfa?.code,
      dvpOrderID,
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      const { response: resp, error: err } = await OrdersAPI.GenerateDVPOrderInvoicePDF({
        dvpOrderID,
      });
      if (err) setError({ error: err, key: Math.random() });
      if (resp) {
        cleanMfa();
        onCloseContractModal();
        setIsDVPBillModal(true);
        setDvpBill(resp);
        setAmount(null);
      }
    }
    setIsLoadingDVP(false);
  };

  const onConfirmDVP = () => {
    onDownloadDVP(dvpBill);
    onCloseDVPBillModal();
  };

  if (isDVPBillModal) {
    return (
      <DVPBillModal
        open={isDVPBillModal}
        onClose={onCloseDVPBillModal}
        onConfirm={onConfirmDVP}
        base64File={dvpBill}
        investFinishDate={formattingDate(token?.investFinishDate)}
      />
    );
  }

  if (state?.mfa) {
    return (
      <MFADialog
        open
        withMissClick={false}
        type="info"
        maxWidth="md"
        onClose={cleanMfa}
        cancelText="назад"
        onConfirm={onSignContract}
        setCode={setState.code}
        code={state.mfa.code}
        descOperation="Идет регистрация Вашей заявки..."
        loading={isLoadingDVP}
      />
    );
  }

  if (isContractModal) {
    return (
      <ContractModal
        open={isContractModal}
        onClose={handleCloseContractModal}
        isChecked={isChecked}
        // TODO: мидл будет меняться
        onConfirm={() => getMfa("dvp_token_order", newOrderID, "CreateDVPOrder")}
        isLoading={state.loading}
        onCheck={onCheck}
        base64File={base64Contract}
      />
    );
  }
  return (
    <CreateOrderModal
      open={props.open}
      isMakingContract={isMakingContract}
      createOrder={createOrder}
      onClose={onCloseMainModal}
      amount={amount}
      setAmount={setAmount}
      token={token}
      isLoading={loading}
    />
  );
};

export default CreateOrder;
