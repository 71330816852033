import { EncodeObject } from "@cosmjs/proto-signing";
import {
  Authorship,
  MarketAPI,
  MarketOrderStatusGql,
  MarketOrderTypeGql,
} from "@front-packages/dfa-gql-api";
import { Badge, Button, ColumnsType, Flex, Paginator, RowsType, Table, Text } from "Theme";
import SigningDialog from "components/Dialogs/Signing";
import { formattingDate, getStatusBadgeProps } from "helpers";
import { useErrors, useGetChannel } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store";
import { setPageLoading } from "store/global";
import { v4 as uuidv4 } from "uuid";
import SearchForm, { FilterPropsType } from "./SearchForm";

const initialFilterProps: FilterPropsType = {
  tokenTicker: undefined,
  orderStatus: undefined,
};

type Columns =
  | "ticker"
  | "issuer"
  | "orderType"
  | "nominal"
  | "redamptionDate"
  | "amount"
  | "sum"
  | "expirationDate"
  | "status"
  | "actions"
  | "id";

const SecondaryMarketOrders: React.FC = () => {
  const dispatch = useAppDispatch();

  const { setError } = useErrors();
  const { channelId: channelID, setChannelId, getChannelId } = useGetChannel();

  const [rows, setRows] = useState<RowsType<Columns>>([]);
  const [page, setPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(null);
  const [count] = useState<number>(10);
  const [marketOrderId, setMarketOrderId] = useState<string>("");
  const [tokenID, setTokenID] = useState<string>("");

  const [filterProps, setFilterProps] = useState<FilterPropsType>(initialFilterProps);
  const memoryFilterProps = useRef<FilterPropsType>(initialFilterProps);
  const firstCount = useRef<number>(null);

  const columns = useRef<ColumnsType<Columns>>([
    { label: "тикер", field: "ticker", proportion: 4 },
    { label: "эмитент", field: "issuer", proportion: 2 },
    { label: "тип заявки", field: "orderType", proportion: 2 },
    { label: "номинал", field: "nominal", proportion: 1 },
    { label: "Дата погашения", field: "redamptionDate", proportion: 2 },
    { label: "количество", field: "amount", proportion: 1 },
    { label: "сумма", field: "sum", proportion: 2 },
    { label: "Срок действия", field: "expirationDate", proportion: 2 },
    { label: "Статус", field: "status", proportion: 2 },
    { label: "Действия", field: "actions", proportion: 2 },
  ]);

  const onOpenSigningDialog = async (ordId: string, tokId: string) => {
    await getChannelId();
    setMarketOrderId(ordId);
    setTokenID(tokId);
  };

  const oCloseSigningDialog = () => {
    setChannelId("");
    setMarketOrderId("");
    setTokenID("");
  };

  const getMarketOrders = async (opts: FilterPropsType) => {
    dispatch(setPageLoading(true));
    memoryFilterProps.current = opts;
    const { response, error } = await MarketAPI.GetMarketOrders({
      opts: {
        page,
        count,
        authorship: Authorship.Own,
        ...opts,
      },
    });
    if (error) setError({ error, key: Math.random() });
    if (response) {
      setRows(
        response?.MarketOrders?.map((m) => {
          return {
            id: m.id,
            ticker: m.tokenTicker,
            issuer: m.token?.issuer,
            orderType:
              m.orderType === MarketOrderTypeGql.BuyMarketOrderType ? (
                <Text color="success">покупка</Text>
              ) : (
                <Text color="success">продажа</Text>
              ),
            nominal: m.token?.nominal,
            redamptionDate: formattingDate(m.token?.redemptionDate),
            amount: m.tokenAmount,
            sum: m.fiatSum,
            expirationDate: formattingDate(m.expireDate),
            status: (
              <Badge
                sx={{ minHeight: "30px" }}
                // isLoading={isLoading}
                {...getStatusBadgeProps(m.status, "MarketOrder")}
              />
            ),
            actions:
              m.status === MarketOrderStatusGql.SigningMarketOrderStatus ||
              m.status === MarketOrderStatusGql.RegisteredMarketOrderStatus ? (
                <Button color="error" onClick={() => onOpenSigningDialog(m.id, m.token.id)}>
                  Отклонить
                </Button>
              ) : (
                <Text color="secondary">нет доступных действий</Text>
              ),
          };
        })
      );
      if (firstCount.current === null) firstCount.current = response.totalCount;
      setTotalCount(response.totalCount);
    }
    if (opts) dispatch(setPageLoading(false));
  };

  const getMessage = (creator: string): EncodeObject => {
    return {
      typeUrl: "/rr.bcpcore.market.MsgSendReject",
      value: {
        creator,
        port: "market",
        channelID,
        timeoutTimestamp: (Date.now() + 60000) * 1000000,
        orderId: marketOrderId,
        ibcId: uuidv4(),
      },
    };
  };

  useEffect(() => {
    getMarketOrders(memoryFilterProps.current);
  }, [count, page]);
  return (
    <>
      {!!firstCount.current && (
        <Flex sx={{ width: "100%", justify: "flex-end", mt: 2 }}>
          <SearchForm state={filterProps} setState={setFilterProps} getOrders={getMarketOrders} />
        </Flex>
      )}
      {!!firstCount.current && (
        <Flex sx={{ width: "100%", direction: "column", rowGap: 2 }}>
          <Flex sx={{ overflow: "auto", maxWidth: "100%" }}>
            <Table
              rowHeight={60}
              rowSx={{ rowStyle: "white" }}
              titleRowSx={{ rowHeight: 60 }}
              columns={columns.current}
              rows={rows}
            />
          </Flex>
          <Paginator page={page} amount={Math.ceil(totalCount / +count)} setPage={setPage} />
        </Flex>
      )}
      {!!channelID && !!marketOrderId && !!tokenID && (
        <SigningDialog
          open={!!channelID && !!marketOrderId && !!tokenID}
          close={oCloseSigningDialog}
          getMessage={getMessage}
          ids={{ orderID: marketOrderId, tokenID }}
        />
      )}
    </>
  );
};

export default SecondaryMarketOrders;
