import { Button } from "Theme";
import React, { useState } from "react";

interface IOpenModalBtnProps {
  onOpen(): Promise<void>;
}

const OpenModalBtn: React.FC<IOpenModalBtnProps> = ({ onOpen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onClick = async () => {
    setLoading(true);
    await onOpen();
    setLoading(false);
  };
  return (
    <Button loading={loading} onClick={onClick} color="primary" variant="link" sx={{ pr: 2 }}>
      Посмотреть данные
    </Button>
  );
};

export default OpenModalBtn;
