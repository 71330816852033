import { ForwardedRef } from "react";
import styled, { css } from "styled-components";
import { MarginType, PaddingType, FlexType, SizeType, IAdaptive, GridItemType } from "../../types";
import {
  paddingStyles,
  marginStyles,
  sizeStyles,
  adaptiveStyles,
  gridItemStyles,
} from "../../styles";

export interface IStyledGridItem extends IAdaptive {
  sx?: MarginType & PaddingType & FlexType & SizeType;
  flex?: boolean;
  gridArea?: GridItemType[keyof Pick<GridItemType, "gridArea">];
  placeSelf?: GridItemType[keyof Pick<GridItemType, "placeSelf">];
  flexColumnGap?: GridItemType[keyof Pick<GridItemType, "flexColumnGap">];
}

interface IStyledGridItemWithRef extends IStyledGridItem {
  ref?: ForwardedRef<HTMLDivElement>;
}

export const StyledGridItem = styled.div<IStyledGridItemWithRef>`
  ${({ sx, flex }) => css`
    display: ${flex ? "flex" : "block"};
    flex-direction: ${sx?.direction};
    justify-content: ${sx?.justify};
    align-items: ${sx?.align};
    flex: ${sx?.flex};
    flex-wrap: ${sx?.wrap};
    flex-shrink: ${sx?.shrink};
    gap: ${sx?.gap && `${sx?.gap}rem`};
    row-gap: ${sx?.rowGap && `${sx?.rowGap}rem`};
    column-gap: ${sx?.columnGap && `${sx?.columnGap}rem`};
    overflow: ${sx?.overflow};
    overflow-y: ${sx?.overflowY};
  `};
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => paddingStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ gridArea, placeSelf, flexColumnGap }) => gridItemStyles(gridArea, placeSelf, flexColumnGap)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;
