import { ForwardedRef } from "react";
import styled, { css } from "styled-components";
import {
  MarginType,
  PaddingType,
  FlexType,
  SizeType,
  BackgroundType,
  ColorType,
  SaturationType,
  BorderType,
  PositionType,
  ShadowsType,
  IAdaptive,
  CursorType,
  TypographyType,
} from "../../types";
import {
  paddingStyles,
  marginStyles,
  sizeStyles,
  bgStyles,
  borderStyles,
  positionStyles,
  adaptiveStyles,
  cursorStyles,
} from "../../styles";

export interface IStyledFlex extends IAdaptive {
  bgColor?: ColorType | "white";
  borderColor?: ColorType;
  bgType?: "contain" | "gradient" | "blur";
  bgSaturation?: SaturationType;
  rows?: number;
  animation?: any;
  sx?: MarginType &
    PaddingType &
    FlexType &
    SizeType &
    BackgroundType &
    BorderType &
    PositionType &
    Pick<TypographyType, "textAlign"> &
    CursorType &
    ShadowsType & { zIndex?: number } & {
      hover?: { transform?: string; boxShadow?: string; opacity?: string; bg?: string };
    };
}

interface IStyledFlexWithRef extends IStyledFlex {
  ref?: ForwardedRef<HTMLDivElement>;
}

export const StyledFlex = styled.div<IStyledFlexWithRef>`
  ${({ sx, bgType, bgColor, bgSaturation, theme, rows, animation }) => css`
    display: flex;
    flex-direction: ${sx?.direction};
    justify-content: ${sx?.justify};
    align-items: ${sx?.align};
    flex: ${sx?.flex};
    flex-wrap: ${sx?.wrap};
    flex-shrink: ${sx?.shrink};
    z-index: ${sx?.zIndex};
    gap: ${sx?.gap && `${sx?.gap}rem`};
    row-gap: ${sx?.rowGap && `${sx?.rowGap}rem`};
    column-gap: ${sx?.columnGap && `${sx?.columnGap}rem`};
    background-image: ${bgColor &&
    bgColor !== "white" &&
    bgType === "gradient" &&
    theme.palette[bgColor].gradient[bgSaturation]};
    background: ${bgColor &&
    bgColor !== "white" &&
    bgType === "contain" &&
    theme.palette[bgColor].main};
    background: ${bgColor === "white" && bgColor};
    background: ${bgType === "blur" && "rgba(255, 255, 255, 0.9)"};
    color: ${bgColor && theme.palette.text.contrast};
    box-shadow: ${sx?.shadow === "left" && theme.shadows.left};
    box-shadow: ${sx?.shadow === "primary" && theme.shadows.primary};
    box-shadow: ${sx?.shadow === "large" && theme.shadows.large};
    text-overflow: ${rows === 1 && "ellipsis"};
    overflow: ${rows && "hidden"};
    display: ${rows > 1 && "-webkit-box"};
    -webkit-box-orient: ${rows > 1 && "vertical"};
    overflow: ${sx?.overflow};
    overflow-y: ${sx?.overflowY};
    white-space: ${rows === 1 && "nowrap"};
    text-align: ${sx?.textAlign};
    ${animation};
    transition: all 0.2s ease-in-out;
    opacity: ${sx?.opacity};
    &:hover {
      transform: ${sx?.hover?.transform && "translateY(-0.2rem)"};
      box-shadow: ${sx?.hover?.boxShadow && "0 10px 19px #00000015"};
      opacity: ${sx?.hover?.opacity || "1"};
      background: ${sx?.hover?.bg || ""};
    }
  `};
  ${({ sx }) => marginStyles(sx)};
  ${({ sx }) => paddingStyles(sx)};
  ${({ sx }) => sizeStyles(sx)};
  ${({ sx }) => bgStyles(sx)};
  ${({ sx }) => borderStyles(sx)};
  ${({ borderColor, theme }) => css`
    border-color: ${borderColor && theme.palette[borderColor].light}5E;
  `};
  ${({ sx }) => positionStyles(sx)};
  ${({ sx }) => cursorStyles(sx)};
  ${({ adaptiveSX }) => adaptiveStyles(adaptiveSX)};
`;

StyledFlex.defaultProps = {
  bgType: "gradient",
  bgSaturation: 75,
};
