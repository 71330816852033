import React from "react";
import { DateRange, NumberRange, OrderStatusEnum } from "@front-packages/dfa-constants";
import { Checkbox, DateRangePicker, Flex, Input, SearchPanel, Select } from "Theme";
import { useAppSelector } from "store";
import { Breakpoints, OrderStatus } from "constants/in_lib";

export interface IFilterProps {
  ticker?: string;
  issuer?: string;
  hideComplete?: boolean;
  hideCanceled?: boolean;
  investorID?: string;
  amount?: NumberRange;
  orderSum?: NumberRange;
  orderStatuses?: Array<OrderStatusEnum>;
  createdDate?: DateRange;
}

interface ISearchFormProps {
  state: IFilterProps;
  setState(state: IFilterProps): void;
  getOrders(opts: IFilterProps): Promise<void>;
}

const SearchForm: React.FC<ISearchFormProps> = ({ state, setState, getOrders }) => {
  const { isAccountIssuer } = useAppSelector((store) => store.account);
  const { windowWidth } = useAppSelector((store) => store.global);
  const {
    ticker,
    issuer,
    createdDate,
    hideCanceled,
    hideComplete,
    orderStatuses,
    orderSum,
    amount,
  } = state;

  const setIssuer = (value: string) => setState({ ...state, issuer: value || undefined });
  const setTicker = (value: string) => setState({ ...state, ticker: value || undefined });
  const setOrderSumFirst = (value: number) =>
    setState({
      ...state,
      orderSum: {
        ...orderSum,
        first: value || undefined,
      },
    });
  const setOrderSumLast = (value: number) =>
    setState({
      ...state,
      orderSum: {
        ...orderSum,
        last: value || undefined,
      },
    });
  const setAmountFirst = (value: number) =>
    setState({
      ...state,
      amount: {
        ...amount,
        first: value || undefined,
      },
    });
  const setAmountLast = (value: number) =>
    setState({
      ...state,
      amount: {
        ...amount,
        last: value || undefined,
      },
    });
  const setCreatedDateDate = (value: DateRange) =>
    setState({ ...state, createdDate: value || undefined });
  const setHideCompleted = (value: boolean) =>
    setState({ ...state, hideComplete: value || undefined });
  const setHideCanceled = (value: boolean) =>
    setState({ ...state, hideCanceled: value || undefined });
  const setStatus = (value: Array<OrderStatusEnum>) =>
    setState({ ...state, orderStatuses: value?.length ? value : undefined });

  const reset = async () => {
    const resetValue = {
      ticker: undefined,
      issuer: undefined,
      hideComplete: undefined,
      hideCanceled: undefined,
      investorID: undefined,
      amount: undefined,
      tokenCount: undefined,
      orderStatuses: undefined,
      publishDate: undefined,
      handlingDate: undefined,
    };
    setState(resetValue);
    await getOrders(resetValue);
  };

  const handleGetOrders = async (newState?: any) => {
    newState ? await getOrders(newState) : await getOrders(state);
  };

  return (
    <SearchPanel
      fullwidth
      sx={{ direction: "column", mb: 1.3 }}
      onSearch={handleGetOrders}
      onReset={Object.values(state).filter((el) => !!el).length ? reset : undefined}
      hideHeaderChildren={windowWidth <= Breakpoints.tablet}
      headerChildren={
        <>
          <Input
            placeholder="Тикер"
            sx={{ height: "40px", width: "50%" }}
            value={ticker || ""}
            onChange={setTicker}
            colored
          />
          <Select
            colored
            multiple
            options={Object.entries(OrderStatus).map(([value, description]) => ({
              description,
              value,
            }))}
            placeholder="Статус"
            sx={{ maxHeight: "40px", width: "50%" }}
            value={orderStatuses?.length ? orderStatuses : undefined}
            onChange={setStatus}
          />
        </>
      }
      badgesData={{ state, setState }}
    >
      {!isAccountIssuer && (
        <Input colored placeholder="Эмитент" value={issuer || ""} onChange={setIssuer} />
      )}
      <Flex sx={{ width: "100%", justify: "space-between", gap: 1 }}>
        <Input
          sx={{ width: "50%" }}
          placeholder="Тикер"
          value={ticker || ""}
          onChange={setTicker}
          colored
        />
        <Select
          colored
          sx={{ width: "50%", maxHeight: "45.5px" }}
          multiple
          options={Object.entries(OrderStatus).map(([value, description]) => ({
            description,
            value,
          }))}
          placeholder="Статус"
          value={orderStatuses?.length ? orderStatuses : undefined}
          onChange={setStatus}
        />
      </Flex>
      <DateRangePicker
        label="Дата создания"
        dateRange={createdDate}
        setDateRange={setCreatedDateDate}
        colored
      />
      <Flex sx={{ width: "100%", justify: "space-between", columnGap: 1 }}>
        <Input
          sx={{ width: "50%" }}
          placeholder="Сумма заявки, от"
          value={orderSum?.first}
          onChange={setOrderSumFirst}
          colored
        />
        <Input
          sx={{ width: "50%" }}
          placeholder="Сумма заявки, до"
          value={orderSum?.last}
          onChange={setOrderSumLast}
          colored
        />
      </Flex>
      <Flex sx={{ width: "100%", gap: 1, justify: "space-between" }}>
        <Input
          sx={{ width: "50%" }}
          placeholder="Количество, от"
          value={amount?.first}
          onChange={setAmountFirst}
          colored
        />
        <Input
          sx={{ width: "50%" }}
          placeholder="Количество, до"
          value={amount?.last}
          onChange={setAmountLast}
          colored
        />
      </Flex>
      <Flex sx={{ gap: 2 }}>
        <Checkbox
          name="hideComplete"
          label="Скрыть исполненые"
          checked={hideComplete || false}
          onChange={setHideCompleted}
        />
        <Checkbox
          name="hideCanceled"
          label="Скрыть отменённые"
          checked={hideCanceled || false}
          onChange={setHideCanceled}
        />
      </Flex>
    </SearchPanel>
  );
};

export default SearchForm;
