import { formattingDate } from "helpers";
import React, { useState } from "react";
import { useAppSelector } from "store";
import { Alert, Button, Card, EntityProps, Flex, InfoCard } from "Theme";
import DeactivateModal from "./DeactivateModal";

const Client: React.FC = () => {
  const { company } = useAppSelector((state) => state.customer);
  const [isOpenDeactivateModal, setIsOpenDeactivateModal] = useState<boolean>(false);
  const handleOpenDeactivateModal = () =>
    !company?.isDeactivationInProgress && setIsOpenDeactivateModal(true);
  const handleCloseDeactivateModal = () => setIsOpenDeactivateModal(false);
  return (
    <>
      <Flex
        sx={{
          direction: "column",
          height: "100%",
          justify: "space-between",
          flex: "1 1 0%",
        }}
      >
        <Flex sx={{ direction: "column" }}>
          <Alert
            title="Инициирована деактивация"
            text="В системе создана заявка на деактивацию клиента. Заявка находится на рассмотрении у администратора Платформы"
            show={company?.isDeactivationInProgress}
            sx={{ mt: 1 }}
            type="error"
          />
          <Flex sx={{ mt: 1, columnGap: 2 }}>
            <InfoCard
              bgWhite
              fullWidth
              type="building"
              title="Название"
              value={`${company.businessEntityType} ${company.fullName}`}
            />
            {company.tickerSymbols && (
              <InfoCard
                bgWhite
                fullWidth
                type="diamond"
                title="Тикер"
                value={company.tickerSymbols}
              />
            )}
            <InfoCard
              bgWhite
              fullWidth
              type="hashtag"
              title="Номер"
              value="MIDDLE add return number"
            />
          </Flex>
          <Card bgWhite sx={{ rowGap: 1, mt: 1 }}>
            <EntityProps title="ИНН" value={company?.inn} />
            <EntityProps title="КПП" value={company?.kpp} />
            {company?.isCreditOrganization && (
              <EntityProps title="БИК" value={(company as any).bik} />
            )}
            <EntityProps title="ОГРН/ОГРНИП" value={company?.ogrn} />
            <EntityProps title="ОКПО" value={company?.okpo} />
            <EntityProps title="Адрес государственной регистрации" value={company?.address} />
            <EntityProps
              title="Зарегистрирован на платформе"
              value={formattingDate(company?.createdDate)}
            />
          </Card>
        </Flex>
        <Button
          onClick={handleOpenDeactivateModal}
          variant="outline"
          color="error"
          sx={{ maxWidth: "max-content", mt: 1 }}
          disabled={company?.isDeactivationInProgress || false}
          title={
            company?.isDeactivationInProgress && "У Вас уже есть активная заявка на деактивацию"
          }
        >
          Деактивировать учетную запись
        </Button>
      </Flex>
      <DeactivateModal
        isOpen={isOpenDeactivateModal}
        handleCloseDeactivateModal={handleCloseDeactivateModal}
      />
    </>
  );
};

export default Client;
