import { AccountOperationAPI } from "@front-packages/dfa-gql-api";
import { EncodeObject } from "@cosmjs/proto-signing";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { Dialog, Flex, Input, Select } from "Theme";
import { useErrors, useGetBalance } from "hooks";
import { addNotification } from "store/notifications";
import { setOpenWithdrawOrder } from "store/dialogs";
import useDialogState from "../useDialogState";
import SigningDialog from "../Signing";

function WithdrawDialog() {
  const dispatch = useAppDispatch();
  const getBalance = useGetBalance();
  const { company, paymentDetails } = useAppSelector((state) => state.customer);
  const { openWithdrawOrder } = useAppSelector((state) => state.dialogs);
  const { state, setState } = useDialogState();
  const { sum, loading } = state;
  const [selectedAccount, setSelectedAccount] = useState<string>(null);
  const [withdrawOrderID, setWithdrawOrderID] = useState<string>(null);
  const [openSign, setOpenSign] = useState<boolean>(false);
  const { setError } = useErrors();

  const handleClose = () => {
    setState.sum("");
    setState.loading(false);
    setSelectedAccount(null);
    if (openWithdrawOrder) dispatch(setOpenWithdrawOrder(false));
  };
  const closeSignDialog = () => {
    handleClose();
    setOpenSign(false);
  };

  const getMessage = (sender: string): EncodeObject => {
    return {
      typeUrl: "/rr.bcpcore.wasm.MsgInstantiateContract",
      value: {
        sender,
        name: "fiat-withdrawal",
        msg: btoa(
          JSON.stringify({
            amount: +sum,
            transaction_guid: uuidv4(),
            hold_date: 0,
          })
        ),
        ibcId: "",
      },
    };
  };

  const createWithdraw = async () => {
    setState.loading(true);
    const { response, error } = await AccountOperationAPI.CreateWithdrawOrder({
      form: {
        representativeID: company.representativeID,
        bankAccountID: selectedAccount,
        sum: +sum,
      },
    });
    if (error) {
      setState.loading(false);
      setError({ error, key: Math.random() });
      return;
    }
    if (response) {
      setWithdrawOrderID(response.id);
      dispatch(
        addNotification({
          message: "Заявка на вывод средств успешно создана",
          type: "success",
          position: "right",
        })
      );
      setOpenSign(true);
    }
  };

  if (openSign)
    return (
      <SigningDialog
        open
        close={closeSignDialog}
        getMessage={getMessage}
        ids={{ withdrawOrderID }}
        queryAfterTx={getBalance}
      />
    );

  return (
    <Dialog
      open={openWithdrawOrder}
      title="Вывод средств"
      onConfirm={createWithdraw}
      confirmText="Вывести средства"
      subTitle="Для подачи заявки на вывод средств с кошелька заполните необходимые поля и подтвердите операцию"
      onClose={handleClose}
      maxWidth="lg"
      disabled={!sum || !selectedAccount || +sum <= 0}
      loading={loading}
      type="warning"
    >
      <Flex sx={{ direction: "column", rowGap: 1, width: "100%", m: "10px 0 30px" }}>
        {/* <Flex sx={{ align: "center", columnGap: 1 }}>
          <Text sx={{ weight: "bold" }}>Адрес кошелька:</Text>
          <Text>{company?.wallet?.address || "no data"}</Text>
          <Badge
            label={company?.status || "no data"}
            color={company?.status === "Active" ? "success" : "error"}
          />
        </Flex> */}
        <Input
          placeholder="Сумма вывода"
          value={sum}
          onChange={setState.sum}
          type="number"
          error={sum && +sum <= 0 ? "Сумма должна быть больше 0" : "none"}
        />
        <Select
          value={selectedAccount}
          onChange={setSelectedAccount as any}
          options={
            paymentDetails
              ? paymentDetails.map((el) => ({
                  value: el.id,
                  description: `${el.bankName} ${el.accountNumber}`,
                }))
              : [{ description: "Нет счетов для вывода средств", value: null }]
          }
          placeholder="Выберите счет для проведения операции"
          color="warning"
        />
      </Flex>
    </Dialog>
  );
}

export default WithdrawDialog;
