import { useEffect, useState } from "react";
import { MarketAPI, MarketOrder } from "@front-packages/dfa-gql-api";
import { useErrors } from "hooks";
import { OrderModalType } from "../../types";

const initialMarketOrderData: MarketOrder = {
  authorRepresentative: null,
  authorRepresentativeID: "",
  createDate: "",
  exchangeRegNumber: "",
  expireDate: "",
  fiatPrice: null,
  id: "",
  orderType: null,
  receiverRepresentative: null,
  receiverRepresentativeID: "",
  status: null,
  token: null,
  tokenAmount: null,
  tokenCurrency: null,
  tokenTicker: "",
  updateDate: "",
};

const useOrderModalsState = () => {
  const { setError } = useErrors();

  const [marketOrderData, setMarketOrderData] = useState<MarketOrder>(initialMarketOrderData);
  const [marketOrderId, setMarketOrderId] = useState(null);
  const [isMyOrder, setIsMyOrder] = useState(false);
  const [orderModalType, setOrderModalType] = useState<OrderModalType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCloseOrderModal = () => {
    setOrderModalType(null);
    setMarketOrderId(null);
    setMarketOrderData(initialMarketOrderData);
    setIsLoading(false);
  };
  const onOpenOrderModal = (type: OrderModalType, orderId?: string, isMy?: boolean) => {
    setOrderModalType(type);
    setIsMyOrder(isMy);
    setMarketOrderId(orderId);
  };
  const getMarketOrderById = async () => {
    setIsLoading(true);
    const { response, error } = await MarketAPI.GetMarketOrderByID({ id: marketOrderId });
    if (error) setError({ error, key: Math.random() });
    if (response) setMarketOrderData(response);
    setIsLoading(false);
  };

  useEffect(() => {
    if (marketOrderId) getMarketOrderById();
  }, [marketOrderId]);

  return {
    orderModalType,
    setOrderModalType,
    onCloseOrderModal,
    onOpenOrderModal,
    // TODO: удалить, как появится АПИ
    isMyOrder,
    marketOrderId,
    setMarketOrderId,
    marketOrderData,
    isLoading,
    getMarketOrderById,
  };
};

export default useOrderModalsState;
