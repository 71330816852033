import React from "react";
import { DateRange } from "@front-packages/dfa-constants";
import { TokenStatusGql } from "@front-packages/dfa-gql-api";
import { DateRangePicker, Flex, Input, SearchPanel, Select } from "Theme";
import { useAppSelector } from "store";
import { Breakpoints, TokenStatus } from "constants/in_lib";
import { IFilterProps } from "../hooks";

interface ISearchFormProps {
  state: IFilterProps;
  setState(state: IFilterProps): void;
  getTokens(opts: IFilterProps): Promise<void>;
}

const SearchForm: React.FC<ISearchFormProps> = ({ state, setState, getTokens }) => {
  const { isAccountIssuer } = useAppSelector((store) => store.account);
  const { windowWidth } = useAppSelector((store) => store.global);
  const { completionDate, startDate, issueAmount, ticker, issuer, tokenStatuses } = state;

  const setIssuer = (value: string) => setState({ ...state, issuer: value || undefined });
  const setTicker = (value: string) => setState({ ...state, ticker: value || undefined });
  const setIssueAmountFirst = (value: number) =>
    setState({
      ...state,
      issueAmount: { ...state.issueAmount, first: value || undefined },
    });
  const setIssueAmountLast = (value: number) =>
    setState({
      ...state,
      issueAmount: { ...state.issueAmount, last: value || undefined },
    });
  const setStartDate = (value: DateRange) => setState({ ...state, startDate: value });
  const setCompletionDate = (value: DateRange) => setState({ ...state, completionDate: value });
  const setStatus = (value: Array<TokenStatusGql>) => {
    setState({ ...state, tokenStatuses: value?.length ? value : undefined });
  };

  const reset = async () => {
    const resetValue = {
      issuer: undefined,
      tokenStatuses: undefined,
      ticker: undefined,
      issueAmount: undefined,
      startDate: undefined,
      completionDate: undefined,
    };
    setState(resetValue);
    await getTokens(resetValue);
  };

  const handleGetTokens = async (newState) => {
    newState ? await getTokens(newState) : await getTokens(state);
  };

  return (
    <SearchPanel
      sx={{ direction: "column", mb: 2.3 }}
      onSearch={handleGetTokens}
      onReset={Object.values(state).filter((el) => !!el).length ? reset : undefined}
      hideHeaderChildren={windowWidth <= Breakpoints.mobile}
      headerChildren={
        <>
          <Input colored placeholder="Тикер" value={ticker || ""} onChange={setTicker} />
          {!isAccountIssuer ? (
            <Input colored placeholder="Эмитент" value={issuer || ""} onChange={setIssuer} />
          ) : (
            <Select
              colored
              multiple
              options={Object.entries(TokenStatus).map(([value, description]) => ({
                description,
                value,
              }))}
              placeholder="Статус"
              value={tokenStatuses?.length ? tokenStatuses : undefined}
              onChange={setStatus}
              sx={{ maxHeight: "45.5px" }}
            />
          )}
        </>
      }
      badgesData={{ state, setState }}
    >
      <Flex sx={{ width: "100%", gap: 1 }}>
        <Flex sx={{ flex: 1, columnGap: 1 }}>
          <Input
            fullWidth
            colored
            placeholder="Тикер"
            value={ticker || ""}
            onChange={setTicker}
            sx={{ width: "50%" }}
          />
          {!isAccountIssuer ? (
            <Input
              fullWidth
              colored
              placeholder="Эмитент"
              value={issuer || ""}
              onChange={setIssuer}
              sx={{ width: "50%" }}
            />
          ) : (
            <Select
              colored
              multiple
              fullWidth
              options={Object.entries(TokenStatus).map(([value, description]) => ({
                description,
                value,
              }))}
              placeholder="Статус"
              value={tokenStatuses?.length ? tokenStatuses : undefined}
              onChange={setStatus}
              sx={{ maxHeight: "45.5px", width: "50%" }}
            />
          )}
        </Flex>
      </Flex>
      <Flex sx={{ width: "100%", gap: 1 }}>
        <Flex sx={{ flex: 1, columnGap: 1 }}>
          <Input
            fullWidth
            colored
            placeholder="Кол-во выпусков oт"
            value={issueAmount?.first}
            onChange={setIssueAmountFirst}
          />
          <Input
            fullWidth
            colored
            placeholder="Кол-во выпусков до"
            value={issueAmount?.last}
            onChange={setIssueAmountLast}
          />
        </Flex>
      </Flex>
      <DateRangePicker
        colored
        label="Дата старта"
        dateRange={startDate}
        setDateRange={setStartDate}
      />
      <DateRangePicker
        colored
        label="Дата завершения"
        dateRange={completionDate}
        setDateRange={setCompletionDate}
      />
    </SearchPanel>
  );
};

export default SearchForm;
