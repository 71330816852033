import React from "react";
import { CloseIcon, DotIcon } from "Theme/icons";
import { IStyledBadge, StyledBadge } from "./Badge";
import { IIcon } from "../../icons/default";

export interface IBadgeProps extends IStyledBadge {
  label: string;
  Icon?: React.FC<IIcon>;
}

const Badge: React.FC<IBadgeProps> = ({ label, Icon, ...props }) => {
  const getIcon = () => {
    if (Icon && !props?.onClick) return <Icon />;
    if (!Icon && !props?.onClick) return <DotIcon />;
    return <CloseIcon color="error" size="small" />;
  };
  return (
    <StyledBadge title={label} {...props}>
      {getIcon()}
      {label}
    </StyledBadge>
  );
};

export default Badge;
