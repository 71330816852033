import { Badge, Checkbox, ColumnsType, Flex, RowsType, Table } from "Theme";
import React, { useRef, useState } from "react";

type Columns = "number" | "sum" | "createdDate" | "expirationDate" | "status" | "id";

const mockRows: RowsType<Columns> = [
  {
    id: 0,
    number: "004572301",
    sum: "10 000 RUB",
    createdDate: "14.08.2023 г.",
    expirationDate: "14.08.2023 г.",
    status: <Badge label="Ожидает подписания" color="warning" />,
  },
  {
    id: 1,
    number: "004572301",
    sum: "10 000 RUB",
    createdDate: "14.08.2023 г.",
    expirationDate: "14.08.2023 г.",
    status: <Badge label="Ожидает подписания" color="warning" />,
  },
  {
    id: 2,
    number: "004572301",
    sum: "10 000 RUB",
    createdDate: "14.08.2023 г.",
    expirationDate: "14.08.2023 г.",
    status: <Badge label="Ожидает подписания" color="warning" />,
  },
  {
    id: 3,
    number: "004572301",
    sum: "10 000 RUB",
    createdDate: "14.08.2023 г.",
    expirationDate: "14.08.2023 г.",
    status: <Badge label="Ожидает подписания" color="warning" />,
  },
];

const WithdrawOrders: React.FC = () => {
  const columns = useRef<ColumnsType<Columns>>([
    { label: "номер", field: "number", proportion: 4 },
    { label: "сумма", field: "sum", proportion: 2 },
    { label: "Дата создания", field: "createdDate", proportion: 2 },
    { label: "Срок действия", field: "expirationDate", proportion: 2 },
    { label: "Статус", field: "status", proportion: 2 },
  ]);
  const [rows] = useState<RowsType<Columns>>(mockRows);
  return (
    <Flex sx={{ width: "100%", direction: "column", rowGap: 1 }}>
      <Flex sx={{ columnGap: 2, width: "100%", mt: 2 }}>
        <Checkbox name="waiting" label="Ожидают оплаты" />
        <Checkbox name="completed" label="Исполненные" />
        <Checkbox name="rejected" label="Отклоненные" />
      </Flex>
      <Table
        rowHeight={40}
        rowSx={{ rowStyle: "white" }}
        titleRowSx={{ rowHeight: 40 }}
        columns={columns.current}
        rows={rows}
      />
    </Flex>
  );
};

export default WithdrawOrders;
