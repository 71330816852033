import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

const IconCross: React.FC<IIcon> = ({
  color = "inherit",
  light,
  size = "small",
  bold,
  contrast,
  opacity,
  sx,
}) => {
  return (
    <Text
      color={contrast ? "contrast" : color}
      sx={{ justify: "center", align: "center", ...sx }}
      light={light}
    >
      <svg
        opacity={opacity ? opacity / 100 : undefined}
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={bold ? 4 : 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </Text>
  );
};

export default IconCross;
