import React from "react";
import { IIcon, getSize } from "./default";
import { Text } from "../components";

const CloseIcon: React.FC<IIcon> = ({ color = "inherit", contrast, size, sx }) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} cursor={sx?.cursor || "auto"}>
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg> */}
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        width={getSize(size, 24)}
        height={getSize(size, 24)}
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
      </svg>
    </Text>
  );
};

export default CloseIcon;
