import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secp256k1 from "secp256k1";
import CryptoJS from "crypto-js";
import { Bip39 } from "@cosmjs/crypto";
import { randomBytes } from "crypto-browserify";
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import {
  CompaniesAPI,
  OnboardingStatusGql,
  RepresentativeStatusGql,
  RepresentativesAPI,
  TransactionsAPI,
} from "@front-packages/dfa-gql-api";
import { delay } from "@front-packages/dfa-helpers";
import { useErrors, useGetInfo } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store";
import OnBoardingInfo from "./components/OnboardingInfo";
import { setOnBoardingStep } from "../../../store/account";
import { Button, Dialog, Flex, Input, Loader, TextArea } from "../../../Theme";
import { CopyIcon } from "../../../Theme/icons";
import { downloadTextHelper } from "../../../helpers";
import { RoutesNames } from "../../../routes";

type GenerateKeysStepsType = "addPassStep" | "downloadKeysStep" | "saveMnemoStep" | "registerStep";
interface IUseSteps {
  (): React.ReactNode;
}

const useSteps: IUseSteps = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getInfo = useGetInfo();
  const { setError } = useErrors();
  const { status, id } = useAppSelector((state) => state.customer.representative || {});
  const [generateKeysSteps, setGenerateKeysSteps] = useState<GenerateKeysStepsType>(null);
  const [passPhrase, setPassPhrase] = useState<string>("");
  const [mnemoDialog, setMnemoDialog] = useState<boolean>(false);
  const [verifyPassPhrase, setVerifyPassPhrase] = useState<string>("");
  const [mnemo, setMnemo] = useState<string>("");
  const [privateKey, setPrivateKey] = useState<Uint8Array>(null);
  const [publicKey, setPublicKey] = useState<Uint8Array>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFailed, setIsFiled] = useState<boolean>(false);
  const [isResident, setIsResident] = useState<boolean>(false);
  const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState<boolean>(false);

  const handleSetPassPhrase = (val: string) => setPassPhrase(val);
  const handleSetVerifyPassPhrase = (val: string) => setVerifyPassPhrase(val);
  const closeMnemoDialog = () => setMnemoDialog(false);

  const changeRepresentativeStatus = async (s: RepresentativeStatusGql) => {
    const { response, error } = await RepresentativesAPI.ChangeRepresentativeStatus({
      representativeID: id,
      status: s,
    });
    if (error) setError({ error, key: Math.random() });
    if (response) await getInfo();
  };
  const keygen = async () => {
    let privKey;
    do {
      privKey = randomBytes(32);
    } while (!secp256k1.privateKeyVerify(privKey));
    const pubKey = secp256k1.publicKeyCreate(privKey);
    const a: any = Bip39.encode(privKey);
    setMnemo(a.data);
    const cryptoPrivKey = passPhrase
      ? CryptoJS.AES.encrypt(JSON.stringify([...privKey]), passPhrase).toString()
      : [...privKey];
    const cryptoPubKey = passPhrase
      ? CryptoJS.AES.encrypt(JSON.stringify([...pubKey]), passPhrase).toString()
      : [...pubKey];
    setPrivateKey(cryptoPrivKey as any);
    setPublicKey(cryptoPubKey as any);
    setGenerateKeysSteps("downloadKeysStep");
  };
  const handleDownloadKeys = () => {
    downloadTextHelper("dfa_private_key.stsoft", `${privateKey}:false`);
    downloadTextHelper("dfa_public_key", `${publicKey}:false`);
    setGenerateKeysSteps("saveMnemoStep");
  };
  const checkOnBoardingStatus = async (processID: string): Promise<boolean> => {
    let isCompleted = false;
    const { response, error } = await TransactionsAPI.GetOnboardingProcess({ processID });
    if (error) {
      setError({ error, key: Math.random() });
      setIsFiled(true);
      return false;
    }
    if (response.status === OnboardingStatusGql.InProgress) {
      await delay(10000);
      isCompleted = await checkOnBoardingStatus(processID);
    } else {
      isCompleted = response.status === OnboardingStatusGql.Completed;
      if (response.status === OnboardingStatusGql.Completed) setIsSuccess(true);
      if (response.status === OnboardingStatusGql.Failed) {
        setIsSuccess(false);
        setIsFiled(true);
      }
    }
    return isCompleted;
  };
  const walletInit = async (): Promise<boolean> => {
    try {
      const mnemonic: any = Bip39.encode(privateKey);
      const wallet = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic.data);
      const [account] = await wallet.getAccounts();
      const { response, error } = await TransactionsAPI.RepresentativeOnboarding({
        customerAddress: account.address,
      });
      if (error) {
        setError({ error, key: Math.random() });
        setIsFiled(true);
        return false;
      }
      if (response) await checkOnBoardingStatus(response);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const handleRegister = async () => {
    setGenerateKeysSteps("registerStep");
    const isGenerateWallet = await walletInit();
    if (isGenerateWallet) setMnemo("");
    setMnemoDialog(false);
  };
  const copyTextToClipboard = async () => {
    await navigator.clipboard.writeText(mnemo);
  };
  const getInfoForChangeStep = async (): Promise<undefined> => {
    const { response, error } = await RepresentativesAPI.GetRepresentativeInfo();
    if (error) setError({ error, key: Math.random() });
    if (response?.status === RepresentativeStatusGql.KycInProcess) {
      await delay(5000);
      return getInfoForChangeStep();
    }
    await getInfo();
    return undefined;
  };

  const goToPersonalAccount = async () => {
    navigate(RoutesNames.Briefcase);
    await getInfo();
  };

  const setIsCompanyResident = async () => {
    setIsLoadingCompanyInfo(true);
    const { response, error } = await CompaniesAPI.GetCompanyInfo();
    if (error) setError({ error, key: Math.random() });
    if (response) setIsResident(response?.isResident);
    setIsLoadingCompanyInfo(false);
  };

  useEffect(() => {
    if (status === RepresentativeStatusGql.KycNeeded) {
      setIsCompanyResident();
      dispatch(setOnBoardingStep(1));
    }
    if (status === RepresentativeStatusGql.KycInProcess) dispatch(setOnBoardingStep(2));
    if (status === RepresentativeStatusGql.KycDone && !generateKeysSteps)
      dispatch(setOnBoardingStep(3));
    if (status === RepresentativeStatusGql.KycDone && generateKeysSteps === "addPassStep")
      dispatch(setOnBoardingStep(4));
    if (status === RepresentativeStatusGql.KycDone && generateKeysSteps === "downloadKeysStep")
      dispatch(setOnBoardingStep(5));
    if (status === RepresentativeStatusGql.KycDone && generateKeysSteps === "saveMnemoStep")
      dispatch(setOnBoardingStep(6));
    if (status === RepresentativeStatusGql.KycDone && generateKeysSteps === "registerStep")
      dispatch(setOnBoardingStep(7));
  }, [status, generateKeysSteps]);

  useEffect(() => {
    if (status === RepresentativeStatusGql.KycInProcess)
      getInfoForChangeStep().catch((e) => console.error(e));
  }, [status]);

  useEffect(() => {
    if (isSuccess) dispatch(setOnBoardingStep(8));
  }, [isSuccess]);

  return [
    <OnBoardingInfo
      title="Проверка личности"
      color="info"
      errorText={
        !isLoadingCompanyInfo &&
        !isResident &&
        "Для прохождения онбординга клиент должна являться резидентом РФ"
      }
      button={{
        onClick: () => changeRepresentativeStatus(RepresentativeStatusGql.KycInProcess),
        text: "Начать проверку",
        disabled: isLoadingCompanyInfo || !isResident,
      }}
    />,
    <OnBoardingInfo color="warning">
      <Flex sx={{ width: "100%", justify: "center", mt: 5, mb: 5 }}>
        <Loader color1="warning" color2="error" size={70} />
      </Flex>
    </OnBoardingInfo>,
    <OnBoardingInfo
      color="success"
      title="Генерация ключей"
      subtitle="Для работы в системе «СМАРТ» сгенерируйте файлы ключей"
      button={{
        onClick: () => setGenerateKeysSteps("addPassStep"),
        text: "Перейти к генерации ключей",
      }}
    />,
    <OnBoardingInfo
      title="Установка парольной фразы"
      color="secondary"
      button={{ onClick: keygen, text: "Пропустить шаг" }}
    >
      <Flex sx={{ rowGap: 1, direction: "row", columnGap: 1, width: "100%" }}>
        <Input
          value={passPhrase}
          sx={{ width: "400px" }}
          onChange={handleSetPassPhrase}
          placeholder="Парольная фраза"
        />
        <Input
          sx={{ width: "400px" }}
          value={verifyPassPhrase}
          onChange={handleSetVerifyPassPhrase}
          placeholder="Подтвердите парольную фразу"
        />
      </Flex>
    </OnBoardingInfo>,
    <OnBoardingInfo
      color="success"
      title="Загрузка файлов ключей"
      button={{
        onClick: handleDownloadKeys,
        text: "Скачать файлы ключей",
      }}
    />,
    <OnBoardingInfo
      color="error"
      title="Запишите мнемофразу!"
      subtitle="Сохраните мнемофразу на физический носитель и храните ее в надежном месте. Не передавайте мнемофразу третьим лицам! После закрытия окна, доступ к мнемофразе будет закрыт!"
      button={{
        onClick: () => setMnemoDialog(true),
        text: "Завершить регистрацию",
      }}
    >
      <Flex sx={{ width: "100%", position: "relative" }}>
        <TextArea
          sx={{ width: "100%", height: "150px", weight: "bold" }}
          value={mnemo}
          color="error"
          onChange={(val: string) => setMnemo(val)}
          readonly
        />
        <Button
          onClick={copyTextToClipboard}
          color="error"
          onlyIcon
          sx={{ position: "absolute", bottom: "7px", right: "7px" }}
          iconStart={<CopyIcon color="error" size="medium" />}
        />
      </Flex>
      <Dialog
        open={mnemoDialog}
        title="Вы уверены, что сохранили мнемофразу?"
        onClose={closeMnemoDialog}
        onConfirm={handleRegister}
        type="error"
        confirmText="Да, мнемофраза сохранена"
        cancelText="Вернуться"
        subTitle="После подтверждения операции, доступ к мнемофразе будет невозможен. Буфер обмена, в который была скопирована мнемофраза, будет очищен!"
      />
    </OnBoardingInfo>,
    <OnBoardingInfo
      title={isFailed && "Ошибка при регистрации данных в блокчейн"}
      subtitle={
        isFailed &&
        "Попробуйте повторить шаги начиная с генерации ключей или обратитесь в службу поддержки"
      }
      color="warning"
      hideLogOutButton={!isFailed}
      button={
        isFailed && {
          text: "Перейти к генерации ключей",
          onClick: () => {
            dispatch(setOnBoardingStep(4));
            setIsFiled(false);
          },
        }
      }
    >
      {!isFailed && (
        <Flex sx={{ width: "100%", justify: "center" }}>
          <Loader color1="warning" color2="error" size={70} />
        </Flex>
      )}
    </OnBoardingInfo>,
    <OnBoardingInfo
      color="success"
      title="Регистрация завершена"
      button={{
        onClick: goToPersonalAccount,
        text: "Перейти в личный кабинет",
      }}
    />,
  ];
};

export default useSteps;
