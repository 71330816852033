import React, { useRef } from "react";
import { ColumnsType, Dialog, Flex, RowsType, Table, Text } from "Theme";
import { RedeemJournalsItemsColumnsType } from "../../types";

interface IRedeemJournalItemsProps {
  open: boolean;
  loading: boolean;
  rows: RowsType<RedeemJournalsItemsColumnsType>;
  onClose(): void;
  journalNumber: string;
}

const RedeemJournalItemsModal: React.FC<IRedeemJournalItemsProps> = ({
  open,
  loading,
  rows,
  onClose,
  journalNumber,
}) => {
  const columns = useRef<ColumnsType<RedeemJournalsItemsColumnsType>>([
    { label: "Инвестор", field: "investor", proportion: 4 },
    { label: "Количество токенов", field: "amount", proportion: 1 },
    { label: "Сумма", field: "sum", proportion: 1 },
  ]);
  return (
    <Dialog open={open} onClose={onClose} title="Журнал погашения Токена ЦФА" maxWidth="xxl">
      <Flex sx={{ direction: "column", gap: 1, width: "100%" }}>
        <Text color="secondary" variant="h4" sx={{ weight: "normal" }}>
          Номер журнала {journalNumber}
        </Text>
        {rows?.length ? (
          <Flex sx={{ width: "100%", maxHeight: "450px", overflowY: "scroll" }}>
            <Table
              rowHeight={40}
              columns={columns.current}
              rows={rows}
              rowSx={{ rowStyle: "white" }}
              loading={loading}
            />
          </Flex>
        ) : (
          <Text>В журнале нет записей</Text>
        )}
      </Flex>
    </Dialog>
  );
};

export default RedeemJournalItemsModal;
