import React from "react";
import { getSize, IIcon } from "./default";
import { Text } from "../components";

interface INotificationIcon extends IIcon {
  notifAmount?: number | string;
}

const Notification: React.FC<INotificationIcon> = ({
  color = "inherit",
  size = "small",
  bold,
  contrast,
  sx,
  notifAmount,
}) => {
  return (
    <Text color={contrast ? "contrast" : color} sx={{ justify: "center", align: "center", ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={getSize(size, 24)}
        viewBox="0 96 960 960"
        width={getSize(size, 24)}
        // TODO: не работает
        strokeWidth={bold ? 2 : 1}
      >
        <path
          fill="currentColor"
          d="M180 845.231v-40h80v-300q0-79.923 50.769-140.577Q361.538 304 440 288.615v-28q0-17.308 11.346-28.654T480 220.615q17.308 0 28.654 11.346T520 260.615v28Q598.462 304 649.231 364.654 700 425.308 700 505.231v300h80v40H180Zm300-280Zm0 395.384q-26.077 0-45.346-18.5-19.269-18.5-19.269-46.115h129.23q0 27.615-18.5 46.115-18.5 18.5-46.115 18.5ZM300 805.231h360v-300q0-75.231-52.385-127.616Q555.231 325.231 480 325.231t-127.615 52.384Q300 430 300 505.231v300Z"
        />
      </svg>
      {notifAmount ? (
        <Text
          sx={{
            bgColor: `red`,
            width: "15px",
            height: "15px",
            borderRadius: "20px",
            position: "absolute",
            top: 0,
            right: "-2px",
            size: "XXS",
            justify: "center",
            align: "center",
          }}
          color="white"
        >
          {notifAmount}
        </Text>
      ) : (
        ""
      )}
    </Text>
  );
};

export default Notification;
